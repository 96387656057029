<div>
  <div class="window-button-group" style="padding: 0 8px 0 0;">
    <div>
      <label class="control-label">{{'components.batch.messageLabel' | translate}} *</label>
      <textarea #message placeholder="{{'components.batch.messagePlaceholder' | translate}}" class="k-textarea" style="display:block; width:100%; margin-bottom:15px"></textarea>
    </div>
    <button class="btn btn-primary" [disabled]="!message.value || (isBusy$ | async)" (click)="create(message.value)"
      id="btn_create-and-close">
      {{'action.delete' | translate}}
    </button>
    <span *ngIf="isBusy$ | async" class="k-icon k-i-loading" style="font-size: 24px; margin-bottom: 5px"></span>
    <button class="btn btn-default" [disabled]="isBusy$ | async" style="float: right" (click)="close()" id="btn_cancel">
      {{'action.cancel' | translate}}
    </button>
  </div>
</div>