<p>
  {{ 'dialog.upload.acdchemsketch'| translate}}
</p>
<kendo-upload [saveUrl]="uploadSaveUrl" [restrictions]="fileRestrictions"
  [withCredentials]="false" 
  [multiple]="false" 
  [autoUpload]="false" 
  (complete)="completed()" 
  (error)="errorEventHandler($event)"
  (upload) = "onUpload($event)">
  <kendo-upload-messages 
    [cancel]="'upload.messages.cancel' | translate" 
    [clearSelectedFiles]="'upload.messages.clearSelectedFiles' | translate"
    [dropFilesHere]="'upload.messages.dropFilesHere' | translate"
    [headerStatusUploaded]="'upload.messages.headerStatusUploaded' | translate"
    [headerStatusUploading]="'upload.messages.headerStatusUploading' | translate"
    [invalidFileExtension]="'upload.messages.invalidFileExtension' | translate"

    [invalidMaxFileSize]="'upload.messages.invalidMaxFileSize' | translate"
    [invalidMinFileSize]="'upload.messages.invalidMinFileSize' | translate"
    [remove]="'upload.messages.remove' | translate"
    [retry]="'upload.messages.retry' | translate"
    [select]="'upload.messages.select' | translate"
    [uploadSelectedFiles]="'upload.messages.uploadSelectedFilesAndClose' | translate"></kendo-upload-messages>
</kendo-upload>
