<div class="navbar-fixed-top nav-for-content">
  <div class="sticky-header">
    <table style="width: 100%">
      <tr>
        <td *ngIf="hasContentBeforeTitle" width="1">
          <ng-content select=".left-content"></ng-content>
        </td>
        <td class="sticky-header-title">
          <div class="ellipsis" title="{{pageTitle}}">
            <i *ngIf="isLoading" class="fas fa-spinner fa-spin fa-sm"></i>
            {{pageTitle}}           
              <ng-content  *ngIf="hasContentAfterTitle"  select=".after-title-content"></ng-content>         
          </div>
        </td>
        <td class="sticky-header-buttons" style="width: auto; text-align-last: end;">
          <ng-content></ng-content>
        </td>
      </tr>
    </table>
  </div>
</div>
