import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationModule } from 'app/core/authorization/authorization.module';
import { AuthorizationGuard } from './core/authorization/authorization.guard';
import { LoginComponent } from './core/authorization/login/login.component';
import { NotFoundComponent } from './core/authorization/not-found/not-found.component';
import { UnauthorizedComponent } from './core/authorization/unauthorized/unauthorized.component';
import { SettingsComponent } from './modules/shared-area/pages/settings-page/settings.component';


const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [ AuthorizationGuard ]
  },
  {
    path: 'home',
    loadChildren: () => import('app/modules/home-area/home-area.module').then(m => m.HomeAreaModule),
    canActivate: [ AuthorizationGuard ]
  },
  {
    path: 'search',
    loadChildren: () => import('app/modules/search-area/search-area.module').then(m => m.SearchAreaModule),
    canActivate: [ AuthorizationGuard ]
  },
  {
    path: 'dossier',
    loadChildren: () => import('app/modules/dossier-area/dossier-area.module').then(m => m.DossierAreaModule),
    canActivate: [ AuthorizationGuard ]
  },
  {
    path: 'admin',
    loadChildren: () => import('app/modules/admin-area/admin-area.module').then(m => m.AdminAreaModule),
    canActivate: [ AuthorizationGuard ]
  },
  {
    path: 'feedback',
    loadChildren: () => import('app/modules/feedback-area/feedback-area.module').then(m => m.FeedbackAreaModule),
    canActivate: [ AuthorizationGuard ]
  },
  { path: '404', component: NotFoundComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    AuthorizationModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [ RouterModule ],
  providers: []
})
export class AppRoutingModule {}
