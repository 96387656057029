import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { SettingsComponent } from 'app/modules/shared-area/pages/settings-page/settings.component';
import { ActionbarModule } from 'app/shared/components/actionbar/actionbar.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    TranslateModule,
    DropDownsModule,
    ActionbarModule
  ],
  declarations: [ SettingsComponent ],
  exports: [ SettingsComponent ]
})
export class SettingsModule {}
