import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DossierAreaState } from 'app/modules/dossier-area/dossier-area.module';
import { copySachverhalteIdsSelector, copySachverhalteIsLoadingSelector, getDossier } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.selectors';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { finalize, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { Dossier } from '@models/dossier/dossier';
import { FormControl } from '@angular/forms';
import { CustomWindowService } from 'app/core/services/custom-window-service/custom-window.service';
import { DossierService } from 'app/modules/dossier-area/services/dossier/dossier.service';
import { SachverhalteKopierenSachverhaltRef } from 'app/core/services/api-client';
import { DossierDucks } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.ducks';
import { Duck } from '@ngrx-ducks/core';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { some } from 'lodash';

@Component({
  templateUrl: './copy-sachverhalte.component.html',
  styleUrls: [ 'copy-sachverhalte.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopySachverhalteComponent implements OnInit, OnDestroy {

  dataLoading$: Observable<boolean> = EMPTY;

  copySachverhalteIdsText$: Observable<string> = EMPTY;

  copySachverhalteIds$: Observable<{ sachverhaltId: number, erfassungSachverhaltId: number }[]> = EMPTY;

  dossier$: Observable<Dossier> = EMPTY;

  targetIdsFormControl = new FormControl('');

  targetIds$ = new BehaviorSubject<string[]>([]);

  targetIdSubscription = Subscription.EMPTY;

  isContainingOwnObjectId$: Observable<boolean> = EMPTY;

  isValid$: Observable<boolean> = EMPTY;

  errorIds$ = new BehaviorSubject<string[]>([]);

  successfulIds$ = new BehaviorSubject<string[]>([]);

  constructor(private store: Store<DossierAreaState>,
              public windowService: CustomWindowService,
              private dossierService: DossierService,
              @Inject(DossierDucks) private dossierDucks: Duck<DossierDucks>,
              private toastService: CustomToastyService) {}

  public ngOnInit(): void {
    this.errorIds$.next([]);
    this.successfulIds$.next([]);

    this.copySachverhalteIds$ = this.store.pipe
    (
      select(copySachverhalteIdsSelector),
    );

    this.dataLoading$ = this.store.pipe(select(copySachverhalteIsLoadingSelector));

    this.copySachverhalteIdsText$ = this.copySachverhalteIds$.pipe
    (
      map(sachverhalteIds => sachverhalteIds.map(id => id.erfassungSachverhaltId ? id.erfassungSachverhaltId : id.sachverhaltId)
                                            .join(', ')),
    );

    this.dossier$ = this.store.pipe
    (
      select(getDossier),
    );

    this.targetIdSubscription = this.targetIdsFormControl.valueChanges.pipe
                                    (
                                      map((ids: string) => ids ? ids.split(',') : []),
                                      map((ids: string[]) => ids
                                        .filter(x => !!x)
                                        .map(x => x.trim())
                                        .filter(x => !!x),
                                      ),
                                      tap(x => this.targetIds$.next(x)),
                                    )
                                    .subscribe();

    this.isContainingOwnObjectId$ = this.targetIds$.pipe
    (
      withLatestFrom(this.dossier$),
      map(([ targetIds, dossier ]) => dossier ? targetIds.indexOf(dossier.objektId) > -1 : false),
    );

    this.isValid$ = combineLatest([ this.isContainingOwnObjectId$, this.copySachverhalteIds$, this.targetIds$ ])
      .pipe
      (
        map(([ isContainingOwnObjectId, copySachverhalteIds, targetIds ]) =>
          targetIds.length > 0 && copySachverhalteIds.length > 0 && !isContainingOwnObjectId),
      );
  }

  ngOnDestroy(): void {
    this.targetIdSubscription.unsubscribe();
  }

  public async copy() {
    this.errorIds$.next([]);
    this.successfulIds$.next([]);
    const dossierId = await this.dossier$.pipe(first(), map(dossier => dossier.objektId))
                                .toPromise();
    const copySachverhalteIds = await this.copySachverhalteIds$.pipe(first())
                                          .toPromise();
    const targetIds = await this.targetIds$.pipe(first())
                                .toPromise();

    this.dossierDucks.setCopySachverhalteDialogLoading(true);
    this.dossierService.sachverhalteKopieren({
          objektId: dossierId,
          sachverhaltRefs: copySachverhalteIds.map(x => new SachverhalteKopierenSachverhaltRef({
            erfassungSachverhaltId: x.erfassungSachverhaltId,
            sachverhaltId: x.sachverhaltId,
          })),
          zielObjektIds: targetIds,
        })
        .pipe
        (
          first(),
          finalize(() => this.dossierDucks.setCopySachverhalteDialogLoading(false),
          ))
        .subscribe(items => {
          if (some(items, item => !item.success)) {
            this.errorIds$.next(items.filter(x => !x.success)
                                     .map(x => x.objektId));
            this.successfulIds$.next(items.filter(x => x.success)
                                          .map(x => x.objektId));
          } else {
            this.windowService.close();
            this.toastService.toastSuccessFormatted('components.copysachverhalte.success', 'components.copysachverhalte.success_message', {});
          }

        });

  }
}
