import { ApiState } from '@models/api-state';
import { Role } from '../user/models/role';
import { FeedbackItem, FeedbackItemState } from 'app/core/services/api-client/api-client';
import { Inject, Injectable } from '@angular/core';
import { FeedbackDucks } from 'app/modules/shared-area/ducks/feedback/feedback.ducks';
import { Duck } from '@ngrx-ducks/core';
import { FeedbackEffects } from 'app/modules/shared-area/ducks/feedback/feedback.effects';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

export interface IFeedbackListState {
  apiState: ApiState;
  items: FeedbackItem[];
  total: number;
  skip: number;
  pageSize: number;
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
}

export interface IFeedbacksState {
  ownFeedback: IFeedbackListState;
  systemFeedback: IFeedbackListState;
  stoffFeedback: IFeedbackListState;
  lookupTablesFeedback: IFeedbackListState;
  roleContext: Role;
  isStofffeedbackWindowVisible: boolean;
}

const feedbackStateTransitions = new Map<FeedbackItemState, FeedbackItemState[]>([
  [ FeedbackItemState.Open, [ FeedbackItemState.Open, FeedbackItemState.InProgress, FeedbackItemState.WontFix, FeedbackItemState.Fixed, FeedbackItemState.Deleted ] ],
  [ FeedbackItemState.InProgress, [ FeedbackItemState.InProgress, FeedbackItemState.WontFix, FeedbackItemState.Fixed, FeedbackItemState.Deleted ] ],
  [ FeedbackItemState.WontFix, [ FeedbackItemState.WontFix, FeedbackItemState.Deleted, FeedbackItemState.Archived ] ],
  [ FeedbackItemState.Fixed, [ FeedbackItemState.Fixed, FeedbackItemState.Archived ] ],
  [ FeedbackItemState.Deleted, [ FeedbackItemState.Deleted ] ],
  [ FeedbackItemState.Archived, [ FeedbackItemState.Archived ] ],
]);

@Injectable({
  providedIn: 'root',
})
export class FeedbackStore {
  public constructor(
    @Inject(FeedbackDucks) public readonly ducks: Duck<FeedbackDucks>,
    public readonly effects: FeedbackEffects,
  ) {
  }

  public validateFeedbackStateTransition(oldState: FeedbackItemState, newState: FeedbackItemState) {
    const possibleNewStates = feedbackStateTransitions.get(oldState);
    return possibleNewStates.indexOf(newState) > -1;
  }
}
