import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { NotHasRightDirective } from 'app/shared/directives/nothasright/nothasright.directive';

@NgModule({
  imports: [ AuthModule ],
  declarations: [ NotHasRightDirective ],
  exports: [ NotHasRightDirective ]
})
export class NotHasRightModule {}
