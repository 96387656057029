import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { CacheInterceptor } from 'app/core/interceptors/cache-interceptor';

@NgModule({
  imports: [ I18nModule ],
})
export class CacheInterceptorModule {
  public static forRoot(): ModuleWithProviders<CacheInterceptorModule> {
    return {
      ngModule: CacheInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CacheInterceptor,
          multi: true,
        },
      ],
    };
  }
}

