import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-actionbar',
  templateUrl: './actionbar.component.html'
})
export class ActionbarComponent implements OnInit {

  @Input() pageTitle: string;
  constructor() { }

  ngOnInit() {
  }
}
