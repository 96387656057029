import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { IMerkmalState } from './merkmal.state';
import { Merkmal, MerkmalLock } from '@models/merkmalbaum';

@Ducksify<IMerkmalState>({
  initialState: {
    lastSelectedMerkmal: null,
    merkmaleTree: [],
    apiState: 'Unknown',
    favoriteAttributes: [
      { key: 'RNAME', id: 3, type: 'Merkmal', name: 'Registriername', items: null },
      { key: 'TOHH', id: 525, type: 'Merkmal', name: 'Hauptaufnahmeweg', items: null },
    ],
    lockedMerkmale: [],
    lockedMerkmaleApiState: 'Unknown',
    selectedGsblIds: [],
  },
})
export class MerkmalDucks {
  readonly loadMerkmaleError = effect<number[]>('[Merkmal - Effect] Load Merkmale Error');
  readonly loadMerkmaleEffect = effect<{ filterByLieferant: boolean }>('[Merkmale - Effect] Load merkmale');
  readonly openSelectMerkmalDialogForBatchEffect = effect<MerkmalLock[]>('[Merkmal - Effect] Open select Merkmal Dialog for create sachverhalt');

  @Action('[Merkmal] Select Merkmal')
  public setSelectedMerkmal(state: IMerkmalState, lastSelectedMerkmal: string): typeof state {
    return {
      ...state,
      lastSelectedMerkmal,
    };
  }

  @Action('[Merkmal] Load Merkmale')
  public loadMerkmale(state: IMerkmalState): typeof state {
    return {
      ...state,
      apiState: 'Loading',
    };
  }

  @Action('[Merkmal] Load Merkmale Success')
  public loadMerkmaleSuccess(state: IMerkmalState, merkmale: Merkmal[]): typeof state {
    return {
      ...state,
      merkmaleTree: merkmale,
      apiState: 'Loaded',
    };
  }

  @Action('[Merkmal] Load locked Merkmale for Batch')
  public loadLockedMerkmale(state: IMerkmalState): typeof state {
    return {
      ...state,
      lockedMerkmaleApiState: 'Loading',
    };
  }

  @Action('[Merkmal] Load locked Merkmale for Batch (Success)')
  public loadLockedMerkmaleSuccess(state: IMerkmalState, lockedMerkmale: MerkmalLock[]): typeof state {
    return {
      ...state,
      lockedMerkmaleApiState: 'Success',
      lockedMerkmale,
    };
  }

  @Action('[Merkmal] Load locked Merkmale for Batch (Error)')
  public loadLockedMerkmaleError(state: IMerkmalState, lockedMerkmale: MerkmalLock[]): typeof state {
    return {
      ...state,
      lockedMerkmaleApiState: 'Failed',
      lockedMerkmale,
    };
  }

  @Action('[Merkmal] Set selected GSBL-IDs for Batch mode')
  setSelectedGsblIds(state: IMerkmalState, selectedGsblIds: number[]) {
    return {
      ...state,
      selectedGsblIds,
    };
  }
}

export function merkmalReducer(state: IMerkmalState, action: DucksifiedAction): typeof state {
  return reducerFrom(MerkmalDucks)(state, action);
}
