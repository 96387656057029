<div class="merge">
  <ng-container *ngIf="result$ | async as result; else loading">
    <div style="text-align: center; font-size: 1.2em; width:750px">
      <ng-container *ngIf="result.collisions.length == 0">
        <i class="fas fa-check-circle" style="color: green; font-size: 2em; display: block;"></i>
        {{'components.merge.noResults' | translate}}
      </ng-container>
      <ng-container *ngIf="result.collisions.length > 0">
        <i class="fas fa-exclamation-triangle" style="color: orange; font-size: 2em; display: block; height: 50px;"></i>
        {{'components.merge.hasResults' | translate}}
      </ng-container>
    </div>

    <table class="table table-striped" style="width:100%" *ngIf="result.collisions.length > 0">
      <thead>
        <tr>
          <th scope="col" width="1">{{'components.merge.headers.id' | translate}} </th>
          <th scope="col">{{'components.merge.headers.name' | translate}}</th>
          <th scope="col" width="1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let reference of result.collisions">
          <td>{{reference.objektId}}</td>
          <td style="max-width: 10vw">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{reference.name}}</div>
          </td>
          <td>
            <app-reference-link [reference]="reference"></app-reference-link>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-container *appHasRight="[120]">
      <table class="table table-striped" style="width:100%" *ngIf="canMerge$ | async">
        <tbody>
          <tr>

          </tr>
          <ng-container>
            <tr>
              <td colspan="3" style="max-width: 10vw">{{(result.collisions.length > 0 ?
                'components.merge.mergeAllDescription' : 'components.merge.mergeDescription') | translate}}</td>
            </tr>
            <tr *ngIf="canMerge$ | async">
              <td width="1">
                <input type="text" [(ngModel)]="additionalId" style="width: 60px; border: 1px solid #ddd" />
              </td>
              <td style="max-width: 10vw" colspan="2">
                <i class="fas fa-arrow-left" style="margin-right: 4px"></i>{{(result.collisions.length > 0 ?
                'components.merge.mergeAllAdditionalDescription' : 'components.merge.mergeAdditionalDescription') |
                translate}}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
    <table class="table table-striped" style="width: 100%">
      <tbody>
        <tr>
          <td colspan="2">
            <i class="fas fa-info-circle" style="color: #888888"></i> {{ formatIdentitiesText(result.identities.length)
            }}
            <button *ngIf="showIdentities !== true && result.identities.length > 0" class="btn btn-default btn-xs"
              style="float: right" (click)="showIdentities = true">
              <i class="far fa-eye"></i>
              {{'components.merge.showIdentities' | translate}}
            </button>
          </td>
        </tr>
        <ng-container *ngIf="showIdentities">
          <tr *ngFor="let identity of result.identities; let i = index">
            <td width="40">
              {{i + 1}}
            </td>
            <td style="max-width: 10vw">
              <div style="border: 1px solid #ddd; font-family: monospace; padding: 5px; background: white">
                <div style="color: black;" [innerHTML]="format(identity.text)">
                </div>
                <div style="color: #888; font-size: 0.8em">
                  Hash: {{identity.hash}}
                </div>
              </div>

            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <div class="window-button-group">
      <ng-container *ngIf="(canMerge$ | async)">
        <button *appHasRight="[120]" class="btn btn-primary" [disabled]="(isBusy$ | async) || (result.collisions.length === 0 && checkAdditional() === false)"
          (click)="merge()" id="btn_merge-and-close">
          {{'action.merge' | translate}}
        </button>
        <span *ngIf="isBusy$ | async" class="k-icon k-i-loading" style="font-size: 24px; margin-bottom: 5px"></span>
      </ng-container>
      <button class="btn btn-default" style="float: right" (click)="close()" id="btn_cancel">
        {{'action.close' | translate}}
      </button>
    </div>
  </ng-container>
  <ng-template #loading>
    <div *ngIf="isBusy$ | async" style="text-align: center">
      <i class="k-icon k-i-loading" style="font-size:50px; columns: #363636; margin: 20px"></i>
    </div>
  </ng-template>
</div>