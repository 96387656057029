import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { ValidationRulesState } from './validation-rules.state';
import { ValidationRule } from 'app/core/services/api-client';

@Ducksify<ValidationRulesState>({
  initialState: {
    validationRules: [],
    selectedValidationRule: null,
    isBusy: false,
  },
})
export class ValidationRulesDucks {
  public readonly getAllValidationRulesEffect = effect<void>('[Validation rules (Effect)] Load all validation rules');
  public readonly updateValidationRuleEffect = effect<ValidationRule>('[Validation rules (Effect)] Update a validation rule');
  public readonly saveValidationRuleEffect = effect<ValidationRule>('[Validation rules (Effect)] Saves a validation rule');
  public readonly deleteValidationRuleEffect = effect<string>('[Validation rules (Effect)] Deletes a validation rule');

  @Action('[Validation rules] Load all validation rules')
  public getAllValidationRules(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Validation rules] Load all validation rules (Success)')
  public getAllValidationRulesSuccess(state: ValidationRulesState, validationRules: ValidationRule[]): typeof state {
    return {
      ...state,
      isBusy: false,
      validationRules: validationRules,
    };
  }

  @Action('[Validation rules] Load all validation rules (Error)')
  public getAllValidationRulesError(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Validation rules] Update a validation rule')
  public updateValidationRule(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Validation rules] Update a validation rule (Success)')
  public updateValidationRuleSuccess(state: ValidationRulesState, rule: ValidationRule): typeof state {
    return {
      ...state,
      isBusy: false,
      validationRules: updateValidationRule(state.validationRules, rule),
    };
  }

  @Action('[Validation rules] Update a validation rule (Error)')
  public updateValidationRuleError(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Validation rules] Add new validation rule')
  public addValidationRule(state: ValidationRulesState, ruleName: string): typeof state {
    return {
      ...state,
      selectedValidationRule: addNewValidationRule(ruleName),
    };
  }

  @Action('[Validation rules] Sets the name from the selected validation rule')
  public setSelectedValidationRule(state: ValidationRulesState, ruleName: string): typeof state {
    return {
      ...state,
      selectedValidationRule: state.validationRules.filter(rule => rule.name === ruleName)[0],
    };
  }

  @Action('[Validation rules] Saves a validation rule')
  public saveValidationRule(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Validation rules] Saves a validation rule (Success)')
  public saveValidationRuleSuccess(state: ValidationRulesState, savedRule: ValidationRule): typeof state {
    return {
      ...state,
      isBusy: false,
      validationRules: saveValidationRule(state.validationRules, savedRule),
    };
  }

  @Action('[Validation rules] Saves a validation rule (Error)')
  public saveValidationRuleError(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
      validationRules: state.validationRules.filter(r => r.name !== '[new]'),
    };
  }

  @Action('[Validation rules] Deletes a validation rule')
  public deleteValidationRule(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Validation rules] Deletes a validation rule (Success)')
  public deleteValidationRuleSuccess(state: ValidationRulesState, ruleName: string): typeof state {
    return {
      ...state,
      isBusy: false,
      validationRules: state.validationRules.filter(rule => rule.name !== ruleName),
    };
  }

  @Action('[Validation rules] Deletes a validation rule (Error)')
  public deleteValidationRuleError(state: ValidationRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }
}

function updateValidationRule(rules: ValidationRule[], update: ValidationRule): ValidationRule[] {
  const r = [ ...rules ];
  const newRules = r.filter(rule => rule.name !== update.name);
  newRules.push(update);
  return newRules;
}

function addNewValidationRule(ruleName: string): ValidationRule {
  return new ValidationRule({
    name: ruleName,
    affectedFields: [],
    merkmalName: '',
    messages: null,
    rule: '',
  });
}

function saveValidationRule(rules: ValidationRule[], savedRule: ValidationRule): ValidationRule[] {
  const oldRules = [ ...rules ];
  const filteredRules = oldRules.filter(rule => rule.name !== '[new]');
  filteredRules.push(savedRule);
  return filteredRules;
}

export function validationRulesReducer(state: ValidationRulesState, action: DucksifiedAction): typeof state {
  return reducerFrom(ValidationRulesDucks)(state, action);
}
