import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CreateFeedbackItemCategory, FeedbackItem, FeedbackItemState, NachschlageTabelle, NachschlageTabelleWert, UpdateFeedbackItem, UpdateFeedbackItemState } from 'app/core/services/api-client';
import { Subscription } from 'rxjs';
import { NachschlagetabellenStore } from '../../../shared-area/ducks/nachschlagetabellen/nachschlagetabellen-store.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as _ from 'lodash';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { FeedbackService } from 'app/core/services/feedback/feedback.service';
import { FeedbackStore } from 'app/modules/shared-area/ducks/feedback/feedback-store';
import { priorityItems } from 'app/models/feedback';
import { DropdownListTextItem } from '@models/dropdownListTextItem';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { IUser } from 'app/modules/shared-area/ducks/user/models/IUser';
import { environment } from '@environments/environment';
import { ITriggerOnClose, ITriggerOnLoad } from 'app/core/services/custom-window-service/custom-window.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { LookupService } from 'app/core/services/lookup-service/lookup-service.service';

@Component({
  selector: 'app-edit-lookup-table',
  templateUrl: './edit-lookup-table.component.html',
  styleUrls: ['./edit-lookup-table.component.scss'],
})
export class EditLookupTableComponent implements OnInit, OnDestroy, ITriggerOnClose, ITriggerOnLoad {

  constructor(
    private nachschlagetabellenStore: NachschlagetabellenStore,
    private securityService: OidcSecurityService,
    private i18n: I18nService,
    private feedbackService: FeedbackService,
    private feedbackStore: FeedbackStore,
    private readonly toastyService: CustomToastyService,
    private dialogService: DialogService,
    private readonly service: LookupService
  ) {
    this.setPriorityItems();
  }

  get feedbackButtonIsVisible(): boolean {
    return !(this.userHasRight && this.selectedLookupTable.offen) && !this.feedback;
  }

  get isInClosableState() {
    return this.selectedLookupTable.offen && (this.feedback.state === FeedbackItemState.Open || this.feedback.state === FeedbackItemState.InProgress);
  }

  get getCurrentCultureTitle(): string {
    return this.supportedUICultures.find(c => c.CultureCode === this.currentCulture).Title;
  }

  @ViewChild('commentInput', { read: ElementRef })
  private commentInput: ElementRef<HTMLTextAreaElement>;

  @Input()
  public feedback = new FeedbackItem();

  public priorityListItems = priorityItems;
  public priorityItems: DropdownListTextItem[];
  public feedbackState: string[];
  public isFeedback = false;
  public isValidTable = true;
  public tableNotFoundMessage: string;
  public currentCulture: string = this.i18n.EditorCultureCode;
  public supportedUICultures = environment.supportedUILanguages;

  public loadWindow: EventEmitter<any> = new EventEmitter<any>();
  public closeWindow: EventEmitter<FeedbackItem | null> = new EventEmitter<FeedbackItem | null>();

  public selectedLookupTable: NachschlageTabelle = new NachschlageTabelle();

  public newValueInput = '';
  public newValueInputLocalized: { [key: string]: string; } = {};

  public userHasRight = false;
  private requiredUserRight = '710';
  private userDataSubscr: Subscription;

  /* Werte zusammenführen */

  public wertIdsSelected: number[] = [];
  private consolidateWithWertId: number;
  private confirmConsolidationDialog;
  public isConsolidating = false;

  ngOnInit() {
    this.userDataSubscr = this.securityService.userData$.subscribe((user: IUser) => {
      if (user) {
        this.userHasRight = user.right.includes(this.requiredUserRight);
      }
    });
    this.setFeedbackState();

    this.supportedUICultures.forEach(culture => {
      this.newValueInputLocalized[culture.CultureCode] = '';
    });
  }

  public setFeedbackState(): void {
    this.feedbackState = [];
    Object.keys(FeedbackItemState)
      .filter(state => this.canChangeState(state))
      .map(state => {
        this.feedbackState.push(state);
      });
  }

  private canChangeState(state: string) {
    const oldState = this.feedback.state || FeedbackItemState.Open;
    const newState = FeedbackItemState[state];
    return this.feedbackStore.validateFeedbackStateTransition(oldState, newState);
  }

  public initLookupTableFeedback(table: NachschlageTabelle, dataItem: FeedbackItem, isFeedback: boolean, isValidTable: boolean = true) {
    this.selectedLookupTable = table;
    this.newValueInput = dataItem.nachschlageTabelleWert;
    this.feedback = dataItem;
    this.isFeedback = isFeedback;
    this.isValidTable = isValidTable;
    if (!isValidTable) {
      this.tableNotFoundMessage = this.i18n.getLocalizedString('components.lookup.tableNotFound').replace('{{value}}', dataItem.nachschlageTabelleName);
    }
    this.loadWindow.emit();
  }

  ngOnDestroy(): void {
    this.userDataSubscr.unsubscribe();
  }

  public deleteLookupTableValue(name: string, id: number) {
    this.nachschlagetabellenStore.effects.deleteLookupTableValue(name, id)
      .then(
        () => {
          this.toastyService.toastSuccessFormatted('lookupTables.messages.deleteSuccess.title', 'lookupTables.messages.deleteSuccess.message', {});
          this.selectedLookupTable.werte = this.selectedLookupTable.werte.filter(v => v.nachschlageTabelleWertId !== id);
          this.wertIdsSelected = this.wertIdsSelected.filter(v => v !== id);
        },
      );
  }

  public addValueToLookupTable(name: string, value: string, finishTicket: boolean) {
    const newValue = new NachschlageTabelleWert({
      wert: this.newValueInput,
      wertLokalisiert: this.newValueInputLocalized
    });
    this.nachschlagetabellenStore.effects.postLookupTableValue(this.selectedLookupTable.name, newValue)
      .then(
        wert => {
          this.toastyService.toastSuccessFormatted('lookupTables.messages.createSuccess.title', 'lookupTables.messages.createSuccess.message', {});
          this.selectedLookupTable.werte.push(wert);
          this.selectedLookupTable.werte = _.orderBy(this.selectedLookupTable.werte, [text => text.wert.toLocaleLowerCase()], ['asc']);
          this.newValueInput = '';

          if (finishTicket) {
            this.feedback.state = FeedbackItemState.Fixed;
            this.updateCommonFeedback();
          }
        },
      );
  }

  openFeedbackWindow(table: string, value: string) {
    const subject = this.i18n.getLocalizedString('components.lookup.feedback.subject')
      .replace('{{table}}', table);
    const description = this.i18n.getLocalizedString('components.lookup.feedback.description')
      .replace('{{table}}', table);
    this.feedbackService.openTemplated(subject, description, CreateFeedbackItemCategory.LookupTableEntry, table, value);
  }

  public async updateCommonFeedback() {
    const item = new UpdateFeedbackItem({
      comment: this.commentInput.nativeElement.value,
      state: UpdateFeedbackItemState[this.feedback.state],
      emailExpected: this.feedback.emailExpected,
    });
    const update = <{ uid: string, item: UpdateFeedbackItem }>({
      uid: this.feedback.feedbackUid,
      item: item,
    });
    await this.feedbackStore.effects.updateFeedbackEffect(update);
    this.closeWindow.emit(this.feedback);
  }

  public updateCommonFeedbackState(state: FeedbackItemState) {
    this.feedback.state = FeedbackItemState[state];
  }

  public setPriorityItems(): void {
    this.priorityItems = [];
    this.priorityListItems.forEach((key: string) => {
      this.priorityItems.push({
        text: this.i18n.getLocalizedString('models.feedback.priorities.' + key),
        value: key,
      });
    });
  }

  public cancel() {
    this.closeWindow.emit(null);
  }

  public getLocalizedValue(wert: NachschlageTabelleWert): string {

    const localizedValue = wert.wertLokalisiert[this.currentCulture];

    return localizedValue;
  }

  toggleWert(wertId: number) {
    const index = this.wertIdsSelected.indexOf(wertId, 0);
    if (index > -1) {
      this.wertIdsSelected.splice(index, 1);
    } else {
      this.wertIdsSelected.push(wertId);
    }
    return true;
  }

  consolidateWith(wertId: number, actionTemplate: TemplateRef<string>) {
    this.consolidateWithWertId = wertId;
    this.confirmConsolidationDialog = this
      .dialogService
      .open({
        title: this
          .i18n
          .getLocalizedString('dialog.confirmConsolidatingLookupValueTitel'),
        content: this
          .i18n
          .getLocalizedString('dialog.confirmConsolidatingLookupValueMessage'),
        actions: actionTemplate,
        preventAction: () => this.isConsolidating
      });
  }

  async consolidate(): Promise<any> {
    this.isConsolidating = true;
    try {
      await this.service.consolidateTableValues(this.selectedLookupTable.name, this.consolidateWithWertId, this.wertIdsSelected).toPromise();
      if (this.selectedLookupTable.offen) {
        this.selectedLookupTable.werte = this.selectedLookupTable.werte.filter(w => this.wertIdsSelected.indexOf(w.nachschlageTabelleWertId) === -1
        || w.nachschlageTabelleWertId === this.consolidateWithWertId);
      }
      this.wertIdsSelected = [];
      this.close();
    } catch (error) {
      console.error(error);
    }
    this.isConsolidating = false;
  }

  close() {
    this.confirmConsolidationDialog.close();
  }
}
