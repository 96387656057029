<form (ngSubmit)="submit()"
      [formGroup]="form"
      class="form-horizontal form-label-left">
  <input type="hidden"
         id="isNew"
         formControlName="isNew">
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.rulesEdit.ruleName' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input kendoTextBox
             *ngIf="isNew"
             id="name"
             pattern="[A-Za-z0-9_]*"
             formControlName="name">
      <ng-container *ngIf="!isNew">
        <div style="padding-top:6px">{{rule.name}}</div>
        <input type="hidden"
               id="name"
               formControlName="name">
      </ng-container>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.rulesEdit.merkmalName' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input style="width: 100%"
             class="k-textbox"
             id="merkmalName"
             formControlName="merkmalName">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.rulesEdit.affectedFields' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input kendoTextBox
             style="width: 100%"
             id="affectedFields"
             formControlName="affectedFields">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.rulesEdit.rule' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <kendo-textbox-container style="width: 100%; height: 100px">
        <textarea kendoTextArea
                  style="resize: none"
                  res
                  id="rule"
                  formControlName="rule"></textarea>
      </kendo-textbox-container>
    </div>
  </div>
  <div class="form-group"
       *ngFor="let cultureCode of supportedUICultureCodes">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.rulesEdit.errorMessage' | translate}} ({{cultureCode}})*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <kendo-textbox-container style="width: 100%; height: 100px">
        <textarea kendoTextArea
                  style="resize: none"
                  formControlName="{{cultureCode}}"></textarea>
      </kendo-textbox-container>
    </div>
  </div>

  <div class="form-group"
       style="display: flex; justify-content: flex-end; padding:5px">

    <button id="btn_close"
            class="btn btn-default"
            (click)="cancel()">{{'action.cancel' | translate}}</button>

    <button id="btn_save"
            class="btn btn-primary"
            type="submit"
            [disabled]="form.invalid">
      <i class="fas fa-save"></i>
      {{'action.applyChanges' | translate}}</button>
  </div>

</form>
