import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { PanelDucks } from '../../ducks/panel/panel.ducks';
import { getDefaultViewName, getNamedViewsKeyValue } from '../../ducks/panel/panel.selectors';
import { IAppState } from '../../root-state';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { Duck } from '@ngrx-ducks/core';
import { UserProfileDucks } from '../../ducks/user-profile/user-profile.ducks';
import { environment } from '@environments/environment';
import { KeyValue } from '@angular/common';
import { IView } from 'app/modules/shared-area/ducks/panel/models/IView';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: [ './settings.component.css' ],
})
export class SettingsComponent implements OnInit, OnDestroy {

  public namedViewsKeyValue$: Observable<KeyValue<string, { view: IView }>[]> = EMPTY;
  public defaultView$: Observable<string>;
  private subscriptions: Subscription[] = [];
  public version: string;

  constructor(private i18n: I18nService,
    private translate: TranslateService,
    @Inject(PanelDucks) private panelDucks: Duck<PanelDucks>,
    @Inject(UserProfileDucks) private userProfileDucks: Duck<UserProfileDucks>,
    private store: Store<IAppState>) { }

  ngOnInit() {
    this.namedViewsKeyValue$ = this.store.pipe(select(getNamedViewsKeyValue));
    this.defaultView$ = this.store.pipe(select(getDefaultViewName));
    this.version = environment.version;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public setDefaultView(view: any): void {
    this.panelDucks.changeDefaultView(view.key);
    this.panelDucks.applyDefaultView();
  }

  public deleteView(viewName: string): void {
    this.panelDucks.deleteNamedView(viewName);
  }
}
