import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { combineReducers } from '@ngrx/store';
import { erfassungenReducers } from 'app/modules/shared-area/ducks/erfassungen/erfassungen.ducks';
import { panelReducer } from 'app/modules/shared-area/ducks/panel/panel.ducks';
import { IPanelState } from 'app/modules/shared-area/ducks/panel/panel.state';
import { userProfileReducers } from 'app/modules/shared-area/ducks/user-profile/user-profile.ducks';

import { userReducer } from 'app/modules/shared-area/ducks/user/user.ducks';
import { IUserState } from 'app/modules/shared-area/ducks/user/user.state';
import { sachverhaltReducer } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.ducks';
import { SachverhaltEffects, SachverhaltEffectsModule } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.effect';
import { SachverhaltState } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.state';
import { IErfassungenState } from './ducks/erfassungen/erfassungen.state';
import { merkmalReducer } from './ducks/merkmal/merkmal.ducks';
import { IMerkmalState } from './ducks/merkmal/merkmal.state';
import { SachverhaltCreateModule } from './entry-components/sachverhalt/sachverhalt-create/sachverhalt-create.module';
import { IDossiersState } from './ducks/dossiers/dossiers.state';
import { dossiersReducer } from './ducks/dossiers/dossiers.ducks';
import { IFeedbacksState } from 'app/modules/shared-area/ducks/feedback/feedback-store';
import { feedbackReducer } from 'app/modules/shared-area/ducks/feedback/feedback.ducks';
import { IStartPageInfoState } from './ducks/system-info/system-info.state';
import { systemInfoReducer } from './ducks/system-info/system-info.ducks';
import { UserProfileState } from 'app/modules/shared-area/ducks/user-profile/user-profile-store.service';
import { UserProfileEffects } from 'app/modules/shared-area/ducks/user-profile/user-profile.effects';
import { IWartungsarbeitenState } from './ducks/wartungsarbeiten/wartungsarbeiten-store.service';
import { wartungsarbeitenReducer } from './ducks/wartungsarbeiten/wartungsarbeiten.ducks';
import { WartungsarbeitenEffects } from './ducks/wartungsarbeiten/wartungsarbeiten.effects';
import {nachschlagetabellenReducer} from './ducks/nachschlagetabellen/nachschlagetabellen.ducks';
import {NachschlagetabellenEffects} from './ducks/nachschlagetabellen/nachschlagetabellen.effects';
import {INachschlageTabellenState} from './ducks/nachschlagetabellen/nachschlagetabellen-store.service';

export interface ISharedAreaState {
  userProfile: UserProfileState;
  panels: IPanelState;
  user: IUserState;
  erfassungen: IErfassungenState;
  merkmale: IMerkmalState;
  sachverhalt: SachverhaltState;
  dossiers: IDossiersState;
  feedback: IFeedbacksState;
  systemInfo: IStartPageInfoState;
  wartungsarbeiten: IWartungsarbeitenState;
  nachschlagetabellen: INachschlageTabellenState;
}

export const sharedReducers = combineReducers<ISharedAreaState>({
  userProfile: userProfileReducers,
  panels: panelReducer,
  user: userReducer,
  erfassungen: erfassungenReducers,
  merkmale: merkmalReducer,
  sachverhalt: sachverhaltReducer,
  dossiers: dossiersReducer,
  feedback: feedbackReducer,
  systemInfo: systemInfoReducer,
  wartungsarbeiten: wartungsarbeitenReducer,
  nachschlagetabellen: nachschlagetabellenReducer
});

@NgModule({
  imports: [
    SachverhaltCreateModule,
    SachverhaltEffectsModule,
    EffectsModule.forFeature([
      SachverhaltEffects,
      UserProfileEffects,
      WartungsarbeitenEffects,
      NachschlagetabellenEffects,
    ]),
  ],
})

export class SharedAreaModule {
}


