import { Injectable } from '@angular/core';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import * as _ from 'lodash';
import { isSachverhaltSubset, SachverhaltField, SachverhaltSet, SachverhaltSetEntry, isSachverhaltPrimitives, isSachverhaltPrimitive } from '../../../models/dossier/dossier-models';
import { BadRequestResult, BadRequestResultReason } from 'app/core/services/api-client';
import { of } from 'rxjs';
import { IValidationError } from 'app/core/services/validation/models/IValidationError';
import { LoadedAction } from '@ngrx-ducks/core/decorator-api/typings/action/loaded-action';

@Injectable()
export class ValidationService {

  constructor() { }

  public isNullOrEmpty(value: any): boolean {
    return !value;
  }

  public checkLookupFields(fields: SachverhaltField[], values: SachverhaltSet): string[] {
    const invalidFields = [];
    fields.forEach(field => {
      if (field.type === 'lookup' && field.multiple === true) {
        const fieldValue = this.getValue(field, values);
        if (!_.isNil(fieldValue) && isSachverhaltPrimitives(fieldValue)) {
          fieldValue.forEach(v => {
            if (!_.isNil(v) && field.options.indexOf(v as string) === -1) {
              invalidFields.push(field.key);
            }
          });
        }
      } else if (field.type === 'lookup') {
        let fieldValue = this.getValue(field, values);
        fieldValue = (_.isString(fieldValue) && _.isEmpty(fieldValue.trim())) ? undefined : fieldValue;
        if (!_.isNil(fieldValue) && isSachverhaltPrimitive(fieldValue) && field.options.indexOf(fieldValue as string) === -1) {
          invalidFields.push(field.key);
        }
      } else if (field.type === 'subset') {
        const subsetValues = this.getValue(field, values);
        if (!_.isNil(subsetValues) && isSachverhaltSubset(subsetValues)) {
          field.fields.forEach(subsetField => {
            if (subsetField.type === 'lookup') {
              subsetValues.forEach(subsetValue => {
                let fieldValue = this.getValue(subsetField, subsetValue);
                fieldValue = (_.isString(fieldValue) && _.isEmpty(fieldValue.trim())) ? undefined : fieldValue;
                if (!_.isNil(fieldValue) && isSachverhaltPrimitive(fieldValue) && subsetField.options.indexOf(fieldValue as string) === -1) {
                  invalidFields.push(`${field.key}_${subsetValues.indexOf(subsetValue)}_${subsetField.key}`);
                }
              });
            }
          });
        }
      }
    });
    return invalidFields;
  }

  public checkForRequiredFields(fields: SachverhaltField[], values: SachverhaltSet): string[] {
     const emptyFields = [];
    fields.forEach(field => {
      const fieldValue = this.getValue(field, values);
      if (field.required && this.isEmpty(fieldValue)) {
        emptyFields.push(field.key);
      }

      const sachverhaltSubset = values[field.key];
      if (field.type === 'subset' && isSachverhaltSubset(sachverhaltSubset)) {
        field.fields.forEach(subsetField => {
          sachverhaltSubset.forEach(subsetValue => {
            const subsetFieldValue = this.getValue(subsetField, subsetValue);
            const notNulls = _.valuesIn(subsetValue)
              .filter(value => !_.isNil(value));
            if (notNulls.length !== 0 && subsetField.required && this.isEmpty(subsetFieldValue)) {
              emptyFields.push(`${field.key}_${sachverhaltSubset.indexOf(subsetValue)}_${subsetField.key}`);
            }
          });
        });
      }
    });
    return emptyFields;
  }

  /*
  //aktuell nicht mehr verwendet
  public checkForFilledFields(fields: SachverhaltField[], values: SachverhaltSet): string[] {
    const filledFields = [];
    fields.forEach(field => {
      const fieldValue = this.getValue(field, values);
      if (!this.isEmpty(fieldValue) && field.type !== 'subset') {
        filledFields.push(field.key);
      }
      const sachverhaltSubset = values[ field.key ];
      if (field.type === 'subset' && isSachverhaltSubset(sachverhaltSubset)) {
        field.fields.forEach(subsetField => {
          sachverhaltSubset.forEach(subsetValue => {
            const subsetFieldValue = this.getValue(subsetField, subsetValue);
            const notNulls = _.valuesIn(subsetValue)
              .filter(value => !_.isNil(value));
            if (notNulls.length !== 0 && !this.isEmpty(subsetFieldValue)) {
              filledFields.push(`${field.key}_${sachverhaltSubset.indexOf(subsetValue)}_${subsetField.key}`);
            }
          });
        });
      }
    });
    return filledFields;
  }
  */

  public countEmptyMandatoryFields(fields: SachverhaltField[], values: SachverhaltSet): string[] {
    const emptyMandatoryFields = [];
    fields.forEach(field => {
      const fieldValue = this.getValue(field, values);
      if (field.type !== 'subset' && field.required && this.isEmpty(fieldValue)) {
        emptyMandatoryFields.push(field.key);
      }
      const sachverhaltSubset = values[field.key];
      if (field.type === 'subset' && isSachverhaltSubset(sachverhaltSubset)) {
        field.fields.forEach(subsetField => {
          sachverhaltSubset.forEach(subsetValue => {
            const subsetFieldValue = this.getValue(subsetField, subsetValue);
            const notNulls = _.valuesIn(subsetValue)
              .filter(value => !_.isNil(value));
            if (notNulls.length === 0 || this.isEmpty(subsetFieldValue)) {
              emptyMandatoryFields.push(`${field.key}_${sachverhaltSubset.indexOf(subsetValue)}_${subsetField.key}`);
            }
          });
        });
      }
    });
    return emptyMandatoryFields;
  }

  public createServerValidationErrorActionObservable(error: any, duck: LoadedAction<IServerValidationErrors>) {
    const ruleValidationError = this.getRuleValidationFailedError(error);
    return ruleValidationError
      ? of(duck.action(this.getServerValidationErrorsFromResponse(ruleValidationError)))
      : of();
  }

  public getServerValidationErrorsFromResponse(errors: IValidationError[]): IServerValidationErrors {
      return {
        summary: errors.map(data => data.errorMessage || ''),
        invalidFields: _.flatten(errors.map(data => {
          if (data.focusFields && data.focusFields.length > 0) {
            return data.focusFields.map(field => this.createFieldName(field));
          } return [];
        }
        ))
      };
  }

  public getRuleValidationFailedError(error: any): IValidationError[] | null {
    if (error instanceof BadRequestResult &&
      error.reason === BadRequestResultReason.RuleValidationFailed) {
      return error.data as IValidationError[] || [];
    }
    return null;
  }

  private createFieldName(field: {
    subsetKey: string,
    lfdSubsetNr: number,
    fieldKey: string
  }): string {
    return [field.subsetKey, isNaN(field.lfdSubsetNr) ? null : field.lfdSubsetNr - 1, field.fieldKey]
      .filter(x => !_.isNil(x) && x !== '')
      .join('_');
  }

  private getValue(field: SachverhaltField, values: SachverhaltSet): SachverhaltSetEntry {
    const value = values[field.key];
    return value;
  }

  private isEmpty(value: any): boolean {
    const result = _.isNil(value) ||
      (_.isString(value) && _.isEmpty(value.trim())) ||
      (_.isArray(value) && (_.isEmpty(value) || _.findIndex(value, (item) => _.isNil(item)) > -1));
    return result;
  }
}
