import { Component, OnInit, Inject } from '@angular/core';
import { RankingAuftragStatistik } from 'app/core/services/api-client';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MerkmalDucks } from 'app/modules/shared-area/ducks/merkmal/merkmal.ducks';
import { Duck } from '@ngrx-ducks/core';
import { Observable, EMPTY } from 'rxjs';
import { Merkmal } from '@models/merkmalbaum';
import { select, Store } from '@ngrx/store';
import { getMerkmale } from 'app/modules/shared-area/ducks/merkmal/merkmal.selectors';
import { map, first, tap, filter } from 'rxjs/operators';
import { IAppState } from 'app/modules/shared-area/root-state';
import * as _ from 'lodash';

@Component({
  selector: 'app-ranking-auftrag-result',
  templateUrl: './ranking-auftrag-result.component.html',
  styleUrls: ['./ranking-auftrag-result.component.css']
})
export class RankingAuftragResultComponent implements OnInit {

  public result: RankingAuftragStatistik;
  public gridView: GridDataResult;
  public merkmale$: Observable<Merkmal[]> = EMPTY;
  private alleMerkmale: { [key: string]: string } = {};
  public sort: SortDescriptor[] = [
    {
      field: 'key',
      dir: 'asc',
    }
  ];
  constructor(
    private store: Store<IAppState>,
    @Inject(MerkmalDucks) private merkmalDucks: Duck<MerkmalDucks>,
  ) { }

  ngOnInit() {
    this.merkmale$ = this.store.pipe
      (
        select(getMerkmale),
        map((merkmale) => {
          return merkmale;
        }),
      );

    this.merkmale$.pipe
      (
        filter(merkmale => merkmale.length > 0),
        first(),
        tap(merkmale => {
          this.flatMerkmale(merkmale, this.alleMerkmale);
        })).subscribe();

    this.merkmalDucks.loadMerkmaleEffect.dispatch({ filterByLieferant: false });
  }

  private flatMerkmale(merkmale: Merkmal[], flat: { [key: string]: string }): void {
    merkmale.forEach(m => {
      flat[m.key] = m.name;
      if (m.items && m.items.length > 0) {
        this.flatMerkmale(m.items, flat);
      }
    });
  }

  public init(result: RankingAuftragStatistik) {
    this.result = result;
    this.loadItems();
  }

  private loadItems() {
    if (this.result.factsCountByAttribute !== null) {
      const resultArray = this.getArrayFromResult(this.result.factsCountByAttribute);
      this.gridView = {
        data: orderBy(resultArray, this.sort),
        total: resultArray.length
      };
      // this.cultureCode = _.isEmpty(this.i18n.CurrentUILanguage) ? 'de-DE' : this.i18n.CurrentUILanguage.CultureCode;
    }
  }

  private getArrayFromResult(factsByAttribute: { [key: string]: number }): { key: string, value: number }[] {
    const result = [];

    for (const item in factsByAttribute) {
      if (factsByAttribute.hasOwnProperty(item)) {
        result.push({ key: item, value: factsByAttribute[item] });
      }
    }
    return result;
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }
  public getMerkmalName(key: string): string {
    return this.alleMerkmale[key] || key;
  }
}
