import { Component, OnInit, Inject } from '@angular/core';
import { ExportAuftrag } from 'app/core/services/api-client';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';

import { Duck } from '@ngrx-ducks/core';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';
import { selectedExportAuftrag, getRankingTags } from '../../pages/export-page/ducks/export-auftraege.selectors';
import { ExportAuftraegeDucks } from '../../pages/export-page/ducks/export-auftraege.ducks';

@Component({
  selector: 'app-export-auftraege-edit',
  templateUrl: './export-auftraege-edit.component.html',
  styleUrls: ['./export-auftraege-edit.component.scss']
})
export class ExportAuftraegeEditComponent implements OnInit {

  public exportAuftrag: ExportAuftrag;
  public rankingTags$: Observable<string[]>;
  public rankingTags: { name: string, checked: boolean }[] = [];
  public selectedExportAuftrag$: Observable<ExportAuftrag>;
  public exportAuftragNameRegex: RegExp = /^[a-zA-Z0-9_-]{1,255}$/;

  public isNew: boolean;
  constructor(
    private store: Store<IAppState>,
    @Inject(ExportAuftraegeDucks) private exportAuftraegeDucks: Duck<ExportAuftraegeDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
  ) {
  }

  ngOnInit() {

    this.exportAuftraegeDucks.getRankingTagsEffect.dispatch();
    this.rankingTags$ = this.store.pipe(select(getRankingTags));

    this.rankingTags$.subscribe(rankingTags => {
      if (rankingTags && rankingTags.length > 0) {
        this.rankingTags = [];
        rankingTags.map(tag => this.rankingTags.push({ name: tag, checked: false }));
      }
    });

    this.selectedExportAuftrag$ = this.store.pipe(select(selectedExportAuftrag));
    this.selectedExportAuftrag$.subscribe(exportAuftrag => {
      if (exportAuftrag) {
        this.exportAuftrag = exportAuftrag;
        this.isNew = exportAuftrag.name === '[new]';
      }
    });
  }

  public submit() {
    this.appendCheckedRankings();
    if (this.isNew) {
      this.exportAuftraegeDucks.saveExportAuftragEffect.dispatch(this.exportAuftrag);
    } else {
      this.exportAuftraegeDucks.updateExportAuftragEffect.dispatch(this.exportAuftrag);
    }
  }

  public cancel() {
    this.windowDucks.closeWindow.dispatch();
  }

  public get isValid() {
    return !_.isEmpty(this.exportAuftrag.name) && this.exportAuftragNameRegex.test(this.exportAuftrag.name);
  }

  private appendCheckedRankings() {
    this.exportAuftrag.rankingTags = this.rankingTags.filter(t => t.checked).map(n => n.name);
  }
}
