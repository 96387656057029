import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiClientModule } from 'app/core/services/api-client/api-client.module';
import { ApiErrorHandlerServiceModule } from 'app/core/services/api-default-error-handler/api-error-handler.service.module';
import { BenutzerprofilService } from 'app/core/services/benutzerprofil/benutzerprofil.service';

@NgModule({
  imports: [
    // HttpClientModule.forChild(), // Interceptors does not work when specified
    // ToastaModule.forChild(),
    ApiErrorHandlerServiceModule,
    ApiClientModule
  ],
})
export class BenutzerprofilModule {
  public static forRoot(): ModuleWithProviders<BenutzerprofilModule> {
    return {
      ngModule: BenutzerprofilModule,
      providers: [ BenutzerprofilService ],
    };
  }
}
