import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { DossierHelperModule } from 'app/modules/dossier-area/services/dossier-helper/dossier-helper.module';
import { ReferencesDialogComponent } from './references-dialog.component';
import { ReferenceLinkModule } from '../reference-link/reference-link.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    I18nModule,
    DossierHelperModule,
    ReferenceLinkModule
  ],
  declarations: [ ReferencesDialogComponent ],
  exports: [ ReferencesDialogComponent ]
})
export class ReferencesDialogModule {}
