import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { IVisiblePanels } from 'app/modules/shared-area/ducks/panel/models/IVisiblePanels';
import {
  getDossierView,
  getFeedbackPanels,
  getSearchPanels,
  getConfigurationsPanels,
  getPsatzPanels
} from 'app/modules/shared-area/ducks/panel/panel.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import * as _ from 'lodash';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { IView } from 'app/modules/shared-area/ducks/panel/models/IView';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[isPanelVisible]',
})
export class IsPanelVisibleDirective implements OnDestroy, AfterViewInit {

  @Input() isPanelVisible: string;

  private dossierView$: Observable<IView> = EMPTY;
  private visibleSearchPanels$: Observable<IVisiblePanels> = EMPTY;
  private visibleFeedbackPanels$: Observable<IVisiblePanels> = EMPTY;
  private visibleConfigurationsPanels$: Observable<IVisiblePanels> = EMPTY;
  private visiblePsatzPanels$: Observable<IVisiblePanels> = EMPTY;
  private searchPanelSubscription: Subscription;
  private dossierPanelSubscription: Subscription;
  private feedbackPanelSubscription: Subscription;
  private configurationsPanelSubscription: Subscription;
  private psatzPanelSubscription: Subscription;

  constructor(
    private element: ElementRef<HTMLElement>,
    private store: Store<IAppState>,
    private i18n: I18nService,
  ) { }

  ngOnDestroy(): void {
    this.searchPanelSubscription.unsubscribe();
    this.dossierPanelSubscription.unsubscribe();
    this.feedbackPanelSubscription.unsubscribe();
    this.configurationsPanelSubscription.unsubscribe();
    this.psatzPanelSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    const tag = this.element.nativeElement;

    this.dossierView$ = this.store.pipe(select(getDossierView));
    this.visibleSearchPanels$ = this.store.pipe(select(getSearchPanels));
    this.visibleFeedbackPanels$ = this.store.pipe(select(getFeedbackPanels));
    this.visibleConfigurationsPanels$ = this.store.pipe(select(getConfigurationsPanels));
    this.visiblePsatzPanels$ = this.store.pipe(select(getPsatzPanels));

    this.searchPanelSubscription = this.visibleSearchPanels$.subscribe(
      panels => {
        if (this.isPanelVisible.indexOf('search_') === 0) {
          const isVisible = this.getVisibility(panels, this.isPanelVisible);
          switch (tag.tagName.toUpperCase()) {
            case 'DIV':
              this.updateDIV(tag, isVisible);
              break;
            case 'I':
              this.updateI(tag, isVisible);
              break;
            case 'A':
              this.updateA(tag, isVisible);
              break;
            default:
              throw new Error('invalid TAG for Directive');
          }
        }
      },
    );


    this.dossierPanelSubscription = this.dossierView$.subscribe(
      view => {
        if (this.isPanelVisible.indexOf('search_') !== 0 && this.isPanelVisible.indexOf('feedback_') !== 0 && this.isPanelVisible.indexOf('configurations_') !== 0) {
          const isVisible = this.getVisibility(view.panels, this.isPanelVisible, view.openAllPanels);
          // console.log('dossierPanels has changed: ' + this.isPanelVisible + ' => ' + isVisible);
          switch (tag.tagName.toUpperCase()) {
            case 'DIV':
              this.updateDIV(tag, isVisible);
              break;
            case 'I':
              this.updateI(tag, isVisible);
              break;
            case 'A':
              this.updateA(tag, isVisible);
              break;
            default:
              throw new Error('invalid TAG for Directive');
          }
        }
      },
    );

    this.feedbackPanelSubscription = this.visibleFeedbackPanels$.subscribe(
      panels => {
        if (this.isPanelVisible.indexOf('feedback_') === 0) {
          const isVisible = this.getVisibility(panels, this.isPanelVisible);
          switch (tag.tagName.toUpperCase()) {
            case 'DIV':
              this.updateDIV(tag, isVisible);
              break;
            case 'I':
              this.updateI(tag, isVisible);
              break;
            case 'A':
              this.updateA(tag, isVisible);
              break;
            default:
              throw new Error('invalid TAG for Directive');
          }
        }
      },
    );


    this.configurationsPanelSubscription = this.visibleConfigurationsPanels$.subscribe(
      panels => {
        if (this.isPanelVisible.indexOf('configurations_') === 0) {
          const isVisible = this.getVisibility(panels, this.isPanelVisible);
          // console.log('searchPanels has changed: ' + this.isPanelVisible + ' => ' + isVisible);
          switch (tag.tagName.toUpperCase()) {
            case 'DIV':
              this.updateDIV(tag, isVisible);
              break;
            case 'I':
              this.updateI(tag, isVisible);
              break;
            case 'A':
              this.updateA(tag, isVisible);
              break;
            default:
              throw new Error('invalid TAG for Directive');
          }
        }
      },
    );

    this.psatzPanelSubscription = this.visiblePsatzPanels$.subscribe(
      panels => {
        if (this.isPanelVisible.indexOf('psatz_') === 0) {
          const isVisible = this.getVisibility(panels, this.isPanelVisible);
          // console.log('searchPanels has changed: ' + this.isPanelVisible + ' => ' + isVisible);
          switch (tag.tagName.toUpperCase()) {
            case 'DIV':
              this.updateDIV(tag, isVisible);
              break;
            case 'I':
              this.updateI(tag, isVisible);
              break;
            case 'A':
              this.updateA(tag, isVisible);
              break;
            default:
              throw new Error('invalid TAG for Directive');
          }
        }
      },
    );


  }



  private updateI(tag: HTMLElement, isVisible: boolean) {
    tag.classList.remove('fas', 'fa-chevron-up', 'fa-chevron-down');
    tag.classList.add('fas');
    tag.classList.add(isVisible ? 'fa-chevron-up' : 'fa-chevron-down');
  }

  private updateA(tag: HTMLElement, isVisible: boolean) {
    tag.title = isVisible
      ? this.i18n.getLocalizedString('action.minimize')
      : this.i18n.getLocalizedString('action.expand');
  }

  private updateDIV(tag: HTMLElement, isVisible: boolean) {
    switch (tag.className) {
      case 'x_panel':
      case 'x_panel_root':
        tag.style.height = isVisible ? '' : 'auto';
        break;
      case 'x_content':
      case 'panel-collapse collapse in':
        tag.style.display = isVisible ? 'block' : 'none';
        break;
      default:
        console.log(tag, tag.className);
    }
  }

  private getVisibility(panelState: IVisiblePanels, selector: string, openAllPanels = false): boolean {
    let visibility = openAllPanels;
    const panel = panelState[selector];
    if (!_.isNil(panel)) {
      visibility = <boolean>panel;
    }
    return visibility;
  }
}
