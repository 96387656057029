import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { IStartPageInfoState } from './system-info.state';
import { ISystemInfo } from 'app/core/services/api-client';

@Ducksify<IStartPageInfoState>({
  initialState: {
    apiState: 'Unknown',
    systemMessage: null,
    maintenanceWindows: [],
  },
})
export class SystemInfoDucks {
  public readonly loadSystemInfoEffect = effect('[SystemInfo] load the current system information');

  @Action('[Startpage SystemInfo - Effect] Load System Info')
  public loadSystemInfo(state: IStartPageInfoState): typeof state {
    return {
      ...state,
      apiState: 'Loading',
    };
  }

  @Action('[Startpage SystemInfo] Load System Info Success')
  public loadSystemInfoSuccess(state: IStartPageInfoState, systemInfo: ISystemInfo): typeof state {
    return {
      ...state,
      systemMessage: systemInfo.systemMessage,
      maintenanceWindows: systemInfo.maintenanceWindows,
      apiState: 'Success',
    };
  }

  @Action('[Startpage SystemInfo] Load System Info Error')
  public loadSystemInfoError(state: IStartPageInfoState): typeof state {
    return {
      ...state,
      systemMessage: null,
      maintenanceWindows: [],
      apiState: 'Failed',
    };
  }
}

export function systemInfoReducer(state: IStartPageInfoState, action: DucksifiedAction): typeof state {
  return reducerFrom(SystemInfoDucks)(state, action);
}
