import { NgModule } from '@angular/core';
import { StruktursucheDialogComponent } from 'app/modules/search-area/entry-components/struktursuche-dialog/struktursuche-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [ CommonModule, FormsModule, RouterModule ],
  declarations: [ StruktursucheDialogComponent ],
  exports: [ StruktursucheDialogComponent ],
})
export class StruktursucheDialogModule {}
