import { Injectable } from '@angular/core';
import { WindowCloseResult, WindowService, WindowRef, WindowComponent } from '@progress/kendo-angular-dialog';
import { WindowSettings } from '@progress/kendo-angular-dialog';
import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';

/**
 * cancel method: will be called on WindowClose (click close button on windowFrame)
 */
export interface ICanCancel {
  cancel: (event?: any) => void;
}

/**
 * event emitter is triggered on WindowLoadComplete event: can be used by the window service to initialize window auto sizing based on content
 */
export interface ITriggerOnLoad {
  loadWindow: EventEmitter<any>;
}

/**
 * event emitter is triggered on WindowClose event: can be used to detect type of close (cancel, save ..)
 */
export interface ITriggerOnClose {
  closeWindow: EventEmitter<any>;
}

/**
 * TypeGuard for ICanCancel Interface: DuckTyping
 * @param test
 */
export function isICanCancel(test: any): test is ICanCancel {
  return test.cancel !== undefined;
}

/**
 * TypeGuard for ITriggerOnLoad Interface: DuckTyping
 * @param test
 */
export function isITriggerOnLoad(test: any): test is ITriggerOnLoad {
  return test.loadWindow !== undefined;
}

/**
 * TypeGuard for ITriggerOnClose Interface: DuckTyping
 * @param test
 */
export function isITriggerOnClose(test: any): test is ITriggerOnClose {

  return test.closeWindow !== undefined;
}

export type WindowType = 'default' | 'feedback';

/**
 * extended windows settings.
 * @see WindowSettings
 */
export class CustomWindowSettings extends WindowSettings {
  public type?: WindowType = 'default';
}

@Injectable()
export class CustomWindowService {
  private window: WindowRef;
  private feedbackWindow: WindowRef;

  public onClose: Function;

  constructor(private windowService: WindowService) { }

  /**
   * Create Window with expected Content Component.
   * @returns: component-instance or undefined
   * @param params @see CustomWindowSettings
   */
  public open(params: CustomWindowSettings) {
    const defaultedParams = { ...new CustomWindowSettings(), ...params };

    let kendoWindow = this.getWindow(defaultedParams.type);
    if (kendoWindow != null) {
      kendoWindow.close();
    }
    kendoWindow = this.windowService.open(defaultedParams);

    this.setWindow(defaultedParams.type, kendoWindow);
    const windowContentComponent = kendoWindow.content.instance;
    const windowComponent = kendoWindow.window.instance;
    const windowElement = windowComponent['el'].nativeElement as HTMLElement;

    windowComponent.dragEnd.subscribe(() => this.moveIntoScreen(windowComponent, windowElement));

    let loadSubscribed = false;
    if (isITriggerOnLoad(windowContentComponent)) {
      windowContentComponent.loadWindow.subscribe(() => {
        this.initWindowDelayed(windowComponent, windowElement, 10);
      });
      loadSubscribed = true;
    }
    const observer = this.observeContentChanges(windowComponent, windowElement, loadSubscribed);

    let isCanceled = true;
    if (isITriggerOnClose(windowContentComponent)) {
      windowContentComponent.closeWindow.subscribe(result => {
        isCanceled = false;
        this.close(defaultedParams.type);
      });
    }
    kendoWindow.result.subscribe(result => {
      if (result instanceof WindowCloseResult && isCanceled) {
        if (isICanCancel(windowContentComponent)) {
          windowContentComponent.cancel();
        }
        this.setWindow(defaultedParams.type, null);
      }
    });
    return windowContentComponent;
  }

  public getWindow(type: WindowType = 'default'): WindowRef {
    switch (type) {
      case 'default':
        return this.window;
      case 'feedback':
        return this.feedbackWindow;
      default:
        throw new Error('unknown window type');
    }
  }

  public setWindow(type: WindowType = 'default', value: WindowRef) {
    switch (type) {
      case 'default':
        this.window = value;
        break;
      case 'feedback':
        this.feedbackWindow = value;
        break;
      default:
        throw new Error('unknown window type');
    }
  }

  public close(type: WindowType = 'default') {
    const window = this.getWindow(type);
    if (window) {
      window.close();
    }
  }

  private setCalculatedWidth(windowComponent: WindowComponent, windowElement: HTMLElement) {
    if (windowComponent.width !== windowElement.offsetWidth) {
      windowComponent.setDimension('width', windowElement.offsetWidth);
    }
  }

  private centerHorizontally(windowComponent: WindowComponent) {
    const offsetLeft = document.documentElement.clientWidth / 2 - windowComponent.width / 2;
    if (windowComponent.left !== offsetLeft) {
      windowComponent.setOffset('left', offsetLeft);
    }
  }

  private moveIntoScreen(windowComponent: WindowComponent, windowElement: HTMLElement) {
    const clientHeight = document.documentElement.clientHeight;
    const clientWidth = document.documentElement.clientWidth;
    const windowBottom = windowComponent.top + windowElement.offsetHeight;
    const windowRight = windowComponent.left + windowElement.offsetWidth;
    if (windowComponent.top < 0) {
      windowComponent.setOffset('top', 0);
    } else if (windowBottom >= clientHeight) {
      windowComponent.setOffset('top', clientHeight - windowElement.offsetHeight);
    }
    if (windowComponent.left < 0) {
      windowComponent.setOffset('left', 0);
    } else if (windowRight >= clientWidth) {
      windowComponent.setOffset('left', clientWidth - windowElement.offsetWidth);
    }
  }

  private observeContentChanges(windowComponent: WindowComponent, windowElement: HTMLElement, loadSubscribed: boolean) {
    const observerInstance = new window.MutationObserver((mutations, observer) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes.length > 0 && mutation.addedNodes) {
          const addedNodes = Array.from(mutation.addedNodes);
          if (addedNodes.some(node => node instanceof HTMLDivElement && node.classList.contains('k-window-content'))) {
            windowElement.style.setProperty('width', 'auto');
            observer.disconnect();
            if (!loadSubscribed) {
              this.initWindowDelayed(windowComponent, windowElement);
            }
          }
        }
      }
    });
    observerInstance.observe(windowElement, { attributes: false, childList: true });
    return observerInstance;
  }

  private initWindow(windowComponent: WindowComponent, windowElement: HTMLElement): void {
    this.setCalculatedWidth(windowComponent, windowElement);
    this.centerHorizontally(windowComponent);
    windowElement.classList.add('fadeIn');
  }

  private initWindowDelayed(windowComponent: WindowComponent, windowElement: HTMLElement, delay: number = 250): void {
    window.setTimeout(() => this.initWindow(windowComponent, windowElement), delay);
  }
}
