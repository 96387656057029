import { Component, Inject, OnInit, EventEmitter, Output } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { Observable } from 'rxjs';
import { isBusy, getResult } from './ducks/batch.selectors';
import { BatchResult } from 'app/core/services/api-client';
import { BatchDucks } from './ducks/batch.ducks';

@Component({
  selector: 'app-batch-dialog',
  templateUrl: './batch-dialog.component.html',
  styleUrls: ['./batch-dialog.component.css'],
})
export class BatchDialogComponent implements OnInit {

  public isBusy$: Observable<boolean>;
  public result$: Observable<BatchResult>;

  @Output()
  public closeWindow: EventEmitter<any | null> = new EventEmitter<any | null>();

  constructor(private store: Store<IAppState>,
    @Inject(BatchDucks) private batchDucks: Duck<BatchDucks>) {
  }

  public ngOnInit() {
    this.isBusy$ = this.store.pipe(select(isBusy));
    this.result$ = this.store.pipe(select(getResult));
  }

  retry(ignoreErrors: boolean) {
    this.batchDucks.retry(ignoreErrors);
  }

  close() {
    this.closeWindow.emit(null);
  }
}


