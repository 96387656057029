import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopySachverhalteComponent } from 'app/modules/dossier-area/entry-components/copy-sachverhalte/copy-sachverhalte.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DossierModule } from 'app/modules/dossier-area/services/dossier/dossier.module';

@NgModule({
  imports: [ CommonModule, TranslateModule, ReactiveFormsModule, DossierModule ],
  declarations: [ CopySachverhalteComponent ],
  exports: [ CopySachverhalteComponent ],
})
export class CopySachverhalteModule {
}
