import { Inject, Injectable } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { environment } from '@environments/environment';
import { Dossier } from '@models/dossier/dossier';
import { IntlService } from '@progress/kendo-angular-intl';
import { IVisiblePanels, PanelDucks } from 'app/modules/shared-area/ducks/panel/panel.ducks';
import { Role } from 'app/modules/shared-area/ducks/user/models/role';
import * as _ from 'lodash';
import { isNil } from 'lodash';
import { ApprovalRequiredFact, DossierEintrag, DossierEintragCategory, DossierEintragItem, DossierEintragItemField, Fact, StofflisteStoffTreffer, Value } from '@models/dossier/dossier-models';
import { PartialDossier, Reference, ReferenceObjektTyp, DossierAttributeFactState } from 'app/core/services/api-client';
import { Merkmal } from '@models/merkmalbaum';
import { ItemTemplateDirective } from '@progress/kendo-angular-dropdowns';

export interface IMerkmalIds {
  sachverhaltId: number | undefined;
  erfasssungSachverhaltId: number | undefined;
}

@Injectable()
export class DossierHelperService {

  public dossierLinkTree: any[] = [];

  public dossierSearchableTexts = new Map<string, string>();

  private decimalSeparator: string = null;

  public constructor(
    @Inject(PanelDucks) private panelDucks: Duck<PanelDucks>,
    private intlService: IntlService) {
    const decimalSeparator = intlService.numberSymbols().decimal;
    if (decimalSeparator !== '.') {
      this.decimalSeparator = decimalSeparator;
    }
  }

  // **********************************************************************************
  // Functionality for searching within the dossier and the dossier structure search
  // **********************************************************************************

  public static getAllMerkmaleKeys(merkmale: Merkmal[], parentId = ''): any {
    return _.flatMap(merkmale.map(merkmal => {
      const isMerkmal = merkmal.type === 'Merkmal';

      const id = !isMerkmal
        ? `H${merkmal.id}`
        : `M${merkmal.id}`;
      const anchor = [parentId, id].filter(x => !!x)
        .join('-');
      if (isMerkmal) {
        return anchor;
      }
      return DossierHelperService.getAllMerkmaleKeys(merkmal.items, anchor);
    }));
  }

  public static removeMerkmaleFromDossier(dossier: Dossier, shownMerkmale: IVisiblePanels, showAllMerkmale: boolean) {
    const clonedDossier = { ...dossier };
    clonedDossier.items = dossier.items
      .map((item: any) => {
        const clonedItem = { ...item };
        const anchor = DossierHelperService.getDossierHierarchyId(<DossierEintrag>clonedItem);
        clonedItem.items = DossierHelperService.removeMerkmaleFromDossierInternal(clonedItem, anchor, shownMerkmale || {}, showAllMerkmale);
        if (clonedItem.items.length === 0) {
          return null;
        }
        return clonedItem;
      })
      .filter(x => x !== null);
    return clonedDossier;
  }

  public static getDossierMerkmalId(parentName: string, item: DossierEintragItem, index: number | null = null): string {
    return [parentName, `M${item.merkmalId}`, index]
      .filter(x => x !== '')
      .filter(x => x !== null)
      .join('-');
  }

  public static getDossierHierarchyId(item: DossierEintrag | DossierEintragCategory) {
    return `H${item.hierarchieId}`;
  }

  public static getOpenPanelsFromAnchorKeys(anchor: string): Set<string> {
    const anchorSplits = anchor.split('-');
    let panelName = '';
    const panelSet = new Set<string>();
    anchorSplits.forEach((anchorKey: string) => {
      if (anchorKey.startsWith('H')) {
        panelName += anchorKey;
        panelSet.add(panelName);
        panelName += '-';
      }
    });
    return panelSet;
  }

  private static isShowMerkmal(showMerkmal: boolean | null | undefined, showAllMerkmale: boolean): boolean {
    return isNil(showMerkmal) ? showAllMerkmale : showMerkmal;
  }

  private static removeMerkmaleFromDossierInternal(node: any, parentAnchor: string, shownMerkmale: IVisiblePanels, showAllMerkmale: boolean) {
    return node.items
      .map(item => {
        const clonedItem = { ...item };
        const isMerkmal = !clonedItem.hierarchieId;
        const id = !isMerkmal
          ? DossierHelperService.getDossierHierarchyId(clonedItem)
          : DossierHelperService.getDossierMerkmalId('', clonedItem, null);
        const anchor = `${parentAnchor}-${id}`;

        if (isMerkmal && !DossierHelperService.isShowMerkmal(<boolean>shownMerkmale[anchor], showAllMerkmale)) {
          return null;
        }
        clonedItem.items = clonedItem.items != null && clonedItem.items.length > 0
          ? DossierHelperService.removeMerkmaleFromDossierInternal(clonedItem, anchor, shownMerkmale, showAllMerkmale)
          : null;
        if (clonedItem.items != null && clonedItem.items.length === 0) {
          return null;
        }
        return clonedItem;
      })
      .filter(x => x !== null);
  }

  public getDossierSearchHits(search: string, isWholeWord: boolean, isIgnoreCase: boolean): Set<string> {
    const hits = new Set<string>();
    if (search.length > 2) {
      search = _.escapeRegExp(search);
      this.dossierSearchableTexts.forEach((text: string | number, id: string) => {
        if (isWholeWord) {
          search = `\\b${search}\\b`;
        }
        const searchRegEx = new RegExp(search, `g${isIgnoreCase ? 'i' : ''}`);
        if (searchRegEx.test(text.toString())) {
          hits.add(id);
        }
      });
    }
    return hits;
  }

  public createOpenPanelsFromHitsActions(hits: Set<string>): { type: string; payload: string }[] {
    const panelSet = new Set<string>();
    hits.forEach(hit => {
      const panels = DossierHelperService.getOpenPanelsFromAnchorKeys(hit);
      panels.forEach(panel => panelSet.add(panel));
    });
    return [...panelSet].map(panel => this.panelDucks.showPanel.action(panel));
  }

  public updateDossierLinkTreeAndSetSearchableTexts(dossier: Dossier) {
    this.dossierLinkTree = [];
    this.dossierSearchableTexts = new Map<string, string>();

    dossier.items.forEach(item => {
      const anchor = DossierHelperService.getDossierHierarchyId(<DossierEintrag>item);
      const text = item.name;
      const node = {
        text,
        anchor: anchor,
        items: this.createLinkHierarchyAndSetSearchableFacts(item, anchor),
      };
      this.dossierSearchableTexts.set(anchor, text);
      this.dossierLinkTree.push(node);
    });
  }

  public getSearchableValueId(parentId: string, index: number) {
    return parentId + index.toString();
  }

  public getSearchableFieldId(parentId: string) {
    return `${parentId}_FIELD`;
  }

  public getDossierItemId(parentName: string, item: DossierEintragItem, field: DossierEintragItemField, factIndex: number,
    subset: DossierEintragItemField = null, subsetIndex: number = null): string {
    let key = `${DossierHelperService.getDossierMerkmalId(parentName, item, factIndex)}-${field.key}`;
    if (subset) {
      key += `-${subsetIndex}-${subset.key}`;
    }
    return key;
  }

  public getFullDossierMerkmalId(dossierEintrag: DossierEintrag): string[] {
    const parent = DossierHelperService.getDossierHierarchyId(dossierEintrag);
    const value = _.flatMap(dossierEintrag.items.map(categoryOrEintrag => {
      if (!this.isMerkmal(categoryOrEintrag)) {
        const category = categoryOrEintrag;
        return category.items.map(eintrag =>
          DossierHelperService.getDossierMerkmalId(parent + '-' + DossierHelperService.getDossierHierarchyId(category), eintrag));
      } else {
        const eintrag: DossierEintragItem = categoryOrEintrag;
        return DossierHelperService.getDossierMerkmalId(parent, eintrag);
      }
    }),
    );
    return value;
  }

  public getFactIds(dossierEintrag: DossierEintrag): string[] {
    const value = _.flatMap(dossierEintrag.items.map(categoryOrEintrag => {
      if (!this.isMerkmal(categoryOrEintrag)) {
        const category = categoryOrEintrag;
        return category.items.map(eintrag => eintrag.facts.map(fact => fact.erfassungSachverhaltId || fact.erfassungSachverhaltId));
      } else {
        const eintrag: DossierEintragItem = categoryOrEintrag;
        return eintrag.facts.map(fact => fact.erfassungSachverhaltId || fact.erfassungSachverhaltId);
      }
    }),
    );
    return value;
  }

  /**
   * Returns 'sachverhaltId' & 'erfasssungSachverhaltId' if matching Merkmal name.
   * @param dossierEintrag
   * @param merkmalName
   */
  public findMerkmal(dossierEintrag: DossierEintrag, merkmalName: string): IMerkmalIds {
    let result = <IMerkmalIds>undefined;
    _.flatMap(dossierEintrag.items.map(categoryOrEintrag => {
      if (!this.isMerkmal(categoryOrEintrag)) {
        const category = categoryOrEintrag;
        const item = category.items.filter(itemMerkmal => itemMerkmal.name === merkmalName);
        if (item.length > 0 && item[0].facts.length > 0) {
          result = {
            erfasssungSachverhaltId: item[0].facts[0].erfassungSachverhaltId,
            sachverhaltId: item[0].facts[0].sachverhaltId,
          };
        }
      } else {
        const eintrag: DossierEintragItem = categoryOrEintrag;
        if (eintrag.name === merkmalName && eintrag.facts.length > 0) {
          result = {
            erfasssungSachverhaltId: eintrag.facts[0].erfassungSachverhaltId,
            sachverhaltId: eintrag.facts[0].sachverhaltId,
          };
        }
      }
    }),
    );
    return result;
  }

  public getSearchableValue(fieldType: string, value: Value, fieldUnit: string | null): string {
    switch (fieldType) {
      case 'image':
        return this.GetFullUrl(value.value);

      case 'download':
        return value.fileName;

      case 'datetime':
        return this.intlService.formatDate(this.intlService.parseDate(value.value));

      case 'double':
        return (this.decimalSeparator ? value.value.toPrecision(5).replace('.', this.decimalSeparator) : value.value.toPrecision(5)) + (fieldUnit ? ` ${fieldUnit}` : '');

      case 'reference':
      default:
        return value.value;
    }
  }

  public GetFullUrl(url: string): string {
    const apiServer = new URL(environment.baseAPIUrl).origin;
    return `${apiServer}${url}`;
  }

  /**
   * Find all elements which need admin or lecturer approval.
   * @param dossier
   */
  public findEditDossierItemsIDs(dossier: Dossier): ApprovalRequiredFact[] {
    const items = dossier.items;
    const itemIDs = [];
    if (items && items.length > 0) {
      items.filter((i: DossierEintrag) => i.items && i.items.length)
        .forEach((item: DossierEintrag) => {
          const merkmalID = this.getFullDossierMerkmalId(item);
          // directly found a Merkmal
          if (item.type !== 'accordion') {
            item.items.forEach((eintragItem: DossierEintragItem) => {
              eintragItem.facts.forEach((fact: Fact) => {
                const requiresMergeApproval = dossier.erfassungType === 'Merge' && !_.isNil(fact.erfassungSachverhaltId);
                if (fact.requiresAdminApproval || fact.requiresLecturerApproval || requiresMergeApproval || fact.erfassungSachverhaltId) {
                  itemIDs.push({
                    id: fact.sachverhaltId || fact.erfassungSachverhaltId,
                    parentContId: merkmalID[0] as string,
                    admin: fact.requiresAdminApproval,
                    lecturer: fact.requiresLecturerApproval,
                    merge: requiresMergeApproval,
                    editor: !_.isNil(fact.erfassungSachverhaltId)
                  });
                }
              },
              );
            },
            );
          } else {
            // we have a category
            item.items.filter((category: DossierEintragCategory | DossierEintragItem) => {
              if (!this.isMerkmal(category)) {
                category.items.forEach((categoryEintrag: DossierEintragItem) => {
                  categoryEintrag.facts.forEach((fact: Fact) => {
                    const requiresMergeApproval = dossier.erfassungType === 'Merge' && !_.isNil(fact.erfassungSachverhaltId);
                    if (fact.requiresAdminApproval || fact.requiresLecturerApproval || requiresMergeApproval || fact.erfassungSachverhaltId) {
                      itemIDs.push({
                        id: fact.sachverhaltId || fact.erfassungSachverhaltId,
                        parentContId: merkmalID.find(id => id.includes('M' + fact.merkmalId)),
                        admin: fact.requiresAdminApproval,
                        lecturer: fact.requiresLecturerApproval,
                        merge: requiresMergeApproval,
                        editor: !_.isNil(fact.erfassungSachverhaltId)
                      });
                    }
                  },
                  );
                });
              } else if (category.facts) {
                category.facts.forEach((fact: Fact) => {
                  const requiresMergeApproval = dossier.erfassungType === 'Merge' && !_.isNil(fact.erfassungSachverhaltId);
                  if (fact.requiresAdminApproval || fact.requiresLecturerApproval || requiresMergeApproval || fact.erfassungSachverhaltId) {
                    itemIDs.push({
                      id: fact.sachverhaltId || fact.erfassungSachverhaltId,
                      parentContId: merkmalID.find(id => id.includes('M' + fact.merkmalId)),
                      admin: fact.requiresAdminApproval,
                      lecturer: fact.requiresLecturerApproval,
                      merge: requiresMergeApproval,
                      editor: !_.isNil(fact.erfassungSachverhaltId)
                    });
                  }
                },
                );
              }
            });
          }
        });
    }
    return itemIDs;
  }

  public appendMatches(dossier: Dossier, matches: StofflisteStoffTreffer[]): void {
    dossier.items.forEach(item => {
      this.deepFindMatches(item, matches);
    });
  }

  public appendRoleContext(dossier: Dossier, roleContext: Role, fachlektorMerkmale: Set<number>): void {
    let h1: number;
    let h2: number;

    this.panelDucks.clearCurrentView({ showAllMerkmale: true, openAllPanels: false });
    dossier.items.forEach((item: DossierEintrag) => {
      h1 = item.hierarchieId;
      if (!_.isNil(item.items)) {
        item.items.forEach(subItem => {
          // subItem type === DossierEintragCategory
          if (!this.isMerkmal(subItem)) {
            h2 = subItem.hierarchieId;
            if (!_.isNil(subItem.items)) {
              subItem.items.forEach(subSubItem => {
                if (!_.isNil(subSubItem.facts)) {
                  subSubItem.facts.forEach((fact: Fact) => {
                    if (!_.isNil(roleContext)) {
                      switch (roleContext) {
                        case 'merge':
                          if (fact.erfassungSachverhaltId) {
                            fact.approvalClass = 'requiresAdminApproval';
                            this.panelDucks.showPanel(`H${h1}`);
                            this.panelDucks.showPanel(`H${h1}-H${h2}`);
                          }
                          break;
                        case 'admin':
                          if (fact.requiresAdminApproval === true) {
                            fact.approvalClass = 'requiresAdminApproval';
                            this.panelDucks.showPanel(`H${h1}`);
                            this.panelDucks.showPanel(`H${h1}-H${h2}`);
                          }
                          break;
                        case 'lecturer':
                          if (fact.requiresLecturerApproval === true && (fachlektorMerkmale.has(fact.merkmalId))) {
                            fact.approvalClass = 'requiresLecturerApproval';
                            this.panelDucks.showPanel(`H${h1}`);
                            this.panelDucks.showPanel(`H${h1}-H${h2}`);
                          }
                          break;
                        case 'editor':
                          break;
                        default:
                          console.error('no such role found:', roleContext);
                          break;
                      }
                    }
                  });
                }
              });
            }
          } else if (this.isMerkmal(subItem)) {
            //  subItem type === DossierEintragItem
            h2 = subItem.merkmalId;
            if (!_.isNil(subItem.facts)) {
              subItem.facts.forEach((fact: Fact) => {
                if (!_.isNil(roleContext)) {
                  switch (roleContext) {
                    case 'merge':
                      if (fact.erfassungSachverhaltId) {
                        fact.approvalClass = 'requiresAdminApproval';
                        this.panelDucks.showPanel(`H${h1}`);
                        this.panelDucks.showPanel(`H${h1}-M${h2}`);
                      }
                      break;
                    case 'admin':
                      if (fact.requiresAdminApproval === true) {
                        fact.approvalClass = 'requiresAdminApproval';
                        this.panelDucks.showPanel(`H${h1}`);
                        this.panelDucks.showPanel(`H${h1}-M${h2}`);
                      }
                      break;
                    case 'lecturer':
                      if (fact.requiresLecturerApproval === true && (fachlektorMerkmale.has(fact.merkmalId))) {
                        fact.approvalClass = 'requiresLecturerApproval';
                        this.panelDucks.showPanel(`H${h1}`);
                        this.panelDucks.showPanel(`H${h1}-M${h2}`);
                      }
                      break;
                    case 'editor':
                      break;
                    default:
                      console.error('no such role found:', roleContext);
                      break;
                  }
                }
              });
            }
          }
        });
      }
    });
  }

  public deepFindMatches(item: any, matches: StofflisteStoffTreffer[]) {
    matches.forEach(match => {
      if (item.wertId === match.wertId || (item.sachverhaltId === match.sachverhaltId && _.isNil(match.wertId))) {
        item.match = true;
      }
    });

    if (item.items && item.items.length > 0) {
      item.items.forEach(i => {
        if (this.deepFindMatches(i, matches)) {
          item.match = true;
        }
      });
    }

    if (item.facts && item.facts.length > 0) {
      item.facts.forEach(fact => {
        if (this.deepFindMatches(fact, matches)) {
          item.match = true;
        }
      });
    }

    if (item.values) {
      Object.keys(item.values)
        .forEach(key => {
          matches.forEach(match => {
            const value = item.values[key];
            if (_.isArray(value)) {
              value.forEach(val => {
                if (match.wertId === val.wertId) {
                  val.match = true;
                  item.match = true;
                }
              });
            } else {
              if (match.wertId === value.wertId) {
                value.match = true;
                item.match = true;
              }
            }
          });
        });
    }
    return item.match;
  }

  public getReferenceRouterLink(reference: Reference | any): any[] | string {
    if (reference) {
      if (reference.objektTyp) {
        switch (reference.objektTyp) {
          case ReferenceObjektTyp.Zitat:
            return ['/dossier/citation/', reference.objektId];
          case ReferenceObjektTyp.Stoff:
            return ['/dossier/substance/', reference.objektId];
          case ReferenceObjektTyp.Lieferant:
            return ['/dossier/supplier/', reference.objektId];
          case ReferenceObjektTyp.Spezies:
            return ['/dossier/species/', reference.objektId];
          default:
            return ['/home'];
        }
      } else {
        // Link im Dossier
        if (reference.importId && reference.referenceType) {
          switch (reference.referenceType) {
            case ReferenceObjektTyp.Stoff:
              return ['/dossier/import/substance/' + reference.importId, { objektTyp: reference.referenceType, importObjektId: reference.referenceId }];
            case ReferenceObjektTyp.Zitat:
              return ['/dossier/import/citation/' + reference.importId, { objektTyp: reference.referenceType, importObjektId: reference.referenceId }];
            case ReferenceObjektTyp.Spezies:
              return ['/dossier/import/species/' + reference.importId, { objektTyp: reference.referenceType, importObjektId: reference.referenceId }];
            case ReferenceObjektTyp.Lieferant:
              return ['/dossier/import/supplier/' + reference.importId, { objektTyp: reference.referenceType, importObjektId: reference.referenceId }];
            default:
              return ['/home'];              
          }
        } else {
          switch (reference.referenceType) {
            case ReferenceObjektTyp.Zitat:
              return ['/dossier/citation/', reference.referenceId];
            case ReferenceObjektTyp.Stoff:
              return ['/dossier/substance/', reference.referenceId];
            case ReferenceObjektTyp.Lieferant:
              return ['/dossier/supplier/', reference.referenceId];
            case ReferenceObjektTyp.Spezies:
              return ['/dossier/species/', reference.referenceId];
            default:
              return ['/home'];
          }
        }
      }
    }
    return [];
  }

  public getModifiedFacts(items: any) {
    let modifiedFactsNumber = 0;
    items.forEach(item => {
      if (item.merkmalId) {
        const facts: Fact[] = item.facts;
        modifiedFactsNumber += _.countBy(facts, fact => !!fact.state).true || 0;
      } else if (item.hierarchieId) {
        modifiedFactsNumber += this.getModifiedFacts(item.items);
      }
    });
    return modifiedFactsNumber;
  }

  public removeFactFromDossier(dossier: Dossier, partialDossier: PartialDossier, erfassungSachverhaltId: number) {
    dossier = this.copyBasePartialDossier(dossier, partialDossier);
    dossier.items = this.removeFactFromDossierInteral(dossier.items, erfassungSachverhaltId);
    return dossier;
  }

  public updateDossier(dossier: Dossier, partialDossier: PartialDossier): Dossier {
    dossier = this.copyBasePartialDossier(dossier, partialDossier);
    dossier.type = partialDossier.type;
    dossier.items = this.updateDossierItems(partialDossier.items, dossier.items);
    return dossier;
  }

  private removeFactFromDossierInteral(items: any, erfassungSachverhaltId: number) {
    return items.map(item => {
      item = { ...item };
      if (item.merkmalId) {
        item.facts = this.removeFacts(item.facts, erfassungSachverhaltId);
        if (item.facts.length === 0) {
          return null;
        }
      } else if (item.hierarchieId) {
        item.items = this.removeFactFromDossierInteral(item.items, erfassungSachverhaltId);
        if (item.items.length === 0) {
          return null;
        }
      }
      return item;
    })
      .filter(x => x !== null);
  }

  private removeFacts(facts: Fact[], erfassungSachverhaltId: number): Fact[] {
    const index = _.findIndex(facts, fact => fact.erfassungSachverhaltId === erfassungSachverhaltId);
    return index > -1
      ? [...facts.slice(0, index), ...facts.slice(index + 1)]
      : facts;
  }

  private copyBasePartialDossier(dossier: Dossier, partialDossier: PartialDossier) {
    return {
      ...dossier,
      erfassungTitle: partialDossier.erfassungTitel,
      erfassungOwner: partialDossier.erfassungOwner,
      erfassungType: partialDossier.erfassungType,
      erfassungStatus: partialDossier.erfassungStatus,
    };
  }

  private updateDossierItems(partialDossierItems: any, dossierItems: any) {
    if (partialDossierItems.length === 0) { return dossierItems; }
    const partialDossierItem = partialDossierItems[0];
    if (partialDossierItem.sachverhaltId || partialDossierItem.erfassungSachverhaltId) {
      return this.updateFact(partialDossierItem, dossierItems);
    } else if (partialDossierItem.merkmalId) {
      return this.updateMerkmal(partialDossierItem, dossierItems);
    } else if (partialDossierItem.hierarchieId) {
      return this.updateAccordion(partialDossierItem, dossierItems);
    }
  }

  private updateAccordion(partialDossierItem: any, dossierItems: any) {
    const dossierItemIndex = _.findIndex(dossierItems, (item: any) => item.hierarchieId === partialDossierItem.hierarchieId);
    if (dossierItemIndex === -1) {
      return _.sortBy([...dossierItems, partialDossierItem], item => item.order);
    } else {
      const dossierItem = { ...dossierItems[dossierItemIndex] };
      dossierItem.items = this.updateDossierItems(partialDossierItem.items, dossierItem.items);
      return dossierItems.map((item, index) => index === dossierItemIndex ? dossierItem : item);
    }
  }

  private updateMerkmal(partialDossierItem: any, dossierItems: any) {
    const dossierItemIndex = _.findIndex(dossierItems, (item: any) => item.merkmalId === partialDossierItem.merkmalId);
    if (dossierItemIndex === -1) {
      return _.sortBy([...dossierItems, partialDossierItem], item => item.order);
    } else {
      const dossierItem = { ...dossierItems[dossierItemIndex] };
      if (partialDossierItem.fields && partialDossierItem.fields.length > 0) {
        dossierItem.fields = partialDossierItem.fields;
      }
      dossierItem.facts = this.updateDossierItems(partialDossierItem.facts, dossierItem.facts);
      return dossierItems.map((item, index) => index === dossierItemIndex ? dossierItem : item);
    }
  }

  private updateFact(partialDossierFact: Fact, dossierFacts: Fact[]) {
    let dropFactIndex = -1;
    if (partialDossierFact.sachverhaltId) {
      dropFactIndex = _.findIndex(dossierFacts, (item: any) => item.sachverhaltId === partialDossierFact.sachverhaltId);
    } else {
      dropFactIndex = _.findIndex(dossierFacts, (item: any) => item.erfassungSachverhaltId === partialDossierFact.erfassungSachverhaltId);
    }
    if (dropFactIndex > -1) {
      return dossierFacts.map((fact, index) => dropFactIndex === index ? partialDossierFact : fact);
    } else {
      return [...dossierFacts, partialDossierFact];
    }
  }

  // ************************************
  // Find Matches from the search area
  // ************************************

  private createLinkHierarchyAndSetSearchableFacts(node: any, parentAnchor: string): any {
    const tree = [];
    if (node && node.items && node.items.length > 0) {
      node.items.forEach(item => {
        const id = item.hierarchieId
          ? DossierHelperService.getDossierHierarchyId(item)
          : DossierHelperService.getDossierMerkmalId('', item, null);
        const anchor = `${parentAnchor}-${id}`;
        const text = item.name;
        this.dossierSearchableTexts.set(anchor, text);
        const subNote = {
          text: text,
          anchor: anchor,
          items: item.items != null && item.items.length > 0
            ? this.createLinkHierarchyAndSetSearchableFacts(item, anchor)
            : null,
        };
        tree.push(subNote);
        if (item.facts && item.facts.length > 0) {
          this.setSearchableFacts(item.facts, item.fields, anchor);
        }
      });
    }

    return tree;
  }

  private setSearchableFacts(facts: Fact[], fields: DossierEintragItemField[], parentAnchor: string) {
    facts.forEach((fact, factIndex) => {
      this.setSearchableValues(fact.values, fields, `${parentAnchor}-${factIndex}`);
    });
  }

  private setSearchableValues(values: Value, fields: DossierEintragItemField[], parentAnchor: string) {
    fields
      .forEach(field => {
        const key = field.key;
        const value = values[key];
        if (isNil(value)) {
          return true;
        }
        const anchor = `${parentAnchor}-${key}`;
        if (key.startsWith('_SUBSET_')) {
          value.forEach((subsetValues, subsetIndex) => {
            this.setSearchableValues(subsetValues, field.fields, `${anchor}-${subsetIndex}`);
          });
        } else {
          // set the field
          this.dossierSearchableTexts.set(this.getSearchableFieldId(anchor), field.name);

          // set the values
          const valuesArray = _.isArray(value) ? value.map(v => this.getSearchableValue(field.type, v, field.unit)) : [this.getSearchableValue(field.type, value, field.unit)];
          valuesArray.forEach((v, i) => this.dossierSearchableTexts.set(this.getSearchableValueId(anchor, i), v));
        }
      });
  }

  private isMerkmal(categoryOrEintrag: DossierEintragCategory | DossierEintragItem): categoryOrEintrag is DossierEintragItem {
    const entry: any = categoryOrEintrag;
    return !(entry.items && entry.items.length > 0);
  }

  private getPanelId(item: any) {
    const panelId = !_.isNil(item.hierarchieId) ? `H${item.hierarchieId}` : !_.isNil(item.merkmalId) ? `M${item.merkmalId}` : '';
    return panelId;
  }

  public getFactsWithThesaurus(items: any, panels: IVisiblePanels[] = [], parent: string): void {
    items.forEach(item => {
      const anchor = _.isNil(parent) ? this.getPanelId(item) : `${parent}-${this.getPanelId(item)}`;
      if (item.facts) {
        item.facts.forEach(fact => {
          if (fact.state === 'Thesaurus') {
            panels[anchor] = true;
          }
        });
      }
      if (item.items) {
        this.getFactsWithThesaurus(item.items, panels, anchor);
      }
    });
  }

  public appendParentPanels(panels: IVisiblePanels[]) {
    const parents: string[] = [];
    Object.keys(panels).forEach(key => {
      this.getParents(key, parents);
    });
    parents.forEach(parent => {
      panels[parent] = true;
    });
  }

  private getParents(parentId: string, parents: string[]): void {
    parents.push(parentId);
    const parent = parentId.substr(0, parentId.lastIndexOf('-'));
    if (parent) {
      this.getParents(parent, parents);
    }
  }

  public getFactsWithAenderung(items: any, panels: IVisiblePanels[] = [], parent: string): void {
    items.forEach(item => {
      const anchor = _.isNil(parent) ? this.getPanelId(item) : `${parent}-${this.getPanelId(item)}`;
      if (item.facts) {
        item.facts.forEach(fact => {
          if (fact.selected !== undefined || fact.state) {
            panels[anchor] = true;
          }
        });
      }
      if (item.items) {
        this.getFactsWithAenderung(item.items, panels, anchor);
      }
    });
  }

  public updateStatusBySelection(items: any): void {
    items.forEach(item => {
      if (item.facts) {
        item.facts.forEach(fact => {
          if (fact.selected === false) {
            fact.selectedState = fact.state;
            fact.state = 'Deleted';
          }
        });
      }
      if (item.items) {
        this.updateStatusBySelection(item.items);
      }
    });
  }
}
