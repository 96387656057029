import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { catchError } from 'rxjs/operators';

import { ImportAuftrag, ApiClient, FileParameter, ImportAuftragLogItem, Dossier, Modus } from 'app/core/services/api-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';

@Injectable()
export class ImportPageService {

  constructor(private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService) {

  }
  public getAllImportAuftraege(): Observable<ImportAuftrag[]> {
    return this.apiClient.apiImportAuftragGet()
      .pipe
      (
        catchError(this.apiClient.apiImportAuftragGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        }))
      );
  }

  // Aktuell ungenutzt, da Auftrag bei direktem Upload angelegt wird.
  public saveImportAuftrag(file: FileParameter, auftrag: ImportAuftrag): Observable<ImportAuftrag> {
    return this.apiClient.apiImportAuftragPost(auftrag.lieferantId, auftrag.formatName, auftrag.sachverhalteUeberspringen, auftrag.stoffeErstellen, auftrag.modus as unknown as Modus, file)
      .pipe
      (
        catchError(this.apiClient.apiImportAuftragPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
        }))
      );
  }
  public deleteImportAuftrag(id: number): Observable<void> {
    return this.apiClient.apiImportAuftragByIdDelete(id)
      .pipe
      (
        catchError(this.apiClient.apiImportAuftragByIdDeleteErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404)
        }))
      );
  }
  public dismissImportErfassung(id: number): Observable<any> {
    return this.apiClient.apiImportByImportIdAusblendenPost(id)
      .pipe
      (
        catchError(this.apiClient.apiImportByImportIdAusblendenPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
          error400Handler: error400 => this.errorHandler.handle400Error(error400)
        }))
      );
  }
  public getImportFormats(): Observable<{ [key: string]: string }> {
    return this.apiClient.apiImportAuftragFormateGet()
      .pipe
      (
        catchError(this.apiClient.apiImportAuftragFormateGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        }))
      );
  }
  public getLieferanten(): Observable<{ [key: string]: string }> {
    return this.apiClient.apiImportAuftragLieferantenGet()
      .pipe
      (
        catchError(this.apiClient.apiImportAuftragLieferantenGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        }))
      );
  }
  public getLog(id: number): Observable<ImportAuftragLogItem[]> {
    return this.apiClient.apiImportAuftragByIdProtokollGet(id)
      .pipe
      (
        catchError(this.apiClient.apiImportAuftragByIdProtokollGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        }))
      );
  }

  public getErfassung(id: number): Observable<Dossier> {
    return this.apiClient.apiImportByImportIdGet(id)
      .pipe
      (
        catchError(this.apiClient.apiImportByImportIdGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404)
        }))
      );
  }

  public getImportErfassungen(): Observable<any> {
    return this.apiClient.apiImportGet()
      .pipe
      (
        catchError(this.apiClient.apiImportGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        }))
      );
  }

  public deleteImportErfassung(id: number): Observable<void> {
    return this.apiClient.apiImportByImportIdDelete(id)
      .pipe
      (
        catchError(this.apiClient.apiImportByImportIdDeleteErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404)
        }))
      );
  }

  public confirmImportErfassung(id: number, autoResolveConflicts: boolean): Observable<any> {
    return this.apiClient.apiImportByImportIdFreigebenPost(id, autoResolveConflicts)
      .pipe
      (
        catchError(this.apiClient.apiImportByImportIdFreigebenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404)
        }))
      );
  }
}
