import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAdminAreaState } from 'app/modules/admin-area/admin-area.module';

const adminAreaState = createFeatureSelector<IAdminAreaState>('admin-area');
const exportPage = createSelector(adminAreaState, state => state.exportPage);
export const getExportAuftraege = createSelector(exportPage, state => state.exportAuftraege);
export const isExportAuftraegeBusy = createSelector(exportPage, state => state.isBusy);
export const selectedExportAuftrag = createSelector(exportPage, state => state.selectedExportAuftrag);
export const getRankingTags = createSelector(exportPage, state => state.rankingTags);
export const getExportFormats = createSelector(exportPage, state => state.exportFormats);
export const getDownloadExportAuftrag = createSelector(exportPage, state => state.downloadExportAuftrag);
