import { Injectable } from '@angular/core';
import { ToastaService } from 'ngx-toasta';
import { HttpErrorResponse } from '@angular/common/http';
import { I18nService } from '../i18n/i18n.service';

@Injectable()
export class CustomToastyService {
  constructor(private toastyService: ToastaService, private i18n: I18nService) { }

  public toastError(error: HttpErrorResponse) {
    switch (error.status) {

      case 400:
        this.toastyService.warning({
          title: this.getLocalizedTitle(error.error.reason),
          msg: error.error.description,
          theme: 'bootstrap',
          showClose: true,
          timeout: 5000
        });
        break;

      case 403:
        const locks: any = error.error;
        this.toastyService.warning({
          title: this.i18n.getLocalizedString('validation.titles.locked'),
          msg: this.i18n.getLocalizedString('validation.messages.attributeLockedBy').replace('{{user}}', locks.data.currentlyLockedBy[0]),
          theme: 'bootstrap',
          showClose: true,
          timeout: 5000
        });
        break;

      case 404:
        this.toastyService.error({
          title: this.i18n.getLocalizedString('validation.titles.notFound'),
          msg: this.i18n.getLocalizedString('validation.messages.notFound'),
          theme: 'bootstrap',
          showClose: true,
          timeout: 0
        });
        break;

      case 409:
        this.toastyService.error({
          title: this.i18n.getLocalizedString('validation.titles.alreadyOpen'),
          msg: this.i18n.getLocalizedString('validation.messages.alreadyOpen'),
          theme: 'bootstrap',
          showClose: true,
          timeout: 0
        });
        break;

      case 500:
        this.toastyService.error({
          title: this.i18n.getLocalizedString('validation.titles.error'),
          msg: error.error.error,
          theme: 'bootstrap',
          showClose: true,
          timeout: 0
        });
        break;
      default:
        this.toastyService.error({
          title: error.statusText,
          msg: error.message,
          theme: 'bootstrap',
          showClose: true,
          timeout: 0
        });
        break;
    }
  }
  public toastWarning(titleKey: string, msgKey: string) {
    this.toastWarningFormatted(titleKey, msgKey, {});
  }
  public toastWarningFormatted(titleKey: string, msgKey: string, data: any) {
    const title = this.i18n.getLocalizedString(titleKey);
    let msg = this.i18n.getLocalizedString(msgKey);
    for (const key of Object.keys(data)) {
      msg = msg.replace(`{{${key}}}`, data[key]);
    }
    this.toastyService.warning({
      title: title,
      msg: msg,
      theme: 'bootstrap',
      showClose: true,
      timeout: 0
    });
  }
  public toastErrorFormatted(titleKey: string, msgKey: string, data: any) {
    const title = this.i18n.getLocalizedString(titleKey);
    let msg = this.i18n.getLocalizedString(msgKey);
    for (const key of Object.keys(data)) {
      msg = msg.replace(`{{${key}}}`, data[key]);
    }
    this.toastyService.error({
      title: title,
      msg: msg,
      theme: 'bootstrap',
      showClose: true,
      timeout: 0
    });
  }

  public toastSuccessFormatted(titleKey: string, msgKey: string, data: any) {
    const title = this.i18n.getLocalizedString(titleKey);
    let msg = this.i18n.getLocalizedString(msgKey);
    for (const key of Object.keys(data)) {
      msg = msg.replace(`{{${key}}}`, data[key]);
    }
    this.toastyService.success({
      title: title,
      msg: msg,
      theme: 'bootstrap',
      showClose: true,
      timeout: 5000
    });
  }

  public toastSuccess(title: string, successMessage: string) {
    this.toastyService.success({
      title: title,
      msg: successMessage,
      theme: 'bootstrap',
      showClose: true,
      timeout: 5000
    });
  }

  private getLocalizedTitle(reason: string): string {
    const title = this.i18n.getLocalizedString(`validation.titles.${reason}`);
    return title;
  }
}
