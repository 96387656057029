import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ApiClient, MaintenanceWindow} from '../api-client';
import { ApiErrorHandlerService } from '../api-default-error-handler/api-error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WartungsarbeitenService {

  constructor(private http: HttpClient,
              private apiClient: ApiClient,
              private errorHandler: ApiErrorHandlerService) {
  }

  public getMaintenanceWindows(): Observable<MaintenanceWindow[]> {
    return this.apiClient.apiSystemInfoMaintenanceWindowGet().pipe(
      catchError(this.apiClient.apiSystemInfoMaintenanceWindowGetErrorHandlers({
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403)
      }))
      );
  }

  public addMaintenanceWindow(maintenanceWindow: MaintenanceWindow): Observable<MaintenanceWindow> {
    return this.apiClient.apiSystemInfoMaintenanceWindowPost(maintenanceWindow).pipe(
      catchError(this.apiClient.apiSystemInfoMaintenanceWindowPostErrorHandlers({
        error400Handler: error400 => this.errorHandler.handle400Error(error400),
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403),
      }))
    );
  }

  public updateMaintenanceWindow(window: MaintenanceWindow): Observable<MaintenanceWindow> {
    return this.apiClient.apiSystemInfoMaintenanceWindowByIdPatch(window.id, window).pipe(
      catchError(this.apiClient.apiSystemInfoMaintenanceWindowByIdPatchErrorHandlers({
        error400Handler: error400 => this.errorHandler.handle400Error(error400),
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403),
        error404Handler: error404 => this.errorHandler.handle404Error(error404)
      }))
    );
  }
}
