import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { DynamicSachverhalteFormModule } from 'app/modules/dossier-area/components/dynamic-sachverhalte-form/dynamic-sachverhalte-form.module';
import { BatchDeleteDialogComponent } from './batch-delete-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    StoreModule,
    ValidationModule,
    I18nModule,
    TranslateModule,
    DynamicSachverhalteFormModule,
  ],
  declarations: [ BatchDeleteDialogComponent ],
  exports: [ BatchDeleteDialogComponent ],
})
export class BatchDeleteDialogModule {}
