<div class="form-horizontal form-label-left" style="width: 750px">
  <input type="hidden" id="isNew" [value]="isNew">
  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.exportAuftraegeEdit.name' | translate}}*
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8">
      <input kendoTextBox [attr.disabled]="isNew ? null : ''" id="tb_name" pattern="[A-Za-z0-9_]*"
        [(ngModel)]="exportAuftrag.name" autoFocus>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.exportAuftraegeEdit.description' | translate}}
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8">
      <kendo-textbox-container style="width: 100%; height: 100px">
        <textarea kendoTextArea style="resize: none" id="ta_description"
          [(ngModel)]="exportAuftrag.description"></textarea>
      </kendo-textbox-container>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.exportAuftraegeEdit.thesaurus' | translate}}   
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8" style="padding: 6px 10px;">
      <input type="checkbox" name="cb_thesaurus" id="cb_thesaurus" class="k-checkbox" [(ngModel)]="exportAuftrag.thesaurus">
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.exportAuftraegeEdit.ranking' | translate}}
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8">
      <ng-container *ngFor="let rankingTag of rankingTags">
        <input *ngIf="rankingTag" type="checkbox" class="k-checkbox" [name]="rankingTag.name" [id]="'cb_' + rankingTag.name"  [(ngModel)]="rankingTag.checked"
          [ngModelOptions]="{standalone: true}">
          <label class="k-checkbox-label" [for]="'cb_' + rankingTag.name">
          {{rankingTag.name}}
          </label>
        <br>
      </ng-container>
    </div>
  </div>

  <div class="form-group" style="display: flex; justify-content: flex-end; padding:5px">
    <button id="btn_close" class="btn btn-default" (click)="cancel()">{{'action.cancel' | translate}}</button>
    <button id="btn_save" class="btn btn-primary" type="submit" (click)="submit()" [disabled]="!isValid">
      <i class="fas fa-save"></i>
      {{'action.create' | translate}}</button>
  </div>
</div>
