import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { IMergeState } from './merge.state';
import { ObjektId } from '@models/objekt-id';
import { IdentitaetPruefenResult } from 'app/core/services/api-client';

@Ducksify<IMergeState>({
  initialState: {
    result: null,
    isBusy: false,
    canMerge: false,
    id: null,
  },
})
export class MergeDucks {
  @Action('[Merge] Show collisions')
  showCollisions(state: IMergeState, data: [ ObjektId, boolean ]): typeof state {
    return {
      ...state,
      id: data[ 0 ],
      canMerge: data[ 1 ],
      result: null,
      isBusy: true,
    };
  }

  @Action('[Merge] Show collisions (Success)')
  showCollisionsSuccess(state: IMergeState, result: IdentitaetPruefenResult): typeof state {
    return {
      ...state,
      result: result,
      isBusy: false,
    };
  }

  @Action('[Merge] Show collisions Error (Error)')
  showCollisionsError(state: IMergeState): typeof state {
    return {
      ...state,
      result: null,
      isBusy: false,
    };
  }

  @Action('[Merge] Merge')
  merge(state: IMergeState, additionalId: number | null): typeof state {
    return {
      ...state,
      additionalId: additionalId,
      isBusy: true,
    };
  }

  @Action('[Merge] Merge (Success)')
  mergeSuccess(state: IMergeState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Merge] Merge (Failed)')
  mergeFailed(state: IMergeState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }
}

export function mergeReducer(state: IMergeState, action: DucksifiedAction): IMergeState {
  return reducerFrom(MergeDucks)(state, action);
}
