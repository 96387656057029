<div class="references">
  <ng-container *ngIf="result$ | async as result; else loading">
    <ng-template #noResults>
      {{'dialog.references.noResults' | translate}}
    </ng-template>
    <table class="table table-striped" style="width:100%" *ngIf="result.total > 0; else noResults">
      <thead>
        <tr>
          <th scope="col" width="1">{{'components.references.headers.id' | translate}} </th>
          <th scope="col" width="125">{{'components.references.headers.type' | translate}}</th>
          <th scope="col">{{'components.references.headers.name' | translate}}</th>
          <th scope="col" width="1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let reference of result.references">
          <td>{{reference.objektId}}</td>
          <td>{{reference.objektTyp}}</td>
          <td style="max-width: 10vw">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{reference.name}}</div>
          </td>
          <td>
            <app-reference-link [reference]="reference"></app-reference-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="result.total > result.references.length" style="text-align: center">
      <button (click)="loadMore()" [disabled]="isBusy$ | async" class="btn btn-default">
        {{'components.references.loadMore' | translate}}
      </button>
    </div>
  </ng-container>
  <ng-template #loading>
    <div *ngIf="isBusy$ | async" style="text-align: center">
      <i class="k-icon k-i-loading" style="font-size:50px; columns: #363636; margin: 20px"></i>
    </div>
  </ng-template>
</div>