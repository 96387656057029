import { createSelector } from '@ngrx/store';
import { getSubstanceId } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';

export const sachverhaltState = (state: IAppState) => state.shared.sachverhalt;

export const getSachverhaltEditValues = createSelector(sachverhaltState, getSubstanceId, (state, id) => ({
  ...state.sachverhaltEdit,
  id
}));

export const getSachverhalt = createSelector(sachverhaltState, state => state.sachverhalt);
export const isSachverhaltLoading = createSelector(sachverhaltState, state => state.isSachverhaltLoading);
export const serverValidationErrors = createSelector(sachverhaltState, state => state.serverValidationErrors);
