import {Action, DucksifiedAction, Ducksify, reducerFrom} from '@ngrx-ducks/core';
import {NachschlageTabelle} from 'app/core/services/api-client';
import {INachschlageTabellenState} from './nachschlagetabellen-store.service';

@Ducksify<INachschlageTabellenState>({
  initialState: {
    lookupTables: [],
    loading: true,
  },
})
export class NachschlagetabellenDucks {

  @Action('[Nachschlagetabellen] Start loading Nachschlagetabellen')
  public nachschlagetabellenRequest(state: INachschlageTabellenState): typeof state {
    return {
      ...state,
      loading: true,
    };
  }

  @Action('[Nachschlagetabellen] Load Nachschlagetabellen Success')
  public loadNachschlagetabellenSuccess(state: INachschlageTabellenState, lookupTables: NachschlageTabelle[]): typeof state {
    return {
      ...state,
      lookupTables: lookupTables,
      loading: false,
    };
  }
}

export function nachschlagetabellenReducer(state: INachschlageTabellenState, action: DucksifiedAction): typeof state {
  return reducerFrom(NachschlagetabellenDucks)(state, action);
}
