import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { BatchDialogComponent } from './batch-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TreeViewModule,
    ReactiveFormsModule,
    DropDownsModule,
    TranslateModule,
    I18nModule,
    StoreModule,
  ],
  declarations: [ BatchDialogComponent ],
  exports: [ BatchDialogComponent ],
  entryComponents: [
    // move all entryComponents to root until this issues get resolved:
    // https://github.com/angular/angular/issues/14324
  ]
})
export class BatchDialogModule {}
