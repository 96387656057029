import {ModuleWithProviders, NgModule} from '@angular/core';
import {ApiErrorHandlerServiceModule} from '../api-default-error-handler/api-error-handler.service.module';
import {ApiClientModule} from '../api-client/api-client.module';
import {LookupService} from './lookup-service.service';

@NgModule({
  imports: [
    ApiErrorHandlerServiceModule,
    ApiClientModule
  ],
  declarations: []
})
export class LookupServiceModule {
  public static forRoot(): ModuleWithProviders<LookupServiceModule> {
    return {
      ngModule: LookupServiceModule,
      providers: [ LookupService ],
    };
  }
}
