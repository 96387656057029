<div class="form-horizontal form-label-left">

  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.exportAuftraegeDownload.sections' | translate}}
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <ng-container *ngFor="let section of sections">
        <input *ngIf="section" type="checkbox" [name]="section.value" [id]="section.value" class="k-checkbox" [(ngModel)]="section.checked"
          [ngModelOptions]="{standalone: true}"> 
          <label class="k-checkbox-label" [for]="section.value">{{section.label}}</label>
        <br>
      </ng-container>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.exportAuftraegeDownload.downloadFormats' | translate}}
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <ng-container *ngFor="let format of exportFormats">
        <input *ngIf="format" type="radio" [name]="'format'" class="k-radio" [value]="format.value" [id]="format.value" [(ngModel)]="selectedExportFormat"
          [ngModelOptions]="{standalone: true}"> 
          <label class="k-radio-label" [for]="format.value">{{format.label}}</label>
        <br>
      </ng-container>
    </div>
  </div>
</div>
<div class="form-group" style="display: flex; justify-content: flex-end; padding:5px">
  <button id="btn_download" class="btn btn-primary" [disabled]="!isValid" (click)="download()">{{'action.download' | translate}}</button>
</div>
