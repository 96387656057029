import { Inject, Injectable } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { BenutzerprofilService } from 'app/core/services/benutzerprofil/benutzerprofil.service';
import { PanelDucks } from 'app/modules/shared-area/ducks/panel/panel.ducks';
import { BenutzerProfil, Lieferant } from 'app/core/services/api-client';
import * as _ from 'lodash';
import { IViews } from 'app/modules/shared-area/ducks/panel/models/IViews';
import { UserProfileDucks } from 'app/modules/shared-area/ducks/user-profile/user-profile.ducks';
import { ClientInfo } from 'app/core/services/benutzerprofil/models/clientInfo';
import { FavoriteMerkmal } from '@models/dossier/dossier-models';
import { IView } from 'app/modules/shared-area/ducks/panel/models/IView';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UserProfileEffects {



  public constructor(
    @Inject(UserProfileDucks) private readonly duck: Duck<UserProfileDucks>,
    @Inject(PanelDucks) private readonly panelDucks: Duck<PanelDucks>,
    private readonly service: BenutzerprofilService,
    private translateService: TranslateService
    ) {}

  public async loadUserProfilesAsync(): Promise<void> {
    this.duck.userProfileRequest();
    try {
      const [ profil, lieferant ] = await Promise.all<BenutzerProfil, Lieferant>([
        this.service.loadUserProfile().toPromise(),
        this.service.loadMeinLieferant().toPromise(),
      ]);

      let namedViews: IViews = profil.clientInfo && profil.clientInfo.namedViews || null; // Bug 60373, kein Default-View gewünscht

      const hasDefault = _.some(namedViews, (v: { view: IView }) => v.view.isDefaultView);

      namedViews = {
        ...this.getSystemViews(!hasDefault),
        ...namedViews,
      };

      this.panelDucks.setNamedViews(namedViews);
      this.panelDucks.applyDefaultView();
      this.duck.userProfileResponse(this.createUserProfileResponse(profil, lieferant));
    } catch (error) {
      this.duck.userProfileError(error);
    }
  }

  public async addFavoriteAsync(gsblId: number) {
    const favorit = await this.service.setzeFavorit(gsblId).toPromise();
    this.duck.addFavoriteSuccess(favorit);
  }

  public async removeFavoriteAsync(gsblId: number) {
    await this.service.entferneFavorit(gsblId).toPromise();
    this.duck.removeFavoriteSuccess(gsblId);
  }

  public async addFavouriteMerkmalAsync(merkmal: FavoriteMerkmal) {
    const favouriteMerkmale = await this.service.addFavouriteMerkmal(merkmal).toPromise();
    this.duck.setFavoriteMerkmale(favouriteMerkmale);
  }

  public async saveClientInfoAsync(clientInfo: ClientInfo) {
    try {
      await this.service.saveClientInfo(clientInfo).toPromise();
      this.duck.saveClientInfoSuccess();
    } catch (error) {
      this.duck.saveClientInfoError();
    }
  }

  private createUserProfileResponse(profil: BenutzerProfil, lieferant: Lieferant) {
    return {
      favorites: profil.favoriten,
      fachlektorMerkmale: new Set<number>(profil.fachlektorMerkmale),
      lieferantenMerkmale: new Set<number>(lieferant.merkmalIds),
      clientInfo: profil.clientInfo,
      favouriteMerkmale: localStorage.getItem('favouriteMerkmale') ? JSON.parse(localStorage.getItem('favouriteMerkmale')) : [],
    };
  }

  private getSystemViews(isDefault: boolean): IViews {
    const viewNameAllAttributesVisible = this.translateService.instant('components.dossier.system.allAttributesVisible');
    const viewNameAllAttributesButCollapsed = this.translateService.instant('components.dossier.system.allAttributesButCollapsed');
    const systemViews = {
      [ viewNameAllAttributesVisible ]: {
        view: {
          id: viewNameAllAttributesVisible,
          viewName: viewNameAllAttributesVisible,
          isDefaultView: false,
          showAllMerkmale: true,
          isSystem: true,
          shownMerkmale: {},
          panels: {},
          openAllPanels: true
        },
      },
      [ viewNameAllAttributesButCollapsed ]: {
        view: {
          id: viewNameAllAttributesButCollapsed,
          viewName: viewNameAllAttributesButCollapsed,
          isDefaultView: isDefault,
          showAllMerkmale: true,
          isSystem: true,
          shownMerkmale: {},
          panels: {},
          openAllPanels: false
        },
      },
    };
    return systemViews;
  }
}
