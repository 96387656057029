<ng-container *ngIf="(maintenance$ | async) as maintenance; else noMaintenance">
  <div style="text-align: center; font-size: 16px; padding-top: 2em">
    <p><i class="fas fa-tools" style="font-size: 48px"></i></p>
    <p style="font-size: 1.5em">{{'maintenance.title' | translate}}</p>
    <p *ngIf="maintenance.reason">{{maintenance.reason}}</p>
    <p *ngIf="maintenance.endDate">{{'maintenance.plannedEnd' | translate}}: {{formatDate(
      maintenance.endDate)}} {{'components.maintenance.uhr' | translate}}</p>
      <button (click)="signOut()"
      class="sign-out"
      id="btn_sign-out"
      title="{{'components.navbar.signout' | translate}}">
     <i class="fas fa-sign-out-alt">
     </i> {{'action.signout' | translate}}
    </button>
  </div>
</ng-container>
<ng-template #noMaintenance>

  <ng-container *ngIf="Initialized">
    <app-navbar *ngIf="(maintenance$ | async) === null"></app-navbar>
    <ngx-toasta [position]="'top-right'"></ngx-toasta>
    <div (window:resize)="onResize()" [ngClass]="{'navbar-md-active': !toggle, 'navbar-sm-active': toggle}">
      <router-outlet></router-outlet>
      <div kendoDialogContainer></div>
      <div kendoWindowContainer></div>
    </div>
  </ng-container>

  <router-outlet *ngIf="router.url === '/login'"></router-outlet>

</ng-template>
