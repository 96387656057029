import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { AuthModule } from 'angular-auth-oidc-client';
import { NavbarComponent } from 'app/core/layout/navbar/navbar.component';
import { GlobalModule } from 'app/core/services/global/global.module';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { ClickOutsideModule } from 'app/shared/directives/click-outside/click-outside.module';
import { HasrightModule } from 'app/shared/directives/hasright/hasright.module';
import { CustomWindowModule } from 'app/core/services/custom-window-service/custom-window.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HasrightModule,
    TranslateModule,
    I18nModule,
    DialogModule,
    AuthModule,
    GlobalModule,
    WindowModule,
    ClickOutsideModule,
    CustomWindowModule
  ],
  declarations: [ NavbarComponent ],
  exports: [ NavbarComponent ]
})
export class NavbarModule {}
