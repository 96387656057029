import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { ErfassungListItem, RequestForApprovalBatch } from 'app/core/services/api-client';
import { IErfassungenState } from './erfassungen.state';

export * from './erfassungen.state';

@Ducksify<IErfassungenState>({
  initialState: {
    isLoadingErfassungFreigabeList: false,
    isLoadingErfassungen: false,
    isLoadingAllErfassungen: false,
    erfassungen: [],
    erfassungFreigabeList: [],
    allErfassungen: [],
  },
})
export class ErfassungenDucks {

  @Action('[Erfassungen] Submit Erfassungen')
  public submitErfassungen(state: IErfassungenState, batch: RequestForApprovalBatch): typeof state {
    return {
      ...state,
      isLoadingErfassungen: true
    };
  }

  @Action('[Erfassungen] Submit Erfassungen Error')
  public submitErfassungenError(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungen: false
    };
  }

  @Action('[Erfassungen] Fachlektorfreigabe')
  public approveAsLecturer(state: IErfassungenState, batch: number[]): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: true
    };
  }

  @Action('[Erfassungen] Fachlektorfreigabe Error')
  public approveAsLecturerError(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: false
    };
  }

  @Action('[Erfassungen] Administratorfreigabe')
  public approveAsAdmin(state: IErfassungenState, batch: number[]): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: true
    };
  }

  @Action('[Erfassungen] Administratorfreigabe Error')
  public approveAsAdminError(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: false
    };
  }

  @Action('[Erfassungen] Load ErfassungFreigabe List')
  public loadErfassungFreigabeList(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: true,
    };
  }

  @Action('[Erfassungen] Load ErfassungFreigabe List Success')
  public loadErfassungFreigabeListSuccess(state: IErfassungenState, erfassungFreigabeList: ErfassungListItem[]): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: false,
      erfassungFreigabeList: erfassungFreigabeList,
    };
  }

  @Action('[Erfassungen] Load ErfassungFreigabe List Error')
  public loadErfassungFreigabeListError(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungFreigabeList: false,
      erfassungFreigabeList: [],
    };
  }

  @Action('[Erfassungen] Load Erfassungen')
  public loadErfassungen(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungen: true,
    };
  }

  @Action('[Erfassungen] Load Erfassungen Success')
  public loadErfassungenSuccess(state: IErfassungenState, erfassungen: ErfassungListItem[]): typeof state {
    return {
      ...state,
      isLoadingErfassungen: false,
      erfassungen: erfassungen
    };
  }

  @Action('[Erfassungen] Load Erfassungen Error')
  public loadErfassungenError(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingErfassungen: false,
    };
  }

  @Action('[Erfassungen] Load all Erfassungen')
  public loadAllErfassungen(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingAllErfassungen: true,
    };
  }

  @Action('[Erfassungen] Load all Erfassungen Success')
  public loadAllErfassungenSuccess(state: IErfassungenState, allErfassungen: ErfassungListItem[]): typeof state {
    return {
      ...state,
      isLoadingAllErfassungen: false,
      allErfassungen,
    };
  }

  @Action('[Erfassungen] Load all Erfassungen Error')
  public loadAllErfassungenError(state: IErfassungenState): typeof state {
    return {
      ...state,
      isLoadingAllErfassungen: false,
    };
  }

  @Action('[Erfassungen] Dismiss')
  public dismiss(state: IErfassungenState, item: ErfassungListItem) {
    const items = state.erfassungen;
    const index = items.findIndex(e => e.erfassungId === item.erfassungId);
    if (index > -1) {
      items.splice(index, 1);
    }
    return {
      ...state,
      erfassungen: items,
    };
  }

  @Action('[Erfassungen] Dismiss Success')
  public dismissSuccess(state: IErfassungenState) {
    return {
      ...state,
    };
  }

  @Action('[Erfassungen] Dismiss Error')
  public dismissError(state: IErfassungenState) {
    return {
      ...state,
    };
  }
}

export function erfassungenReducers(state: IErfassungenState, action: DucksifiedAction): typeof state {
  return reducerFrom(ErfassungenDucks)(state, action);
}

