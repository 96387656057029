import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { IPanelState } from './panel.state';
import { IView } from './models/IView';
import { IViews } from './models/IViews';
import { IVisiblePanels } from 'app/modules/shared-area/ducks/panel/models/IVisiblePanels';
import { isNil } from 'lodash';

export { IPanelSelector } from 'app/modules/shared-area/ducks/panel/models/IPanelSelector';
export { IVisiblePanels } from 'app/modules/shared-area/ducks/panel/models/IVisiblePanels';
export { IVisiblePanel } from 'app/modules/shared-area/ducks/panel/models/IVisiblePanel';
export * from './panel.state';

@Ducksify<IPanelState>({
  initialState: {
    dossierView: <IView>{ panels: {}, shownMerkmale: {} },
    searchView: <IView>{ panels: {}, shownMerkmale: {} },
    namedViews: <IViews>{},
    feedbackView: <IView>{ panels: {} },
    configurationsView: <IView>{ panels: {} },
    psatzView: <IView>{ panels: {} },
  },
})
export class PanelDucks {

  @Action('[Panels] Update ShownMerkmale')
  public updateShownMerkmale(state: IPanelState, merkmale: IVisiblePanels): typeof state {
    return {
      ...state,
      dossierView: {
        ...state.dossierView,
        shownMerkmale: merkmale,
      },
    };
  }

  @Action('[Panels] Toggle panel')
  public togglePanel(state: IPanelState, selector: string): typeof state {
    const currentState = { ...state };
    const visibility = getVisibility(currentState, selector);
    updatePanels(currentState, selector, !visibility);

    return {
      ...state,
      dossierView: currentState.dossierView,
      searchView: currentState.searchView,
      feedbackView: currentState.feedbackView,
      configurationsView: currentState.configurationsView,
      psatzView: currentState.psatzView
    };
  }

  @Action('[Panels] Show panel')
  public showPanel(state: IPanelState, selector: string): typeof state {
    const currentState = { ...state };
    updatePanels(currentState, selector, true);
    return {
      ...state,
      dossierView: currentState.dossierView,
      searchView: currentState.searchView,
      feedbackView: currentState.feedbackView,
      configurationsView: currentState.configurationsView,
      psatzView: currentState.psatzView
    };
  }

  @Action('[Panels] Hide panel')
  public hidePanel(state: IPanelState, selector: string): typeof state {
    const currentState = { ...state };
    updatePanels(currentState, selector, false);
    return {
      ...state,
      dossierView: currentState.dossierView,
      searchView: currentState.searchView,
      feedbackView: currentState.feedbackView,
      configurationsView: currentState.configurationsView,
      psatzView: currentState.psatzView
    };
  }

  @Action('[Panels] Add a new view')
  public addView(state: IPanelState, view: IView): typeof state {
    const namedViews = { ...state.namedViews };
    return {
      ...state,
      namedViews: addView(namedViews, view),
    };
  }

  @Action('[Panels] Copies the default view into the current view')
  public applyDefaultView(state: IPanelState): typeof state {
    const namedViews = { ...state.namedViews };
    return {
      ...state,
      dossierView: applyDefaultView(namedViews),
    };
  }

  @Action('[Panels] Changes the default view')
  public changeDefaultView(state: IPanelState, id: string): typeof state {
    const namedViews = { ...state.namedViews };
    changeDefaultView(namedViews, id);
    return {
      ...state,
      namedViews: namedViews,
    };
  }

  @Action('[Panels] Deletes a named view')
  public deleteNamedView(state: IPanelState, viewName: string): typeof state {
    const namedViews = { ...state.namedViews };
    deleteNamedView(namedViews, viewName);
    return {
      ...state,
      namedViews: namedViews,
    };
  }

  @Action('[Panels] Applies a named view to the current')
  public applyNamedView(state: IPanelState, viewName: string): typeof state {
    const namedViews = { ...state.namedViews };
    const currentView = applyNamedView(namedViews, viewName);
    return {
      ...state,
      dossierView: currentView,
    };
  }

  @Action('[Panels] Sets the named views from API')
  public setNamedViews(state: IPanelState, views: IViews): typeof state {
    return {
      ...state,
      namedViews: views,
    };
  }

  @Action('[Panels] Clears the current view')
  public clearCurrentView(state: IPanelState, { showAllMerkmale, openAllPanels }: { showAllMerkmale: boolean, openAllPanels: boolean }): typeof state {
    return {
      ...state,
      dossierView: getEmptyView(showAllMerkmale, openAllPanels),
    };
  }
  @Action('[Panels] Close all panels')
  public closeAllPanels(state: IPanelState): typeof state {
    return {
      ...state,
      dossierView: getEmptyView(true, false)
    };
  }
}

function updatePanels(state: IPanelState, selector: string, isVisible: boolean): void {
  if (selector.indexOf('search_') === 0) {
    const searchView = { ...state.searchView };
    const searchPanels = { ...searchView.panels };
    searchPanels[selector] = isVisible;
    searchView.panels = searchPanels;
    state.searchView = searchView;
  } else if (selector.indexOf('feedback_') === 0) {
    const feedbackView = { ...state.feedbackView };
    const feedbackPanels = { ...feedbackView.panels };
    feedbackPanels[selector] = isVisible;
    feedbackView.panels = feedbackPanels;
    state.feedbackView = feedbackView;
  } else if (selector.indexOf('configurations_') === 0) {
    const configurationsView = { ...state.configurationsView };
    const configurationsViewPanels = { ...configurationsView.panels };
    configurationsViewPanels[selector] = isVisible;
    configurationsView.panels = configurationsViewPanels;
    state.configurationsView = configurationsView;
  } else if (selector.indexOf('psatz_') === 0) {
    const psatzView = { ...state.psatzView };
    const psatzPanels = { ...psatzView.panels };
    psatzPanels[selector] = isVisible;
    psatzView.panels = psatzPanels;
    state.psatzView = psatzView;
  } else {
    const dossierView = { ...state.dossierView };
    const dossierPanels = { ...dossierView.panels };
    dossierPanels[selector] = isVisible;
    dossierView.panels = dossierPanels;
    state.dossierView = dossierView;
  }
}

function getVisibility(state: IPanelState, selector: string): boolean {
  const currentDossierView: IView = { ...state.dossierView };
  const currentSearchView: IView = { ...state.searchView };
  const currentFeedbackView: IView = { ...state.feedbackView };
  const currentConfigurationsView: IView = { ...state.configurationsView };
  const currentPsatzView: IView = { ...state.psatzView };

  let visibility = false;
  if (selector.indexOf('search_') === 0) {
    visibility = <boolean>currentSearchView.panels[selector];
  } else if (selector.indexOf('feedback_') === 0) {
    visibility = <boolean>currentFeedbackView.panels[selector];
  } else if (selector.indexOf('configurations_') === 0) {
    visibility = <boolean>currentConfigurationsView.panels[selector];
  } else if (selector.indexOf('psatz_') === 0) {
    visibility = <boolean>currentPsatzView.panels[selector];
  } else {
    const panelState = <boolean>currentDossierView.panels[selector];
    visibility = isNil(panelState) ? currentDossierView.openAllPanels : panelState;
  }
  return visibility;
}

function addView(views: IViews, view: IView): IViews {

  const currentView = <IView>{};
  currentView.viewName = view.viewName;
  currentView.id = null;
  currentView.isDefaultView = view.isDefaultView == null ? currentView.isDefaultView : view.isDefaultView;
  currentView.copyOf = view.id;
  currentView.panels = view.panels;
  currentView.openAllPanels = false;
  currentView.shownMerkmale = view.shownMerkmale;
  currentView.isSystem = false;
  currentView.showAllMerkmale = false;
  views[currentView.viewName] = { view: currentView };
  if (view.isDefaultView) {
    changeDefaultView(views, view.viewName);
  }

  return views;
}

function applyDefaultView(namedViews: IViews): IView {

  const currentView = <IView>{
    isDefaultView: false,
    viewName: null,
    panels: {},
    shownMerkmale: {},
  };

  for (const view in namedViews) {
    if (namedViews.hasOwnProperty(view) && namedViews[view].view.isDefaultView) {
      const config = namedViews[view].view;
      currentView.viewName = config.viewName;
      currentView.panels = config.panels;
      currentView.openAllPanels = config.openAllPanels;
      currentView.shownMerkmale = config.shownMerkmale;
      currentView.showAllMerkmale = config.showAllMerkmale;
      currentView.isSystem = config.isSystem;
    }
  }
  return currentView;
}

function applyNamedView(namedViews: IViews, viewName: string): IView {

  const currentView = <IView>{
    isDefaultView: true,
    viewName: viewName,
    id: null,
    panels: {},
    shownMerkmale: {},
  };
  for (const view in namedViews) {
    if (namedViews.hasOwnProperty(view)) {
      const config = namedViews[viewName].view;
      currentView.viewName = config.viewName;
      currentView.panels = config.panels;
      currentView.openAllPanels = config.openAllPanels;
      currentView.shownMerkmale = config.shownMerkmale;
      currentView.showAllMerkmale = config.showAllMerkmale;
      currentView.isSystem = config.isSystem;
    }
  }
  return currentView;
}

function changeDefaultView(namedViews: IViews, selector: string): void {
  for (const view in namedViews) {
    if (namedViews.hasOwnProperty(view)) {
      namedViews[view].view.isDefaultView = false;
      if (view === selector) {
        namedViews[view].view.isDefaultView = true;
      }
    }
  }
}

function deleteNamedView(namedViews: IViews, viewName: string): void {

  for (const view in namedViews) {
    if (namedViews.hasOwnProperty(view) && namedViews[view].view.viewName === viewName) {
      delete namedViews[view];
    }
  }
}

function getEmptyView(showAllMerkmale = false, openAllPanels = false): IView {

  const currentView = <IView>{
    isDefaultView: false,
    viewName: null,
    panels: {},
    openAllPanels,
    shownMerkmale: {},
    showAllMerkmale,
    isSystem: false,
  };
  return currentView;
}

export function panelReducer(state: IPanelState, action: DucksifiedAction): typeof state {
  return reducerFrom(PanelDucks)(state, action);
}


