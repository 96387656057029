import { NgModule } from '@angular/core';
import { ApiClientModule } from 'app/core/services/api-client/api-client.module';
import { ApiErrorHandlerServiceModule } from 'app/core/services/api-default-error-handler/api-error-handler.service.module';
import { DossierService } from 'app/modules/dossier-area/services/dossier/dossier.service';

@NgModule({
  imports: [
    ApiErrorHandlerServiceModule,
    ApiClientModule,
  ],
  providers: [ DossierService ],
})
export class DossierModule {}

