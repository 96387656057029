import { Inject, Injectable, NgModule } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { SachverhaltBearbeiten, SachverhaltErstellen } from '@models/dossier/dossier-models';
import { Merkmal, MerkmalLock } from '@models/merkmalbaum';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store, StoreModule } from '@ngrx/store';
import { BatchService } from 'app/core/services/batch/batch.service';
import { CustomWindowService } from 'app/core/services/custom-window-service/custom-window.service';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { DossierSachverhaltLoeschenBeantragen } from 'app/modules/dossier-area/ducks/models/dossierSachverhaltLoeschenBeantragen';
import { LoadSachverhalt } from 'app/modules/dossier-area/ducks/models/loadSachverhalt';
import { DossierDucks } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.ducks';
import { getDossier, getDossierType } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.selectors';
import { DossierService } from 'app/modules/dossier-area/services/dossier/dossier.service';
import { MerkmalDucks } from 'app/modules/shared-area/ducks/merkmal/merkmal.ducks';
import { getLastSelectedMerkmal } from 'app/modules/shared-area/ducks/merkmal/merkmal.selectors';
import { SachverhaltCreateComponent } from 'app/modules/shared-area/entry-components/sachverhalt/sachverhalt-create/sachverhalt-create.component';
import { SelectMerkmalDialogComponent } from 'app/modules/shared-area/entry-components/select-merkmal-dialog/select-merkmal-dialog.component';
import { IAppState } from 'app/modules/shared-area/root-state';
import { findIndex } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SachverhaltDucks } from './sachverhalt.ducks';
import { BatchModule } from 'app/core/services/batch/batch.module';
import { CustomWindowModule } from 'app/core/services/custom-window-service/custom-window.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { DossierModule } from 'app/modules/dossier-area/services/dossier/dossier.module';
import { ActionOf } from 'app/core/utils/action-of';
import { DossierHelperService } from 'app/modules/dossier-area/services/dossier-helper/dossier-helper.service';

@NgModule({
  imports: [
    DossierModule,
    I18nModule,
    ValidationModule,
    CustomWindowModule,
    StoreModule,
    BatchModule,
  ],
})
export class SachverhaltEffectsModule { }

@Injectable()
export class SachverhaltEffects {
  constructor(
    private actions$: Actions,
    @Inject(SachverhaltDucks) private sachverhaltDucks: Duck<SachverhaltDucks>,
    @Inject(DossierDucks) private dossierDucks: Duck<DossierDucks>,
    @Inject(MerkmalDucks) private merkmalDucks: Duck<MerkmalDucks>,
    private dossierHelperService: DossierHelperService,
    private dossierService: DossierService,
    private i18n: I18nService,
    private validationService: ValidationService,
    private windowService: CustomWindowService,
    private batchService: BatchService,
    private store: Store<IAppState>) { }

  @Effect()
  DossierSachverhaltLoeschenBeantragen$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.sachverhaltDucks.dossierSachverhaltLoeschenBeantragen.type),
      tap(() => this.dossierDucks.showSmallLoadingSpinner(true)),
      map((action: any) => <DossierSachverhaltLoeschenBeantragen>action.payload),
      switchMap(
        (payload) => this.dossierService.DossierSachverhaltLoeschenBeantragen(payload.id, payload.sachverhaltId)
          .pipe(
            withLatestFrom(this.store.pipe(select(getDossier))),
            map(([partialDossier, dossier]) => this.dossierDucks.partialUpdateDossier.action(this.dossierHelperService.updateDossier(dossier, partialDossier))),
            tap(() => this.windowService.close()),
            catchError((error) => of(this.sachverhaltDucks.dossierSachverhaltLoeschenBeantragenError.action())),
            finalize(() => setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0)), // after rendering in the next tick
          ),
      ),
    );

  @Effect()
  dossierBearbeitenSachverhaltAenderungenVerwerfen$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.sachverhaltDucks.dossierBearbeitenSachverhaltAenderungenVerwerfen.type),
      tap(() => this.dossierDucks.showSmallLoadingSpinner(true)),
      map((action: any) => <DossierSachverhaltLoeschenBeantragen>action.payload),
      switchMap(
        (payload) => this.dossierService.DossierBearbeitenSachverhaltAenderungenVerwerfen(payload.id, payload.sachverhaltId)
          .pipe(
            withLatestFrom(this.store.pipe(select(getDossier))),
            map(([partialDossier, dossier]) => this.dossierDucks.partialUpdateDossier.action(this.dossierHelperService.updateDossier(dossier, partialDossier))),
            tap(() => this.windowService.close()),
            catchError((error) => of(this.sachverhaltDucks.dossierBearbeitenSachverhaltAenderungenVerwerfenError.action(error))),
            finalize(() => setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0)), // after rendering in the next tick
          ),
      ),
    );

  @Effect()
  DossierNeuenSachverhaltLoeschen$: Observable<Action> = this.actions$
    .pipe
    (
      ofType(this.sachverhaltDucks.dossierNeuenSachverhaltLoeschen.type),
      tap(() => this.dossierDucks.showSmallLoadingSpinner(true)),
      map((action: ActionOf<DossierSachverhaltLoeschenBeantragen>) => action.payload),
      switchMap(({ id, sachverhaltId }) =>
        this.dossierService.DossierNeuenSachverhaltLoeschen(id, sachverhaltId)
          .pipe
          (
            withLatestFrom(this.store.pipe(select(getDossier))),
            map(([partialDossier, dossier]) => this.dossierDucks.partialUpdateDossier.action(
              this.dossierHelperService.removeFactFromDossier(dossier, partialDossier, sachverhaltId))),
            tap(() => this.windowService.close()),
            catchError(error => of(this.sachverhaltDucks.dossierNeuenSachverhaltLoeschenError.action())),
            finalize(() => setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0)), // after rendering in the next tick
          ),
      ),
    );

  @Effect({ dispatch: false })
  openSelectMerkmalDialog$ = this.actions$.pipe
    (
      ofType(this.sachverhaltDucks.openSelectMerkmalDialog.type),
      withLatestFrom(
        this.store.pipe(select(getLastSelectedMerkmal)),
      ),
      tap(([, lastMerkmal]) => {
        let dossier = null;
        let dossierType = null;
        this.store.pipe(
          select(getDossier),
          withLatestFrom(
            this.store.pipe(select(getDossierType)),
          ),
          tap(([dossierEntry, dossierTypeEntry]) => {
            dossier = dossierEntry;
            dossierType = dossierTypeEntry;

          }),
          first(),
        )
          .subscribe();
        const locks = (dossier && dossier.locks && dossier.locks.attributeLocks) ? dossier.locks.attributeLocks : [];
        this.createSelectMerkmalDialog(lastMerkmal, locks, dossierType);
      }),
    );

  @Effect()
  createSachverhaltAndOpen$ = this.actions$
    .pipe
    (
      ofType(this.sachverhaltDucks.createSachverhaltAndOpen.type),
      tap(() => this.dossierDucks.showSmallLoadingSpinner(true)),
      map((action: ActionOf<SachverhaltErstellen>) => action.payload),
      switchMap
        (sachverhalt => this.dossierService.DossierNeuenSachverhaltErstellen(sachverhalt.Id, sachverhalt.Merkmal, sachverhalt.Values)
          .pipe
          (
            withLatestFrom(this.store.pipe(select(getDossier))),
            switchMap(([partialDossier, dossier]) => [
              this.dossierDucks.partialUpdateDossier.action(this.dossierHelperService.updateDossier(dossier, partialDossier)),
              this.sachverhaltDucks.openSelectMerkmalDialog.action(),
            ]),
            tap(() => this.windowService.close()),
            catchError(error => this.validationService.createServerValidationErrorActionObservable(error, this.sachverhaltDucks.setServerValidationError)),
            finalize(() => setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0)), // after rendering in the next tick
          ),
        ),
    );

  @Effect()
  loadSachverhalt$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.sachverhaltDucks.loadSachverhaltEffect.type),
      map((action: ActionOf<LoadSachverhalt>) => action.payload),
      switchMap((sachverhalt) => this.dossierService.DossierNeuenSachverhaltErstellenAnzeigen(sachverhalt.id, sachverhalt.merkmal)
        .pipe(
          map(data => this.sachverhaltDucks.loadSachverhaltSuccess.action(data)),
          catchError(error => of(this.sachverhaltDucks.loadSachverhaltError.action())),
        ),
      ),
    );
  @Effect()
  speichereSachverhaltInDossier$ = this.actions$
    .pipe
    (
      ofType(this.sachverhaltDucks.speichereSachverhaltInDossier.type),
      tap(() => this.dossierDucks.showSmallLoadingSpinner(true)),
      map((action: ActionOf<SachverhaltBearbeiten>) => action.payload),
      switchMap(sachverhaltBearbeiten => this.dossierService
        .DossierSachverhaltBearbeiten(sachverhaltBearbeiten.Id, sachverhaltBearbeiten.SachverhaltId, sachverhaltBearbeiten.Values)
        .pipe
        (
          withLatestFrom(this.store.pipe(select(getDossier))),
          map(([partialDossier, dossier]) => this.dossierDucks.partialUpdateDossier.action(this.dossierHelperService.updateDossier(dossier, partialDossier))),
          tap(() => this.windowService.close()),
          catchError(error => this.validationService.createServerValidationErrorActionObservable(error, this.sachverhaltDucks.setServerValidationError)),
          finalize(() => setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0)), // after rendering in the next tick
        ),
      ),
    );

  @Effect()
  bearbeiteNeuenSachverhaltInDossier$ = this
    .actions$
    .pipe(
      ofType(this.sachverhaltDucks.bearbeiteNeuenSachverhaltInDossier.type),
      tap(() => this.dossierDucks.showSmallLoadingSpinner(true)),
      map((action: any) => <SachverhaltBearbeiten>action.payload),
      switchMap(sachverhalt => this
        .dossierService.DossierNeuenSachverhaltBearbeiten(sachverhalt.Id, sachverhalt.ErfassungSachverhaltId, sachverhalt.Values)
        .pipe(
          withLatestFrom(this.store.pipe(select(getDossier))),
          map(([partialDossier, dossier]) => this.dossierDucks.partialUpdateDossier.action(this.dossierHelperService.updateDossier(dossier, partialDossier))),
          tap(() => this.windowService.close()),
          catchError(error => this.validationService.createServerValidationErrorActionObservable(error, this.sachverhaltDucks.setServerValidationError)),
          finalize(() => setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0)), // after rendering in the next tick
        ),
      ),
    );

  private createSelectMerkmalDialog(lastMerkmal: string, locks: MerkmalLock[], dossierType: number) {
    this.windowService.open({
      title: this.i18n.getLocalizedString('dialog.addFactTitle'),
      content: SelectMerkmalDialogComponent,
      top: 75,
      left: window.innerWidth / 2 - 375,
      minWidth: 750,
      resizable: false
    });
    this.configureSelectMerkmalDialog(lastMerkmal, locks, dossierType);
  }

  private isLocked(merkmalId: number, locks: MerkmalLock[]): boolean {
    return findIndex(locks, lock => lock.merkmalId === merkmalId) !== -1;
  }

  private configureSelectMerkmalDialog(lastMerkmal: string, locks: MerkmalLock[], dossierType: number) {
    const selectMerkmalDialog: SelectMerkmalDialogComponent = this.windowService.getWindow().content.instance;
    // show only 2 levels of the tree
    selectMerkmalDialog.limitLevel = 2;
    // select last merkmal in tree
    selectMerkmalDialog.merkmal = lastMerkmal;
    selectMerkmalDialog.merkmalLocks = locks;
    selectMerkmalDialog.filterByLieferant = true;
    selectMerkmalDialog.allowedDossierType = dossierType;
    selectMerkmalDialog.excludeRoot = true;
    selectMerkmalDialog.selectMerkmal.pipe
      (
        // only possible to click on type Merkmal
        filter((merkmal: Merkmal) => merkmal.type === 'Merkmal'),

        // is merkmal locked by another user?
        filter((merkmal) => !this.isLocked(merkmal.id, locks)),
        map(merkmal => merkmal.key),
        // save last merkmal in store
        tap(key => this.merkmalDucks.setSelectedMerkmal(key)),
        // reset previous edit set (used for duplicate merkmale)
        tap(() => this.sachverhaltDucks.loadSachverhaltEditSet({ sachverhaltId: null, erfassungSachverhaltId: null })),
        // open create sachverhalt window
        tap(() => {
          this.windowService.close();
          this.windowService.open({
            title: this.i18n.getLocalizedString('dialog.createSachverhaltTitle'),
            content: SachverhaltCreateComponent,
            top: 75,
            left: window.innerWidth / 2 - 375,
            minWidth: 750,
            resizable: false
          });
        }),
        first(),
      )
      .subscribe();
  }

}
