import { Inject, Injectable } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { WartungsarbeitenDucks } from './wartungsarbeiten.ducks';
import { WartungsarbeitenService } from '../../../../core/services/wartungsarbeiten-service/wartungsarbeiten-service.service';
import { MaintenanceWindow } from '../../../../core/services/api-client';
import { SystemInfoDucks } from '../system-info/system-info.ducks';

@Injectable()
export class WartungsarbeitenEffects {
  public constructor(
    @Inject(WartungsarbeitenDucks) private readonly wartungsarbeitenDuck: Duck<WartungsarbeitenDucks>,
    @Inject(SystemInfoDucks) private systemInfoDucks: Duck<SystemInfoDucks>,
                     private readonly service: WartungsarbeitenService) {}

  public async loadMaintenanceWindowsAsync(): Promise<void>  {
    this.wartungsarbeitenDuck.wartungsarbeitenRequest();
    try {
      const maintenanceWindows = await this.service.getMaintenanceWindows().toPromise();
      this.wartungsarbeitenDuck.loadWartungsarbeitenSuccess(maintenanceWindows);
    } catch (error) {
      this.wartungsarbeitenDuck.wartungsarbeitenError(error);
    }
  }

  public async updateMaintenanceWindow(window: MaintenanceWindow) {
    const updated = await this.service.updateMaintenanceWindow(window).toPromise();
    this.wartungsarbeitenDuck.updateWartungsarbeiten(updated);
    this.systemInfoDucks.loadSystemInfoEffect.dispatch();
  }

  public async createNewMaintenanceWindow(maintenanceWindow: MaintenanceWindow) {
    const newWindow = await this.service.addMaintenanceWindow(maintenanceWindow).toPromise();
    this.wartungsarbeitenDuck.postWartungsarbeiten(newWindow);
    this.systemInfoDucks.loadSystemInfoEffect.dispatch();
  }
}
