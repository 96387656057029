import { Component, ViewEncapsulation, Input } from '@angular/core';
import {
  ConfigurationRankingStep,
  ConfigurationRankingCascade
} from 'app/core/services/api-client';
import * as _ from 'lodash';
import { DropdownListTextItem } from '@models/dropdownListTextItem';
import { I18nService } from 'app/core/services/i18n/i18n.service';

@Component({
  selector: 'app-configurations-ranking-step',
  templateUrl: './configuration-ranking-step.component.html',
  styleUrls: ['./configuration-ranking-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationRankingStepComponent {
  @Input()
  public selectedRankingStep: ConfigurationRankingCascade[] = [];
  @Input()
  public selectedKey: string;
  @Input()
  public rankingRulesListItems: DropdownListTextItem[];

  public defaultRankingRuleListItem: DropdownListTextItem = {
    text: this.i18n.getLocalizedString('action.selectRule'),
    value: undefined
  };

  constructor(private i18n: I18nService) {}

  public addRow(tableIndex: number) {
    const row = this.getNewRow();
    this.selectedRankingStep[tableIndex].steps.push(row);
  }

  public deleteRow(tableIndex: number, rowIndex: number) {
    if (
      this.selectedRankingStep[tableIndex] &&
      this.selectedRankingStep[tableIndex].steps &&
      this.selectedRankingStep[tableIndex].steps.length >= rowIndex
    ) {
      this.selectedRankingStep[tableIndex].steps.splice(rowIndex, 1);
    }
  }

  public addTable() {
    const newTable = this.getNewTable();
    this.selectedRankingStep.push(newTable);
  }

  public deleteTable(tableIndex: number) {
    if (
      this.selectedRankingStep &&
      this.selectedRankingStep.length >= tableIndex
    ) {
      this.selectedRankingStep.splice(tableIndex, 1);
    }
  }

  private getNewRow(): ConfigurationRankingStep {
    const newRow = new ConfigurationRankingStep();
    newRow.isConditional = false;
    newRow.ruleName = undefined;
    return newRow;
  }

  private getNewTable(): ConfigurationRankingCascade {
    const newCascade = new ConfigurationRankingCascade();
    newCascade.steps = new Array<ConfigurationRankingStep>();
    newCascade.firstOrDefault = false;
    const newRow = this.getNewRow();
    newCascade.steps.push(newRow);
    return newCascade;
  }
}
