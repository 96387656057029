import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { RangeFieldComponent } from 'app/shared/components/range-field/range-field.component';
import { IntlModule } from '@progress/kendo-angular-intl';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    I18nModule,
    DropDownsModule,
    NumericTextBoxModule,
    IntlModule
  ],
  declarations: [RangeFieldComponent],
  exports: [RangeFieldComponent]
})
export class RangeFieldModule { }
