import { map } from 'lodash';
import { createSelector } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { KeyValue } from '@angular/common';
import { IView } from 'app/modules/shared-area/ducks/panel/models/IView';

export const panelState = (state: IAppState) => state.shared.panels;

export const getDossierView = createSelector(panelState, state => state.dossierView);
export const getSearchPanels = createSelector(panelState, state => state.searchView.panels);
export const getFeedbackPanels = createSelector(panelState, state => state.feedbackView.panels);
export const getConfigurationsPanels = createSelector(panelState, state => state.configurationsView.panels);
export const getPsatzPanels = createSelector(panelState, state => state.psatzView.panels);

export const getDefaultViewName = createSelector(panelState, state => {
    const namedViews = state.namedViews;
    let id = null;
    for (const view in namedViews) {
      if (namedViews.hasOwnProperty(view) && namedViews[ view ].view.isDefaultView) {
        id = view;
      }
    }
    return id;
  },
);

export const getNamedViews = createSelector(panelState, state => state.namedViews);

function compareViewsAlphabetically(a: IView, b: IView) {
  if (a.viewName < b.viewName) { return -1; }
  if (a.viewName > b.viewName) { return 1; }
  return 0;
}

function compareViews(a: KeyValue<string, { view: IView }>, b: KeyValue<string, { view: IView }>): number {
  if (a.value.view.isSystem) {
    if (b.value.view.isSystem) {
      return compareViewsAlphabetically(a.value.view, b.value.view);
    } else {
      return -1;
    }
  } else {
    if (b.value.view.isSystem) {
      return 1;
    } else {
      return compareViewsAlphabetically(a.value.view, b.value.view);
    }
  }
}

export const getNamedViewsKeyValue = createSelector(getNamedViews, namedViews => {
  const keyValueViews = map(namedViews, (view: { view: IView }) => ({ key: view.view.viewName, value: view }));
  keyValueViews.sort(compareViews);
  return keyValueViews;
});
