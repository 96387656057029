import { ObjectAttributeLock, ObjectLocks } from 'app/core/services/api-client';

export class AttributeLock {
  merkmalId: number;
  lockedBy: string;

  public static fromDto(dto: ObjectAttributeLock): AttributeLock {
    if (dto == null) {
      return null;
    }
    const vm = new AttributeLock();
    vm.merkmalId = dto.merkmalId;
    vm.lockedBy = dto.lockedBy;
    return vm;
  }
}

export class Locks {
  lockedBy: string;
  attributeLocks: AttributeLock[];

  public static fromDto(dto: ObjectLocks): Locks {
    if (dto == null) {
      return null;
    }
    const vm = new Locks();
    vm.lockedBy = dto.lockedBy;
    if (dto.attributeLocks) {
      vm.attributeLocks = dto.attributeLocks.map(x => AttributeLock.fromDto(x));
    }
    return vm;
  }
}
