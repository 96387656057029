import { Injectable } from '@angular/core';
import { ApiClient, BenutzerProfil, Favorit, Lieferant } from 'app/core/services/api-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';
import { Observable, of as ObservableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientInfo } from './models/clientInfo';
import { FavoriteMerkmal } from '@models/dossier/dossier-models';
import * as _ from 'lodash';

@Injectable()
export class BenutzerprofilService {

  private MAX_FAVOURITE_MERKMALE = 15;

  public constructor(private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService) { }

  public loadUserProfile(): Observable<BenutzerProfil> {
    return this.apiClient.apiMeinBenutzerProfilGet()
      .pipe(
        catchError(this.apiClient.apiMeinBenutzerProfilGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401)
        }))
      );
  }

  public loadMeinLieferant(): Observable<Lieferant> {
    return this.apiClient.apiMeinLieferantGet()
      .pipe(
        catchError(this.apiClient.apiMeinLieferantGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, {
            messageKey: 'errors.404.apiMeinLieferantGet', // Lieferant existiert nicht
          }),
        }))
      );
  }

  public setzeFavorit(gsblId: number): Observable<Favorit> {
    return this.speichereFavorit(gsblId, true);
  }

  public entferneFavorit(gsblId: number): Observable<Favorit> {
    return this.speichereFavorit(gsblId, false);
  }

  private speichereFavorit(gsblId: number, isFavorit: boolean): Observable<Favorit> {
    return this.apiClient.apiMeinBenutzerProfilFavoritenByGsblIdPut(gsblId, new Favorit({ gsblId, isFavorit }))
      .pipe(
        catchError(this.apiClient.apiMeinBenutzerProfilFavoritenByGsblIdPutErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, {
            messageKey: 'errors.404.apiMeinBenutzerProfilFavoritenByGsblIdPut', // Benutzerprofil oder Stoff existiert nicht
          })
        }))
      );
  }

  public addFavouriteMerkmal(merkmal: FavoriteMerkmal): Observable<FavoriteMerkmal[]> {
    return this.saveFavouriteMerkmal(merkmal);
  }
  private saveFavouriteMerkmal(merkmal: FavoriteMerkmal): Observable<FavoriteMerkmal[]> {
    let localFavouriteMerkmale = JSON.parse(localStorage.getItem('favouriteMerkmale')) as FavoriteMerkmal[] || [];
    localFavouriteMerkmale = localFavouriteMerkmale.filter(m => m.key !== merkmal.key);
    localFavouriteMerkmale = _.uniqBy(localFavouriteMerkmale, 'key');
    merkmal.timestamp = new Date();
    localFavouriteMerkmale.push(merkmal);
    localFavouriteMerkmale = _.orderBy(localFavouriteMerkmale,
      function (m) {
        return - (new Date(m.timestamp).getTime());
      });
    localFavouriteMerkmale = localFavouriteMerkmale.slice(0, this.MAX_FAVOURITE_MERKMALE);
    const newFavouriteMerkmale = [...new Set(localFavouriteMerkmale)];
    localStorage.setItem('favouriteMerkmale', JSON.stringify(newFavouriteMerkmale));
    return ObservableOf(newFavouriteMerkmale);
  }

  public saveClientInfo(clientInfo: ClientInfo): Observable<ClientInfo> {
    return this.apiClient.apiMeinBenutzerProfilClientInfoPut(clientInfo)
      .pipe(
        catchError(this.apiClient.apiMeinBenutzerProfilClientInfoPutErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }))
      ) as Observable<ClientInfo>;
  }

  public loadClientInfo(): Observable<ClientInfo> {
    return this.apiClient.apiMeinBenutzerProfilClientInfoGet()
      .pipe(
        catchError(this.apiClient.apiMeinBenutzerProfilClientInfoGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }))
      ) as Observable<ClientInfo>;
  }

}
