import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { RankingAuftragResultComponent } from './ranking-auftrag-result.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
  imports: [
    GridModule,
    I18nModule,
    TranslateModule,
    CommonModule,
    ButtonModule,
    GridModule,
    I18nModule,
    TranslateModule,
    CommonModule,
    TooltipModule
  ],
  declarations: [RankingAuftragResultComponent],
  exports: [RankingAuftragResultComponent],
})
export class RankingAuftragResultModule {
}
