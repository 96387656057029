<div *ngIf="!(isSachverhaltLoading$ | async); else loading">

  <app-dynamic-sachverhalte-form [header]="header"
                                 [fields]="fields"
                                 [values]="values"
                                 [emptyFields]="emptyFields"
                                 [serverValidationErrors]="serverValidationErrors$ | async">
  </app-dynamic-sachverhalte-form>


  <div class="window-button-group">
    <button class="btn btn-primary"
            [disabled]="!isFormValid"
            (click)="neuenSachverhaltErstellenUndWeitereAnlegen()"
            id="btn_create-and-reopen"
            *ngIf="!isBatchMode">
      {{'action.createAndReopen' | translate}}
    </button>
    <div style="padding-right:8px"
         *ngIf="isBatchMode">
      <textarea placeholder="{{'dialog.submitMessagePlaceholder' | translate}}"
                class="k-textarea"
                style="display:block; width:100%; margin-bottom:15px"
                [(ngModel)]="message"></textarea>
    </div>
    <button class="btn btn-default"
            [disabled]="!isFormValid"
            (click)="neuenSachverhaltErstellenUndSchliessen()"
            id="btn_create-and-close">
      {{'action.createAndClose' | translate}}
    </button>

    <button class="btn btn-default"
            (click)="close()"
            id="btn_cancel">
      {{'action.cancel' | translate}}
    </button>
  </div>

</div>

<ng-template #loading>
  <div style="text-align: center">
    <i class="k-icon k-i-loading"
       style="font-size:50px; columns: #363636; margin: 20px"></i>
  </div>
</ng-template>
