import { NgModule } from '@angular/core';
import { FileDialogComponent } from 'app/modules/dossier-area/entry-components/file-dialog/file-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CustomToastyModule } from 'app/core/services/customToasty/customToasty.module';
import { TranslateModule } from '@ngx-translate/core';
import { UploadModule } from '@progress/kendo-angular-upload';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CustomToastyModule,
    TranslateModule,
    UploadModule,
  ],
  declarations: [ FileDialogComponent ],
  exports: [ FileDialogComponent ],
})
export class FileDialogModule {}
