import { IViews } from './models/IViews';
import { IView } from './models/IView';

export interface IPanelState {
  dossierView: IView;
  searchView: IView;
  namedViews: IViews;
  feedbackView: IView;
  configurationsView: IView;
  psatzView: IView;
}
