import { Inject, Injectable } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { UserProfileEffects } from 'app/modules/shared-area/ducks/user-profile/user-profile.effects';
import { UserProfileDucks } from 'app/modules/shared-area/ducks/user-profile/user-profile.ducks';
import { Favorit } from 'app/core/services/api-client';
import { ApiState } from '@models/api-state';
import { ClientInfo } from 'app/core/services/benutzerprofil/models/clientInfo';
import { FavoriteMerkmal } from '@models/dossier/dossier-models';

export interface UserProfileState {
  favorites: Favorit[];
  fachlektorMerkmale: Set<number>;
  lieferantenMerkmale: Set<number>;
  apiState: ApiState;
  clientInfoHasChanged: boolean;
  clientInfo?: ClientInfo;
  favouriteMerkmale: FavoriteMerkmal[];
}

@Injectable({
  providedIn: 'root',
})
export class UserProfileStore {
  public constructor(
    @Inject(UserProfileDucks) public readonly ducks: Duck<UserProfileDucks>,
    public readonly effects: UserProfileEffects,
  ) {
  }
}
