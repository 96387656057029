import {NachschlageTabelle} from '../../../../core/services/api-client';
import {Inject, Injectable} from '@angular/core';
import {Duck} from '@ngrx-ducks/core';
import {NachschlagetabellenDucks} from './nachschlagetabellen.ducks';
import {NachschlagetabellenEffects} from './nachschlagetabellen.effects';

export interface INachschlageTabellenState {
  lookupTables: NachschlageTabelle[];
  loading: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NachschlagetabellenStore {
  public constructor(
    @Inject(NachschlagetabellenDucks) public readonly ducks: Duck<NachschlagetabellenDucks>,
    public readonly effects: NachschlagetabellenEffects,
  ) {
  }
}
