import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyValue } from 'app/core/utils/keyValue';
import * as _ from 'lodash';
import { DropdownListTextItem } from '@models/dropdownListTextItem';
import { IntlService } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-range-field',
  templateUrl: './range-field.component.html',
})
export class RangeFieldComponent implements AfterContentInit {

  @Input() field: any;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRangeChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() minOperator: string;
  @Input() minValue: number;
  @Input() maxOperator: string;
  @Input() maxValue: number;
  @Input() plausibility: string;
  @Input() description: string;
  @Input() stringValue: string;
  @Input() stringValueLength: number;
  @Input() stringDescriptionLength: number;

  @Input('maxUnit')
  set maxUnit(value: string) { // null wird immer intern auf DefaultUnit übersetzt
    this.maxUnitInternal = _.isEmpty(value) && !_.isNil(this.field) ? this.field.defaultUnit : value;
  }

  @Input('minUnit')
  set minUnit(value: string) { // null wird immer intern auf DefaultUnit übersetzt
    this.minUnitInternal = _.isEmpty(value) && !_.isNil(this.field) ? this.field.defaultUnit : value;
  }

  minUnitInternal: string;
  maxUnitInternal: string;
  operatorItems: string[];
  unitItems: string[];
  plausibilityItems: string[];
  isInitialized = false;
  isOpen = false;
  hasUnits = false;
  isValid = true;

  private onStringValueChanging = false;
  private decimalSeparator: string;

  constructor(public intl: IntlService) {
    this.decimalSeparator = intl.numberSymbols().decimal;
  }

  ngAfterContentInit(): void {
    this.isInitialized = false;
    if (!_.isNil(this.field)) {
      this.loadConfig();
    }
  }

  private loadConfig() {
    this.plausibilityItems = !_.isNil(this.field.plausibility) ? [ '' ].concat(this.field.plausibility) : [];
    this.operatorItems = !_.isNil(this.field.operators) ? [ '' ].concat(this.field.operators) : [];
    this.unitItems = !_.isNil(this.field.units) ? this.field.units : [];
    if (_.isNil(this.minValue) && _.isEmpty(this.minUnitInternal)) {
      this.minUnitInternal = this.field.defaultUnit;
    }
    if (_.isNil(this.maxValue) && _.isEmpty(this.maxUnitInternal)) {
      this.maxUnitInternal = this.field.defaultUnit;
    }
    this.hasUnits = !_.isNil(this.field.units);
    this.isInitialized = true;
  }

  get litMinOperator() {
    let result = '';
    if (!_.isEmpty(this.minOperator) && !_.isNil(this.operatorItems)) {
      const item = this.operatorItems.find(o => o === this.minOperator);
      if (!_.isNil(item)) {
        result = item;
      }
    }
    return result;
  }

  get litMaxOperator() {
    let result = '';
    if (!_.isEmpty(this.maxOperator) && !_.isNil(this.operatorItems)) {
      const item = this.operatorItems.find(o => o === this.maxOperator);
      if (!_.isNil(item)) {
        result = item;
      }
    }
    return result;
  }

  get hasMaxValue(): boolean {
    return !_.isNil(this.field) && !_.isNil(this.field.maxValueKey);
  }

  public onStringValueChanged(value: string) {
    this.onStringValueChanging = true;
    this.isValid = this.parseStringValue(value);
    this.onStringValueChanging = false;
    this.onRangeChanged.emit(this.createResultDictionary());
  }

  public onValuesChanged() {
    if (this.onStringValueChanging === false) {
      this.updateStringValue();
      this.onRangeChanged.emit(this.createResultDictionary());
    }
  }

  private updateStringValue() {
    let stringValue = '';
    if (!_.isNil(this.minValue)) {
      if (!_.isEmpty(this.minOperator)) {
        stringValue += this.litMinOperator + ' ';
      }
      let stringMinValue = '';
      stringMinValue += this.minValue;
      stringValue += stringMinValue.replace('.', this.decimalSeparator) + ' ';
      if (!_.isEmpty(this.minUnitInternal)) {
        stringValue += this.minUnitInternal + ' ';
      }
      if (!_.isNil(this.maxValue)) {
        stringValue += '- ';
        if (!_.isEmpty(this.maxOperator)) {
          stringValue += this.litMaxOperator + ' ';
        }
        let stringMaxValue = '';
        stringMaxValue += this.maxValue;
        stringValue += stringMaxValue.replace('.', this.decimalSeparator) + ' ';
        if (!_.isEmpty(this.maxUnitInternal)) {
          stringValue += this.maxUnitInternal + ' ';
        }
      }
    }
    stringValue = stringValue.trim();
    this.stringValue = stringValue;
  }

  private consumeNumericValue(input: string): string {
    const regex = new RegExp('^[+-]?[0-9]*[\.,]?[0-9]+([eE][+-]?[0-9]+)?');
    const valueMatch = regex.exec(input.replace(',', '.'));

    if (!_.isNil(valueMatch) && valueMatch.length > 0) {
      const mv = parseFloat(valueMatch[ 0 ]);
      if (!_.isNil(mv)) {
        return valueMatch[ 0 ];
      } else {
        return null;
      }
    }
    return null;
  }

  private consume(items: string[], input: string): string {
    let sorted: any = _.sortBy(items, [
      function (o) {
        return o.length;
      },
    ]);
    sorted = _.reverse(sorted) as DropdownListTextItem[];
    return _.find(sorted, function (item: any) {
      return input.startsWith(item);
    });
  }

  private parseStringValue(originalInput: string): boolean {
    let input = originalInput.trim();

    let minOperator: string = null;
    let minValue = null;
    let minUnit: string = null;
    let maxOperator: string = null;
    let maxValue = null;
    let maxUnit: string = null;
    let isValid = true;

    if (input.length > 0) {
      // MinOperator
      const minOp = this.consume(this.operatorItems, input);
      if (!_.isNil(minOp)) {
        minOperator = minOp;
        input = input.substr(minOp.length)
                     .trim();
      }

      // MinValue
      const minV = this.consumeNumericValue(input);
      if (!_.isNil(minV)) {
        minValue = parseFloat(minV);
        input = input.substr(minV.length)
                     .trim();
      } else {
        isValid = false;
      }

      // MinUnit
      const minU = this.consume(this.unitItems, input);
      if (!_.isNil(minU)) {
        minUnit = minU;
        input = input.substr(minU.length)
                     .trim();
      }

      // Range?
      const isRange = _.startsWith(input, '-');
      if (isRange) {
        input = input.substr(1)
                     .trim();
      }

      if (isRange && this.hasMaxValue) {
        // Max Operator
        const maxOp = this.consume(this.operatorItems, input);
        if (!_.isNil(maxOp)) {
          maxOperator = maxOp;
          input = input.substr(maxOp.length)
                       .trim();
        }

        // MaxValue
        const maxV = this.consumeNumericValue(input);
        if (!_.isNil(maxV)) {
          maxValue = parseFloat(maxV);
          input = input.substr(maxV.length)
                       .trim();
        } else {
          isValid = false;
        }

        // MaxUnit
        const maxU = this.consume(this.unitItems, input);
        if (!_.isNil(maxU)) {
          maxUnit = maxU;
          input = input.substr(maxU.length)
                       .trim();
        }
      } else if (isRange) {
        isValid = false;
      }

      if (input.trim().length > 0) {
        isValid = false;
      }
    }

    if (isValid === true) {
      if (_.isEmpty(maxUnit) && _.isEmpty(minUnit)) {
        minUnit = this.field.defaultUnit;
        maxUnit = minUnit;
      } else if (!_.isEmpty(maxUnit) && _.isEmpty(minUnit)) {
        minUnit = maxUnit;
      } else if (_.isEmpty(maxUnit) && !_.isEmpty(minUnit)) {
        maxUnit = minUnit;
      }

      this.minOperator = minOperator;
      this.minUnit = minUnit;
      this.minValue = minValue;
      this.maxOperator = maxOperator;
      this.maxUnit = maxUnit;
      this.maxValue = maxValue;

      // this.updateStringValue();
    }
    return isValid;
  }

  private createResultDictionary(): any {
    const result = [];
    this.add(result, this.field.minOperatorKey, _.isNil(this.minValue) || _.isEmpty(this.minOperator) ? null : this.minOperator);
    this.add(result, this.field.minValueKey, this.minValue);
    this.add(result, this.field.minUnitKey, _.isNil(this.minValue) || _.isEmpty(this.minUnitInternal) ? null : this.minUnitInternal);
    this.add(result, this.field.maxOperatorKey, _.isNil(this.maxValue) || _.isEmpty(this.maxOperator) ? null : this.maxOperator);
    this.add(result, this.field.maxValueKey, this.maxValue);
    this.add(result, this.field.maxUnitKey, _.isNil(this.maxValue) || _.isEmpty(this.maxUnitInternal) ? null : this.maxUnitInternal);
    this.add(result, this.field.descriptionKey, _.isEmpty(this.description) ? null : this.description);
    this.add(result, this.field.plausibilityKey, _.isEmpty(this.plausibility) ? null : this.plausibility);
    this.add(result, this.field.stringValueKey, _.isEmpty(this.stringValue) ? null : this.stringValue.trim());
    return result;
  }

  private add(list: KeyValue[], key: string, value: any) {
    if (!_.isNil(key)) {
      const item = <KeyValue>{ key: key, value: value };
      list.push(item);
    }
    return list;
  }
}
