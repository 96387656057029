import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/app.module';
import { environment } from '@environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

export const environmentLoader = new Promise<any>((resolve, reject) => {
  const xmlhttp = new XMLHttpRequest(),
    method = 'GET',
    url = './assets/settings.json';
  xmlhttp.open(method, url, true);
  xmlhttp.onload = function () {
    if (xmlhttp.status === 200) {
      resolve(JSON.parse(xmlhttp.responseText));
    } else {
      resolve(environment);
    }
  };
  xmlhttp.send();
});

environmentLoader.then(env => {
  environment.baseAPIUrl = env.baseAPIUrl;
  environment.production = env.production;
  environment.authentication.stsServer = env.authentication.stsServer;
  environment.authentication.redirect_url = env.authentication.redirect_url;
  environment.authentication.post_logout_redirect_uri = env.authentication.post_logout_redirect_uri;
  environment.authentication.log_console_debug_active = env.authentication.log_console_debug_active;
  environment.authentication.log_console_warning_active = env.authentication.log_console_warning_active;
  environment.userManagementUrl = env.userManagementUrl;
  environment.manageUserUrl = env.userManagementProfileUrl;
  environment.changePasswordUrl = env.userManagementPasswordUrl;
  environment.dataModelEditorUrl = env.dataModelEditorUrl;

  if (environment.production) {
    console.log('%c Mode: production ', 'background: #090; color: #fff; font-size: large');
    enableProdMode();
  } else {
    console.log('%c Mode: development ', 'background: #900; color: #fff; font-size: large');
    console.log('%c SETTINGS ', 'background: #333; color: #fff');
    for (const setting of Object.keys(environment)) {
      console.log('%c ' + setting + ': ', 'font-size: x-small; color: #aaa' , JSON.stringify(environment[setting]));
    }
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    })
    .catch(err => console.log(err));
});
