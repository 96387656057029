import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { CommonFeedbackComponent } from 'app/shared/entry-components/common-feedback/common-feedback.component';
import { GlobalService } from 'app/core/services/global/global.service';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { CustomWindowService } from 'app/core/services/custom-window-service/custom-window.service';
import { CreateFeedbackItem, CreateFeedbackItemCategory } from '../../services/api-client';
import { environment } from '@environments/environment';
import { FeedbackService } from 'app/core/services/feedback/feedback.service';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { isMaintenanceWindowRunning } from 'app/modules/shared-area/ducks/system-info/system-info.selectors';
import { Duck } from '@ngrx-ducks/core';
import { SystemInfoDucks } from 'app/modules/shared-area/ducks/system-info/system-info.ducks';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [ './navbar.component.scss' ],
})
export class NavbarComponent implements OnInit, OnDestroy {

  public runningMaintenanceWindow$: Observable<boolean> = EMPTY;
  public dialog;
  public feedbackComponent: CommonFeedbackComponent;
  public result;

  public toggle = false;
  public toggleMes = false;
  public toggleU = false;
  public toggleSucheMenu = true;
  public toggleCreateMenu = true;
  public toggleAdminMenu = true;
  public toggleLanguage = false;
  public feedbackOpen = false;
  public toggleFeedbackMenu = false;
  public visiblitySearch = 'block';
  public visiblityCreate = 'block';
  public visibilityAdmin = 'block';
  public visibilityFeedback = 'block';
  private headerId = 'navHeader';

  // public currentUILanguage: string;
  private lSearchMenuButtons = [ 'btn_search', 'btn_simpleSearch', 'btn_advancedSearch', 'btn_structureSearch', 'btn_expertSearch', 'btn_search_icon' ];
  private lCreateMenuButtons = [ 'btn_create', 'btn_create_icon', 'btn_createDossier', 'btn_create-citation' ];
  private lAdminMenuButtons = [ 'btn_admin', 'btn_admin_icon', 'btn_adminEntries' ];
  private lFeedbackMenuButtons = [ 'btn_feedback', 'btn_feedback-icon', 'btn_feedback-list' ];
  public loggendInUser: string;
  public userManagementUrl = environment.userManagementUrl;
  public changePasswordUrl = environment.changePasswordUrl;
  public manageUserUrl = environment.manageUserUrl;
  public dataModelEditorUrl = environment.dataModelEditorUrl;

  subscriptions: Subscription[] = [];

  // public userDataSubscription: Subscription | undefined;

  @ViewChild('FeedbackTitleBar', { static: true })
  private FeedbackTitleBar: TemplateRef<any>;

  public ngOnInit() {
    // this.setLanguage(this.i18n.CurrentUILanguage.CultureCode);
    this.runningMaintenanceWindow$ = this.store.pipe(select(isMaintenanceWindowRunning));
    this.systemInfoDucks.loadSystemInfoEffect.dispatch();
    this.subscriptions.push(this.oidcSecurityService.userData$
                                .subscribe(
                                  (userData: any) => {
                                    if (userData && userData !== '') {
                                      this.loggendInUser = (userData.given_name && userData.family_name) ? userData.given_name + ' ' + userData.family_name : userData.preferred_username;
                                    }
                                  }));
    this.subscriptions.push(this.feedbackService.openTemplatedMessage
                                .subscribe(msg => {
                                  if (msg) {
                                    this.showFeedbackWindow(msg.subject, msg.description, msg.category, msg.table, msg.value);
                                  }
                                }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  constructor(private dialogService: DialogService,
              private feedbackService: FeedbackService,
              private globalListenerService: GlobalService,
              private router: Router,
              private translate: TranslateService,
              private i18n: I18nService,
              private windowService: CustomWindowService,
              private store: Store<IAppState>,
              @Inject(SystemInfoDucks) private systemInfoDucks: Duck<SystemInfoDucks>,
              public oidcSecurityService: OidcSecurityService) {
    this.onResize();
  }

  public get isNavHeaderSlim(): boolean {
    const header = document.getElementById(this.headerId);
    return header.className.indexOf('nav-sm') > -1;
  }

  public onResize() {
    const width = document.body.clientWidth;
    if (width <= 990) {
      this.toggle = true;
      this.displayActiveSearch(false);
      this.displayActiveCreate(false);
      this.displayActiveAdmin(false);
      this.displayActiveFeedback(false);
    } else {
      this.toggle = false;
      this.displayActiveSearch(true);
      this.displayActiveCreate(true);
      this.displayActiveAdmin(true);
      this.displayActiveFeedback(true);
    }
  }

  // public toggleMessages() {
  //   this.toggleMes = !this.toggleMes;
  // }
  //
  // public toggleLanguages() {
  //   this.toggleLanguage = !this.toggleLanguage;
  // }
  //
  // public toggleUser() {
  //   this.toggleU = !this.toggleU;
  // }

  public toggleNavBarSearch() {
    this.toggleSucheMenu = !this.toggleSucheMenu;
    this.displayActiveSearch(this.toggleSucheMenu);
  }

  public toggleNavBarCreate() {
    this.toggleCreateMenu = !this.toggleCreateMenu;
    this.displayActiveCreate(this.toggleCreateMenu);
  }

  public toggleNavBarAdmin() {
    this.toggleAdminMenu = !this.toggleAdminMenu;
    this.displayActiveAdmin(this.toggleAdminMenu);
  }

  public toggleNavBarFeedback() {
    this.toggleFeedbackMenu = !this.toggleFeedbackMenu;
    this.displayActiveFeedback(this.toggleFeedbackMenu);
  }

  public displayActiveSearch(active: boolean) {
    if (active) {
      this.visiblitySearch = 'block';
      this.toggleSucheMenu = true;
    } else {
      this.visiblitySearch = 'none';
      this.toggleSucheMenu = false;
    }
  }

  public displayActiveCreate(active: boolean) {
    if (active) {
      this.visiblityCreate = 'block';
      this.toggleCreateMenu = true;
    } else {
      this.visiblityCreate = 'none';
      this.toggleCreateMenu = false;
    }
  }

  public displayActiveAdmin(active: boolean) {
    if (active) {
      this.visibilityAdmin = 'block';
      this.toggleAdminMenu = true;
    } else {
      this.visibilityAdmin = 'none';
      this.toggleAdminMenu = false;
    }
  }

  public displayActiveFeedback(active: boolean) {
    if (active) {
      this.visibilityFeedback = 'block';
      this.toggleFeedbackMenu = true;
    } else {
      this.visibilityFeedback = 'none';
      this.toggleFeedbackMenu = false;
    }
  }

  public close(event: any) {

    const elementIds = [ 'messages', 'messages_icons', 'user', 'languages', 'languages_icons' ];
    const elementId = (event.target as Element).id;
    if (elementId === 'user' && this.toggleMes) {
      this.toggleMes = false;
    }
    if ((elementId === 'messages' || elementId === 'messages_icons') && this.toggleU) {
      this.toggleU = false;
    }
    if (elementIds.indexOf(elementId) === -1) {
      this.toggleMes = false;
      this.toggleU = false;
      this.toggleLanguage = false;
    }

    if (this.lSearchMenuButtons.indexOf(elementId) === -1 && this.toggleSucheMenu && this.isNavHeaderSlim) {
      this.displayActiveSearch(false);
    }
    if (this.lCreateMenuButtons.indexOf(elementId) === -1 && this.toggleCreateMenu && this.isNavHeaderSlim) {
      this.displayActiveCreate(false);
    }
    if (this.lAdminMenuButtons.indexOf(elementId) === -1 && this.toggleAdminMenu && this.isNavHeaderSlim) {
      this.displayActiveAdmin(false);
    }
    if (this.lFeedbackMenuButtons.indexOf(elementId) === -1 && this.toggleFeedbackMenu && this.isNavHeaderSlim) {
      this.displayActiveFeedback(false);
    }
  }

  public showFeedbackWindow(subject: string, description: string, category: CreateFeedbackItemCategory, table: string, value: string) {
    if (!this.feedbackOpen) {
      this.feedbackComponent = this.windowService.open({
        titleBarContent: this.FeedbackTitleBar,
        content: CommonFeedbackComponent,
        top: 75,
        left: window.innerWidth / 2 - 375,
        minWidth: 750,
        type: 'feedback',
        resizable: false,
      });
      const componentInstance = this.windowService.getWindow('feedback').content.instance;
      if (componentInstance instanceof CommonFeedbackComponent) {
        if (subject) {
          componentInstance.createFeedback.subject = subject;
        }
        if (description) {
          componentInstance.createFeedback.description = description;
        }
        if (category) {
          componentInstance.createFeedback.category = category;
        }
        if (table) {
          componentInstance.createFeedback.nachschlageTabelleName = table;
        }
        if (value) {
          componentInstance.createFeedback.nachschlageTabelleWert = value;
        }
        componentInstance.initCommonFeedback(new CreateFeedbackItem(), true);
        this.feedbackOpen = true;
        this.feedbackComponent.closeWindow.subscribe(() => {
          this.windowService.close('feedback');
          this.feedbackOpen = false;
        });
      } else {
        throw new Error('The created Component is of wrong Type!!');
      }
    }
  }

  public closeWindow() {
    this.windowService.close('feedback');
    this.feedbackOpen = false;
  }

  public signOut() {
    this.oidcSecurityService.logoff();
  }
}
