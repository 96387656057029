<div class="input-group" [title]="field.key">
  <div>
    <div class="k-numerictextbox maximize-input">
      <span class="k-numeric-wrap">
              <input
              id="stringValue-{{field.key}}"
              #box
              (change)="onStringValueChanged(box.value)"
              [(ngModel)]="stringValue"
              class="k-input"
              attr.maxlength="{{stringValueLength}}"
              [class.textbox-invalid]="isValid === false">
          <span class="k-select">
              <span class="k-link" (click)="isOpen = !isOpen">
                  <span class="k-icon" [class.k-i-arrow-s]="isOpen === false" [class.k-i-arrow-n]="isOpen === true" style="top: 5px;"></span>
              </span>
          </span>
      </span>
    </div>
  </div>
</div>
<div class="range-field" *ngIf="isInitialized && isOpen">
  <table class="table table-borderless range">
    <tr>
      <td width="1" style="padding: 5px;">{{ 'components.rangeField.from' | translate}}</td>
      <td width="1">
        <kendo-dropdownlist [data]="operatorItems"
                            [valuePrimitive]="true"
                            id="minOperator"
                            [(ngModel)]="minOperator"
                            style="width:50px"
                            (valueChange)="onValuesChanged()">
        </kendo-dropdownlist>
      </td>
      <td>
        <kendo-numerictextbox [(ngModel)]="minValue"
                              *ngIf="!hasRange"
                              id="minValue"
                              [autoCorrect]="'true'"
                              [format]="'##.####'"
                              [spinners]="false"
                              (valueChange)="onValuesChanged()"
                              style="width: 100%;">
        </kendo-numerictextbox>
      </td>
      <td width="1">
        <kendo-dropdownlist *ngIf="hasUnits"
                            id="minUnit"
                            [data]="unitItems"
                            [valuePrimitive]="true"
                            [(ngModel)]="minUnitInternal"
                            style="width:105px"
                            [popupSettings]="{ width: 150 }"
                            (valueChange)="onValuesChanged()">
        </kendo-dropdownlist>
      </td>
    </tr>
    <tr style="margin-bottom:20px" *ngIf="hasMaxValue">
      <td width="1" style="padding: 5px;">{{ 'components.rangeField.to' | translate}}</td>
      <td width="1">
        <kendo-dropdownlist [data]="operatorItems"
                            id="maxOperator"
                            [valuePrimitive]="true"
                            (valueChange)="onValuesChanged()"
                            [(ngModel)]="maxOperator"
                            style="width:50px">
        </kendo-dropdownlist>
      </td>
      <td>
        <kendo-numerictextbox [(ngModel)]="maxValue"
                              *ngIf="!hasRange"
                              id="maxValue"
                              [autoCorrect]="'true'"
                              [format]="'##.####'"
                              [spinners]="false"
                              (valueChange)="onValuesChanged()"
                              style="width: 100%;">
        </kendo-numerictextbox>
      </td>
      <td width="1">
        <kendo-dropdownlist *ngIf="hasUnits"
                            id="maxUnit"
                            [data]="unitItems"
                            [valuePrimitive]="true"
                            [(ngModel)]="maxUnitInternal"
                            style="width:105px"
                            [popupSettings]="{ width: 150 }"
                            (valueChange)="onValuesChanged()">
        </kendo-dropdownlist>
      </td>
    </tr>
    <tr>
      <td width="1" style="padding: 5px;">{{ 'components.rangeField.description' | translate}}</td>
      <td colspan="3" style="height: 100px; padding: 5px 0">
        <textarea kendoTextArea
                  [(ngModel)]="description"
                  id="description"
                  [ngClass]="{'filled': description?.length > 0}"
                  (change)="onValuesChanged()"
                  class="k-textarea input-group"
                  attr.maxlength="{{stringDescriptionLength}}"
                  style="width: 100%; height: 100%; resize: none;"></textarea>
      </td>
    </tr>
    <tr>
      <td width="1" style="padding: 5px;">{{ 'components.rangeField.plausibility' | translate}}</td>
      <td colspan="3">
        <kendo-dropdownlist [data]="plausibilityItems"
                            id="plausibility"
                            [valuePrimitive]="true"
                            [(ngModel)]="plausibility"
                            (valueChange)="onValuesChanged()"
                            style="width: 100%">
        </kendo-dropdownlist>
      </td>
    </tr>
  </table>
</div>
