import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { DynamicSachverhalteFormModule } from 'app/modules/dossier-area/components/dynamic-sachverhalte-form/dynamic-sachverhalte-form.module';
import { SachverhaltEditComponent } from 'app/modules/shared-area/entry-components/sachverhalt/sachverhalt-edit/sachverhalt-edit.component';
import { DossierModule } from 'app/modules/dossier-area/services/dossier/dossier.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DossierModule,
    I18nModule,
    // ToastaModule.forChild(),
    StoreModule,
    ValidationModule,
    TranslateModule,
    DynamicSachverhalteFormModule,
  ],
  declarations: [ SachverhaltEditComponent ],
  exports: [ SachverhaltEditComponent ],
})
export class SachverhaltEditModule {}
