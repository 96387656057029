<div *ngIf="!IsFormInitialized"
     style="text-align: center">
  <i class="k-icon k-i-loading"
     style="font-size:50px; columns: #363636; margin: 20px"></i>
</div>

<div *ngIf="IsFormInitialized">

  <app-dynamic-sachverhalte-form [header]="header"
                                 [sachverhaltId]="sachverhaltId"
                                 [erfassungSachverhaltId]="erfassungSachverhaltId"                                 
                                 [fields]="fields"
                                 [values]="values"
                                 [emptyFields]="emptyFields"
                                 [serverValidationErrors]="serverValidationErrors$ | async">
  </app-dynamic-sachverhalte-form>

  <div class="window-button-group">

    <button class="btn btn-primary"
            [disabled]="IfValuesNotExists"
            (click)="saveSachverhalt()">{{'action.save' | translate}}
    </button>

    <button class="btn btn-default"
            (click)="close()">
      {{'action.cancel' | translate}}
    </button>

  </div>
</div>
