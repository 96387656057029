import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { IUserState } from 'app/modules/shared-area/ducks/user/user.state';
import { IUser } from 'app/modules/shared-area/ducks/user/models/IUser';

@Ducksify<IUserState>({
  initialState: {
    userData: null,
    apiState: 'Unknown',
  },
})
export class UserDucks {
  public readonly loadUser = effect('[User] load the current logged in User');

  @Action('[User] Load User Success')
  public loadUserSuccess(state: IUserState, userData: IUser): typeof state {
    return {
      ...state,
      userData,
      apiState: 'Success',
    };
  }

  @Action('[User] Load User Error')
  public loadUserError(state: IUserState, error: any) {
    return {
      ...state,
      userData: null,
      apiState: 'Failed',
    };
  }
}

export function userReducer(state: IUserState, action: DucksifiedAction): typeof state {
  return reducerFrom(UserDucks)(state, action);
}

