import { Component, OnInit, Inject } from '@angular/core';
import { ReferencesResult } from 'app/core/services/api-client';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { Duck } from '@ngrx-ducks/core';
import { ReferencesDucks } from './ducks/references.ducks';
import { isBusy, getReferences } from './ducks/references.selectors';

@Component({
  selector: 'app-references-dialog',
  templateUrl: './references-dialog.component.html'
})
export class ReferencesDialogComponent implements OnInit {

  public isBusy$: Observable<boolean>;
  public result$: Observable<ReferencesResult>;

  constructor(private store: Store<IAppState>,
    @Inject(ReferencesDucks) private referencesDucks: Duck<ReferencesDucks>) { }

  ngOnInit() {
    this.isBusy$ = this.store.pipe(select(isBusy));
    this.result$ = this.store.pipe(select(getReferences));
  }

  loadMore() {
    this.referencesDucks.loadMore();
  }
}
