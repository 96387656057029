<div *ngIf="sachverhaltModel$ | async as sachverhaltModel; else loading">
  <app-dynamic-sachverhalte-form [header]="sachverhaltModel.header" [fields]="sachverhaltModel.fields"
    [values]="sachverhaltModel.values" [emptyFields]="emptyFields" [serverValidationErrors]="serverValidationErrors$ | async">
  </app-dynamic-sachverhalte-form>
  <div class="window-button-group" style="margin-top: -15px; padding: 0 8px;">
    <div>
      <label class="control-label">{{'components.batch.messageLabel' | translate}} *</label>
      <textarea #message placeholder="{{'components.batch.messagePlaceholder' | translate}}" class="k-textarea" style="display:block; width:100%; margin-bottom:15px"></textarea>
    </div>
    <button class="btn btn-primary" [disabled]="!isFormValid(sachverhaltModel, message.value) || (isBusy$ | async)" (click)="create(sachverhaltModel, message.value)"
      id="btn_create-and-close">
      {{'action.create' | translate}}
    </button>
    <span *ngIf="isBusy$ | async" class="k-icon k-i-loading" style="font-size: 24px; margin-bottom: 5px"></span>
    <button class="btn btn-default" [disabled]="isBusy$ | async" style="float: right" (click)="close()" id="btn_cancel">
      {{'action.cancel' | translate}}
    </button>
  </div>
</div>
<ng-template #loading>
  <div style="text-align: center">
    <i class="k-icon k-i-loading" style="font-size:50px; columns: #363636; margin: 20px"></i>
  </div>
</ng-template>