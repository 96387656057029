import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ValidationRule, ValidationRuleMessage } from 'app/core/services/api-client';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectedValidationRule } from '../../pages/validation-rules-page/ducks/validation-rules.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import { ValidationRulesDucks } from '../../pages/validation-rules-page/ducks/validation-rules.ducks';
import { Duck } from '@ngrx-ducks/core';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';

@Component({
  selector: 'app-validation-rules-edit',
  templateUrl: './validation-rules-edit.component.html',
  styleUrls: ['./validation-rules-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationRulesEditComponent implements OnInit {

  public rule: ValidationRule;
  public selectedValidationRule$: Observable<ValidationRule>;
  public form: FormGroup;
  public supportedUICultureCodes = environment.supportedUILanguages.map(culture => culture.CultureCode);

  constructor(
    private fb: FormBuilder,
    private store: Store<IAppState>,
    @Inject(ValidationRulesDucks) private validationRuleDucks: Duck<ValidationRulesDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
  ) {
  }

  ngOnInit() {
    this.form = this.createGroup();
    this.selectedValidationRule$ = this.store.pipe(select(selectedValidationRule));
    this.selectedValidationRule$.subscribe(rule => {
      this.rule = rule;
      if (rule) {
        this.setValues(rule);
      }
    });
  }

  private createGroup() {
    const group = this.fb.group({
      rule: [null, {validators: Validators.required, updateOn: 'blur'}],
      name: [null, {validators: Validators.required, updateOn: 'blur'}],
      affectedFields: [null, {validators: Validators.required, updateOn: 'blur'}],
      merkmalName: [null, {validators: Validators.required, updateOn: 'blur'}],
      isNew: ['true', {validators: Validators.required, updateOn: 'blur'}],
    });
    for (const culture of this.supportedUICultureCodes) {
      // let text = '';

      // if (this.rule.messages) {
      //   const message = this.rule.messages.find(m => m.cultureCode === culture);
      //   text = _.isNil(message) ? '' : message.text;
      // }
      // // );
      group.addControl(culture, new FormControl());
    }
    return group;
  }

  private setValues(rule: ValidationRule): void {
    const values = this.flattenValidationRule(rule);
    const ctrls = this.form.controls;
    for (const key of Object.keys(ctrls)) {
      ctrls[key].setValue(values[key]);
    }
    ctrls['isNew'].setValue(rule.name === '[new]');
  }

  private flattenValidationRule(rule: ValidationRule): { [key: string]: string; } {
    const result = {};
    for (const key of Object.keys(rule)) {
      result[key] = rule[key];
    }
    if (rule.messages && rule.messages.length > 0) {
      for (const message of rule.messages) {
        const cultureCode = message.cultureCode;
        const text = message.text;
        result[cultureCode] = text;
      }
    }
    return result;
  }

  private createValidationRule(values: { [key: string]: string }): ValidationRule {
    const result = new ValidationRule({
      name: values['name'],
      merkmalName: values['merkmalName'],
      rule: values['rule'],
      // merkmalDisplayName: values['merkmalDisplayName']
    });
    result.affectedFields = [];
    if (!_.isNil(values['affectedFields'])) {
      const affectedFields = values['affectedFields'].toString().split(',').map(value => value.trim());
      if (_.isArray(affectedFields)) {
        affectedFields.forEach(field => {
          result.affectedFields.push(field);
        });
      }
    }
    result.messages = [];
    for (const culture of this.supportedUICultureCodes) {
      const message = new ValidationRuleMessage({
        cultureCode: culture,
        text: _.isNil(values[culture]) ? '' : values[culture]
      });
      result.messages.push(message);
    }
    return result;
  }

  public submit() {
    const ctrls = this.form.controls;
    const payload = {};
    for (const key of Object.keys(ctrls)) {
      payload[key] = ctrls[key].value;
    }
    const rule = this.createValidationRule(payload);
    if (!!ctrls['isNew'].value) {
      this.validationRuleDucks.saveValidationRuleEffect.dispatch(rule);
    } else {
      this.validationRuleDucks.updateValidationRuleEffect.dispatch(rule);
    }
  }

  public cancel() {
    this.windowDucks.closeWindow.dispatch();
  }

  public get isNew() {
    return _.isNil(this.rule) || (this.rule.name && this.rule.name === '[new]');
  }
}
