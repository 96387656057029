import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { AutoCompleteModule } from '@progress/kendo-angular-dropdowns';
import { NumericTextBoxModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DynamicControlComponent } from 'app/modules/dossier-area/components/dynamic-control/dynamic-control.component';
import { FileLinkModule } from 'app/modules/dossier-area/components/file-link/file-link.module';
import { RangeFieldModule } from 'app/shared/components/range-field/range-field.module';
import { NotHasRightModule } from 'app/shared/directives/nothasright/nothasright.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RangeFieldModule,
    NumericTextBoxModule,
    DatePickerModule,
    AutoCompleteModule,
    TextBoxModule,
    TranslateModule,
    FileLinkModule,
    NotHasRightModule
  ],
  declarations: [ DynamicControlComponent ],
  exports: [ DynamicControlComponent ]
})
export class DynamicControlModule {}
