import { Dossier } from '@models/dossier/dossier';
import { Role } from 'app/modules/shared-area/ducks/user/models/role';

export interface ISearchState {
  search: string;
  hits: Set<string>;
  currentHit: string | null;
  isWholeWord: boolean;
  isIgnoreCase: boolean;
  state: SearchState;
}

export interface DossierState {
  id?: string;
  erfassungId?: number;
  isLoading: boolean;
  dossier: Dossier;
  isDeleting: boolean;
  isDeleted: boolean;
  roleContext?: Role;
  isReEditing?: boolean;
  isTaking: boolean;
  isPartialUpdateInProgress: boolean;
  renderState: Map<string, RenderState>;
  renderingItems: number;
  searchState: ISearchState;
  submitIsBusy: boolean;
  copySachverhalte: {
    dossierIds: number[],
    sachverhaltIds: { sachverhaltId: number, erfassungSachverhaltId: number }[],
    showDialog: boolean,
    isLoading: boolean
  };
  thesaurusMode?: ThesaurusMode;
}

export type RenderState = 'rendering' | 'rendered';
export type SearchState = 'unknown' | 'searching' | 'searched';
export type ThesaurusMode = 'none' | 'original' | 'recalculated';
