import {Action, DucksifiedAction, Ducksify, reducerFrom} from '@ngrx-ducks/core';
import {MaintenanceWindow} from 'app/core/services/api-client';
import {IWartungsarbeitenState} from './wartungsarbeiten-store.service';

@Ducksify<IWartungsarbeitenState>({
  initialState: {
    maintenanceWindows: [],
    apiState: 'Unknown',
    loading: true,
  },
})
export class WartungsarbeitenDucks {

  @Action('[Wartungsarbeiten] Start loading Wartungsarbeiten')
  public wartungsarbeitenRequest(state: IWartungsarbeitenState): typeof state {
    return {
      ...state,
      apiState: 'Loading',
      loading: true,
    };
  }

  @Action('[Wartungsarbeiten] Load Wartungsarbeiten Success')
  public loadWartungsarbeitenSuccess(state: IWartungsarbeitenState, maintenanceWindows: MaintenanceWindow[]): typeof state {
    return {
      ...state,
      maintenanceWindows: maintenanceWindows,
      apiState: 'Success',
      loading: false,
    };
  }

  @Action('[Wartungsarbeiten] Load Wartungsarbeiten Error')
  public wartungsarbeitenError(state: IWartungsarbeitenState, error: string): typeof state {
    return {
      ...state,
      apiState: 'Failed',
      loading: false,
    };
  }

  @Action('[Wartungsarbeiten] Update Wartungsarbeiten')
  public updateWartungsarbeiten(state: IWartungsarbeitenState, maintenanceWindow: MaintenanceWindow): typeof state {
    const newState = state.maintenanceWindows.map( win => {
      let updated = {...win};
      if (win.id === maintenanceWindow.id) {
        updated = maintenanceWindow;
      }
      return updated as MaintenanceWindow;
    });
    return {
      ...state,
      maintenanceWindows: newState,
    };
  }

  @Action('[Wartungsarbeiten] Create Wartungsarbeiten')
  public postWartungsarbeiten(state: IWartungsarbeitenState, maintenanceWindow: MaintenanceWindow): typeof state {
    return {
      ...state,
      maintenanceWindows: [...state.maintenanceWindows, maintenanceWindow]
    };
  }
}

export function wartungsarbeitenReducer(state: IWartungsarbeitenState, action: DucksifiedAction): typeof state {
  return reducerFrom(WartungsarbeitenDucks)(state, action);
}
