import { Ducksify, effect } from '@ngrx-ducks/core';
import { WindowParams } from './windowParams';

@Ducksify<{}>({
  initialState: {},
})
export class WindowDucks {
  readonly openWindow = effect<WindowParams>('[Window - Effect] Open Window');
  readonly closeWindow = effect<void>('[Dossier - Effect] Close window');
  readonly closeWindowAndOpenNext = effect<WindowParams>('[Dossier - Effect] Close current window and open next window');
}
