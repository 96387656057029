<div class="import-auftraege-edit form-horizontal form-label-left" style="width: 750px">

  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.importAuftraegeEdit.supplier' | translate}}
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8">
      <kendo-dropdownlist style="width: 100%" [data]="lieferanten" *ngIf="lieferanten && lieferanten.length > 0" [textField]="'text'"
        [valueField]="'value'" [defaultItem]="defaultListItem" [(ngModel)]="selectedLieferantId"
        (valueChange)="onUrlChanged()" [valuePrimitive]="true">
      </kendo-dropdownlist>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <small class="form-text text-muted">
        {{'components.importAuftraegeEdit.info.supplier' | translate}}
      </small>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.importAuftraegeEdit.modus' | translate}}
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8">
      <kendo-dropdownlist 
        style="width: 100%" 
        [data]="modusItems" 
        [textField]="'text'"
        [valueField]="'value'" 
        [(ngModel)]="selectedModus"
        (valueChange)="onUrlChanged()"
        [valuePrimitive]="true">
      </kendo-dropdownlist>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <small class="form-text text-muted">
        {{'components.importAuftraegeEdit.info.modus' | translate}}
      </small>
    </div>
  </div>


  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.importAuftraegeEdit.stoffeErstellen' | translate}}
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8" style="padding: 6px 10px;">
      <input type="checkbox" class="k-checkbox" id="cb_stoffeErstellen" [(ngModel)]="stoffeErstellen"
        (change)="onUrlChanged()">
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <small class="form-text text-muted">
        {{'components.importAuftraegeEdit.info.stoffeErstellen' | translate}}
      </small>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.importAuftraegeEdit.sachverhalteUeberspringen' | translate}}
    </label>
    <div class="col-md-8 col-sm-8 col-xs-8" style="padding: 6px 10px;">
      <input type="checkbox" class="k-checkbox" id="cb_sachverhalteUeberspringen"
        [(ngModel)]="sachverhalteUeberspringen" (change)="onUrlChanged()">
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <small class="form-text text-muted">
        {{'components.importAuftraegeEdit.info.sachverhalteUeberspringen' | translate}}
      </small>
    </div>
  </div>
  <style>
    .k-action-buttons {
display: none;
}
  </style>
  <div class="form-group">
    <label class="control-label col-md-12 col-sm-12 col-xs-12">
      {{'components.importAuftraegeEdit.file' | translate}}
    </label>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <kendo-upload #upload
        [saveUrl]="uploadSaveUrl" 
        [restrictions]="fileRestrictions" 
        [withCredentials]="false"
        [multiple]="false" 
        [autoUpload]="false" 
        (complete)="completed()" 
        (error)="errorEventHandler($event)">
        <kendo-upload-messages           
          [dropFilesHere]="'upload.messages.dropFileHere' | translate"
          [headerStatusUploaded]="'upload.messages.headerStatusUploaded' | translate"
          [headerStatusUploading]="'upload.messages.headerStatusUploading' | translate"
          [invalidFileExtension]="'upload.messages.invalidFileExtension' | translate"

          [invalidMaxFileSize]="'upload.messages.invalidMaxFileSize' | translate"
          [invalidMinFileSize]="'upload.messages.invalidMinFileSize' | translate"
          [remove]="'upload.messages.remove' | translate" [retry]="'upload.messages.retry' | translate"
          [select]="'upload.messages.select' | translate"
          [uploadSelectedFiles]="'upload.messages.uploadSelectedFileAndClose' | translate"></kendo-upload-messages>
      </kendo-upload>
      <!-- [clearSelectedFiles]="'upload.messages.clearSelectedFiles' | translate"-->
    </div>
    <div class="col-md-12 col-sm-12 col-xs-12">
      <small class="form-text text-muted">
        {{'components.importAuftraegeEdit.info.file' | translate}}
      </small>
    </div>
  </div>

  <div class="form-group" style="display: flex; justify-content: flex-end; padding:5px">
    <button id="btn_close" class="btn btn-default" (click)="cancel()">{{'action.cancel' | translate}}</button>
    <button id="btn_save" class="btn btn-primary" type="submit" (click)="submit()" [disabled]="!isValid">
      <i class="fas fa-save"></i>
      {{'action.create' | translate}}</button>
  </div>
  <!-- <br class="form-group">
    <label class="control-label col-md-4 col-sm-4 col-xs-4">
      {{'components.importAuftraegeEdit.ranking' | translate}}
    </label>
    <br class="col-md-8 col-sm-8 col-xs-8">
      <ng-container *ngFor="let rankingTag of rankingTags">
        <input *ngIf="rankingTag" type="checkbox" [name]="rankingTag.name" [(ngModel)]="rankingTag.checked"
          [ngModelOptions]="{standalone: true}"> {{rankingTag.name}}
        <br>
      </ng-container>
    </br>
  </br> -->
</div>

<!-- <div class="form-group" style="display: flex; justify-content: flex-end; padding:5px">
  <button id="btn_close" class="btn btn-default" (click)="cancel()">{{'action.cancel' | translate}}</button>
  <button id="btn_save" class="btn btn-primary" type="submit" (click)="submit()" [disabled]="!isValid">
    <i class="fas fa-save"></i>
    {{'action.create' | translate}}</button>
</div> -->