import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ActionbarModule } from 'app/shared/components/actionbar/actionbar.module';

import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ThesaurusRulesEditComponent } from './thesaurus-rules-edit.component';

@NgModule({
  imports: [
    GridModule,
    I18nModule,
    TranslateModule,
    CommonModule,
    ActionbarModule,
    TextBoxModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
  ],
  declarations: [ ThesaurusRulesEditComponent ],
  exports: [ ThesaurusRulesEditComponent ],
})
export class ThesaurusRulesEditModule {
}
