<div>
  <table class="table table-borderless table-striped" tooltipClass="tooltip-class" kendoTooltip position="right"
    [tooltipTemplate]="hinweistextTemplate" filter="i[data-hinweistext]">
    <colgroup>
      <col style="min-width:18%">
      <col style="width:82%">
    </colgroup>

    <thead *ngIf="header">
      <tr>
        <td colspan="3">
          <h4>
            {{header.merkmal}}<i [attr.data-hinweistext]="header.hinweisText" *ngIf="header.hinweisText"
              class="far fa-question-circle"></i>
            <br /><span *ngIf="sachverhaltId || erfassungSachverhaltId" style="font-size: 0.6em;font-style: italic;">
              ({{'components.dossier.sachverhaltId'  | translate}}:
              {{sachverhaltId ? sachverhaltId : erfassungSachverhaltId}})</span>
          </h4>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of fields;" [ngSwitch]="field.type">
        <ng-container *ngSwitchCase="'subset'">
          <td colspan="3">
            <table class="table table-borderless">
              <tbody>
                <ng-container
                  *ngFor="let row of values[field.key]; let rowIndex = index; let even = even; let odd = odd;">
                  <tr [ngClass]="{'subset-style-a': even, 'subset-style-b': odd}">
                    <td colspan="2">
                      <small>({{rowIndex + 1}}/{{values[ field.key ].length}})</small>
                      <a title="Löschen" (click)="deleteWert(field.key, rowIndex)"
                        class="btn btn-outline-danger btn-sm">
                        <i class="fas fa-trash-alt fa-sm"></i>
                      </a>
                    </td>
                  </tr>
                  <tr *ngFor="let subsetField of field.fields;"
                    [ngClass]="{'subset-style-a': even, 'subset-style-b': odd}">
                    <td style="white-space: nowrap">
                      <label class="control-label"
                        [ngClass]="{'red': errorFields.has(subsetField.key | subsetFieldName:field.key:rowIndex)}">
                        {{subsetField.label}}
                        <span *ngIf="subsetField.required"
                          [ngClass]="{'red': errorFields.has(subsetField.key | subsetFieldName:field.key:rowIndex)}">*
                        </span>
                      </label>
                    </td>
                    <td style="padding-top: 0; padding-bottom: 0">
                      <div class="input-group" [attr.data-isrequired]="field.required"
                        attr.data-itemkey="{{field.key}}">
                        <app-dynamic-control [field]="subsetField" [optionValues]="values[field.key][rowIndex]"
                          [value]="values[field.key][rowIndex][subsetField.key]"
                          (textValueChange)="onTextValueChange($event.value, $event.fieldKey, rowIndex, field.key)"
                          (rangeChanged)="onRangeChanged($event.value, $event.fieldKey, rowIndex, field.key)"
                          (intValueChange)="onIntValueChange($event.value, $event.fieldKey, rowIndex, field.key)"
                          (doubleValueChange)="onDoubleValueChange($event.value, $event.fieldKey, rowIndex, field.key)"
                          (dateChange)="onDateChange($event.value, $event.fieldKey, rowIndex, field.key)"
                          (dropdownValueChange)="onDropdownValueChange($event.value, $event.fieldKey, rowIndex, field.key)"
                          (fileLinkChanged)="onValueChanged($event.value, $event.fieldKey, rowIndex, field.key)">
                        </app-dynamic-control>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <tr>
                  <td colspan="2">
                    <small>{{GetLabelsForSubset(field)}}</small>
                    <a title="{{'action.add' | translate}}" (click)="addWert(field.key, field.type)" id="btn-add-subset"
                      style="margin-left: 10px;" class="btn btn-default btn-sm">
                      <i class="fas fa-plus fa-sm"></i>
                      {{'action.add' | translate}}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <td style="white-space: nowrap">
            <label class="control-label" [ngClass]="{'red': errorFields.has(field.key)}">
              {{field.label}}<span *ngIf="field.required" [ngClass]="{'red': errorFields.has(field.key)}">*</span>

              <i [attr.data-hinweistext]="field.hinweisText" *ngIf="field.hinweisText"
                class="far fa-question-circle"></i>

              <a *ngIf="field.multiple" title="weiteren Wert hinzufügen" (click)="addWert(field.key, field.type)"
                class="btn btn-outline-primary btn-sm" style="padding: 0; margin: 0">
                <i class="fas fa-plus fa-sm"></i>
              </a>
            </label>
          </td>
          <td style="padding-top: 0; padding-bottom: 0">
            <div *ngIf="field.multiple">
              <div *ngFor="let value of values[field.key]; let i = index; trackBy: trackByIndex" class="input-group"
                [attr.data-isrequired]="field.required" attr.data-itemkey="{{field.key}}">
                <app-dynamic-control [field]="field" [optionValues]="values" [value]="value"
                  (textValueChange)="onTextValueChange($event.value, $event.fieldKey, i)"
                  (rangeChanged)="onRangeChanged($event.value, $event.fieldKey, i)"
                  (intValueChange)="onIntValueChange($event.value, $event.fieldKey, i)"
                  (doubleValueChange)="onDoubleValueChange($event.value, $event.fieldKey, i)"
                  (dateChange)="onDateChange($event.value, $event.fieldKey, i)"
                  (dropdownValueChange)="onDropdownValueChange($event.value, $event.fieldKey, i)"
                  (fileLinkChanged)="onValueChanged($event.value, $event.fieldKey, i)">
                </app-dynamic-control>
                <div class="input-group-btn">
                  <a title="Wert löschen" (click)="deleteWert(field.key, i)" class="btn btn-outline-danger btn-sm">
                    <i class="fas fa-trash-alt fa-sm"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="input-group" *ngIf="!field.multiple" [attr.data-isrequired]="field.required"
              attr.data-itemkey="{{field.key}}">
              <app-dynamic-control [field]="field" [optionValues]="values" [value]="values[field.key]"
                (textValueChange)="onTextValueChange($event.value, $event.fieldKey)"
                (rangeChanged)="onRangeChanged($event.value, $event.fieldKey)"
                (intValueChange)="onIntValueChange($event.value, $event.fieldKey)"
                (doubleValueChange)="onDoubleValueChange($event.value, $event.fieldKey)"
                (dateChange)="onDateChange($event.value, $event.fieldKey)"
                (dropdownValueChange)="onDropdownValueChange($event.value, $event.fieldKey)"
                (fileLinkChanged)="onValueChanged($event.value, $event.fieldKey)">
              </app-dynamic-control>
            </div>
          </td>
        </ng-container>
      </tr>
      <tr>
        <td colspan="3" *ngIf="serverValidationErrors.summary.length > 0">
          <div class="alert alert-danger" role="alert">
            <ul>
              <li *ngFor="let validationErrorText of serverValidationErrors.summary">
                {{validationErrorText}}
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #hinweistextTemplate let-anchor>
  <div style="padding: 10px">
    <markdown ngPreserveWhitespaces
      [data]="parseMultilineString(anchor.nativeElement.getAttribute('data-hinweistext'))">


    </markdown>
  </div>
  <!-- <span [innerHTML]="parseMultilineString(anchor.nativeElement.getAttribute('data-hinweistext'))"></span> -->
</ng-template>
