import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchAreaState } from 'app/modules/search-area/search-area.module';

const searchAreaState = createFeatureSelector<SearchAreaState>('search-area');

export const isBusy = createSelector(searchAreaState, state => state.batch.isBusy);
export const getResult = createSelector(searchAreaState, state => state.batch.result);
export const getRequest = createSelector(searchAreaState, state => state.batch.request);
export const getSachverhaltModel = createSelector(searchAreaState, state => state.batch.sachverhaltModel);
export const getServerValidationErrors = createSelector(searchAreaState, state => state.batch.sachverhaltServerValidationErrors);
