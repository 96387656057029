import { FeedbackItemCategory, FeedbackItemPriority } from 'app/core/services/api-client/api-client';
import { EnumHelper } from 'app/core/utils/EnumHelper';
const {
  None,
  Layout,
  ExistingFunctionality,
  MissingFunctionality,
  LookupTableEntry,
  Unattractiveness,
  ErrorInText,
  LightDataError,
  CriticalDataError
} = { ...FeedbackItemCategory };

export const severityItems = [
  None,
  ErrorInText,
  LightDataError,
  CriticalDataError
  ];
export const categoryItems = [
  None,
  Layout,
  ExistingFunctionality,
  MissingFunctionality,
  LookupTableEntry
];
export const priorityItems = EnumHelper.ToArray(FeedbackItemPriority);
