import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { I18nService } from '../services/i18n/i18n.service';
import * as _ from 'lodash';

@Injectable()
export class CultureInterceptor implements HttpInterceptor {
  constructor(private i18n: I18nService) {
  }
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cultureCode = _.isEmpty(this.i18n.CurrentUILanguage) ? 'de-DE' : this.i18n.CurrentUILanguage.CultureCode;
    const request = req.clone({
      headers: req.headers.set('Culture', cultureCode)
    });
    return next.handle(request);
  }
}

