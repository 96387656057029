import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiClient, NachschlageTabelle, NachschlageTabelleInfo, NachschlageTabelleWert, NachschlageTabelleWertData} from '../api-client';
import {ApiErrorHandlerService} from '../api-default-error-handler/api-error-handler.service';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private http: HttpClient,
              private apiClient: ApiClient,
              private errorHandler: ApiErrorHandlerService) {
  }

  public getLookupTables(): Observable<NachschlageTabelleInfo[]> {
    return this.apiClient.apiNachschlageTabelleGet().pipe(
      catchError(this.apiClient.apiNachschlageTabelleGetErrorHandlers({
        error401Handler: error401 => this.errorHandler.handle401Error(error401)
      }))
    );
  }

  public getLookupTableByName(tableName: string): Observable<NachschlageTabelle> {
    return this.apiClient.apiNachschlageTabelleByNameGet(tableName).pipe(
      catchError(this.apiClient.apiNachschlageTabelleByNameGetErrorHandlers({
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiNachschlageTabelleByNameGet' })
      }))
    );
  }

  public postLookupTableValue(tableName: string, value: NachschlageTabelleWertData): Observable<NachschlageTabelleWert> {
    return this.apiClient.apiNachschlageTabelleByNameWertePost(tableName, value).pipe(
      catchError(this.apiClient.apiNachschlageTabelleByNameWertePostErrorHandlers({
        error400Handler: error400 => this.errorHandler.handle400Error(error400),
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403),
        error404Handler: error404 => this.errorHandler.handle404Error(error404),
        error409Handler: error409 => this.errorHandler.handle409Error(error409)
      }))
    );
  }

  public deleteLookupTableValue(tableName: string, valueId: number): Observable<any> {
    return this.apiClient.apiNachschlageTabelleByNameWerteByNachschlageTabelleWertIdDelete(tableName, valueId).pipe(
      catchError(this.apiClient.apiNachschlageTabelleByNameWerteByNachschlageTabelleWertIdDeleteErrorHandlers({
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403),
        error404Handler: error404 => this.errorHandler.handle404Error(error404)
      }))
    );
  }

  public consolidateTableValues(tableName: string, valueId: number, fromValueIds: number[]): Observable<any> {
    return this.apiClient.apiNachschlageTabelleByNameWerteByNachschlageTabelleWertIdZusammenfuehrenPost(tableName, valueId, fromValueIds).pipe(
      catchError(this.apiClient.apiNachschlageTabelleByNameWerteByNachschlageTabelleWertIdZusammenfuehrenPostErrorHandlers({
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403),
        error404Handler: error404 => this.errorHandler.handle404Error(error404) // TODO: Werte konnten nicht zusammengeführt werden
      }))
    );
  }
}
