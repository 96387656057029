import { AfterViewInit, Component, Inject } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { Sachverhalt, SachverhaltBearbeiten, SachverhaltField, SachverhaltHeader, SachverhaltSet } from '@models/dossier/dossier-models';
import { select, Store } from '@ngrx/store';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { getSachverhaltEditValues, serverValidationErrors } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.selectors';
import { DossierDucks } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.ducks';
import { DossierService } from 'app/modules/dossier-area/services/dossier/dossier.service';
import { IAppState } from 'app/modules/shared-area/root-state';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { SachverhaltDucks } from '../ducks/sachverhalt.ducks';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';
import { UserProfileStore } from '../../../ducks/user-profile/user-profile-store.service';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';

@Component({
  selector: 'app-sachverhalt-edit',
  templateUrl: './sachverhalt-edit.component.html',
})
export class SachverhaltEditComponent implements AfterViewInit {

  private id: string;
  public sachverhaltId?: number;
  public erfassungSachverhaltId?: number;

  public sachverhalt: Sachverhalt;
  public header: SachverhaltHeader;
  public fields: SachverhaltField[];
  public values: SachverhaltSet;

  public IsFormInitialized = false;
  public IsSaving: boolean;
  public emptyFields: string[] = [];
  public serverValidationErrors$: Observable<IServerValidationErrors> = EMPTY;
  public merkmal: string;

  constructor(private dossierService: DossierService,
    private i18n: I18nService,
    private toastyService: CustomToastyService,
    private store: Store<IAppState>,
    private userProfileStore: UserProfileStore,
    @Inject(DossierDucks) private dossierDucks: Duck<DossierDucks>,
    @Inject(SachverhaltDucks) private sachverhaltDucks: Duck<SachverhaltDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
    private validation: ValidationService) {
    this.IsSaving = false;

    this.store.pipe(
      select(getSachverhaltEditValues),
      first(),
    )
      .subscribe(values => {
        this.id = values.id;
        this.sachverhaltId = values.sachverhaltId;
        this.erfassungSachverhaltId = values.erfassungSachverhaltId;
      });

    this.serverValidationErrors$ = this.store.pipe(select(serverValidationErrors));
  }

  private initForm(result: Sachverhalt) {
    this.sachverhaltDucks.resetServerValidationError();
    this.sachverhalt = result;
    this.header = result.header;
    this.fields = result.fields;
    this.values = result.values;
    this.IsFormInitialized = true;
  }

  ngAfterViewInit() {
    if (!_.isNil(this.sachverhaltId)) {
      this.dossierService.DossierSachverhaltBearbeitenAnzeigen(this.id, this.sachverhaltId)
        .subscribe(
          sachverhalt => this.initForm(sachverhalt),
        );
    } else if (!_.isNil(this.erfassungSachverhaltId)) {
      this.dossierService.DossierNeuenSachverhaltBearbeitenAnzeigen(this.id, this.erfassungSachverhaltId)
        .subscribe(sachverhalt => this.initForm(sachverhalt));
    } else {
      console.error('sachverhalt edit fehler bei den sachverhalt ids');
    }
  }

  get IfValuesNotExists(): boolean {
    return _.keys(this.values).length <= 0 || _.valuesIn(this.values)
      .filter(value => !_.isNil(value)).length <= 0;
  }

  public saveSachverhalt() {
    const emptyRequiredFields = this.validation.checkForRequiredFields(this.fields, this.values);
    const invalidLookupFields = this.validation.checkLookupFields(this.fields, this.values);
    if (emptyRequiredFields.length === 0 && invalidLookupFields.length === 0) {
      const bearbeiten = new SachverhaltBearbeiten(this.id, this.sachverhaltId, this.erfassungSachverhaltId, this.values);
      if (!_.isNil(bearbeiten.SachverhaltId)) {
        this.sachverhaltDucks.speichereSachverhaltInDossier.dispatch(bearbeiten);
      } else {
        this.sachverhaltDucks.bearbeiteNeuenSachverhaltInDossier.dispatch(bearbeiten);
      }
    } else {
      this.emptyFields = emptyRequiredFields.concat(invalidLookupFields);
      if (emptyRequiredFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.notAllRequiredFieldsAreSet');
      }
      if (invalidLookupFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.invalidLookupFieldValues');
      }
    }
  }

  public close() {
    this.windowDucks.closeWindow.dispatch();
  }
}
