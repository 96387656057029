import { Inject, Injectable, NgModule } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Duck } from '@ngrx-ducks/core';
import { SystemInfoDucks } from './system-info.ducks';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SystemInfoService } from 'app/core/services/system-info/system-info-service.service';
import { SystemInfo } from 'app/core/services/api-client';
import { SystemInfoServiceModule } from 'app/core/services/system-info/system-info-service.module';

@NgModule({
  imports: [SystemInfoServiceModule],
})
export class SystemInfoEffectsModule { }

@Injectable()
export class SystemInfoEffects {
  public constructor(
    private action$: Actions,
    @Inject(SystemInfoDucks) private systemInfoDucks: Duck<SystemInfoDucks>,
    private systemInfoService: SystemInfoService) {
  }

  @Effect()
  public loadSystemInfoEffect$ =
    this.action$
      .pipe
      (
        ofType(this.systemInfoDucks.loadSystemInfoEffect.type),
        exhaustMap(() => this.systemInfoService.getSystemInfos()
          .pipe
          (
            map((systemInfo: SystemInfo) => this.systemInfoDucks.loadSystemInfoSuccess.action(systemInfo)),
            catchError(() => of(this.systemInfoDucks.loadSystemInfoError.action())),
          ),
        ),
      );
}
