import { TableSearchRow } from './table-search-row';
import { StructureSearchValue } from '@models/structure-search';

export class TableSearch {
    Rows: TableSearchRow[];
    StructureSearch?: StructureSearchValue;
    Operation?: string;

    constructor() {
        this.Rows = [];
        const row = new TableSearchRow();
        row.order = 0;
        this.Rows.push(row);
    }
  }
