import { Component, OnInit, Inject, ViewEncapsulation, Input, TemplateRef, OnDestroy } from '@angular/core';
import { Configuration, ConfigurationRankingStep, RankingRule, ConfigurationRankingCascade } from 'app/core/services/api-client';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectedConfiguration, isConfigurationLoading } from '../../pages/configurations-page/ducks/configurations.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import { ConfigurationsDucks } from '../../pages/configurations-page/ducks/configurations.ducks';
import { Duck } from '@ngrx-ducks/core';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { ActivatedRoute } from '@angular/router';
import { MerkmalDucks } from 'app/modules/shared-area/ducks/merkmal/merkmal.ducks';
import { Merkmal } from '@models/merkmalbaum';
import { tap, filter } from 'rxjs/operators';
import { getMerkmale, isMerkmaleSucessfullyLoaded } from 'app/modules/shared-area/ducks/merkmal/merkmal.selectors';
import { CheckboxState } from './checkbox-state';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { RankingRulesDucks } from '../../pages/ranking-rules-page/ducks/ranking-rules.ducks';
import { getRankingRules, selectRankingRulesLastModified } from '../../pages/ranking-rules-page/ducks/ranking-rules.selectors';
import { DropdownListTextItem } from '@models/dropdownListTextItem';

@Component({
  selector: 'app-configurations-edit',
  templateUrl: './configurations-edit.component.html',
  styleUrls: ['./configurations-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfigurationsEditComponent implements OnInit, OnDestroy {


  public configuration: Configuration;
  public id: number;
  public configuration$: Observable<Configuration>;
  public form: FormGroup;
  public configurationRegex: RegExp = /^[a-zA-Z0-9 _-]{1,255}$/;
  public tagRegex: RegExp = /^[a-zA-Z0-9_-]{0,255}$/;
  public isNew: boolean;
  public isLoading$: Observable<boolean>;
  public subscriptions: Subscription[] = [];
  public merkmale$: Observable<Merkmal[]> = EMPTY;
  public merkmale: Merkmal[] = [];
  public selectedKeys: any[] = [];
  public selectedKey: string = null;
  private dialog: DialogRef;
  public rankingRulesListItems: DropdownListTextItem[];
  private rankingRulesLastModified$: Observable<Date> = EMPTY;
  private rankingRulesLastModified?: Date;
  private rankingRulesCachingMinutes = 30; // Rankingregeln werden nur geladen, wenn sie älter sind als 30 Minuten.

  public selectedRankingStep: ConfigurationRankingCascade[] = [];
  public rankingRules$: Observable<RankingRule[]>;
  constructor(
    private store: Store<IAppState>,
    private i18n: I18nService,
    private route: ActivatedRoute,
    @Inject(ConfigurationsDucks) private configurationDucks: Duck<ConfigurationsDucks>,
    @Inject(MerkmalDucks) private merkmalDucks: Duck<MerkmalDucks>,
    private dialogService: DialogService,
    @Inject(RankingRulesDucks)
    private rankingRulesDucks: Duck<RankingRulesDucks>,
  ) {
  }

  ngOnInit() {
    this.configuration$ = this.store.pipe(select(selectedConfiguration));
    this.isLoading$ = this.store.pipe(select(isConfigurationLoading));
    this.merkmale$ = this.store.pipe(select(getMerkmale));
    this.rankingRules$ = this.store.pipe(select(getRankingRules));
    this.rankingRulesLastModified$ = this.store.pipe(select(selectRankingRulesLastModified));
    this.configuration$.subscribe(configuration => {
      if (configuration) {
        this.configuration = configuration;
        this.isNew = configuration.id === undefined;
      }
    });

    this.subscriptions
      .push(this.route.params.subscribe(params => {
        const id = !_.isNil(params['id']) ? params['id'] : null;
        if (id === 'create') {
          this.configurationDucks.addConfiguration('');
        } else {
          this.id = !isNaN(id) ? id : null;
          if (this.id) {
            this.configurationDucks.getConfigurationEffect.dispatch(this.id);
          }
        }
      }));

    this.subscriptions
      .push(
        this.rankingRules$.pipe
          (
            tap((rankingRules) => {
              this.rankingRulesListItems = this.createRankingRulesListItems(rankingRules);
            }),
          )
          .subscribe());

    this.subscriptions
      .push(
        this.merkmale$.pipe
          (
            filter((merkmale) => merkmale && merkmale.length > 0),
            tap((merkmale) => {
              this.merkmale = this.createHierarchyTree(merkmale, '', false);
            }),
          )
          .subscribe());

    this.subscriptions
      .push(
        this.rankingRulesLastModified$.pipe
          (
            tap((timestamp) => {
              this.rankingRulesLastModified = timestamp;
            }),
          )
          .subscribe());

    if (this.rankingRulesLastModified) {
      const now = new Date();
      const diffMinutes: number = (now.getTime() - this.rankingRulesLastModified.getTime()) / (1000 * 60);
      if (diffMinutes > this.rankingRulesCachingMinutes) {
        this.rankingRulesDucks.getAllRankingRulesEffect.dispatch();
      }
    } else {
      this.rankingRulesDucks.getAllRankingRulesEffect.dispatch();
    }
    this.merkmalDucks.loadMerkmaleEffect.dispatch({ filterByLieferant: false });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  createRankingRulesListItems(rankingRules: RankingRule[]): DropdownListTextItem[] {
    const dropdownlistItems: DropdownListTextItem[] = rankingRules.map((rule: RankingRule) => {
      const ddlitem: DropdownListTextItem = { text: rule.name, value: rule.name };
      return ddlitem;
    });
    return dropdownlistItems;
  }
  public submit() {
    this.removeInvalidRules();
    if (this.isNew) {
      this.configurationDucks.saveConfigurationEffect.dispatch(this.configuration);
    } else {
      this.configurationDucks.updateConfigurationEffect.dispatch(this.configuration);
    }
  }
  removeInvalidRules() {
    Object.keys(this.configuration.ranking).forEach(key => {
      for (let i = this.configuration.ranking[key].length - 1; i >= 0; i--) {
        const steps = this.configuration.ranking[key][i].steps;
        this.configuration.ranking[key][i].steps = steps.filter(r => r.ruleName);
      }
    });
  }
  public get isValid() {
    return !_.isEmpty(this.configuration.name)
      && this.configurationRegex.test(this.configuration.name)
      && this.tagRegex.test(this.configuration.tag);
  }

  public onCheckMerkmal(event: any, merkmal: Merkmal, isChecked: boolean, dialog?: TemplateRef<any>) {
    event.preventDefault();
    if (isChecked) {
      if (!this.configuration.ranking.hasOwnProperty(merkmal.key)) {
        this.configuration.ranking[merkmal.key] = new Array<ConfigurationRankingCascade>();
      }
    } else {
      if (this.configuration.ranking.hasOwnProperty(merkmal.key)) {
        if (this.configuration.ranking[merkmal.key].length === 0) {
          delete this.configuration.ranking[merkmal.key];
          this.selectedRankingStep = null;
        } else {
          this.selectedKey = merkmal.key;
          this.showDeleteRankingDialog(dialog);
          return false;
        }
      }
    }
  }

  public isImplicit(node: Merkmal): boolean {
    let isImplicit = false;
    const ancestors = this.getParents(node);
    ancestors.forEach(a => {
      if (this.configuration.ranking.hasOwnProperty(a)) {
        isImplicit = true;
      }
    });
    return isImplicit;
  }

  public getState(merkmal: Merkmal): CheckboxState {

    let state: CheckboxState = 'unchecked';
    const isChecked = this.configuration.ranking.hasOwnProperty(merkmal.key);

    this.hasCheckedDescendant(merkmal, { isTrue: false });
    const hasCheckedDescendant = { isTrue: false };
    this.hasCheckedDescendant(merkmal, hasCheckedDescendant);
    const isImplicit = this.isImplicit(merkmal);

    if (hasCheckedDescendant.isTrue) {
      state = 'indeterminate';
    }
    if (isImplicit) {
      state = 'implicit';
    }
    if (isChecked) {
      state = 'checked';
    }
    return state;
  }

  private hasCheckedDescendant(merkmal: Merkmal, result: { isTrue: boolean }): void {
    result.isTrue = this.isChecked(merkmal) || result.isTrue;
    if (merkmal.items && merkmal.items.length > 0 && !result.isTrue) {
      merkmal.items.forEach(item => {
        return this.hasCheckedDescendant(item, result);
      });
    }
  }

  private getParents(merkmal: Merkmal): string[] {
    const ancestors = merkmal.hierarchy.split('-');
    if (ancestors.length > 1) {
      ancestors.pop();
    }
    if (ancestors.length > 0 && ancestors[0] === merkmal.key) {
      ancestors.shift();
    }
    return ancestors;
  }

  public isChecked = (dataItem: any): boolean => {
    if (this.configuration && this.configuration.ranking.hasOwnProperty(dataItem.key)) {
      return true;
    }
    return false;
  }

  private createHierarchyTree(merkmale: Merkmal[], parentId: string, showFeldNodes = false): Merkmal[] {
    return merkmale.map(merkmal => {
      const isMerkmal = merkmal.type === 'Merkmal';
      const hasCheckedDescendant = { isTrue: false };
      this.hasCheckedDescendant(merkmal, hasCheckedDescendant);
      const id = !isMerkmal
        ? `${merkmal.key}`
        : `${merkmal.key}`;
      const anchor = [parentId, id].filter(x => !!x)
        .join('-');
      const clonedMerkmal: Merkmal = {
        ...merkmal,
        hierarchy: anchor,
        hasCheckedDescendants: hasCheckedDescendant.isTrue
      };

      const hasChildHierarchie = _.some(clonedMerkmal.items, ['type', 'Hierarchie']);
      const hasChildMerkmal = _.some(clonedMerkmal.items, ['type', 'Merkmal']);

      if (showFeldNodes || hasChildHierarchie || hasChildMerkmal) {
        if (clonedMerkmal.items && clonedMerkmal.items.length > 0) {
          clonedMerkmal.items = this.createHierarchyTree(clonedMerkmal.items, anchor, false);
        }

      } else {
        clonedMerkmal.items = null;
      }
      return clonedMerkmal;
    });
  }

  public showDeleteRankingDialog(actionTemplate: TemplateRef<string>) {
    const content = this
      .i18n
      .getLocalizedString('dialog.deleteRanking');
    const title = this
      .i18n
      .getLocalizedString('dialog.confirmationTitle');

    this.dialog = this
      .dialogService
      .open({
        title: title,
        content: content,
        actions: actionTemplate,
      });
  }

  public deleteRanking(): void {
    delete this.configuration.ranking[this.selectedKey];
    this.selectedRankingStep = null;
    this.dialog.close();
  }

  public close(): void {
    this.dialog.close();
  }

  public edit(merkmal: Merkmal) {
    const key = merkmal.key;
    this.selectedKey = key;
    if (!this.configuration.ranking.hasOwnProperty(key)) {
      this.configuration.ranking[key] = new Array<ConfigurationRankingCascade>();
    }
    this.selectedRankingStep = this.configuration.ranking[key];
  }
}
