import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-file-link',
  templateUrl: './file-link.component.html'
})
export class FileLinkComponent implements OnChanges {
  @Input() value: string;
  @Input() hideClear: boolean;
  @Output() valueChange = new EventEmitter<string>();

  fileName: string;
  url: string;

constructor(private oidcSecurityService: OidcSecurityService) {}

  ngOnChanges() {
    this.update();
  }

  public clear() {
    this.value = null;
    this.update();
    this.valueChange.emit(this.value);
  }

  update() {
    if (_.isNil(this.value)) {
      this.url = '';
      this.fileName = '';
    } else {
      const splitted = this.value.split('|');
      const token = this.oidcSecurityService.getToken();
      const apiServer = new URL(environment.baseAPIUrl).origin;
      this.url = `${apiServer}${splitted[ 0 ]}?access_token=${token}`;
      this.fileName = splitted[ 2 ];
    }
  }
}
