import { NgModule } from '@angular/core';
import { NotFoundComponent } from 'app/core/authorization/not-found/not-found.component';
import { PageHeaderModule } from 'app/core/layout/page-header/page-header.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    PageHeaderModule,
    TranslateModule,
  ],
  declarations: [ NotFoundComponent ],
  exports: [ NotFoundComponent ]
})
export class NotFoundModule {}
