<div *ngIf="result$ | async as result" class="batch">
  <div style="text-align: center; font-size: 1.2em; width: 750px">
    <ng-container *ngIf="result.created === false && result.errors && result.errors.length > 0">
      <i class="fas fa-exclamation-circle" style="color: red; font-size: 2em; display: block;"></i>
      {{'components.batch.result.failed' | translate}}
    </ng-container>
    <ng-container *ngIf="result.created === true && result.errors && result.errors.length > 0">
      <i class="fas fa-exclamation-triangle" style="color: orange; font-size: 2em; display: block;"></i>
      {{'components.batch.result.createdWithErrors' | translate}}
    </ng-container>
    <ng-container *ngIf="result.created === true && (!result.errors || result.errors.length === 0)">
      <i class="fas fa-check-circle" style="color: green; font-size: 2em; display: block;; height: 50px;"></i>
      {{'components.batch.result.created' | translate}}
    </ng-container>
  </div>
  <table *ngIf="result.errors && result.errors.length > 0" class="table table-striped">
    <thead>
      <tr>
        <th width="80">{{'components.batch.headers.id' | translate}} </th>
        <th>{{'components.batch.headers.name' | translate}}</th>
        <th width="1" style="white-space: nowrap; text-align: right">{{'components.batch.headers.error' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let error of result.errors">
        <tr>
          <td>{{error.objektId}}</td>
          <td>{{error.name}}</td>
          <td style="white-space: nowrap; text-align: right;">{{'components.batch.errors.' + error.error |
            translate}}</td>
        </tr>
        <tr *ngIf="error.affectedObjects && error.affectedObjects.length > 0">
          <td></td>
          <td colspan="2">
            {{'components.batch.errorMessage.' + error.error | translate}}
            <ul>
              <li *ngFor="let affectedObject of error.affectedObjects">
                {{affectedObject.name}} ({{'objectTypes.' + affectedObject.objektTyp | translate}},
                {{affectedObject.objektId}})
              </li>
            </ul>
          </td>
        </tr>
        <tr *ngIf="error.lockedBy && error.lockedBy.length > 0">
          <td></td>
          <td colspan="2">
            {{'components.batch.errorMessage.' + error.error | translate}}
            <ul>
              <li *ngFor="let lockedBy of error.lockedBy">
                {{lockedBy}}
              </li>
            </ul>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div *ngIf="result.created === false" class="window-button-group">
    <button class="btn btn-primary" [disabled]="isBusy$ | async" (click)="retry(chkIgnoreErrors.checked)" style="margin-right: 16px">{{'components.batch.retry'
      | translate}}
    </button>
    <input #chkIgnoreErrors [disabled]="isBusy$ | async" id="chkIgnoreErrors" type="checkbox" class="k-checkbox">
    <label class="k-checkbox-label" for="chkIgnoreErrors" style="margin-right: 16px">{{'components.batch.ignoreErrors' | translate}}</label>
    <span *ngIf="isBusy$ | async" class="k-icon k-i-loading" style="font-size: 24px; margin-bottom: 5px"></span>
    <button class="btn btn-default" [disabled]="isBusy$ | async" style="float: right" (click)="close()" id="btn_cancel">
      {{'action.cancel' | translate}}
    </button>
  </div>
</div>