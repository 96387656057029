import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as merge from 'deepmerge';

const prefix = './assets/i18n/';
const suffix = '.json';

export class CustomTranslateLoader implements TranslateLoader {
  public constructor(private http: HttpClient) {}

  private loadTranslationFile(lang: string): Observable<any> {
    const path = prefix + lang + suffix;
    return this.http.get(path)
      .pipe
      (
        catchError(() => {
            console.error('Could not find translation file:', path);
            return of({});
          },
        ));
  }

  public getTranslation(locale: string): Observable<any> {
    const [ language, ] = locale.split('-');

    const language$ = this.loadTranslationFile(language);
    const country$ = this.loadTranslationFile(locale);
    return forkJoin([ language$, country$ ])
      .pipe
      (
        map(languages => merge.all(languages)),
      );
  }
}
