import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ILanguageValue } from 'app/core/services/i18n/models/ILanguageValue';

@Injectable()
export class I18nService {
  public supportedUIUserLanguages: ILanguageValue[];

  constructor(private translate: TranslateService,
    private titleService: Title) {
    this.supportedUIUserLanguages = environment.supportedUIUserLanguages;
  }

  public async init() {
    this.translate.addLangs(this.supportedUIUserLanguages.map(lang => lang.CultureCode));
    this.translate.setDefaultLang(environment.defaultUIUserCulture);
    await this.translate.use(this.CurrentUILanguage.CultureCode).toPromise<void>();
  }

  public get CurrentUILanguage(): ILanguageValue {
    const storedLanguage = localStorage.getItem('cultureInfo');
    const defaultLanguage = this.supportedUIUserLanguages.find(lang => lang.CultureCode === environment.defaultUIUserCulture);
    return storedLanguage
      ? this.supportedUIUserLanguages.find(lang => lang.CultureCode === storedLanguage)
      : defaultLanguage
        ? defaultLanguage
        : this.supportedUIUserLanguages[0];
  }

  public set CurrentUILanguage(language: ILanguageValue) {
    localStorage.setItem('cultureInfo', language.CultureCode);
    this.translate.use(language.CultureCode).subscribe(() => {
      console.log(`Successfully initialized '${this.CurrentUILanguage.CultureCode}' language.'`);
    }, error => {
      console.log(`Problem with '${this.CurrentUILanguage.CultureCode}' language initialization.'`);
    }, () => {
      // complete
      console.log(`language initialization completed.'`);
      console.log(`Current language: ${this.translate.currentLang}`);
    });

    // toDo: Storage
  }

  public get EditorCultureCode() {
    const supportedUICultures = environment.supportedUILanguages;
    let cultureCode = this.CurrentUILanguage.CultureCode.toLowerCase();
    let currentLang: ILanguageValue = supportedUICultures.find(lang => lang.CultureCode.toLowerCase() === cultureCode);
    if (!currentLang && cultureCode.length > 2) {
      cultureCode = cultureCode.substr(0, 2);
      currentLang = supportedUICultures.find(lang => lang.CultureCode.toLowerCase() === cultureCode);
    }
    if (!currentLang) {
      currentLang = supportedUICultures[0];
    }
    return currentLang.CultureCode;
  }

  public setTitle(title: string) {
    const pageTitle = this.getLocalizedString('pageTitle.pattern', { title: title });
    this.titleService.setTitle(pageTitle);
  }

  public setTitleFromKey(key: string) {
    if (this.CurrentUILanguage.CultureCode !== this.translate.currentLang) {
      this.translate.use(this.CurrentUILanguage.CultureCode).subscribe(() => {
        this.translate.get(key).subscribe(title => {
          const pageTitle = this.getLocalizedString('pageTitle.pattern', { title: title });
          this.titleService.setTitle(pageTitle);
        });
      }, error => {
        console.log('Could not set title.', error);
      }, () => {
        // console.log('set title completed');
      });
    } else {
      const title = this.translate.instant(key);
      const pageTitle = this.getLocalizedString('pageTitle.pattern', { title: title });
      this.titleService.setTitle(pageTitle);
    }
  }

  public getLocalizedString(key: string | string[], params?: any): string {
    const translated = this.translate.instant(key, params);
    // ToDo: Remove in PROD
    console.log('key: ', key + ' -> ' + translated);
    return translated;
  }
}
