import { Injectable } from '@angular/core';
import { Dossier } from '@models/dossier/dossier';
import {
  ApiClient,
  IdentitaetPruefenResult,
  ISachverhalteKopierenRequest,
  MergeRequest,
  ObjektTyp2,
  PartialDossier,
  RejectReason,
  RequestForApprovalMessage,
  SachverhalteKopierenRequest, SachverhalteKopierenResponseItem,
  StoffErstellenCreated,
} from 'app/core/services/api-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';

import { Sachverhalt, SachverhaltSet, StofflisteStoffTreffer } from 'app/models/dossier/dossier-models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ThesaurusMode } from '../../pages/dossier/ducks/dossier.state';
import { LoadImport } from '../../ducks/models/loadImport';
import { FactAenderung } from '@models/dossier/factAenderung';

@Injectable()
export class DossierService {

  public constructor(
    private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService,
  ) { }

  public loadSubstanceDossier(id: string, thesaurusMode: ThesaurusMode): Observable<Dossier> {
    if (!thesaurusMode || thesaurusMode === 'original' || thesaurusMode === 'none') {
      const thesaurierteSachverhalteAufloesen = thesaurusMode === 'original';
      return this.apiClient.apiStoffByStoffIdBearbeitenGet(id, thesaurierteSachverhalteAufloesen)
        .pipe
        (
          catchError(this.apiClient.apiStoffByStoffIdBearbeitenGetErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400, { invalidStateMessageKey: 'errors.400.invalidstate.apiStoffByStoffIdBearbeitenByErfassungIdGet' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenGet' }),
          })),
          map(dossierDto => Dossier.fromDto(dossierDto)),
        );
    } else {
      return this.apiClient.apiStoffByStoffIdBearbeitenThesaurierungVorschauGet(id, true)
        .pipe
        (
          catchError(this.apiClient.apiStoffByStoffIdBearbeitenThesaurierungVorschauGetErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400, { invalidStateMessageKey: 'errors.400.invalidstate.apiStoffByStoffIdBearbeitenByErfassungIdGet' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenGet' }),
          })),
          map(dossierDto => Dossier.fromDto(dossierDto)),
        );
    }
  }

  public revertAllChanges(id: string): Observable<any> {
    return this.apiClient.apiStoffByStoffIdBearbeitenDelete(id)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenDeleteErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, { invalidStateMessageKey: 'errors.400.invalidstate.apiStoffByStoffIdBearbeitenByErfassungIdDelete' }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenByErfassungIdDelete' }),
        })),
      );
  }

  public StoffLoeschenBeantragen(id: string, message: string) {
    const payload = new RequestForApprovalMessage({ message: message });
    return this.apiClient.apiStoffByStoffIdLoeschenPost(id, payload)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdLoeschenPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403, {
            lockedByOtherUserMessageKey: 'errors.403.lockedByOtherUser.apiStoffByStoffIdLoeschenPost',
            referenceViolationError: 'errors.403.referenceViolation.apiStoffByStoffIdLoeschenPost',
          }),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdLoeschenPost' }),
        })),
      );
  }

  public FreigabeProzessStarten(id: string, message: string): Observable<boolean> {
    const payload = new RequestForApprovalMessage({ message: message });
    return this.apiClient.apiStoffByStoffIdBearbeitenFreigabeProzessStartenPost(id, payload)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenFreigabeProzessStartenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, {
            invalidStateMessageKey: 'errors.400.invalidstate.apiNeuesDossierByErfassungIdFreigabeProzessStartenPost',
            identityCollisionMessageKey: 'errors.400.identityCollision.apiStoffByStoffIdBearbeitenFreigabeProzessStartenPost',
            cardinalityValidationFailedMessageKey: 'errors.400.cardinalityValidationFailed.apiStoffByStoffIdBearbeitenFreigabeProzessStartenPost',
          }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenFreigabeProzessStartenPost' }),
        })),
      );
  }

  public DossierSachverhaltLoeschenBeantragen(id: string, sachverhaltId: number) {
    return this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdLoeschenPost(id, sachverhaltId)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdLoeschenPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403, {
            lockedByOtherUserMessageKey: 'errors.403.lockedByOtherUser.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdLoeschenPost',
          }),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdLoeschenPost' }),
        })),
      );
  }

  public DossierNeuenSachverhaltLoeschen(id: string, erfassungSachverhaltId: number): Observable<PartialDossier> {
    return this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdDelete(id, erfassungSachverhaltId)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdDeleteErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiDossierByGsblIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdDelete' }),
        })),
      );
  }

  public DossierSachverhaltBearbeitenAnzeigen(id: string, sachverhaltId: number): Observable<Sachverhalt> {
    return this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdGet(id, sachverhaltId)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403, {
            lockedByOtherUserMessageKey: 'errors.403.lockedByOtherUser.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdGet',
          }),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdGet' }),
        })),
        map(sachverhaltBearbeiten => Sachverhalt.fromDto(sachverhaltBearbeiten)),
      );
  }

  public DossierSachverhaltBearbeiten(id: string, sachverhaltId: number, values: SachverhaltSet): Observable<PartialDossier> {
    return this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdPut(id, sachverhaltId, values)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdPutErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403, {
            supplierNotAssignedMessageKey: 'errors.403.suppliernotassigned.apiDossierByGsblIdBearbeitenSachverhaltBySachverhaltIdPut',
          }),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiDossierByGsblIdBearbeitenSachverhaltBySachverhaltIdPut' }),
        })),
      );
  }

  public DossierNeuenSachverhaltBearbeitenAnzeigen(id: string, erfassungSachverhaltId: number): Observable<Sachverhalt> {
    return this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdGet(id, erfassungSachverhaltId)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdGetErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403, {
            supplierNotAssignedMessageKey: 'errors.403.suppliernotassigned.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdPut',
          }),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdGet' }),
        })),
        map(sachverhaltBearbeiten => Sachverhalt.fromDto(sachverhaltBearbeiten)),
      );
  }

  public DossierNeuenSachverhaltBearbeiten(id: string, erfassungSachverhaltId: number, values: SachverhaltSet): Observable<PartialDossier> {
    return this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdPut(id, erfassungSachverhaltId, values)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdPutErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, {
            duplicateCopyErrorMessageKey: 'errors.400.duplicatecopy.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdPut',
          }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdPut' }),
        })),
      );
  }

  public DossierBearbeitenSachverhaltAenderungenVerwerfen(id: string, sachverhaltId: number): Observable<PartialDossier> {
    return this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdDelete(id, sachverhaltId)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenSachverhaltBySachverhaltIdDeleteErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenNeuerSachverhaltByErfassungSachverhaltIdPut' }),
        },
        )),
      );
  }

  public DossierNeuenSachverhaltErstellenAnzeigen(id: string, merkmal: string): Observable<Sachverhalt> {
    return this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltGet(id, merkmal)
      .pipe(
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltGetErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
        })),
        map(result => Sachverhalt.fromDto(result)),
      );
  }

  public DossierNeuenSachverhaltErstellen(id: string, merkmal: string, values: SachverhaltSet): Observable<PartialDossier> {

    return this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltPost(id, merkmal, values)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdBearbeitenNeuerSachverhaltPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, {
            duplicateCopyErrorMessageKey: 'errors.400.duplicatecopy.apiStoffByStoffIdBearbeitenNeuerSachverhaltPost',
          }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403, {
            supplierNotAssignedMessageKey: 'errors.403.suppliernotassigned.apiStoffByStoffIdBearbeitenNeuerSachverhaltPost',
          }),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiStoffByStoffIdBearbeitenNeuerSachverhaltPost' }),
        })),
      );

  }

  public getCreateModel(): Observable<Sachverhalt> {
    return this.apiClient.apiStoffErstellenGet()
      .pipe
      (
        catchError(this.apiClient.apiStoffErstellenGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
        })),
        map(result => Sachverhalt.fromDto(result)),
      );
  }

  public create(values: SachverhaltSet): Observable<StoffErstellenCreated> {
    return this.apiClient.apiStoffErstellenPost(values)
      .pipe
      (
        catchError(this.apiClient.apiStoffErstellenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
        })),
      );
  }

  /*public NeuesDossierLoeschen(erfassungId: number): Observable<any> {
    console.log('apiNeuesDossierByErfassungIdDelete');
    return this.apiClient.apiNeuesDossierByErfassungIdDelete(erfassungId)
      .pipe(
        catchError(this.apiClient.apiNeuesDossierByErfassungIdDeleteErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
        })),
      );
  }*/

  public getTrefferListe(id: string, searchId: string): Observable<StofflisteStoffTreffer[]> {
    return this.apiClient.apiSucheBySearchIdByIdGet(searchId, id)
      .pipe
      (
        catchError(this.apiClient.apiSucheBySearchIdByIdGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401)
        })),
      );
  }

  public loadErfassung(erfassungId: number): Observable<Dossier> {
    return this.apiClient.apiErfassungByErfassungIdGet(erfassungId)
      .pipe
      (
        catchError(this.apiClient.apiErfassungByErfassungIdGetErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdGet' }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdGet' }),
        })),
        map(dossierDto => Dossier.fromDto(dossierDto)),
      );
  }

  public deleteErfassung(erfassungId: number): Observable<any> {
    return this.apiClient.apiErfassungByErfassungIdDelete(erfassungId)
      .pipe(
        catchError(this.apiClient.apiErfassungByErfassungIdDeleteErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
        })),
      );
  }

  public confirmAsLecturer(erfassungId: number): Observable<any> {
    return this.apiClient.apiErfassungByErfassungIdFreigebenAlsFachlektorPost(erfassungId)
      .pipe
      (
        catchError(
          // use one error handler to handle both error cases
          this.apiClient.apiErfassungByErfassungIdFreigebenAlsFachlektorPostErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400,
              { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdFreigebenAlsFachlektorPost' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdFreigebenAlsFachlektorPost' }),
          })),
      );
  }

  public confirmAsAdmin(erfassungId: number): Observable<any> {
    return this.apiClient.apiErfassungByErfassungIdFreigebenAlsLaenderadministratorPost(erfassungId)
      .pipe
      (
        catchError(
          // use one error handler to handle both error cases
          this.apiClient.apiErfassungByErfassungIdFreigebenAlsLaenderadministratorPostErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400,
              { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdFreigebenAlsLaenderadministratorPost' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdFreigebenAlsLaenderadministratorPost' }),
          })),
      );
  }

  public confirmMerge(erfassungId: number): Observable<any> {
    return this.apiClient.apiErfassungByErfassungIdStoffzusammenfuehrungFreigebenPost(erfassungId)
      .pipe
      (
        catchError(
          // use one error handler to handle both error cases
          this.apiClient.apiErfassungByErfassungIdStoffzusammenfuehrungFreigebenPostErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400,
              { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdStoffzusammenfuehrungFreigebenPost' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdStoffzusammenfuehrungFreigebenPost' }),
          })),
      );
  }

  public rejectAsLecturer(erfassungId: number, reason?: string): Observable<any> {
    const payload = new RejectReason({ reason: reason });
    return this.apiClient.apiErfassungByErfassungIdAblehnenAlsFachlektorPost(erfassungId, payload)
      .pipe
      (
        catchError(
          // use one error handler to handle both error cases
          this.apiClient.apiErfassungByErfassungIdAblehnenAlsFachlektorPostErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400,
              { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdAblehnenAlsFachlektorPost' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdAblehnenAlsFachlektorPost' }),
          })),
      );
  }

  public rejectAsAdmin(erfassungId: number, reason?: string): Observable<any> {
    const payload = new RejectReason({ reason: reason });
    return this.apiClient.apiErfassungByErfassungIdAblehnenAlsLaenderadministratorPost(erfassungId, payload)
      .pipe
      (
        catchError(
          // use one error handler to handle both error cases
          this.apiClient.apiErfassungByErfassungIdAblehnenAlsLaenderadministratorPostErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400,
              { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdAblehnenAlsLaenderadministratorPost' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdAblehnenAlsLaenderadministratorPost' }),
          })),
      );
  }

  public rejectMerge(erfassungId: number, reason?: string): Observable<any> {
    const payload = new RejectReason({ reason: reason });
    return this.apiClient.apiErfassungByErfassungIdStoffzusammenfuehrungAblehnenPost(erfassungId, payload)
      .pipe
      (
        catchError(
          // use one error handler to handle both error cases
          this.apiClient.apiErfassungByErfassungIdStoffzusammenfuehrungAblehnenPostErrorHandlers({
            error400Handler: error400 => this.errorHandler.handle400Error(error400,
              { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdStoffzusammenfuehrungAblehnenPost' }),
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
            error403Handler: error403 => this.errorHandler.handle403Error(error403),
            error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdStoffzusammenfuehrungAblehnenPost' }),
          })),
      );
  }

  public uebernehmen(erfassungId: number): Observable<void> {
    return this.apiClient.apiErfassungByErfassungIdUebernehmenPost(erfassungId)
      .pipe
      (
        catchError(this.apiClient.apiErfassungByErfassungIdUebernehmenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, { invalidStateMessageKey: 'errors.400.invalidstate.apiErfassungByErfassungIdUebernehmenPost' }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiErfassungByErfassungIdUebernehmenPost' }),
          error409Handler: error409 => this.errorHandler.handle409Error(error409, { messageKey: 'errors.409.apiErfassungByErfassungIdUebernehmenPost' }),
        })),
      );
  }

  public weiterarbeiten(erfassungId: number): Observable<void> {
    return this.apiClient.apiErfassungByErfassungIdWeiterarbeitenPost(erfassungId)
      .pipe
      (
        catchError(this.apiClient.apiErfassungByErfassungIdWeiterarbeitenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400,
            { invalidStateMessageKey: 'errors.400.invalidstate.apiDossierByGsblIdBearbeitenByErfassungIdUebernehmenPost' }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiNeuesDossierByErfassungIdWeiterarbeitenPost' }),
          error409Handler: error409 => this.errorHandler.handle409Error(error409, { messageKey: 'errors.409.apiDossierByGsblIdBearbeitenByErfassungIdWeiterarbeitenPost' }),
        })),
      );
  }

  merge(id: string, ids: string[]): Observable<boolean> {
    const payload = new MergeRequest({ objektIds: ids });
    return this.apiClient.apiStoffByStoffIdZusammenfuehrenPost(id, payload)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdZusammenfuehrenPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
          error409Handler: error409 => this.errorHandler.handle409Error(error409),
        })),
      );
  }

  checkIdentity(id: string): Observable<IdentitaetPruefenResult> {
    return this.apiClient.apiStoffByStoffIdIdentitaetPruefenGet(id)
      .pipe
      (
        catchError(this.apiClient.apiStoffByStoffIdIdentitaetPruefenGetErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
        })),
      );
  }

  sachverhalteKopieren(request: ISachverhalteKopierenRequest): Observable<SachverhalteKopierenResponseItem[]> {
    return this.apiClient.apiStoffSachverhalteKopierenPost(new SachverhalteKopierenRequest(request))
      .pipe
      (
        catchError(this.apiClient.apiStoffSachverhalteKopierenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404),
        })),
      );
  }

  // Import

  public loadImportDossier(loadImport: LoadImport): Observable<Dossier> {
    return this.apiClient.apiImportByImportIdByObjektTypByImportObjektIdGet(loadImport.importId, loadImport.objektTyp, loadImport.importObjektId)
      .pipe
      (
        catchError(this.apiClient.apiImportByImportIdByObjektTypByImportObjektIdGetErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400, { invalidStateMessageKey: 'errors.400.invalidstate.apiImportByImportIdByObjektTypByImportObjektIdGet' }),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404, { messageKey: 'errors.404.apiImportByImportIdByObjektTypByImportObjektIdGet' }),
        })),
        map(dossierDto => Dossier.fromDto(dossierDto)),
      );
  }

  public aendereSachverhalt(aenderung: FactAenderung): Observable<boolean> {
    return this.apiClient.apiImportByImportIdByObjektTypByImportObjektIdBySachverhaltKeyAusgewaehltPut(
      aenderung.importId,
      aenderung.objektTyp as unknown as ObjektTyp2,
      aenderung.importObjektId,
      aenderung.sachverhaltKey,
      aenderung.selected)
      // aenderung.culture)
      .pipe
      (
        catchError(this.apiClient.apiImportByImportIdByObjektTypByImportObjektIdBySachverhaltKeyAusgewaehltPutErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404)
        }))
      );
  }








  /*
    public updateBatchErfassung(erfassung: IErfassungReferenz): Observable<FakeResponse> {
      return this.apiClient.apiBatchErfassungenPut(erfassung)
        .pipe
        (
          catchError(this.apiClient.apiBatchErfassungenPutErrorHandlers({
            error401Handler: error401 => this.errorHandler.handle401Error(error401),
          })),
        );
    }
  }*/

}
