import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiClientModule } from 'app/core/services/api-client/api-client.module';
import { ApiErrorHandlerServiceModule } from 'app/core/services/api-default-error-handler/api-error-handler.service.module';
import { MerkmalService } from './merkmal.service';

@NgModule({
  imports: [
    // HttpClientModule.forChild(), // Interceptors does not work when specified
    // CustomToastyModule
    ApiClientModule,
    ApiErrorHandlerServiceModule
  ],
})
export class MerkmalModule {
  public static forRoot(): ModuleWithProviders<MerkmalModule> {
    return {
      ngModule: MerkmalModule,
      providers: [ MerkmalService ],
    };
  }
}
