import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { DossierHelperModule } from 'app/modules/dossier-area/services/dossier-helper/dossier-helper.module';
import { MergeDialogComponent } from './merge-dialog.component';
import { ReferenceLinkModule } from '../reference-link/reference-link.module';
import { FormsModule } from '@angular/forms';
import { HasrightModule } from 'app/shared/directives/hasright/hasright.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    I18nModule,
    DossierHelperModule,
    ReferenceLinkModule,
    FormsModule,
    HasrightModule
  ],
  declarations: [ MergeDialogComponent ],
  exports: [ MergeDialogComponent ]
})
export class MergeDialogModule {}
