import { IMerkmalBaum, MerkmalBaum, MerkmalBaumMerkmal } from 'app/core/services/api-client';

export class Merkmal {
  key: string;
  name: string;
  id: number;
  items: Merkmal[];
  type: 'Hierarchie' | 'Merkmal' | 'Feld';
  hierarchy?: string;
  isDisabled?: boolean;
  isMatch?: boolean;
  isLocked?: boolean;
  lockedBy?: string;
  allowedTypes?: number[];
  root?: boolean;
  hasCheckedDescendants?: boolean;

  public static fromDto(dto: MerkmalBaum): Merkmal {
    if (dto == null) {
      return null;
    }
    const vm = new Merkmal();
    vm.name = dto.name;
    vm.key = dto.key;
    vm.id = dto.id;
    vm.type = dto.type;
    vm.allowedTypes = dto.allowedTypes;
    vm.root = dto.root;
    const items: MerkmalBaum[] = (<any>dto).items;
    if (items) {
      vm.items = items.map(merkmal => Merkmal.fromDto(merkmal));
    }
    return vm;
  }
}

export class MerkmalLock {
  merkmalId: number;
  lockedBy: string;
}


