import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileLinkComponent } from 'app/modules/dossier-area/components/file-link/file-link.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [ FileLinkComponent ],
  exports: [ FileLinkComponent ]
})
export class FileLinkModule {}
