import { I18nService } from 'app/core/services/i18n/i18n.service';

// see https://github.com/angular/angular/issues/15039
export class DynamicLocaleId extends String {
  public constructor(protected i18n: I18nService) {
    super();
  }

  public toString() {
    return this.i18n.CurrentUILanguage.CultureCode;
  }
}
