import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { Dossier } from '@models/dossier/dossier';
import { Role } from 'app/modules/shared-area/ducks/user/models/role';
import { ErfassungRef, ErfassungRefWithReason, ReEdit, RequestForApproval, RevertAllChanges, TakeErfassung } from 'app/models/erfassungRef';
import { DossierState, RenderState, SearchState, ThesaurusMode } from './dossier.state';
import { LoadSubstance } from 'app/modules/dossier-area/ducks/models/loadSubstance';
import { PartialDossier } from 'app/core/services/api-client';
import { DossierHelperService } from 'app/modules/dossier-area/services/dossier-helper/dossier-helper.service';

export * from './dossier.state';

@Ducksify<DossierState>({
  initialState: {
    id: null,
    erfassungId: null,
    isLoading: false,
    dossier: null,
    isDeleting: false,
    isDeleted: false,
    roleContext: null,
    isReEditing: false,
    isTaking: false,
    renderState: new Map(),
    renderingItems: 0,
    isPartialUpdateInProgress: false,
    searchState: {
      search: '',
      hits: new Set<string>(),
      currentHit: null,
      isIgnoreCase: true,
      isWholeWord: false,
      state: 'unknown',
    },
    submitIsBusy: false,
    copySachverhalte: {
      dossierIds: [],
      sachverhaltIds: [],
      showDialog: false,
      isLoading: false,
    },
    thesaurusMode: 'none'
  },
})

export class DossierDucks {
  public readonly dossierLoeschenBeantragen = effect<RequestForApproval>('[Dossier] Dossier Loeschen Beantragen');
  public readonly dossierLoeschenBeantragenSuccess = effect<void>('[Dossier] Dossier Loeschen Beantragen Success');
  public readonly dossierLoeschenBeantragenError = effect<string>('[Dossier - Effect] Dossier Loeschen Beantragen (Error)');
  public readonly revertAllChanges = effect<RevertAllChanges>('[Dossier] Revert all changes');
  public readonly revertAllChangesError = effect<void>('[Dossier] Revert all changes Error');
  public readonly revertAllChangesSuccess = effect<RevertAllChanges>('[Dossier] Revert all changes Success');
  public readonly deleteErfassung = effect<ErfassungRef>('[Dossier] Delete Erfassung');
  public readonly deleteErfassungError = effect<void>('[Dossier] Delete Erfassung Error');
  public readonly stoffFreigabeprozessStartenEffect = effect<RequestForApproval>('[Dossier Effect] Stoff Freigabeprozess starten');
  public readonly stoffFreigabeprozessStartenSuccessEffect = effect<void>('[Dossier Effect] Stoff Freigabeprozess starten Success');
  public readonly stoffFreigabeprozessStartenErrorEffect = effect<string>('[Dossier Effect] Stoff Freigabeprozess starten Error');
  public readonly confirmAsAdminEffect = effect<ErfassungRef>('[Dossier - Effect] Confirm as admin');
  public readonly confirmAsAdminError = effect<void>('[Dossier] Confirm as admin (Error)');
  public readonly confirmAsLecturerEffect = effect<ErfassungRef>('[Dossier - Effect] Confirm as lecturer');
  public readonly confirmAsLecturerError = effect<void>('[Dossier] Confirm as lecturer (Error)');
  public readonly confirmMergeEffect = effect<ErfassungRef>('[Dossier - Effect] Confirm merge');
  public readonly confirmMergeError = effect<void>('[Dossier] Confirm merge (Error)');
  public readonly rejectAsAdminEffect = effect<ErfassungRefWithReason>('[Dossier] Reject as admin');
  public readonly rejectAsAdminError = effect<void>('[Dossier] Reject as admin (Error)');
  public readonly rejectAsLecturerEffect = effect<ErfassungRefWithReason>('[Dossier] Reject as lecturer');
  public readonly rejectAsLecturerError = effect<void>('[Dossier] Reject as lecturer (Error)');
  public readonly rejectMergeEffect = effect<ErfassungRefWithReason>('[Dossier] Reject merge');
  public readonly rejectMergeError = effect<void>('[Dossier] Reject merge (Error)');
  public readonly reEditDossierEffect = effect<ReEdit>('[Dossier - Effect] Bearbeite Erfassung');
  public readonly startSearchEffect = effect<{ search: string, isWholeWord: boolean, isIgnoreCase: boolean }>('[Dossier - Effect] Starte die Suche im Dossier');

  @Action('[Dossier] Load Erfassung')
  public loadErfassung(state: DossierState, erfassungRef: ErfassungRef): typeof state {
    return {
      ...state,
      erfassungId: erfassungRef.erfassungId,
      isLoading: true,
      isDeleting: false,
      isDeleted: false,
      submitIsBusy: false,
    };
  }

  @Action('[Dossier] Load Erfassung Success')
  public loadErfassungSuccess(state: DossierState, dossier: Dossier): typeof state {
    return {
      ...state,
      isLoading: false,
      dossier,
      renderState: new Map(),
      renderingItems: 0,
    };
  }

  @Action('[Dossier] Load Erfassung Error')
  public loadErfassungError(state: DossierState): typeof state {
    return {
      ...state,
      isLoading: false
    };
  }

  @Action('[Dossier] Load Dossier')
  public loadSubstance(state: DossierState, loadSubstance: LoadSubstance): typeof state {
    return {
      ...state,
      id: loadSubstance.id,
      isLoading: true,
      isDeleting: false,
      isDeleted: false,
      submitIsBusy: false,
    };
  }

  @Action('[Dossier] Load Dossier Success')
  public loadSubstanceSuccess(state: DossierState, dossier: Dossier): typeof state {
    return {
      ...state,
      isLoading: false,
      dossier,
      renderState: new Map(),
      renderingItems: 0,
    };
  }

  @Action('[Dossier] Toggle small loading spinner')
  public showSmallLoadingSpinner(state: DossierState, isInProgress: boolean): typeof state {
    return {
      ...state,
      isPartialUpdateInProgress: isInProgress,
    };
  }

  @Action('[Dossier] Partial Update Dossier')
  public partialUpdateDossier(state: DossierState, updatedDossier: Dossier): typeof state {
    return {
      ...state,
      dossier: updatedDossier,
      renderState: new Map(),
      renderingItems: 0,
    };
  }

  @Action('[Dossier] Clear dossier render state')
  public clearRenderState(state: DossierState): typeof state {
    return {
      ...state,
      renderState: new Map(),
      renderingItems: 0,
    };
  }

  @Action('[Dossier] Load Dossier Error')
  public loadSubstanceError(state: DossierState): typeof state {
    return {
      ...state,
      isLoading: false,
      dossier: null,
      isDeleted: true
    };
  }

  @Action('[Dossier] Set the role for the current dossier (lector / admin)')
  public setRoleContext(state: DossierState, role: Role | null): typeof state {
    return {
      ...state,
      roleContext: role,
    };
  }

  @Action('[Dossier] Re-Edit Dossier')
  public reEditDossier(state: DossierState): typeof state {
    return {
      ...state,
      isReEditing: true,
    };
  }

  @Action('[Dossier] Re-Edit Dossier (Success)')
  public reEditDossierSuccess(state: DossierState, reEdit: ReEdit): typeof state {
    return {
      ...state,
      isReEditing: false,
    };
  }

  @Action('[Dossier] Re-Edit Dossier (Error)')
  public reEditDossierError(state: DossierState): typeof state {
    return {
      ...state,
      isReEditing: false,
    };
  }

  @Action('[Dossier] Übernehme Erfassung')
  public takeErfassung(state: DossierState, takeErfassung: TakeErfassung): typeof state {
    return {
      ...state,
      isTaking: true,
    };
  }

  @Action('[Dossier] Übernehme Erfassung (Success)')
  public takeErfassungSuccess(state: DossierState, takeErfassung: TakeErfassung): typeof state {
    return {
      ...state,
      isTaking: false,
    };
  }

  @Action('[Dossier] Übernehme Erfassung (Error)')
  public takeErfassungError(state: DossierState): typeof state {
    return {
      ...state,
      isTaking: false,
    };
  }

  @Action('[Dossier] Setze Renderstatus der Dossier Panels')
  public setRenderState(state: DossierState, data: { panelId: string, renderState: RenderState }): typeof state {
    const { panelId, renderState } = data;
    const clonedState = new Map(state.renderState);
    clonedState.set(panelId, renderState);
    return {
      ...state,
      renderState: clonedState,
      renderingItems: Math.max(0, state.renderingItems + (data.renderState === 'rendered' ? -1 : 1)),
    };
  }

  @Action('[Dossier] Setze die Daten, welche zur Suche im Dossier benötig werden')
  public setSearchState(state: DossierState, searchState: { search: string, hits: Set<string>, isWholeWord: boolean, isIgnoreCase: boolean, state: SearchState }): typeof state {
    return {
      ...state,
      searchState: {
        ...state.searchState,
        search: searchState.search,
        hits: searchState.hits,
        isWholeWord: searchState.isWholeWord,
        isIgnoreCase: searchState.isIgnoreCase,
        currentHit: null,
        state: searchState.state,
      },
    };
  }

  @Action('[Dossier] Setze den Status der Dossiersuche')
  public setSearchStateState(state: DossierState, searchState: SearchState): typeof state {
    return {
      ...state,
      searchState: {
        ...state.searchState,
        state: searchState,
      },
    };
  }

  @Action('[Dossier] Setze das nächste/verherige Suchergebnis im Dossier')
  public gotoSearchResult(state: DossierState, next: string): typeof state {
    return {
      ...state,
      searchState: {
        ...state.searchState,
        currentHit: next,
      },
    };
  }

  @Action('[Dossier] Submit substance')
  public submitSubstance(state: DossierState): typeof state {
    return {
      ...state,
      submitIsBusy: true,
    };
  }

  @Action('[Dossier] Submit substance (Success)')
  public submitSubstanceSuccess(state: DossierState): typeof state {
    return {
      ...state,
      submitIsBusy: false,
    };
  }

  @Action('[Dossier] Submit substance (Error)')
  public submitSubstanceError(state: DossierState): typeof state {
    return {
      ...state,
      submitIsBusy: false,
    };
  }

  @Action('[Dossier] Set Copy-Sachverhalte Loading')
  public setCopySachverhalteDialogLoading(state: DossierState, isLoading: boolean): typeof state {
    return {
      ...state,
      copySachverhalte: {
        ...state.copySachverhalte,
        isLoading,
      },
    };
  }

  @Action('[Dossier] Show Copy-Sachverhalte Dialog')
  public showCopySachverhalteDialog(state: DossierState, { showDialog }: { showDialog: boolean }): typeof state {
    return {
      ...state,
      copySachverhalte: {
        dossierIds: [],
        sachverhaltIds: [],
        showDialog,
        isLoading: false,
      },
    };
  }

  @Action('[Dossier] Toggle sacherhalt-id from Copy-Sachverhalte Dialog')
  public toggleCopySachverhalte(state: DossierState, { sachverhaltId, erfassungSachverhaltId }: { sachverhaltId: number, erfassungSachverhaltId: number }): typeof state {
    return {
      ...state,
      copySachverhalte: {
        ...state.copySachverhalte,
        sachverhaltIds: state.copySachverhalte.sachverhaltIds.find(id => id.sachverhaltId === sachverhaltId && id.erfassungSachverhaltId === erfassungSachverhaltId)
                        ? state.copySachverhalte.sachverhaltIds.filter(id => id.sachverhaltId !== sachverhaltId || id.erfassungSachverhaltId !== erfassungSachverhaltId)
                        : [ ...state.copySachverhalte.sachverhaltIds, { sachverhaltId, erfassungSachverhaltId } ],
      },
    };
  }

  @Action('[Dossier] Set thesaurus mode (none / original / recalculated)')
  public setThesaurusMode(state: DossierState, thesaurusMode: ThesaurusMode): typeof state {
    return {
      ...state,
      thesaurusMode: thesaurusMode,
    };
  }
}

export function dossierReducer(state: DossierState, action: DucksifiedAction): typeof state {
  return reducerFrom(DossierDucks)(state, action);
}
