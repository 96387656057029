import { Component, Inject, OnInit } from '@angular/core';
import { ExportAuftrag } from 'app/core/services/api-client';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';

import { Duck } from '@ngrx-ducks/core';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';
import { getDownloadExportAuftrag, getExportFormats } from '../../pages/export-page/ducks/export-auftraege.selectors';
import { ExportAuftraegeDucks } from '../../pages/export-page/ducks/export-auftraege.ducks';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as fs from 'file-saver';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { I18nService } from 'app/core/services/i18n/i18n.service';

@Component({
  selector: 'app-export-auftraege-download',
  templateUrl: './export-auftraege-download.component.html',
  styleUrls: [ './export-auftraege-download.component.scss' ],
})
export class ExportAuftraegeDownloadComponent implements OnInit {

  public exportAuftrag$: Observable<ExportAuftrag>;
  public exportAuftrag: ExportAuftrag;
  public exportFormats$: Observable<{ [ key: string ]: string }>;
  public exportFormats: { label: string, value: string, selected: boolean }[] = [];
  public selectedExportFormat: string = undefined;
  public sections: { label: string, value: string, checked: boolean }[] = [
    {
      label: 'Lieferanten',
      value: 'Lieferant',
      checked: true,
    },
    {
      label: 'Stoffe',
      value: 'Stoff',
      checked: true,
    },
    {
      label: 'Zitat',
      value: 'Zitat',
      checked: true,
    },
    {
      label: 'Spezies',
      value: 'Spezies',
      checked: true,
    },
    {
      label: 'Dateien/Strukturen',
      value: 'Datei',
      checked: true,
    },
  ];

  constructor(
    private store: Store<IAppState>,
    @Inject(ExportAuftraegeDucks) private exportAuftraegeDucks: Duck<ExportAuftraegeDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
    private oidcSecurityService: OidcSecurityService,
    private customToastyService: CustomToastyService,
    private i18n: I18nService,
  ) {
  }

  ngOnInit() {

    this.exportAuftraegeDucks.getExportFormateEffect.dispatch();
    this.exportFormats$ = this.store.pipe(select(getExportFormats));
    this.exportAuftrag$ = this.store.pipe(select(getDownloadExportAuftrag));

    this.exportFormats$.subscribe(exportFormats => {
      if (exportFormats) {
        this.exportFormats = [];
        const keys = Object.keys(exportFormats);

        for (const key of keys) {
          this.exportFormats.push({
            label: exportFormats[ key ],
            selected: false,
            value: key,
          });
        }
      }
    });

    this.exportAuftrag$.subscribe(exportAuftrag => {
      if (exportAuftrag) {
        this.exportAuftrag = exportAuftrag;
      }
    });
  }

  public download() {
    this.customToastyService.toastSuccess(
      this.i18n.getLocalizedString('toasts.exportAuftragDownloadStarted.title'),
      this.i18n.getLocalizedString('toasts.exportAuftragDownloadStarted.message'),
    );
    fs.saveAs(this.buildUrl);
    this.windowDucks.closeWindow.dispatch();
  }

  public cancel() {
    this.windowDucks.closeWindow.dispatch();
  }

  public get isValid() {
    return this.selectedExportFormat && this.sections && this.sections.filter(s => s.checked).length > 0;
  }

  public get buildUrl(): string {
    const sektionen = this.sections.filter(s => s.checked)
                          .map(s => s.value);
    const token = this.oidcSecurityService.getToken();
    const url = `${environment.baseAPIUrl}/api/ExportAuftrag/${this.exportAuftrag.id}/Download?format=${this.selectedExportFormat}&sektionen=${sektionen.join(',')}&access_token=${token}`;
    return url;
  }
}
