import { Inject, Injectable } from '@angular/core';
import { SachverhaltDucks } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.ducks';
import { Duck } from '@ngrx-ducks/core';
import { DossierDucks } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.ducks';
import { DossierService } from 'app/modules/dossier-area/services/dossier/dossier.service';
import { DossierHelperService } from 'app/modules/dossier-area/services/dossier-helper/dossier-helper.service';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { CustomWindowService } from 'app/core/services/custom-window-service/custom-window.service';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { DossierEintrag, SachverhaltErstellen } from '@models/dossier/dossier-models';
import { getDossier, panelIsRenderedSelector } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.selectors';
import { filter, finalize, first, map, tap } from 'rxjs/operators';
import { PanelDucks } from 'app/modules/shared-area/ducks/panel/panel.ducks';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { every } from 'lodash';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SachverhaltEffectsNew {

  private subscription: Subscription = Subscription.EMPTY;

  public constructor(
    @Inject(SachverhaltDucks) private sachverhaltDucks: Duck<SachverhaltDucks>,
    @Inject(DossierDucks) private dossierDucks: Duck<DossierDucks>,
    @Inject(PanelDucks) private panelDucks: Duck<PanelDucks>,
    private dossierService: DossierService,
    private dossierHelperService: DossierHelperService,
    private validationService: ValidationService,
    private windowService: CustomWindowService,
    private store: Store<IAppState>,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any,
  ) {}

  public async createSachverhaltAndUpdateDossier(sachverhalt: SachverhaltErstellen) {
    try {
      const dossier = await this.store.pipe(select(getDossier), first())
                                .toPromise();
      this.dossierDucks.showSmallLoadingSpinner(true);

      const partialDossier = await this.dossierService.DossierNeuenSachverhaltErstellen(sachverhalt.Id, sachverhalt.Merkmal, sachverhalt.Values)
                                       .toPromise();
      this.windowService.close();
      this.dossierDucks.partialUpdateDossier(this.dossierHelperService.updateDossier(dossier, partialDossier));

      // open panels and scroll to attributes
      const dossierEintrag: DossierEintrag = partialDossier.items[ 0 ] as any;
      const anchor = this.dossierHelperService.getFullDossierMerkmalId(dossierEintrag)[ 0 ];
      const factId = this.dossierHelperService.getFactIds(dossierEintrag)[ 0 ];
      const panelsToOpen = DossierHelperService.getOpenPanelsFromAnchorKeys(anchor);
      panelsToOpen.forEach(panel => this.panelDucks.showPanel(panel));

      // wait for all panels to be open
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      const panelsToOpen$: Observable<boolean>[] = [ ...panelsToOpen ].map(panel => this.store.pipe(select(panelIsRenderedSelector(panel))));
      this.subscription = combineLatest(panelsToOpen$)
        .pipe(
          filter(results => every(results, result => !!result)),
          map(() => true),
          first(),
          tap(() => this.scrollTo(factId)),
        )
        .subscribe();

    } catch (error) {
      if (error.reason !== 'DuplicateCopy') {
        const ruleValidationError = this.validationService.getRuleValidationFailedError(error);
        this.sachverhaltDucks.setServerValidationError(this.validationService.getServerValidationErrorsFromResponse(ruleValidationError));
      }
    } finally {
      setTimeout(() => this.dossierDucks.showSmallLoadingSpinner(false), 0); // after rendering in the next tick
    }
  }

  private scrollTo(target): void {
    this
      .pageScrollService
      .scroll({
        document: this.document,
        scrollTarget: `#${target}`,
        duration: 1000,
        scrollOffset: 150,
      });
  }
}
