import {ModuleWithProviders, NgModule} from '@angular/core';
import { ApiErrorHandlerServiceModule } from '../api-default-error-handler/api-error-handler.service.module';
import { ApiClientModule } from '../api-client/api-client.module';
import { WartungsarbeitenService } from './wartungsarbeiten-service.service';

@NgModule({
  imports: [
    ApiErrorHandlerServiceModule,
    ApiClientModule
  ],
  declarations: []
})
export class WartungsarbeitenServiceModule {
  public static forRoot(): ModuleWithProviders<WartungsarbeitenServiceModule> {
    return {
      ngModule: WartungsarbeitenServiceModule,
      providers: [ WartungsarbeitenService ],
    };
  }
}
