import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subsetFieldName'
})
export class SubsetFieldNamePipe implements PipeTransform {

  transform(subsetFieldKey: string, fieldKey?: string, rowIndex?: number): string {
    return `${fieldKey}_${rowIndex}_${subsetFieldKey}`;
  }

}
