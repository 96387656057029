import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
// main state object
import { ISharedAreaState, sharedReducers } from 'app/modules/shared-area/shared-area.module';

export interface IAppState {
  shared: ISharedAreaState;
}

export const REDUCER_TOKEN = new InjectionToken<
  ActionReducerMap<IAppState>
  >('Root reducer');

export function getReducers(): ActionReducerMap<IAppState> {
  return {
    shared: sharedReducers,
  };
}

export const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducers,
};
