import { NgModule } from '@angular/core';
import { AuthInterceptorModule } from 'app/core/interceptors/auth.interceptor.module';
import { CultureInterceptorModule } from 'app/core/interceptors/culture.interceptor.module';
import { MaintenanceInterceptorModule } from './maintenance.interceptor.module';
import { InternalServerErrorInterceptorModule } from 'app/core/interceptors/internal-server-error.interceptor.module';
import { CacheInterceptorModule } from 'app/core/interceptors/cache-interceptor.module';

@NgModule({
  imports: [
    AuthInterceptorModule.forRoot(),
    CultureInterceptorModule.forRoot(),
    MaintenanceInterceptorModule.forRoot(),
    InternalServerErrorInterceptorModule.forRoot(),
    CacheInterceptorModule.forRoot(),
  ],
})
export class InterceptorsForRootModule {}
