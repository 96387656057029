import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalService } from '../services/global/global.service';

@Injectable()
export class MaintenanceInterceptor implements HttpInterceptor {
  constructor(
    private globalService: GlobalService,
  ) {
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(evt => { }),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 503) {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errmsg = JSON.parse((<any>e.target).result);
                this.globalService.maintenance.next(errmsg);
              } catch (e) {
                console.log(e);
              }
            };
            reader.onerror = (e) => {
              console.log(e);
            };
            reader.readAsText(error.error);
          }
          return throwError(error);
        }),
      );
  }
}

