import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAdminAreaState } from 'app/modules/admin-area/admin-area.module';

const adminAreaState = createFeatureSelector<IAdminAreaState>('admin-area');
const importPage = createSelector(adminAreaState, state => state.importPage);
export const getImportAuftraege = createSelector(importPage, state => state.importAuftraege);
export const isImportAuftraegeBusy = createSelector(importPage, state => state.isImportAuftraegeBusy);
export const selectedImportAuftrag = createSelector(importPage, state => state.selectedImportAuftrag);
export const getLieferanten = createSelector(importPage, state => state.lieferanten);
export const getImportFormats = createSelector(importPage, state => state.lieferanten);
export const getDownloadImportAuftrag = createSelector(importPage, state => state.downloadImportAuftrag);
export const getSelectedImportAuftragLog = createSelector(importPage, state => state.selectedImportAuftragLog);
export const isImportErfassungenBusy = createSelector(importPage, state => state.isImportErfassungenBusy);
export const getImportErfassungen = createSelector(importPage, state => state.importErfassungen);
