import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BadRequestResult, BadRequestResultReason, ForbiddenResult, ForbiddenResultReason, SwaggerException } from 'app/core/services/api-client';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomToastyService } from '../customToasty/custom-toasty.service';

/**
 * In case of a bad request, the data could be a validation error
 */
interface IValidationError {
  message: string;
}

interface ICardinalityValidationResult {
  min?: number;
  max?: number;
  attributeKey: string;
  errorType: string;
  currentCount?: number;
}

interface IValueInUseResult {
  lookupKey: number;
  usageCount: number;
}

interface IDossierLocked {
  currentlyLockedBy: string[];
}

@Injectable()
export class ApiErrorHandlerService {
  public constructor(private toastyService: ToastaService,
    private i18n: I18nService,
    private router: Router,
    private customToastyService: CustomToastyService,
    private oidcSecurityService: OidcSecurityService,
  ) {
  }

  public showSuccessToast(titleKey: string, messageKey: string) {
    return tap(() => {
      const toastOptions: ToastOptions = {
        title: this.i18n.getLocalizedString(titleKey),
        msg: this.i18n.getLocalizedString(messageKey),
        theme: 'bootstrap',
      };
      this.toastyService.success(toastOptions);
    });
  }

  public handle400Error(error400: BadRequestResult,
    {
      invalidStateMessageKey, noFactsToApproveOrRejectMessageKey, validationMessageKey, identityCollisionMessageKey, cardinalityValidationFailedMessageKey, duplicateCopyErrorMessageKey,
      duplicateCopiesInAttributesErrorMessageKey,
    }: {
      invalidStateMessageKey?: string,
      noFactsToApproveOrRejectMessageKey?: string,
      validationMessageKey?: string,
      identityCollisionMessageKey?: string,
      cardinalityValidationFailedMessageKey?: string,
      duplicateCopyErrorMessageKey?: string,
      duplicateCopiesInAttributesErrorMessageKey?: string
    } = {}): Observable<never> {
    switch (error400.reason) {
      case BadRequestResultReason.IdentityCollision:
        return this.handle400IdentityCollision(error400, identityCollisionMessageKey);
      case BadRequestResultReason.CardinalityValidationFailed:
        this.handle400CardinalityValidationFailed(error400, cardinalityValidationFailedMessageKey);
        break;
      case BadRequestResultReason.DuplicateCopy:
        return this.handle400DuplicateCopyError(error400, duplicateCopyErrorMessageKey);
      case BadRequestResultReason.DuplicateCopiesInAttributes:
        return this.handle400DuplicateCopiesInAttributesError(error400, duplicateCopiesInAttributesErrorMessageKey);
      case BadRequestResultReason.RuleValidationFailed:
        return this.handle400RuleValidationFailedError(error400);
      case BadRequestResultReason.ValidationError:
        return this.handle400ValidationError(error400, validationMessageKey);
      case BadRequestResultReason.InvalidState:
        return this.handle400InvalidStateError(error400, invalidStateMessageKey);
      case BadRequestResultReason.NoFactsToApproveOrReject:
        return this.handle400NoFactsToApproveOrRejectError(error400, noFactsToApproveOrRejectMessageKey);
      case BadRequestResultReason.InvalidStateTransition:
        return this.handle400InvalidStateTransitionError(error400);
      default:
        console.error(`Error 400 reason ${error400.reason} not handled.`);
        return observableThrowError(error400);
    }
  }

  public handle401Error(error: SwaggerException, options: { titleKey?: string, messageKey?: string } = {}): Observable<never> {
    console.warn('handle401Error');
    this.showErrorToast({ titleKey: options.titleKey || 'errors.401.title', messageKey: options.messageKey || 'errors.401.message' });
    // redirect to login page
    this.oidcSecurityService.logoff();
    return observableThrowError(error);
  }

  public handle403Error(error403: ForbiddenResult,
    { authorizationMessageKey, lockedByOtherUserMessageKey, referenceViolationError, supplierNotAssignedMessageKey, valueInUseError }:
      {
        authorizationMessageKey?: string,
        lockedByOtherUserMessageKey?: string
        referenceViolationError?: string,
        supplierNotAssignedMessageKey?: string
        valueInUseError?: string
      } = {}): Observable<never> {
    switch (error403.reason) {
      case ForbiddenResultReason.Authorization:
        return this.handle403AuthorizationError(error403, authorizationMessageKey);
      case ForbiddenResultReason.SupplierIsNotAssigned:
        return this.handle403SupplierNotAssignedError(error403, supplierNotAssignedMessageKey);
      case ForbiddenResultReason.LockedByOtherUser:
        return this.handle403LockedByOtherUserError(error403, lockedByOtherUserMessageKey);
      case ForbiddenResultReason.ReferenceViolation:
        return this.handle403ReferenceViolationError(error403, referenceViolationError);
      case ForbiddenResultReason.ValueInUse:
        return this.handle403ValueInUseError(error403, valueInUseError);
      case ForbiddenResultReason.MasterConfigurationCannotBeDeleted:
        return this.handle403ErrorWithMessageKey(error403, 'masterConfigurationCannotBeDeleted');
      default:
        console.error(`Error 403 reason ${error403.reason} not handled.`);
        return observableThrowError(error403);
    }
  }

  public handle404Error(error: SwaggerException, options: { titleKey?: string, messageKey?: string } = {}): Observable<never> {
    this.showErrorToast({ titleKey: options.titleKey || 'errors.404.title', messageKey: options.messageKey || 'errors.404.message' });
    return observableThrowError(error);
  }

  public handle409Error(error: SwaggerException, options: { titleKey?: string, messageKey?: string } = {}): Observable<never> {
    this.showErrorToast({ titleKey: options.titleKey || 'errors.409.title', messageKey: options.messageKey || 'errors.409.message' });
    return observableThrowError(error);
  }

  private handle400ValidationError(error: BadRequestResult, messageKey?: string): Observable<never> {
    const validationError = error.data as IValidationError;
    console.warn(validationError);
    const message = (validationError && validationError.message) ? validationError.message : this.i18n.getLocalizedString(messageKey || 'errors.400.validation.message');
    this.showWarnToast({
      titleKey: 'errors.400.validation.title',
      message,
    });
    return observableThrowError(error);
  }

  private handle400DuplicateCopyError(error: BadRequestResult, message?: string) {
    console.warn(error);
    this.showWarnToast({
      titleKey: 'errors.400.duplicatecopy.title',
      message: this.i18n.getLocalizedString(message || 'errors.400.duplicatecopy.message'),
    });
    return observableThrowError(error);
  }

  private handle400DuplicateCopiesInAttributesError(error: BadRequestResult, messageKey?: string) {
    console.warn(error);
    let message = this.i18n.getLocalizedString(messageKey || 'errors.400.duplicatecopiesinattributes.message');
    const keys = error.data as string[];
    message = message.replace('{{keys}}', keys.join(', '));
    this.showWarnToast({
      titleKey: 'errors.400.duplicatecopiesinattributes.title',
      message: message,
    });
    return observableThrowError(error);
  }

  private handle400RuleValidationFailedError(error: BadRequestResult) {
    console.warn(error);
    /*
    let dataAsString = '';
    if (error.data && error.data.length > 0) {
      error.data.forEach(e => {
        dataAsString += e.errorMessage;
      });
    }
    */
    this.showWarnToast({
      titleKey: 'errors.400.rulevalidationfailed.title',
      message: this.i18n.getLocalizedString('errors.400.rulevalidationfailed.message')
    });
    return observableThrowError(error);
  }

  private handle400CardinalityValidationFailed(error: BadRequestResult, errorMessage?: string): Observable<never> {
    console.warn(error);
    const [nichtZulaessigMessage, nichtMultipelMessage, anzahlUnterschrittenMessage, anzahlUeberschrittenMessage] =
      ['MerkmalFuerStoffartNichtZulaessig',
        'MehrAlsEinSachverhaltAberMerkmalNichtMultipel',
        'MerkmalMinAnzahlConstraintUnterschritten',
        'MerkmalMaxAnzahlConstraintUeberschritten']

        .map(key => `errors.400.cardinalityValidationFailed.${key}`)
        .map(messageKey => this.i18n.getLocalizedString(messageKey));

    const validationErrors = error.data as ICardinalityValidationResult[];
    const message = validationErrors
      .map(validationError => this.makeValidationErrorText(validationError, nichtZulaessigMessage, nichtMultipelMessage, anzahlUnterschrittenMessage, anzahlUeberschrittenMessage))
      .filter(errorText => errorText.length > 0)
      .map(errorText => `<div>${errorText}</div>`)
      .join('');

    this.showWarnToast({
      titleKey: errorMessage || 'errors.400.cardinalityValidationFailed.title',
      message,
    });
    return observableThrowError(error);
  }

  private makeLookupTableReferenceErrorText(validationError: IValueInUseResult, valueInUseLocalizedMessage) {
    return valueInUseLocalizedMessage
      .replace('{{lookupKey}}', validationError.lookupKey)
      .replace('{{usageCount}}', validationError.usageCount)
      ;
  }

  private makeValidationErrorText(validationError: ICardinalityValidationResult, nichtZulaessig, nichtMultipel, anzahlUnterschritten, anzahlUeberschritten) {
    switch (validationError.errorType) {
      case 'MerkmalFuerStoffartNichtZulaessig':
        return nichtZulaessig
          .replace('{{attributeKey}}', validationError.attributeKey);

      case 'MehrAlsEinSachverhaltAberMerkmalNichtMultipel':
        return nichtMultipel
          .replace('{{attributeKey}}', validationError.attributeKey)
          .replace('{{currentCount}}', validationError.currentCount.toString());

      case 'MerkmalMinAnzahlConstraintUnterschritten':
        return anzahlUnterschritten
          .replace('{{attributeKey}}', validationError.attributeKey)
          .replace('{{currentCount}}', validationError.currentCount.toString())
          .replace('{{value}}', validationError.min.toString());

      case 'MerkmalMaxAnzahlConstraintUeberschritten':
        return anzahlUeberschritten
          .replace('{{attributeKey}}', validationError.attributeKey)
          .replace('{{currentCount}}', validationError.currentCount.toString())
          .replace('{{value}}', validationError.max.toString());

      default:
        return '';
    }
  }

  private handle400IdentityCollision(error: BadRequestResult, messageKey?: string) {
    console.warn(error);
    const data = error.data || {};
    let message = this.i18n.getLocalizedString(messageKey || 'errors.400.identityCollision.message');
    if (data.id) {
      message = message.replace('{{id}}', data.id);
    }
    this.showErrorToast({
      titleKey: 'errors.400.identityCollision.title',
      message,
    });
    return observableThrowError(error);
  }

  private handle400InvalidStateError(error: BadRequestResult, messageKey?: string): Observable<never> {
    console.warn(error);
    this.showWarnToast({
      titleKey: 'errors.400.invalidstate.title',
      messageKey: messageKey || 'errors.400.invalidstate.message',
    });
    return observableThrowError(error);
  }

  private handle400NoFactsToApproveOrRejectError(error: BadRequestResult, messageKey?: string): Observable<never> {
    console.warn(error);
    this.showWarnToast({
      titleKey: 'errors.400.nofactstoapproveorreject.title',
      messageKey: messageKey || 'errors.400.nofactstoapproveorreject.message',
    });
    return observableThrowError(error);
  }

  private handle400InvalidStateTransitionError(error: BadRequestResult): Observable<never> {
    this.showErrorToast({ titleKey: 'errors.400.invalidStateTransition.title', messageKey: 'errors.400.invalidStateTransition.message' });
    return observableThrowError(error);
  }

  private handle403AuthorizationError(error: ForbiddenResult, authorizationMessageKey?: string): Observable<never> {
    console.error(error);
    const message = error.description || this.i18n.getLocalizedString(authorizationMessageKey || 'errors.403.authorization.message');
    this.showErrorToast({ titleKey: 'errors.403.authorization.title', message });
    return observableThrowError(error);
  }

  private handle403SupplierNotAssignedError(error: ForbiddenResult, messageKey?: string): Observable<never> {
    console.error(error);
    this.showErrorToast({
      titleKey: 'errors.403.suppliernotassigned.title',
      messageKey: messageKey || 'errors.403.suppliernotassigned.message',
    });
    return observableThrowError(error);
  }

  private handle403LockedByOtherUserError(error: ForbiddenResult, messageKey?: string): Observable<never> {
    console.error(error);
    const dossierLocked: IDossierLocked = error.data;
    this.customToastyService
      .toastErrorFormatted('errors.403.lockedByOtherUser.title',
        messageKey || 'errors.403.lockedByOtherUser.message', {
        user: dossierLocked.currentlyLockedBy.join(', '),
      });
    return observableThrowError(error);
  }

  private handle403ReferenceViolationError(error: ForbiddenResult, messageKey?: string): Observable<never> {
    this.showErrorToast({
      titleKey: this.i18n.getLocalizedString('errors.403.referenceViolation.title'),
      messageKey: this.i18n.getLocalizedString(messageKey || 'errors.403.referenceViolation.message'),
    });
    return observableThrowError(error);
  }

  private handle403ValueInUseError(error: ForbiddenResult, messageKey?: string): Observable<never> {
    console.error(error);
    const valueInUseMessageKey = 'errors.403.valueInUse.message';

    const localizedMessage = this.i18n.getLocalizedString(valueInUseMessageKey);

    const validationErrors = error.data as IValueInUseResult[];
    const message = validationErrors
      .map(validationError => this.makeLookupTableReferenceErrorText(validationError, localizedMessage))
      .map(errorText => `<div>${errorText}</div>`)
      .join('');

    this.showErrorToast({
      titleKey: this.i18n.getLocalizedString('errors.403.valueInUse.title'),
      message
    });
    return observableThrowError(error);
  }

  private handle403ErrorWithMessageKey(error: ForbiddenResult, messageKey: string): Observable<never> {
    this.showErrorToast({
      titleKey: this.i18n.getLocalizedString(`errors.403.${messageKey}.title`),
      messageKey: this.i18n.getLocalizedString(`errors.403.${messageKey}.message`)
    });
    return observableThrowError(error);
  }

  private showWarnToast({ titleKey, messageKey, title, message }: { titleKey?: string, messageKey?: string, title?: string, message?: string }) {
    const toastOptions: ToastOptions = {
      title: titleKey ? this.i18n.getLocalizedString(titleKey) : title,
      msg: messageKey ? this.i18n.getLocalizedString(messageKey) : message,
      theme: 'bootstrap',
      timeout: 10000,
    };
    this.toastyService.warning(toastOptions);
    console.warn(toastOptions.title, toastOptions.msg);
  }

  private showErrorToast({ titleKey, messageKey, title, message }: { titleKey?: string, messageKey?: string, title?: string, message?: string }) {
    const toastOptions: ToastOptions = {
      title: titleKey ? this.i18n.getLocalizedString(titleKey) : title,
      msg: messageKey ? this.i18n.getLocalizedString(messageKey) : message,
      theme: 'bootstrap',
      timeout: null,
    };
    this.toastyService.error(toastOptions);
    console.error(toastOptions.title, toastOptions.msg);
  }
}

