import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { Favorit } from 'app/core/services/api-client';
import { ClientInfo } from 'app/core/services/benutzerprofil/models/clientInfo';
import { UserProfileState } from 'app/modules/shared-area/ducks/user-profile/user-profile-store.service';
import { FavoriteMerkmal } from '@models/dossier/dossier-models';

@Ducksify<UserProfileState>({
  initialState: {
    favorites: [],
    fachlektorMerkmale: new Set<number>([]),
    lieferantenMerkmale: new Set<number>([]),
    apiState: 'Unknown',
    clientInfoHasChanged: false,
    clientInfo: null,
    favouriteMerkmale: [],
  },
})
export class UserProfileDucks {

  @Action('[UserProfile] Start loading user profile')
  public userProfileRequest(state: UserProfileState): typeof state {
    return {
      ...state,
      apiState: 'Loading',
    };
  }

  @Action('[UserProfile] Load user profile Success')
  public userProfileResponse(state: UserProfileState, response: { favorites: Favorit[], fachlektorMerkmale: Set<number>, lieferantenMerkmale: Set<number>, clientInfo: ClientInfo }): typeof state {
    return {
      ...state,
      ...response,
      apiState: 'Success',
    };
  }

  @Action('[UserProfile] Load user profile Error')
  public userProfileError(state: UserProfileState, error: string): typeof state {
    return {
      ...state,
      apiState: 'Failed',
    };
  }

  @Action('[UserProfile] Add to favorites (Success)')
  public addFavoriteSuccess(state: UserProfileState, favorite: Favorit): typeof state {
    return {
      ...state,
      favorites: [ ...state.favorites, favorite ],
    };
  }

  @Action('[UserProfile] Remove favorite (Success)')
  public removeFavoriteSuccess(state: UserProfileState, gsblId: number): typeof state {
    return {
      ...state,
      favorites: state.favorites.filter(f => f.gsblId !== gsblId),
    };
  }

  @Action('[UserProfile] Add to favorite merkmale (Success)')
  public addFavoriteMerkmalSuccess(state: UserProfileState, favoriteMerkmal: FavoriteMerkmal): typeof state {

    let favoriteMerkmale =  [ ...state.favouriteMerkmale ];
    favoriteMerkmale = favoriteMerkmale.filter(m => m.key !== favoriteMerkmal.key);
    return {
      ...state,
      favouriteMerkmale:  [...favoriteMerkmale, favoriteMerkmal],
    };
  }

  @Action('[UserProfile] Set favorite merkmale')
  public setFavoriteMerkmale(state: UserProfileState, newFavoriteMerkmale: FavoriteMerkmal[]): typeof state {
    return {
      ...state,
      favouriteMerkmale:  [...newFavoriteMerkmale],
    };
  }

  @Action('[UserProfile] Load client info (Success)')
  public loadClientInfoSuccess(state: UserProfileState): typeof state {
    return {
      ...state,
      apiState: 'Success',
    };
  }

  @Action('[UserProfile] Load client info (Error)')
  public loadClientInfoError(state: UserProfileState): typeof state {
    return {
      ...state,
      apiState: 'Failed',
    };
  }

  @Action('[UserProfile] Save client info (Success)')
  public saveClientInfoSuccess(state: UserProfileState): typeof state {
    return {
      ...state,
      apiState: 'Success',
    };
  }

  @Action('[UserProfile] Save client info (Error)')
  public saveClientInfoError(state: UserProfileState): typeof state {
    return {
      ...state,
      apiState: 'Failed',
    };
  }
}

export function userProfileReducers(state: UserProfileState, action: DucksifiedAction): UserProfileState {
  return reducerFrom(UserProfileDucks)(state, action);
}

