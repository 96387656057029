import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { ConfigurationsState } from './configurations.state';
import { Configuration, RankingAuftrag } from 'app/core/services/api-client';
import { typePropertyIsNotAllowedMsg } from '@ngrx/store/src/models';
import { RankingAuftraegeListModule } from 'app/modules/admin-area/components/ranking-auftraege-list/ranking-auftraege-list.module';

@Ducksify<ConfigurationsState>({
  initialState: {
    configurations: [],
    selectedConfigurationName: null,
    isConfigurationBusy: false,
    selectedConfiguration: null,
    isConfigurationLoading: false,
    isRankingAuftraegeLoading: false,
    rankingAuftraege: []
  },
})
export class ConfigurationsDucks {

  public readonly getAllConfigurationsEffect = effect<void>('[Configurations (Effect)] Load all configurations');
  public readonly getConfigurationEffect = effect<number>('[Configurations (Effect)] Load configuration');
  public readonly updateConfigurationEffect = effect<Configuration>('[Configurations (Effect)] Update a configuration');
  public readonly saveConfigurationEffect = effect<Configuration>('[Configurations (Effect)] Saves a configuration');
  public readonly deleteConfigurationEffect = effect<number>('[Configurations (Effect)] Deletes a configuration');
  public readonly getAllRankingAuftraegeEffect = effect<void>('[Configurations (Effect)] Load all ranking auftraege');
  public readonly createRankingAuftragEffect = effect<RankingAuftrag>('[Configurations (Effect)] Create a new ranking auftrag');

  @Action('[Configurations] Load all configurations')
  public getAllConfigurations(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: true,
    };
  }

  @Action('[Configurations] Load all configurations (Success)')
  public getAllConfigurationsSuccess(state: ConfigurationsState, configurations: Configuration[]): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
      configurations: configurations,
    };
  }

  @Action('[Configurations] Load all configurations (Error)')
  public getAllConfigurationsError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
    };
  }

  @Action('[Configurations] Load configuration')
  public getConfiguration(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationLoading: true,
    };
  }

  @Action('[Configurations] Load configuration (Success)')
  public getConfigurationSuccess(state: ConfigurationsState, configuration: Configuration): typeof state {
    const newState =  {
      ...state,
      isConfigurationLoading: false,
      selectedConfiguration: configuration
    };
    return newState;
  }

  @Action('[Configurations] Load configuration (Error)')
  public getConfigurationError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationLoading: false,
    };
  }


  @Action('[Configurations] Update a configuration')
  public updateConfiguration(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: true,
    };
  }

  @Action('[Configurations] Update a configuration (Success)')
  public updateConfigurationSuccess(state: ConfigurationsState, configuration: Configuration): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
      configurations: updateConfiguration(state.configurations, configuration),
    };
  }

  @Action('[Configurations] Update a configuration (Error)')
  public updateConfigurationError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
    };
  }

  @Action('[Configurations] Add new configuration')
  public addConfiguration(state: ConfigurationsState, configurationName: string): typeof state {
    const newConfiguration = getNewConfiguration(configurationName);
    return {
      ...state,
      configurations: [...state.configurations, newConfiguration],
      isConfigurationLoading: false,
      selectedConfiguration: newConfiguration
    };
  }

  @Action('[Configurations] Sets the name from the selected configuration')
  public setSelectedConfigurationName(state: ConfigurationsState, configurationName: string): typeof state {
    return {
      ...state,
      selectedConfigurationName: configurationName,
    };
  }

  @Action('[Configurations] Saves a configuration')
  public saveConfiguration(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: true,
    };
  }

  @Action('[Configurations] Saves a configuration (Success)')
  public saveConfigurationSuccess(state: ConfigurationsState, savedConfiguration: Configuration): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
      configurations: saveConfiguration(state.configurations, savedConfiguration),
    };
  }

  @Action('[Configurations] Saves a configuration (Error)')
  public saveConfigurationError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
      configurations: state.configurations.filter(r => r.name !== '[new]'),
    };
  }

  @Action('[Configurations] Deletes a configuration')
  public deleteConfiguration(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: true,
    };
  }

  @Action('[Configurations] Deletes a configuration (Success)')
  public deleteConfigurationSuccess(state: ConfigurationsState, configurationId: number): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
      configurations: state.configurations.filter(configuration => configuration.id !== configurationId),
    };
  }

  @Action('[Configurations] Deletes a configuration (Error)')
  public deleteConfigurationError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isConfigurationBusy: false,
    };
  }

  @Action('[Configurations] Load all ranking auftraege')
  public getAllRankingAuftraege(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isRankingAuftraegeLoading: true,
    };
  }

  @Action('[Configurations] ranking auftraege (Success)')
  public getAllRankingAuftraegeSuccess(state: ConfigurationsState, rankingAuftraege: RankingAuftrag[]): typeof state {
    return {
      ...state,
      isRankingAuftraegeLoading: false,
      rankingAuftraege: rankingAuftraege,
    };
  }

  @Action('[Configurations] ranking auftraege (Error)')
  public getAllRankingAuftraegeError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isRankingAuftraegeLoading: false,
    };
  }


  @Action('[Configurations] Create a ranking auftrag')
  public createRankingAuftrag(state: ConfigurationsState, rankingAuftrag: RankingAuftrag): typeof state {    return {
      ...state,
      isRankingAuftraegeLoading: true,
    };
  }

  @Action('[Configurations] Create a ranking auftrag (Success)')
  public createRankingAuftragSuccess(state: ConfigurationsState, createdRankingAuftrag: RankingAuftrag): typeof state {
    return {
      ...state,
      isRankingAuftraegeLoading: false,
      rankingAuftraege: [...state.rankingAuftraege, createdRankingAuftrag]
    };
  }

  @Action('[Configurations] Create a ranking auftrag (Error)')
  public createRankingAuftragError(state: ConfigurationsState): typeof state {
    return {
      ...state,
      isRankingAuftraegeLoading: false,
    };
  }



}

function updateConfiguration(configurations: Configuration[], update: Configuration): Configuration[] {
  const r = [...configurations];
  const newConfigurations = r.filter(configuration => configuration.id !== update.id);
  newConfigurations.push(update);
  return newConfigurations;
}

function getNewConfiguration(configurationName: string): Configuration {
  const newConfiguration = new Configuration({
    name: configurationName,
    description: '',
    id: undefined,
    sourceQuery: '',
    master: false,
    tag: '',
    ranking: {}
  });
  return newConfiguration;
}

function saveConfiguration(configurations: Configuration[], savedConfiguration: Configuration): Configuration[] {
  const oldConfigurations = [...configurations];
  const filteredConfigurations = oldConfigurations.filter(configuration => configuration.id !== savedConfiguration.id);
  filteredConfigurations.push(savedConfiguration);
  return filteredConfigurations;
}

function createRankingAuftrag(rankingAuftraege: RankingAuftrag[], createdRankingAuftrag: RankingAuftrag): RankingAuftrag[] {
  return [...rankingAuftraege, createdRankingAuftrag];
}

export function configurationsReducer(state: ConfigurationsState, action: DucksifiedAction): typeof state {
  return reducerFrom(ConfigurationsDucks)(state, action);
}
