import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Duck } from '@ngrx-ducks/core';
import { MerkmalSearchModel, SearchType } from '@models/merkmal-search-model';
import { Merkmal, MerkmalLock } from '@models/merkmalbaum';
import { select, Store } from '@ngrx/store';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { MerkmalDucks } from 'app/modules/shared-area/ducks/merkmal/merkmal.ducks';
import { getFavoriteAttributes, getMerkmale, isLoaded, isMerkmaleSucessfullyLoaded } from 'app/modules/shared-area/ducks/merkmal/merkmal.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { first, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-select-merkmal-dialog',
  templateUrl: './select-merkmal-dialog.component.html',
  styleUrls: ['./select-merkmal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMerkmalDialogComponent implements OnInit {

  @Input()
  public merkmal: string;

  @Input()
  public merkmale: { [key: string]: boolean } = {};

  @Input()
  public showAllMerkmale = false;

  @Input()
  merkmalLocks: MerkmalLock[] = [];

  @Input()
  public enableLocks = true;

  @Input()
  public limitLevel: number | null = null;

  @Input()
  public showFeldNodes: boolean;

  @Input()
  public filterByLieferant: boolean;

  @Input()
  public selectableNodes?: Set<string>;

  @Input()
  public allowedDossierType: number | null = null;

  @Input()
  public excludeRoot = false;

  @Input()
  public isMultiselectable = false;

  @Input()
  public showApplyButton = false;

  @Output()
  public selectMerkmal: EventEmitter<Merkmal> = new EventEmitter();

  @Output()
  public selectMerkmale: EventEmitter<{ [key: string]: boolean }> = new EventEmitter();

  public isLoaded$: Observable<boolean>;
  public merkmale$: Observable<Merkmal[]> = EMPTY;
  public locks$: Observable<MerkmalLock[]> = EMPTY;
  public matchedNode: string;
  public matches: string[];
  public expandedKeys = [];
  public favoriteAttributes$: Observable<Merkmal[]> = EMPTY;
  public readonly searchForm: FormGroup = this.fb.group({
    searchTerm: '',
    searchType: <SearchType>'name',
  });
  private index: number;
  private alleMerkmale: string[] = [];

  constructor(private store: Store<IAppState>,
    @Inject(MerkmalDucks) private merkmalDucks: Duck<MerkmalDucks>,
    @Inject(I18nService) private i18n: I18nService,
    private fb: FormBuilder,
  ) {
  }

  public ngOnInit() {
    this.selectStatesFromStore();
    this.initialSearchByMerkmal();
  }

  public handleSelection(entry: any): void {
    const merkmal: Merkmal = entry.dataItem;
    if (!merkmal.isLocked && !merkmal.isDisabled) {
      this.selectMerkmal.emit(merkmal);
    }
  }

  public onSubmit() {
    const query = <MerkmalSearchModel>this.searchForm.value;
    query.isExactSearch = false;
    this.search(query);
  }

  public get hasMatches() {
    return this.matches && this.matches.length > 0;
  }

  public prev() {
    if (this.matches && this.matches.length > 0) {
      this.index--;
      if (this.index < 0) {
        this.index = this.matches.length - 1;
      }
      this.matchedNode = null;
      this.expandedKeys = [];
      this.expandByKey(this.matches[this.index]);
    }
  }

  public next() {
    if (this.matches && this.matches.length > 0) {
      this.index++;
      if (this.index >= this.matches.length) {
        this.index = 0;
      }
      this.matchedNode = null;
      this.expandedKeys = [];
      this.expandByKey(this.matches[this.index]);
    }
  }

  public isLockedBy(merkmal: Merkmal, locks: MerkmalLock[]): string {
    const isLocked = locks.find(lock => lock.merkmalId === merkmal.id && merkmal.type === 'Merkmal');
    if (isLocked) {
      return this.i18n.getLocalizedString('components.dossier.lockedBy')
        .replace('{{value}}', isLocked.lockedBy);
    }
    return null;
  }

  public toggleIsChecked(key: string, isChecked: boolean) {
    const isMerkmal = key.indexOf('-M') > -1;
    if (isMerkmal) {
      this.checkMerkmal(key, isChecked);
    } else {
      this.alleMerkmale
        .filter(merkmal => merkmal.startsWith(key))
        .forEach(merkmal => this.checkMerkmal(merkmal, isChecked));
    }

  }

  public applyMerkmale() {
    this.selectMerkmale.emit(this.merkmale);
  }

  private selectStatesFromStore() {

    this.isLoaded$ = this.store.pipe(select(isLoaded));
    this.favoriteAttributes$ = this.store.pipe(select(getFavoriteAttributes));

    this.merkmale$ = this.store.pipe
      (
        select(getMerkmale),
        map((merkmale) => {
          this.alleMerkmale = [];
          const tree = this.createHierarchyTree(merkmale, '', this.merkmalLocks, this.showFeldNodes);
          return tree;
        }),
      );
      this.merkmalDucks.loadMerkmaleEffect.dispatch({ filterByLieferant: this.filterByLieferant });
  }

  private initialSearchByMerkmal() {
    if (!_.isNil(this.merkmal)) {
      this.searchForm.controls['searchTerm'].setValue(this.merkmal);
      this.searchForm.controls['searchType'].setValue(<SearchType>'key');
      const query: MerkmalSearchModel = {
        searchTerm: this.merkmal,
        searchType: <SearchType>'key',
        isExactSearch: true,
      };
      this.search(query);
    }
  }

  private createHierarchyTree(merkmale: Merkmal[], parentId: string, locks: MerkmalLock[], showFeldNodes = false): Merkmal[] {
    return merkmale.map(merkmal => {

      const isMerkmal = merkmal.type === 'Merkmal';
      const id = !isMerkmal
        ? `H${merkmal.id}`
        : `M${merkmal.id}`;
      const anchor = [parentId, id].filter(x => !!x)
        .join('-');

      if (isMerkmal) {
        this.alleMerkmale.push(anchor);
      }

      const clonedMerkmal: Merkmal = {
        ...merkmal,
        hierarchy: anchor,
      };

      if (this.enableLocks) {
            const isLockedBy = this.isLockedBy(merkmal, locks);
        clonedMerkmal.isLocked = !_.isNil(isLockedBy);
        clonedMerkmal.lockedBy = isLockedBy;
      }

      if (this.selectableNodes) {
        clonedMerkmal.isDisabled = !this.selectableNodes.has(merkmal.key);
      }

      if (this.excludeRoot && merkmal.root === true) {
        clonedMerkmal.isDisabled = true;
      }

      if (this.allowedDossierType && (merkmal.allowedTypes && merkmal.allowedTypes.indexOf(this.allowedDossierType) === -1)) {
        clonedMerkmal.isDisabled = true;
      }

      const hasChildHierarchie = _.some(clonedMerkmal.items, ['type', 'Hierarchie']);
      const hasChildMerkmal = _.some(clonedMerkmal.items, ['type', 'Merkmal']);

      if (showFeldNodes || hasChildHierarchie || hasChildMerkmal) {
        if (clonedMerkmal.items && clonedMerkmal.items.length > 0) {
          clonedMerkmal.items = this.createHierarchyTree(clonedMerkmal.items, anchor, locks, showFeldNodes);
        }

      } else {
        clonedMerkmal.items = null;
      }
      return clonedMerkmal;
    });
  }

  private search(query: MerkmalSearchModel) {
    this.matches = [];
    this.index = 0;
    this.matchedNode = null;
    this.expandedKeys = [];

    this.merkmale$.pipe
      (
        first(),
        tap(merkmale => this.filterBySearchType(query, merkmale)),
        tap(() => this.expandAndScrollToSearchResult()),
      )
      .subscribe();
  }

  private expandAndScrollToSearchResult() {
    if (this.matches && this.matches.length > 0) {
      this.expandByKey(this.matches[0]);
      this.scrollTo();
    }
  }

  private filterBySearchType(query: MerkmalSearchModel, merkmale: Merkmal[]) {
    switch (query.searchType) {
      case 'name':
        this.filterByName({
          items: merkmale,
          key: 'ROOT', name: 'root',
          id: -1, type: 'Hierarchie',
        },
          query.searchTerm, query.isExactSearch);
        break;

      case 'key':
        this.filterByKey({
          items: merkmale,
          key: 'ROOT',
          name: 'root',
          id: -1,
          type: 'Hierarchie',
        },
          query.searchTerm, query.isExactSearch);
        break;

      default:
        throw new Error('No such search type');
    }
  }

  private filterByKey(merkmal: Merkmal, searchTerm: string, exactMatch: boolean = false): void {
    const key = merkmal.key.toUpperCase();
    let isMatch: boolean;
    if (exactMatch) {
      isMatch = key === searchTerm.toUpperCase();
    } else {
      isMatch = key.indexOf(searchTerm.toUpperCase()) > -1;
    }

    merkmal.isMatch = isMatch;
    if (isMatch) {
      this.matches.push(merkmal.hierarchy);
    }
    if (merkmal.items && merkmal.items.length > 0) {
      merkmal.items.forEach(item => {
        this.filterByKey(item, searchTerm, exactMatch);
      });
    }
  }

  private filterByName(merkmal: Merkmal, searchTerm: string, exactMatch: boolean = false): void {
    const name = merkmal.name.toUpperCase();
    let isMatch: boolean;
    if (exactMatch) {
      isMatch = name === searchTerm.toUpperCase();
    } else {
      isMatch = name.indexOf(searchTerm.toUpperCase()) > -1;
    }

    merkmal.isMatch = isMatch;
    if (isMatch) {
      this.matches.push(merkmal.hierarchy);
    }
    if (merkmal.items && merkmal.items.length > 0) {
      merkmal.items.forEach(item => {
        this.filterByName(item, searchTerm, exactMatch);
      });
    }
  }

  private expandByKey(key: string, scrollToKey = true) {
    if (scrollToKey && _.isNil(this.matchedNode)) {
      this.matchedNode = key;
    }
    this.expandedKeys.push(key);
    if (key.lastIndexOf('-') !== -1) {
      this.expandByKey(key.substring(0, key.lastIndexOf('-')), scrollToKey);
    } else if (scrollToKey) {
      this.scrollTo();
    }
  }

  private scrollTo(): void {
    setTimeout(() => {
      let element = document
        .getElementsByTagName('app-select-merkmal-dialog')[0];
      if (element) {
        element = element.getElementsByClassName(`${this.matchedNode}`)[0];
      } else {
        return;
      }
      if (element) {
        element.scrollIntoView();
      }
    }, 400);
  }

  private checkMerkmal(key: string, isChecked: boolean) {
    this.merkmale = { ...this.merkmale };
    this.merkmale[key] = isChecked;
  }
}


