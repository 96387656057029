import { Component, OnInit, Input } from '@angular/core';
import { Reference } from 'app/core/services/api-client';
import { DossierHelperService } from '../../services/dossier-helper/dossier-helper.service';

@Component({
  selector: 'app-reference-link',
  templateUrl: './reference-link.component.html',
  styleUrls: ['./reference-link.component.scss']
})
export class ReferenceLinkComponent implements OnInit {

  @Input()
  public reference: Reference | any;

  constructor(public helperService: DossierHelperService) { }

  ngOnInit() {
  }

  public get isReferenceType(): boolean {
    const hasType = this.reference.objektTyp ? true : this.reference.referenceType ? true : false;
    const hasId = this.reference.objektId ? true : this.reference.referenceId ? true : false;
    return hasType && hasId;
  }

}
