import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CultureInterceptor } from 'app/core/interceptors/culture.interceptor';
import { I18nModule } from 'app/core/services/i18n/i18n.module';

@NgModule({
  imports: [ I18nModule ],
})
export class CultureInterceptorModule {
  public static forRoot(): ModuleWithProviders<CultureInterceptorModule> {
    return {
      ngModule: CultureInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CultureInterceptor,
          multi: true,
        }
      ]
    };
  }
}

