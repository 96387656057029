import { createSelector } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';

const shared = (state: IAppState) => state.shared;
const userProfileState = createSelector(shared, state => state.userProfile);

export const getFavoriteIds = createSelector(userProfileState, state => state.favorites.filter(f => f.isFavorit).map(f => f.gsblId));
export const isFavoritesLoading = createSelector(userProfileState, state => state.apiState === 'Loading');
export const getFavorites = createSelector(userProfileState, state => state.favorites);
export const getUserProfileApiState = createSelector(userProfileState, state => state.apiState);
export const getFachlektorMerkmale = createSelector(userProfileState, state => state.fachlektorMerkmale);
export const getLieferantenMerkmale = createSelector(userProfileState, state => state.lieferantenMerkmale);
export const getFavouriteMerkmale = createSelector(userProfileState, state => state.favouriteMerkmale);

