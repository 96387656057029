import { TableSearchQueryRowParams } from './table-search-query';

export class TableSearchRow {
  rowId: string;
  order: number;
  query: TableSearchQueryRowParams;
  hasMerkmalChanged?: boolean;
  hasError: boolean;

  constructor() {
    this.rowId = new Date().getTime().toString();
    this.query = {
      relation: 'And',
      openingBrackets: 0,
      key: null,
      operator: null,
      closingBrackets: 0,
      value: null
    };
  }
}
