import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ActionbarModule } from 'app/shared/components/actionbar/actionbar.module';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ValidationRulesEditModule } from '../validation-rules-edit/validation-rules-edit.module';
import { ImportPageService } from '../../services/import-page/import-page.service';
import { IntlModule } from '@progress/kendo-angular-intl';
import { HasrightModule } from 'app/shared/directives/hasright/hasright.module';
import { ImportAuftraegeLogComponent } from './import-auftraege-log.component';


@NgModule({
  imports: [
    GridModule,
    I18nModule,
    TranslateModule,
    CommonModule,
    ActionbarModule,
    I18nModule,
    ButtonModule,
    DialogModule,
    ValidationRulesEditModule,
    IntlModule,
    HasrightModule,
  ],
  declarations: [ ImportAuftraegeLogComponent ],
  providers: [ ImportPageService ],
  exports: [ ImportAuftraegeLogComponent ],
})
export class ImportAuftraegeLogModule {
}
