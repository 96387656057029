import { ModuleWithProviders, NgModule } from '@angular/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ToastaModule } from 'ngx-toasta';
import {  ReferencesService } from './references.service';

@NgModule({
  imports: [
    // HttpClientModule.forChild(), // Interceptors does not work when specified,
    ToastaModule,
    I18nModule
  ],
})
export class ReferencesModule {
  public static forRoot(): ModuleWithProviders<ReferencesModule> {
    return {
      ngModule: ReferencesModule,
      providers: [ ReferencesService ],
    };
  }
}
