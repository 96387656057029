import { ModuleWithProviders, NgModule } from '@angular/core';
import { FeedbackService } from 'app/core/services/feedback/feedback.service';
import { CustomToastyModule } from 'app/core/services/customToasty/customToasty.module';
import { I18nModule } from 'app/core/services/i18n/i18n.module';

@NgModule({
  imports: [
    CustomToastyModule,
    I18nModule,
  ],
})
export class FeedbackServiceModule {
  public static forRoot(): ModuleWithProviders<FeedbackServiceModule> {
    return {
      ngModule: FeedbackServiceModule,
      providers: [ FeedbackService ]
    };
  }
}

