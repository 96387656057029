import { Inject, Injectable, NgModule } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { AuthModule, OidcSecurityService } from 'angular-auth-oidc-client';
import { Duck } from '@ngrx-ducks/core';
import { UserDucks } from 'app/modules/shared-area/ducks/user/user.ducks';
import { ILanguageValue } from 'app/core/services/i18n/models/ILanguageValue';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { IUser } from 'app/modules/shared-area/ducks/user/models/IUser';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';

@NgModule({
  imports: [ AuthModule ],
})
export class UserEffectsModule {}

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions,
              private oidcSecurityService: OidcSecurityService,
              @Inject(UserDucks) private userDucks: Duck<UserDucks>,
              private i18n: I18nService,
              private intlService: IntlService) { }

  @Effect()
  public loadUser$: Observable<Action> = this.actions$.pipe
  (
    ofType(this.userDucks.loadUser.type),
    switchMap(() => this.oidcSecurityService.userData$
                        .pipe
                        (
                          filter(userData => !!userData),
                          tap((userData: IUser) => {
                            const currentLang: ILanguageValue = this.i18n.supportedUIUserLanguages.find(lang => lang.CultureCode.toLowerCase() ===
                                                                                                            userData.culture.toLowerCase());
                            if (currentLang !== null) {
                              this.i18n.CurrentUILanguage = currentLang;
                              (<CldrIntlService>this.intlService).localeId = currentLang.CultureCode;
                            }
                          }),
                          map((userData: IUser) => this.userDucks.loadUserSuccess.action(userData)),
                          catchError(error => of(this.userDucks.loadUserError.action(error))),
                        ),
    ),
  );
}
