import { ModuleWithProviders, NgModule } from '@angular/core';
import { ValidationService } from 'app/core/services/validation/validation.service';

@NgModule({})
export class ValidationModule {
  static forRoot(): ModuleWithProviders<ValidationModule> {
    return {
      ngModule: ValidationModule,
      providers: [ ValidationService ]
    };
  }
}
