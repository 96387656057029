import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { IFeedbackListState, IFeedbacksState } from 'app/modules/shared-area/ducks/feedback/feedback-store';
import { Role } from '../user/models/role';
import { FeedbackItem, FeedbackItemsResult } from 'app/core/services/api-client/api-client';

type FeedbackProperty = 'ownFeedback' | 'systemFeedback' | 'stoffFeedback' | 'lookupTablesFeedback';

function createEmptyFeedbackListState(): IFeedbackListState {
  return {
    apiState: 'Unknown',
    items: [],
    skip: 0,
    pageSize: 10,
    total: 0,
    filter: { filters: [], logic: 'or' },
    sort: [{
      field: 'priorityValue',
      dir: 'desc',
    }],
  };
}

function loadFeedback(state: IFeedbacksState, feedbackProperty: FeedbackProperty) {
  return {
    ...state,
    [ feedbackProperty ]: {
      ...state[ feedbackProperty ],
      apiState: 'Loading',
    },
  };
}

function loadFeedbacksSuccess(state: IFeedbacksState, feedbackItemsResult: FeedbackItemsResult, feedbackProperty: FeedbackProperty): typeof state {
  return {
    ...state,
    [ feedbackProperty ]: {
      ...state[ feedbackProperty ],
      apiState: 'Success',
      items: feedbackItemsResult.feedbackItems,
      total: feedbackItemsResult.total,
    },
  };
}

function loadFeedbacksError(state: IFeedbacksState, feedbackProperty: FeedbackProperty): typeof state {
  return {
    ...state,
    [ feedbackProperty ]: {
      ...state[ feedbackProperty ],
      apiState: 'Failed',
      items: [],
      total: 0,
      page: 0,
    },
  };
}

function updateFeedbackState(state: IFeedbacksState, newListState: Partial<IFeedbackListState>, feedbackProperty: FeedbackProperty) {
  return {
    ...state,
    [ feedbackProperty ]: {
      ...state[ feedbackProperty ],
      ...newListState,
    },
  };
}

function updateFeedback(feedback: FeedbackItem, newfeedback: FeedbackItem) {
  if (feedback.feedbackUid === newfeedback.feedbackUid) {
    const newItem = new FeedbackItem({ ...newfeedback });
    return newItem;
  } else {
    return feedback;
  }
}

@Ducksify<IFeedbacksState>({
  initialState: {
    ownFeedback: createEmptyFeedbackListState(),
    stoffFeedback: createEmptyFeedbackListState(),
    systemFeedback: createEmptyFeedbackListState(),
    lookupTablesFeedback: createEmptyFeedbackListState(),
    roleContext: null,
    isStofffeedbackWindowVisible: false,
  },
})
export class FeedbackDucks {

  // own feedback

  @Action('[Feedback] Loading Own Feedbacks')
  public loadOwnFeedbacks(state: IFeedbacksState): typeof state {
    return loadFeedback(state, 'ownFeedback');
  }

  @Action('[Feedback] Success Loading Own Feedbacks')
  public loadOwnFeedbacksSuccess(state: IFeedbacksState, feedbackItemsResult: FeedbackItemsResult): typeof state {
    return loadFeedbacksSuccess(state, feedbackItemsResult, 'ownFeedback');
  }

  @Action('[Feedback] Error Loading Own Feedbacks')
  public loadOwnFeedbacksError(state: IFeedbacksState): typeof state {
    return loadFeedbacksError(state, 'ownFeedback');
  }

  @Action('[Feedback] Update Own Feedbacks')
  public updateOwnFeedbackState(state: IFeedbacksState, newListState: Partial<IFeedbackListState>) {
    return updateFeedbackState(state, newListState, 'ownFeedback');
  }

  @Action('[Feedback] Loading System Feedbacks')
  public loadSystemFeedbacks(state: IFeedbacksState): typeof state {
    return loadFeedback(state, 'systemFeedback');
  }

  @Action('[Feedback] Success Loading System Feedbacks')
  public loadSystemFeedbacksSuccess(state: IFeedbacksState, feedbackItemsResult: FeedbackItemsResult): typeof state {
    return loadFeedbacksSuccess(state, feedbackItemsResult, 'systemFeedback');
  }

  @Action('[Feedback] Error Loading System Feedbacks')
  public loadSystemFeedbacksError(state: IFeedbacksState): typeof state {
    return loadFeedbacksError(state, 'systemFeedback');
  }

  @Action('[Feedback] Update System Feedbacks')
  public updateSystemFeedbackState(state: IFeedbacksState, newListState: Partial<IFeedbackListState>) {
    return updateFeedbackState(state, newListState, 'systemFeedback');
  }

  @Action('[Feedback] Loading Stoff Feedbacks')
  public loadStoffFeedbacks(state: IFeedbacksState): typeof state {
    return loadFeedback(state, 'stoffFeedback');
  }

  @Action('[Feedback] Success Loading Stoff Feedbacks')
  public loadStoffFeedbacksSuccess(state: IFeedbacksState, feedbackItemsResult: FeedbackItemsResult): typeof state {
    return loadFeedbacksSuccess(state, feedbackItemsResult, 'stoffFeedback');
  }

  @Action('[Feedback] Error Loading Stoff Feedbacks')
  public loadStoffFeedbacksError(state: IFeedbacksState): typeof state {
    return loadFeedbacksError(state, 'stoffFeedback');
  }

  @Action('[Feedback] Update Stoff Feedbacks')
  public updateStoffFeedbackState(state: IFeedbacksState, newListState: Partial<IFeedbackListState>) {
    return updateFeedbackState(state, newListState, 'stoffFeedback');
  }

// Lookup tables -->


  @Action('[Feedback] Loading Lookup Tables Feedbacks')
  public loadLookupTablesFeedbacks(state: IFeedbacksState): typeof state {
    return loadFeedback(state, 'lookupTablesFeedback');
  }

  @Action('[Feedback] Success Loading Lookup Tables Feedbacks')
  public loadLookupTablesFeedbacksSuccess(state: IFeedbacksState, feedbackItemsResult: FeedbackItemsResult): typeof state {
    return loadFeedbacksSuccess(state, feedbackItemsResult, 'lookupTablesFeedback');
  }

  @Action('[Feedback] Error Loading Lookup Tables Feedbacks')
  public loadLookupTablesFeedbacksError(state: IFeedbacksState): typeof state {
    return loadFeedbacksError(state, 'lookupTablesFeedback');
  }

  @Action('[Feedback] Update Lookup Tables Feedbacks')
  public updateLookupTablesFeedbackState(state: IFeedbacksState, newListState: Partial<IFeedbackListState>) {
    return updateFeedbackState(state, newListState, 'lookupTablesFeedback');
  }

// <-- Lookup tables


  @Action('[Feedback] Setting Role to feedback')
  public setRoleContext(state: IFeedbacksState, role: Role): typeof state {
    return {
      ...state,
      roleContext: role,
    };
  }

  @Action('[Feedback] Updates local feedbacks after API call (put)')
  public updateLocalFeedback(state: IFeedbacksState, feedback: FeedbackItem): typeof state {
    return {
      ...state,
      ownFeedback: {
        ...state.ownFeedback,
        items: state.ownFeedback.items.map(f => updateFeedback(f, feedback)),
      },
      systemFeedback: {
        ...state.systemFeedback,
        items: state.systemFeedback.items.map(f => updateFeedback(f, feedback)),
      },
      stoffFeedback: {
        ...state.stoffFeedback,
        items: state.stoffFeedback.items.map(f => updateFeedback(f, feedback)),
      },
      lookupTablesFeedback: {
        ...state.lookupTablesFeedback,
        items: state.lookupTablesFeedback.items.map(f => updateFeedback(f, feedback)),
      },
    };
  }

  @Action('[Feedback] Shows the current state of the window')
  public toggleStofffeedbackWindow(state: IFeedbacksState, isVisible: boolean) {
    return {
      ...state,
      isStofffeedbackWindowVisible: isVisible,
    };
  }
}

export function feedbackReducer(state: IFeedbacksState, action: DucksifiedAction): typeof state {
  return reducerFrom(FeedbackDucks)(state, action);
}
