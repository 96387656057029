import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ImportAuftrag, ImportAuftragModus } from 'app/core/services/api-client';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';

import { Duck } from '@ngrx-ducks/core';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';
import { selectedImportAuftrag, getLieferanten } from '../../pages/import-page/ducks/import-page.selectors';
import { ImportPageDucks } from '../../pages/import-page/ducks/import-page.ducks';
import { DropdownListItem } from '@models/dropdownListItem';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { DropdownListTextItem } from '@models/dropdownListTextItem';
import { EnumHelper } from 'app/core/utils/EnumHelper';

@Component({
  selector: 'app-import-auftraege-edit',
  templateUrl: './import-auftraege-edit.component.html',
  styleUrls: ['./import-auftraege-edit.component.scss']
})
export class ImportAuftraegeEditComponent implements OnInit {

  @ViewChild('upload') upload;

  public importAuftrag: ImportAuftrag;
  public lieferanten$: Observable<{ [key: string]: string; }>;
  public importFormate: Observable<{ [key: string]: string; }>;
  public lieferanten: DropdownListItem[] = [];
  public defaultListItem: DropdownListItem = { text: this.i18n.getLocalizedString('action.selectSupplier'), value: null };
  public selectedLieferantId: number = null;
  public uploadSaveUrl: string;
  public uploadRemoveUrl: string;
  public sachverhalteUeberspringen = false;
  public stoffeErstellen = false;
  public selectedModus = ImportAuftragModus.Hinzufuegen;
  public fileRestrictions: FileRestrictions = {
    // maxFileSize: 4194304, // Bytes = 4 MB
    allowedExtensions: ['.xml', '.ssf', '.gz', '.zip' ]
  };
  public modusItems: DropdownListTextItem[];


  public selectedImportAuftrag$: Observable<ImportAuftrag>;

  constructor(
    private i18n: I18nService,
    private store: Store<IAppState>,
    private toastyService: CustomToastyService,
    @Inject(ImportPageDucks) private importAuftraegeDucks: Duck<ImportPageDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
  ) {
    this.setModusItems();
  }



  ngOnInit() {

    this.importAuftraegeDucks.getLieferantenEffect.dispatch();
    this.lieferanten$ = this.store.pipe(select(getLieferanten));
    this.uploadSaveUrl = '';

    this.lieferanten$.subscribe(l => {
      if (l) {
        this.lieferanten = [];
        for (const item in l) {
          if (l.hasOwnProperty(item)) {
            this.lieferanten.push({ text: l[item], value: parseInt(item, 10) });
          }
        }
        this.lieferanten = _.orderBy(this.lieferanten, [v => v.text.toLocaleLowerCase()], ['asc']);
      }
    });

    this.selectedImportAuftrag$ = this.store.pipe(select(selectedImportAuftrag));
    this.selectedImportAuftrag$.subscribe(importAuftrag => {
      if (importAuftrag) {
        this.importAuftrag = importAuftrag;
      }
    });
  }

  public onUrlChanged() {
    // tslint:disable-next-line: max-line-length
    this.uploadSaveUrl = `${environment.baseAPIUrl}/api/ImportAuftrag?lieferantId=${this.selectedLieferantId}&sachverhalteUeberspringen=${this.sachverhalteUeberspringen}&stoffeErstellen=${this.stoffeErstellen}&modus=${this.selectedModus}`;
  }

  public completed() {
    this.windowDucks.closeWindow.dispatch();
    this.importAuftraegeDucks.getImportAuftraegeEffect.dispatch();
  }

  errorEventHandler(e: any) {
    this.toastyService.toastError(e.response);
  }

  public submit() {
    this.upload.uploadFiles();
    // this.importAuftraegeDucks.saveImportAuftragEffect.dispatch(this.importAuftrag);
  }

  public cancel() {
    this.windowDucks.closeWindow.dispatch();
  }

  public get isValid() {
    return !_.isNil(this.selectedLieferantId) && this.upload.fileList.filesToUpload.length === 1;
  }

  public setModusItems(): void {
    this.modusItems = [];
    EnumHelper.ToArray(ImportAuftragModus).forEach((key: string) => {
      this.modusItems.push({
        text: this.i18n.getLocalizedString('components.importAuftraegeEdit.modusOption.' + key),
        value: key,
      });
    });
  }
}
