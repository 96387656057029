<div class="content-with-navbar">
  <app-actionbar [pageTitle]="'components.settings.title' | translate"></app-actionbar>

  <div class="x_panel">
    <div class="x_title">
      <h2>{{'components.settings.viewSettings' | translate}}</h2>
      <div class="clearfix"></div>
    </div>
    <div class="x_content">
      <label class="k-form-field"
             style="width:200px">{{'components.settings.currentDefaultView' | translate}}</label>
      <kendo-dropdownlist [textField]="'value.view.viewName'"
                          [valueField]="'key'"
                          [value]="defaultView$ | async"
                          [data]="namedViewsKeyValue$ | async"
                          [valuePrimitive]="true"
                          (selectionChange)="setDefaultView($event)"
                          [listHeight]="500"
                          style="width:300px"
                          id="ddl_views">
        <ng-template kendoDropDownListItemTemplate
                     let-dataItem>
          <div style="width:100%">
            <span class="template" style="float: left">{{ dataItem.value.view.viewName}}</span>
            <span *ngIf="!dataItem.value.view.isDefaultView && !dataItem.value.view.isSystem"
                  (click)="deleteView(dataItem.value.view.viewName)"
                  title="{{'action.delete' | translate}}"
                  style="float: right"><i class="fas fa-trash-alt" name="icon_delete_view"></i></span>
          </div>
        </ng-template>
        <ng-template kendoDropDownListValueTemplate
                     let-dataItem>
          <span class="template">{{ dataItem?.value.view.viewName}}</span>
        </ng-template>
      </kendo-dropdownlist>
    </div>
  </div>
  <div style="font-size: xx-small">UI Version: {{version}}</div>
</div>
