import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { ConfigurationRankingStepComponent } from './configuration-ranking-step.component';
import { StoreModule } from '@ngrx/store';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  imports: [
    I18nModule,
    TranslateModule,
    CommonModule,
    TextBoxModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    StoreModule,
    DropDownListModule
  ],
  declarations: [ConfigurationRankingStepComponent],
  exports: [ConfigurationRankingStepComponent],
})
export class ConfigurationRankingStepModule {
}
