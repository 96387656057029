import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { InternalServerErrorInterceptor } from 'app/core/interceptors/internal-server-error.interceptor';

@NgModule({
  // imports: [RouterModule]
})
export class InternalServerErrorInterceptorModule {
  public static forRoot(): ModuleWithProviders<InternalServerErrorInterceptorModule> {
    return {
      ngModule: InternalServerErrorInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InternalServerErrorInterceptor,
          multi: true,
        },
      ],
    };
  }
}

