import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { isSachverhaltPrimitives, isSachverhaltSubset, SachverhaltField, SachverhaltSet, SachverhaltHeader } from '@models/dossier/dossier-models';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import { KeyValue } from 'app/core/utils/keyValue';
import * as _ from 'lodash';
import { Merkmal } from '@models/merkmalbaum';
import { SecurityContext } from '@angular/compiler/src/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-dynamic-sachverhalte-form',
  templateUrl: './dynamic-sachverhalte-form.component.html',
  styleUrls: ['./dynamic-sachverhalte-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicSachverhalteFormComponent implements OnChanges {

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  @Input()
  public header: SachverhaltHeader;

  @Input()
  public sachverhaltId?: number;

  @Input()
  public erfassungSachverhaltId?: number;

  @Input()
  public fields: SachverhaltField[];

  @Input()
  public values: SachverhaltSet;

  @Input()
  public emptyFields: string[] = [];

  @Input()
  public serverValidationErrors: IServerValidationErrors = {
    invalidFields: [],
    summary: []
  };

  public errorFields: Set<string> = new Set<string>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields'] && this.fields) {
      this.fields.forEach(field => {
        if (field.type === 'subset') {
          const sachverhaltSubset = this.values[field.key];
          if (isSachverhaltSubset(sachverhaltSubset) && sachverhaltSubset.length === 0) {
            // add empty subset
            this.addWert(field.key, field.type);
          }
        }
      });
    }

    if (changes['emptyFields'] || changes['serverValidationErrors']) {
      this.errorFields = [...this.emptyFields, ...this.serverValidationErrors.invalidFields].reduce(
        (fieldSet, field) => {
          fieldSet.add(field);
          return fieldSet;
        }, new Set<string>()
      );
    }
  }

  public onTextValueChange(value: any,
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    if (!_.isNull(value) && !_.isUndefined(value)) {
      const textValue = _.isEmpty(value.target.value)
        ? null
        : value.target.value;
      if (index === null) {
        this.values[fieldKey] = textValue;
      } else if (subset !== null) {
        this.values[subset][index][fieldKey] = textValue;
      } else {
        this.values[fieldKey][index] = textValue;
      }
    }
  }

  public onRangeChanged(value: KeyValue[],
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    if (index === null) {
      value.forEach(property => {
        this.values[property.key] = property.value;
      });
    } else if (subset !== null && index !== null) {
      value.forEach(property => {
        this.values[subset][index][property.key] = property.value;
      });
    } else {
      throw new Error('not implemented');
    }
  }

  public onIntValueChange(value: number | null,
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    value = _.isEmpty(value) ? undefined : value;
    if (index === null) {
      if (_.isNil(value)) {
        this.values[fieldKey] = value;
      } else {
        this.values[fieldKey] = Math.round(value);
      }
    } else if (subset !== null) {
      if (_.isNil(value)) {
        this.values[subset][index][fieldKey] = value;
      } else {
        this.values[subset][index][fieldKey] = Math.round(value);
      }
    } else {
      if (_.isNil(value)) {
        this.values[fieldKey][index] = value;
      } else {
        this.values[fieldKey][index] = Math.round(value);
      }
    }
  }

  public onDoubleValueChange(value: number,
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    if (index === null) {
      this.values[fieldKey] = value;
    } else if (subset !== null) {
      this.values[subset][index][fieldKey] = value;
    } else {
      this.values[fieldKey][index] = value;
    }
  }

  public onDateChange(value: Date,
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    const year = value.getFullYear();
    // month is zero based
    const month = value.getMonth() + 1;
    const day = value.getDate();
    const date = `${year}-${month > 9 ? '' : '0'}${month}-${
      day > 9 ? '' : '0'
      }${day}`;

    if (index === null) {
      this.values[fieldKey] = date;
    } else if (subset !== null) {
      this.values[subset][index][fieldKey] = date;
    } else {
      this.values[fieldKey][index] = date;
    }
  }

  public onDropdownValueChange(value: any,
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    if (index === null) {
      this.values[fieldKey] = value;
    } else if (subset !== null) {
      this.values[subset][index][fieldKey] = value;
    } else {
      this.values[fieldKey][index] = value;
    }
  }

  public onValueChanged(value: any,
    fieldKey: string,
    index: number = null,
    subset: string = null) {
    if (index === null) {
      this.values[fieldKey] = value;
    } else if (subset !== null) {
      this.values[subset][index][fieldKey] = value;
    } else {
      this.values[fieldKey][index] = value;
    }
  }

  public addWert(fieldKey: string, type: string = null) {
    if (_.isNull(this.values[fieldKey]) ||
      _.isUndefined(this.values[fieldKey])) {
      this.values[fieldKey] = [];
    }

    const list = this.values[fieldKey];
    if (type === 'subset' && isSachverhaltSubset(list)) {
      const sachverhaltSet: SachverhaltSet = {};
      _.find(this.fields, field => field.key === fieldKey)
        .fields
        .forEach(element => sachverhaltSet[element.key] = null);
      list.push(sachverhaltSet);
    } else if (isSachverhaltPrimitives(list)) {
      list.push(null);
    }
  }

  public deleteWert(fieldKey: string, index: number) {
    const list = this.values[fieldKey];
    if (isSachverhaltPrimitives(list) || isSachverhaltSubset(list)) {
      if (index > -1) {
        list.splice(index, 1);
      }
      if (list.length === 0) {
        delete this.values[fieldKey];
      } else {
        const type = this.fields.find(field => field.key === fieldKey).type;
        if (list.length === 0 && type === 'subset') {
          this.addWert(fieldKey, type);
        }
      }
    }
  }

  public GetLabelsForSubset(field: SachverhaltField): string {
    const labels = [];
    field.fields.forEach(f => {
      labels.push(f.label);
    });
    return labels.join(', ');
  }

  trackByIndex(index: number) {
    return index;
  }

  public parseMultilineString(text: string): string {
    // const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, text);
    return text;
    // return sanitized.replace(/(&#10;)/g, '<br>');
  }
}

