import { Component, OnInit, Inject } from '@angular/core';
import { RankingRule, RankingRuleType, SupplierPriority, NumericCondition, NumericConditionOperator, ValuePriority, ExtremumOperator, Extremum } from 'app/core/services/api-client';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectedRankingRule, getMerkmaleDictionary, getAllSuppliers, getAllLookupTables } from '../../pages/ranking-rules-page/ducks/ranking-rules.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import { RankingRulesDucks } from '../../pages/ranking-rules-page/ducks/ranking-rules.ducks';
import { Duck } from '@ngrx-ducks/core';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-ranking-rules-edit',
  templateUrl: './ranking-rules-edit.component.html',
  styleUrls: ['./ranking-rules-edit.component.scss']
})
export class RankingRulesEditComponent implements OnInit {

  public rule: RankingRule;
  public selectedRankingRule$: Observable<RankingRule>;
  public form: FormGroup;
  public ruleRegex: RegExp = /^[a-zA-Z0-9_-]{1,255}$/;
  public rankingTypeListItems: Array<{ text: string, value: string }> = this.getListItems('RankingRuleType');
  public numericConditionOperatorListItems: Array<{ text: string, value: string }> = this.getListItems('NumericConditionOperator');
  public extremumOperatorListItems: Array<{ text: string, value: string }> = this.getListItems('ExtremumOperator');
  public isNew: boolean;
  public merkmale$: Observable<{ [key: string]: string[] }>;
  public merkmaleDictionary: { [key: string]: string[] } = {};
  public merkmale: string[];
  public merkmalOptions: string[];
  public feldOptions: string[] = [];
  public lookupTables$: Observable<string[]>;
  public suppliers$: Observable<string[]>;
  public lookupTables: string[] = [];
  public lookupTableOptions: string[] = [];
  public suppliers: string[] = [];
  public supplierOptions: string[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  constructor(
    private store: Store<IAppState>,
    private i18n: I18nService,
    @Inject(RankingRulesDucks) private rankingRulesDucks: Duck<RankingRulesDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
  ) {
  }

  ngOnInit() {
    this.merkmale$ = this.store.pipe(select(getMerkmaleDictionary));
    this.suppliers$ = this.store.pipe(select(getAllSuppliers));
    this.lookupTables$ = this.store.pipe(select(getAllLookupTables));
    this.rankingRulesDucks.getAllSuppliersEffect.dispatch();
    this.rankingRulesDucks.getAllLookupTablesEffect.dispatch();

    this.merkmale$.subscribe(merkmale => {
      if (merkmale) {
        this.merkmaleDictionary = merkmale;
        this.merkmale = Object.keys(merkmale);
        this.merkmalOptions = [...this.merkmale];
      }
    });

    this.selectedRankingRule$ = this.store.pipe(select(selectedRankingRule));
    this.selectedRankingRule$.subscribe(rule => {
      if (rule) {
        this.sortRankingRuleItems(rule);
        this.rule = rule;
        this.isNew = rule.name === '[new]';
        this.setFeldOptions(rule.attribute);
      }
    });

    this.suppliers$.subscribe(suppliers => {
      if (suppliers && suppliers.length > 0) {
        this.suppliers = suppliers;
        this.supplierOptions = [...this.suppliers];
      }
    });

    this.lookupTables$.subscribe(lookupTables => {
      if (lookupTables && lookupTables.length > 0) {
        this.lookupTables = lookupTables;
        this.lookupTableOptions = [...this.lookupTables];
      }
    });

  }

  private getListItems(type: string): Array<{ text: string, value: string }> {
    switch (type) {
      case 'RankingRuleType':
        return this.getEnumKeys(RankingRuleType);
        break;
      case 'NumericConditionOperator':
        return this.getEnumKeys(NumericConditionOperator);
        break;
      case 'ExtremumOperator':
        return this.getEnumKeys(ExtremumOperator);
        break;
      default:
        console.error('No such type: ' + this.rule.type);
        break;
    }
  }

  private getEnumKeys(enumType: any): Array<{ text: string, value: string }> {
    const listItems = [];
    for (const type in enumType) {
      if (enumType.hasOwnProperty(type)) {
        const localizedText = this.i18n.getLocalizedString(`components.rankingRulesEdit.listItemValues.${type}`);
        listItems.push({ text: localizedText, value: type });
      }
    }
    return listItems;
  }

  public submit() {

    if (this.isNew) {
      this.rankingRulesDucks.saveRankingRuleEffect.dispatch(this.rule);
    } else {
      this.rankingRulesDucks.updateRankingRuleEffect.dispatch(this.rule);
    }
  }

  public cancel() {
    this.windowDucks.closeWindow.dispatch();
  }

  public onTypeSelectionChange(event: any): void {
    this.rule.type = event.value;
  }

  public deleteRow(index: number): void {
    switch (this.rule.type) {
      case RankingRuleType.Supplier:
        this.rule.supplierPriorities.splice(index, 1);
        break;
      case RankingRuleType.NumericCondition:
        this.rule.numericConditions.splice(index, 1);
        break;
      case RankingRuleType.LookupTable:
        this.rule.valuePriorities.splice(index, 1);
        break;
      case RankingRuleType.Extremum:
        this.rule.extremums.splice(index, 1);
        break;
      default:
        console.error('No such type: ' + this.rule.type);
        break;
    }
  }
  public get isValid() {
    return !_.isEmpty(this.rule.name)
      && this.ruleRegex.test(this.rule.name)
      && this.rule.type !== undefined;
  }

  public addRow() {

    switch (this.rule.type) {
      case RankingRuleType.Supplier:
        this.addSupplierPriority();
        break;
      case RankingRuleType.NumericCondition:
        this.addNumericCondition();
        break;
      case RankingRuleType.LookupTable:
        this.addValuePriority();
        break;
      case RankingRuleType.Extremum:
        this.addExtremum();
        break;
      default:
        console.error('No such type: ' + this.rule.type);
        break;
    }
  }
  private addSupplierPriority() {
    if (!this.rule.supplierPriorities) {
      this.rule.supplierPriorities = [];
    }
    let nextHigherPriority = 0;
    if (this.rule.supplierPriorities.length > 0) {
      const maxPriority = _.maxBy(this.rule.supplierPriorities, (o => parseInt(o.priority.toString(), 10)));
      nextHigherPriority = parseInt(maxPriority.priority.toString(), 10) + 1;
    }
    const supplierPriority: SupplierPriority = new SupplierPriority({ supplier: '', priority: nextHigherPriority });
    this.rule.supplierPriorities.push(supplierPriority);
  }

  private addNumericCondition() {
    if (!this.rule.numericConditions) {
      this.rule.numericConditions = [];
    }
    const numericCondition: NumericCondition = new NumericCondition(
      {
        field: '',
        lower: 0,
        upper: 0,
        operator: NumericConditionOperator.Equals
      });
    this.rule.numericConditions.push(numericCondition);
  }

  private addValuePriority() {
    if (!this.rule.valuePriorities) {
      this.rule.valuePriorities = [];
    }
    let nextHigherPriority = 0;
    if (this.rule.valuePriorities.length > 0) {
      const maxPriority = _.maxBy(this.rule.valuePriorities, (o => parseInt(o.priority.toString(), 10)));
      nextHigherPriority = parseInt(maxPriority.priority.toString(), 10) + 1;
    }
    const valuePriority: ValuePriority = new ValuePriority({ value: '', priority: nextHigherPriority });
    this.rule.valuePriorities.push(valuePriority);
  }

  private addExtremum() {
    if (!this.rule.extremums) {
      this.rule.extremums = [];
    }
    const extremum: Extremum = new Extremum({ operator: ExtremumOperator.Max, field: '' });
    this.rule.extremums.push(extremum);
  }

  private sortRankingRuleItems(rule: RankingRule): void {
    if (rule.supplierPriorities && rule.supplierPriorities.length > 0) {
      rule.supplierPriorities = _.orderBy(rule.supplierPriorities, ['priority'], ['asc']);
    }
    if (rule.valuePriorities && rule.valuePriorities.length > 0) {
      rule.valuePriorities = _.orderBy(rule.valuePriorities, ['priority'], ['asc']);
    }
  }

  onMerkmalChanged(value) {
    const merkmalKey = value.toUpperCase();
    this.setFeldOptions(merkmalKey);
  }

  onMerkmalFilterChanged(value) {
    if (value.length >= 1) {
      this.merkmalOptions = this.merkmale
        .filter((s) => s.toLowerCase()
          .indexOf(value.toLowerCase()) !== -1)
        .slice(0, 100);
    } else {
      this.merkmalOptions = this.merkmale;
      // this.merkmalFeld.toggle(false);
    }
  }

  private setFeldOptions(merkmalKey: string) {
    if (merkmalKey && this.merkmaleDictionary[merkmalKey]) {
      this.feldOptions = this.merkmaleDictionary[merkmalKey];
    } else {
      this.feldOptions = [];
    }
  }

  doOnFocus(ctrl: AutoCompleteComponent) {
    ctrl.toggle(true);
  }
}
