import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { Sachverhalt, SachverhaltErstellen, SachverhaltField, SachverhaltHeader, SachverhaltSet } from '@models/dossier/dossier-models';
import { select, Store } from '@ngrx/store';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { getLastSelectedMerkmal } from 'app/modules/shared-area/ducks/merkmal/merkmal.selectors';
import { getSachverhalt, getSachverhaltEditValues, isSachverhaltLoading, serverValidationErrors } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { SachverhaltDucks } from '../ducks/sachverhalt.ducks';
import { BatchDucks } from 'app/modules/search-area/components/batch/ducks/batch.ducks';
import { UserProfileStore } from '../../../ducks/user-profile/user-profile-store.service';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { SachverhaltEffectsNew } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt-effects-new.service';
import * as _ from 'lodash';
import { DossierService } from 'app/modules/dossier-area/services/dossier/dossier.service';

@Component({
  selector: 'app-sachverhalt-create',
  templateUrl: './sachverhalt-create.component.html',
})
export class SachverhaltCreateComponent implements OnInit, OnDestroy, AfterViewInit {

  private id: string;
  public sachverhaltId?: number;
  public erfassungSachverhaltId?: number;
  private merkmal: string;

  public sachverhalt: Sachverhalt;
  public header: SachverhaltHeader;
  public fields: SachverhaltField[];
  public values: SachverhaltSet;

  duplicatedValues: SachverhaltSet;
  public isBatchMode = false;
  public message: string;

  public IsFormInitialized = false;
  public IsSaving: boolean;
  public emptyFields: string[];
  public serverValidationErrors$: Observable<IServerValidationErrors> = EMPTY;

  public isSachverhaltLoading$: Observable<boolean>;
  public subscriptions: Subscription[];

  @Output()
  public closeWindow: EventEmitter<any | null> = new EventEmitter<any | null>();

  constructor(
    private store: Store<IAppState>,
    @Inject(SachverhaltDucks) private sachverhaltDucks: Duck<SachverhaltDucks>,
    @Inject(BatchDucks) private batchDucks: Duck<BatchDucks>,
    private sachverhaltEffects: SachverhaltEffectsNew,
    private toastyService: CustomToastyService,
    private validation: ValidationService,
    private i18n: I18nService,
    private userProfileStore: UserProfileStore,
    private dossierService: DossierService,
  ) {
    this.IsSaving = false;
    this.emptyFields = [];
    this.subscriptions = [];
  }

  ngOnInit() {
    this.duplicatedValues = null;
    this.isSachverhaltLoading$ = this.store.pipe(select(isSachverhaltLoading));
    this.subscriptions.push(
      this.store.pipe
          (
            select(getLastSelectedMerkmal),
            tap(selectedMerkmal => this.merkmal = selectedMerkmal),
          )
          .subscribe(),
    );

    this.store.pipe(
      select(getSachverhaltEditValues),
      first(),
        )
        .subscribe(values => {
          this.id = values.id;
          this.sachverhaltId = values.sachverhaltId;
          this.erfassungSachverhaltId = values.erfassungSachverhaltId;
        });

    if (this.merkmal) {
      this.loadSachverhalt();
    } else {
      this.duplicateMerkmal();
    }

    this.serverValidationErrors$ = this.store.pipe(select(serverValidationErrors));
  }

  private loadSachverhalt() {
    this.sachverhaltDucks.loadSachverhaltEffect({
      id: this.id,
      merkmal: this.merkmal,
    });
  }

  duplicateMerkmal() {
    this.sachverhaltDucks.startLoadingSachverhalt();
    if (!_.isNil(this.sachverhaltId)) {
      this.dossierService.DossierSachverhaltBearbeitenAnzeigen(this.id, this.sachverhaltId)
          .subscribe(
            sachverhalt => this.setMerkmalAndDuplicatedValuesAndLoadSachverhalt(sachverhalt),
          );
    } else if (!_.isNil(this.erfassungSachverhaltId)) {
      this.dossierService.DossierNeuenSachverhaltBearbeitenAnzeigen(this.id, this.erfassungSachverhaltId)
          .subscribe(sachverhalt => this.setMerkmalAndDuplicatedValuesAndLoadSachverhalt(sachverhalt));
    }
  }

  private setMerkmalAndDuplicatedValuesAndLoadSachverhalt(sachverhalt: Sachverhalt) {
    this.merkmal = sachverhalt.header.merkmalName;
    this.duplicatedValues = sachverhalt.values;
    // Entfernen alle "_originalLfdSubsetNr" aus allen Subsets, damit das Backend die Daten auch 
    // als neues Subset interpretiert
    for (const key in this.duplicatedValues) {
      const value = this.duplicatedValues[key];
      if (key.startsWith("_SUBSET_") && Array.isArray(value)) {
        for (const n in value) {
          delete this.duplicatedValues[key][n]["_originalLfdSubsetNr"];
        }        
      }
    }
    this.loadSachverhalt();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((element: Subscription) => {
      element.unsubscribe();
    });
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.store.pipe(select(getSachverhalt))
          .subscribe(sachverhalt => {
            if (sachverhalt) {
              this.sachverhaltDucks.resetServerValidationError();
              this.sachverhalt = sachverhalt;
              this.header = sachverhalt.header;
              this.fields = sachverhalt.fields;
              this.values = this.duplicatedValues || sachverhalt.values;
              this.IsFormInitialized = true;
            }
          }),
    );
  }

  get isFormValid(): boolean {
    const emptyMandatoryFieldCount = this.validation.checkForRequiredFields(this.fields, this.values).length;
    const invalidLookupFieldCount = this.validation.checkLookupFields(this.fields, this.values).length;
    return emptyMandatoryFieldCount === 0 && invalidLookupFieldCount === 0;
  }

  public neuenSachverhaltErstellenUndWeitereAnlegen() {
    const emptyRequiredFields = this.validation.checkForRequiredFields(
      this.fields,
      this.values,
    );
    const invalidLookupFields = this.validation.checkLookupFields(this.fields, this.values);
    if (emptyRequiredFields.length === 0 && invalidLookupFields.length === 0) {
      const erstellen: SachverhaltErstellen = new SachverhaltErstellen(
        this.id,
        this.merkmal,
        this.values,
        true,
      );
      this.userProfileStore.effects.addFavouriteMerkmalAsync({ key: this.merkmal, displayName: this.sachverhalt.header.merkmal });
      this.sachverhaltDucks.createSachverhaltAndOpen.dispatch(erstellen);
    } else {
      this.emptyFields = emptyRequiredFields.concat(invalidLookupFields);
      if (emptyRequiredFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.notAllRequiredFieldsAreSet');
      }
      if (invalidLookupFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.invalidLookupFieldValues');
      }
    }
  }

  public async neuenSachverhaltErstellenUndSchliessen() {
    const emptyRequiredFields = this.validation.checkForRequiredFields(
      this.fields,
      this.values,
    );
    const invalidLookupFields = this.validation.checkLookupFields(this.fields, this.values);
    if (emptyRequiredFields.length === 0 && invalidLookupFields.length === 0) {
      const erstellen: SachverhaltErstellen = new SachverhaltErstellen(
        this.id,
        this.merkmal,
        this.values,
      );
      this.userProfileStore.effects.addFavouriteMerkmalAsync({ key: this.merkmal, displayName: this.sachverhalt.header.merkmal });
      await this.sachverhaltEffects.createSachverhaltAndUpdateDossier(erstellen);
    } else {
      this.emptyFields = emptyRequiredFields.concat(invalidLookupFields);
      if (emptyRequiredFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.notAllRequiredFieldsAreSet');
      }
      if (invalidLookupFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.invalidLookupFieldValues');
      }
    }
  }

  public close() {
    // this.windowDucks.closeWindow.dispatch();
    this.closeWindow.emit(null);
  }
}
