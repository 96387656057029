import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileInfo, FileRestrictions } from '@progress/kendo-angular-upload';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { uuid } from 'lodash-uuid';
import { environment } from '@environments/environment';
import { DropdownListTextItem } from 'app/models/dropdownListTextItem';
import { priorityItems, severityItems } from '@models/feedback';
import { ICanCancel, ITriggerOnClose } from 'app/core/services/custom-window-service/custom-window.service';
import {
  CreateFeedbackItem,
  CreateFeedbackItemCategory,
  CreateFeedbackItemObjektTyp,
  CreateFeedbackItemPriority,
  CreateFeedbackItemType,
  FeedbackItem,
  FeedbackItemHistory,
  FeedbackItemHistoryState,
  FeedbackItemState,
  UpdateFeedbackItem,
  UpdateFeedbackItemState,
} from 'app/core/services/api-client/api-client';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../../modules/shared-area/root-state';
import { Observable } from 'rxjs';
import { getUsername } from '../../../modules/shared-area/ducks/user/user.selectors';
import { FeedbackStore } from 'app/modules/shared-area/ducks/feedback/feedback-store';

@Component({
  selector: 'app-stofffeedback-view',
  styleUrls: ['./stoff-feedback.component.scss'],
  templateUrl: './stoff-feedback.component.html',
})
export class StoffFeedbackComponent implements OnInit, ICanCancel, ITriggerOnClose {

  @ViewChild('commentInput', { read: ElementRef }) private commentInput: ElementRef<HTMLTextAreaElement>;
  public categories: string[];
  public uploadSaveUrl: string;
  public uploadRemoveUrl: string;
  public fileRestrictions: FileRestrictions = {
    maxFileSize: 4194304, // Bytes = 4 MB
  };
  public severityListItems = severityItems;
  public severityItems: DropdownListTextItem[];
  public priorityListItems = priorityItems;
  public priorityItems: DropdownListTextItem[];
  public feedbackState: string[];
  public feedbackUid = uuid();
  public files: FileInfo[];
  public isEditMode = false;

  private user$: Observable<string>;
  private loggedInUser;
  private initialFeedbackState: string;

  @Input()
  public createFeedbackItem = new CreateFeedbackItem();
  @Input()
  public feedbackItem = new FeedbackItem();

  @Output()
  public closeWindow = new EventEmitter<CreateFeedbackItem | FeedbackItem | null>();

  constructor(private i18n: I18nService,
    private validationService: ValidationService,
    private store: Store<IAppState>,
    private feedbackStore: FeedbackStore) {
    this.setSeverityItems();
    this.setPriorityItems();
    this.createFeedbackItem.objektTyp = CreateFeedbackItemObjektTyp.Stoff;
    this.createFeedbackItem.subject = '';
    this.createFeedbackItem.description = '';
    this.createFeedbackItem.category = CreateFeedbackItemCategory.None;
    this.createFeedbackItem.priority = CreateFeedbackItemPriority.Normal;
    this.createFeedbackItem.issueIds = [];
    this.createFeedbackItem.url = window.location.href;
    this.createFeedbackItem.type = CreateFeedbackItemType.Stoffdaten;
    this.files = [];
    this.uploadSaveUrl = environment.baseAPIUrl + environment.feedback + this.feedbackUid + '/save';
    this.uploadRemoveUrl = environment.baseAPIUrl + environment.feedback + this.feedbackUid + '/remove';

    this.user$ = this.store.pipe(select(getUsername));
    this.user$.subscribe(username => {
      this.loggedInUser = username;
    });
  }

  ngOnInit(): void {
    this.setFeedbackState();
  }

  public setSeverityItems(): void {
    this.severityItems = [];
    this.severityListItems.forEach((key: string) => {
      this.severityItems.push({
        text: this.i18n.getLocalizedString(`components.stofffeedback.severities.${key}`),
        value: key,
      });
    });
  }

  public setPriorityItems(): void {
    this.priorityItems = [];
    this.priorityListItems.forEach((key: string) => {
      this.priorityItems.push({
        text: this.i18n.getLocalizedString(`models.feedback.priorities.${key}`),
        value: key,
      });
    });
  }

  public setFeedbackState(): void {
    this.feedbackState = [];
    Object.keys(FeedbackItemState).filter(state => this.canChangeState(state)).map(state => {
      this.feedbackState.push(state);
    });
  }

  private canChangeState(state: string) {
    const oldState = this.feedbackItem.state || FeedbackItemState.Open;
    const newState = FeedbackItemState[state];
    return this.feedbackStore.validateFeedbackStateTransition(oldState, newState);
  }

  public addHistory(comment) {
    if (comment !== '') {
      const historyItem = new FeedbackItemHistory({
        'state': FeedbackItemHistoryState.Open,
        'comment': comment,
      });
      this.feedbackItem.history.push(historyItem);
    }
  }

  public async sendToApi() {
    await this.feedbackStore.effects.createFeedback(this.createFeedbackItem);
    this.feedbackStore.ducks.toggleStofffeedbackWindow(false);
    this.closeWindow.emit(this.createFeedbackItem);
  }

  public canUpdate(): boolean {
    if (this.commentInput) {
      return this.commentInput.nativeElement.value !== '' || this.initialFeedbackState !== this.feedbackItem.state;
    }
    return false;
  }

  public updateStoffFeedbackState(state: string) {

    this.feedbackItem.state = FeedbackItemState[state];
  }

  public async updateFeedback() {
    const item = new UpdateFeedbackItem({
      comment: this.commentInput.nativeElement.value,
      emailExpected: this.feedbackItem.emailExpected,
      state: UpdateFeedbackItemState[this.feedbackItem.state],
    });
    const update = <{ uid: string, item: UpdateFeedbackItem }>({
      uid: this.feedbackItem.feedbackUid,
      item: item,
    });

    await this.feedbackStore.effects.updateFeedbackEffect(update);
    this.closeWindow.emit(this.feedbackItem);
  }

  private async clear() {
    await this.feedbackStore.effects.cancelFeedback(this.feedbackUid);
  }

  public cancel() {
    this.clear();
    this.closeWindow.emit(null);
  }

  public initStoffFeedback(feedback: FeedbackItem | CreateFeedbackItem, edit: boolean = false) {
    this.isEditMode = edit;
    if (!this.isEditMode) {
      this.createFeedbackItem.feedbackUid = this.feedbackUid;
    }
    const data = {
      ...this.createFeedbackItem,
      ...feedback,
    };
    if (!this.isEditMode) {
      this.createFeedbackItem = new CreateFeedbackItem(data as CreateFeedbackItem);
      this.createFeedbackItem.feedbackUid = this.feedbackUid;
      this.createFeedbackItem.emailExpected = feedback.emailExpected;
    } else {
      this.feedbackItem = feedback as FeedbackItem;
      this.feedbackItem.emailExpected = feedback.emailExpected;
      this.initialFeedbackState = this.feedbackItem.state;
    }

    this.files = [];
  }

  public onKey(event) {
    if (event.which === 27) {
      this.cancel();
    }
  }

  public get canSend() {
    return !this.validationService.isNullOrEmpty(this.createFeedbackItem.category) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.priority) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.subject) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.description) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.objektTyp) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.objektId) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.merkmal) &&
      !this.validationService.isNullOrEmpty(this.createFeedbackItem.merkmalId);
  }

  public get title(): string {
    return this.i18n.getLocalizedString(`components.stofffeedback.titles.${this.createFeedbackItem.objektTyp}`);
  }

  public fileUrl(url: string) {
    return `${environment.baseAPIUrl}${url}`;
  }
}
