import { ErfassungListItem } from 'app/core/services/api-client/api-client';

export interface IErfassungenState {
  isLoadingErfassungFreigabeList: boolean;
  isLoadingErfassungen: boolean;
  isLoadingAllErfassungen: boolean;
  erfassungen: ErfassungListItem[];
  erfassungFreigabeList: ErfassungListItem[];
  allErfassungen: ErfassungListItem[];
}


