
<ng-template #invalidTable>
  <div class="alert alert-warning">{{tableNotFoundMessage}}</div>
</ng-template>

<ng-container *ngIf="isValidTable; else invalidTable">
<div class="feedback-column" style="scroll-margin-bottom: 25px;">
  <label class="k-form-field feedback-column__label" [translate]="'components.lookup.table.usedIn'"></label>
  <div class="feedback-column__value" style="width: 100%"
    *ngIf="selectedLookupTable && selectedLookupTable.merkmale && selectedLookupTable.merkmale.length > 0">
    {{ selectedLookupTable.merkmale.join(', ')}}
  </div>
</div>

<div class="lookup__window-new" *ngIf="selectedLookupTable.offen && userHasRight">
  <h4>{{'components.lookup.dialog.add' | translate}}</h4>

  <div class="feedback-column" style="scroll-margin-bottom: 25px; margin-top:15px">
  <label class="k-form-field feedback-column__label" [translate]="'components.lookup.table.language'"></label>
 
  <kendo-dropdownlist [data]="supportedUICultures" 
  [textField]="'Title'" [valueField]="'CultureCode'" valuePrimitive="true"
  [(ngModel)]="currentCulture" name="ddl_culture-code"></kendo-dropdownlist>
</div>
<label class="k-form-field feedback-column__label" [translate]="'components.lookup.table.invariantValue'" style="margin-top: 10px;"></label>
  <textarea class="k-textarea" [(ngModel)]="newValueInput"></textarea>
  <label class="k-form-field feedback-column__label" [translate]="'components.lookup.table.localizedValue'" style="margin-top: 10px;"></label>
  <ng-container *ngFor="let culture of supportedUICultures">
<textarea *ngIf="culture.CultureCode == currentCulture && newValueInputLocalized" class="k-textarea" [(ngModel)]="newValueInputLocalized[culture.CultureCode]"></textarea> 
  </ng-container>
  <div *appHasRight="[710]" class="btn-group" role="group" aria-label="Basic example">
    <button *ngIf="isFeedback" [disabled]="!newValueInput || newValueInput.length < 1 || !isInClosableState" class="btn btn-primary"
      (click)="addValueToLookupTable(selectedLookupTable.name, newValueInput, true)" id="btn_add-and-finish">
      {{'components.lookup.dialog.addAndFinish' | translate}}</button>
    <button *ngIf="selectedLookupTable.offen" [disabled]="!newValueInput || newValueInput.length < 1"
      class="btn btn-default"
      (click)="addValueToLookupTable(selectedLookupTable.name, newValueInput)" id="btn_add">{{'components.lookup.dialog.add' | translate}}</button>
  </div>
</div>
<ng-template #dialogConfirmConsolidation>
  <button kendoButton [disabled]="isConsolidating" (click)="consolidate()" id="btn_yes">{{'label.yes' | translate}}</button>
  <button kendoButton [disabled]="isConsolidating" (click)="close()" id="btn_no">{{'label.no' | translate}}</button>
  <div *ngIf="isConsolidating" class="k-i-loading"></div>
</ng-template>
<table class="table table-striped" id="tbl_lookup">
  <thead>
    <tr>
      <!-- supportedUICultures[currentCulture].Title -->
      <th *appHasRight="[711]" scope="col" width="1"></th>
      <th scope="col" width="1">{{'components.lookup.table.id' | translate}}</th>
      <th scope="col">{{'components.lookup.table.value' | translate}}</th>
      <th scope="col">{{'components.lookup.table.valueIn' | translate}} {{getCurrentCultureTitle}}</th>
      <th scope="col" width="1"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let wert of selectedLookupTable.werte">
      <td *appHasRight="[711]">
        <input type="checkbox" [checked]="wertIdsSelected.indexOf(wert.nachschlageTabelleWertId) > -1" (click)="toggleWert(wert.nachschlageTabelleWertId)" />
      </td>
      <td class="lookup__window-content--wertid">{{ wert.nachschlageTabelleWertId }}</td>      
      <td class="lookup__window-content--wert">{{ wert.wert }}</td>
      <td class="lookup__window-content--wert">{{ getLocalizedValue(wert) }}</td>
      <td style="white-space: nowrap;">
        <button *appHasRight="[711]" [disabled]="wertIdsSelected.length === 0 || wertIdsSelected.length === 1 && wertIdsSelected[0] === wert.nachschlageTabelleWertId" class="btn btn-default btn-xs" title="{{ 'components.lookup.dialog.consolidate' | translate }}" (click)="consolidateWith(wert.nachschlageTabelleWertId, dialogConfirmConsolidation)">
          <i class="fas fa-code-branch"></i>
        </button>
        <ng-container *ngIf="selectedLookupTable.offen">
          <button *appHasRight="[710]" class="btn btn-danger btn-xs" title="{{ 'components.lookup.dialog.delete' | translate }}" (click)="deleteLookupTableValue(selectedLookupTable.name, wert.nachschlageTabelleWertId)">
            <i class="far fa-trash-alt"></i>
          </button>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>
<div class="lookup__window-button" style="margin-bottom: 20px;">
  <button *ngIf="feedbackButtonIsVisible" 
  class="btn btn-default"
  id="btn_request"
    (click)="openFeedbackWindow(selectedLookupTable.name)"><i class="far fa-comment"></i>
    {{ 'components.lookup.actions.request' | translate}}</button>
</div>
</ng-container>

<!-- Feedback Properties -->
<ng-container *ngIf="isFeedback">
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.subject'"></label>
    <div class="feedback-column__value">{{ feedback.subject }}</div>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'models.feedback.creator'"></label>
    <div class="feedback-column__value">{{ feedback.createdBy }}</div>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'models.feedback.creationdate'"></label>
    <div class="feedback-column__value">{{ feedback.createdOn | kendoDate: 'G' }}</div>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.description'"></label>
    <div class="feedback-column__value">{{ feedback.description }}</div>
  </div>
  <div class="feedback-column" *ngIf="feedback.category == 'LookupTableEntry'">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.lookupTableName'"></label>
    <div class="feedback-column__value">{{ feedback.nachschlageTabelleName }}</div>
  </div>
  <div class="feedback-column" *ngIf="feedback.category == 'LookupTableEntry'">
    <label class="k-form-field feedback-column__label"
      [translate]="'components.systemfeedback.lookupTableValue'"></label>
      <div class="feedback-column__value">{{ feedback.nachschlageTabelleWert }}</div>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'models.feedback.priority'"></label>
    <div class="feedback-column__value" [translate]="'models.feedback.priorities.' + feedback.priority"></div>
  </div>
  <div class="feedback-column" *ngIf="feedback.url">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.url'"></label>
    <div class="feedback-column__value">
      <a [href]="feedback.url" title="{{ 'action.view' | translate }}: {{ feedback.url }}" target="_blank"
        class="feedback-column__value--link"><span>{{ feedback.url }}</span> <i
          class="fas fa-external-link-alt"></i></a>
    </div>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.stofffeedback.state.label'"></label>
    <kendo-dropdownlist *ngIf="feedbackState && feedbackState.length > 0" [data]="feedbackState" id="change-state"
      [valuePrimitive]="true" [value]="feedback.state" (valueChange)="updateCommonFeedbackState($event)">
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        <ng-container *ngIf="dataItem">{{ 'models.feedback.state.' + dataItem.toLowerCase() | translate}}</ng-container>
      </ng-template>
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        <ng-container *ngIf="dataItem">{{ 'models.feedback.state.' + dataItem.toLowerCase() | translate}}</ng-container>
      </ng-template>
    </kendo-dropdownlist>
  </div>
  <div class="feedback-column feedback-column--top">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.comments'"></label>
    <div *ngIf="feedback.history && feedback.history.length > 0; else noHistory" class="feedback-column__value">
      <div *ngFor="let historyItem of feedback.history" class="feedback-column__comment">
        <div class="feedback-column__comment--text">{{historyItem.comment}}</div>
        <div class="feedback-column__comment--by">
          <b>{{'components.systemfeedback.history.label.createdOn' | translate}}</b>
          {{historyItem.createdOn | kendoDate: 'G'}}
          <b>{{'components.systemfeedback.history.label.createdBy' | translate}}</b> {{historyItem.createdBy}}
          <b>{{'components.systemfeedback.history.label.state' | translate}}</b>
          {{'models.feedback.state.' + historyItem.state.toLowerCase() | translate}}
        </div>

      </div>
    </div>
    <ng-template #noHistory>
      <div class="feedback-column__value">{{ 'components.stofffeedback.comments.emptyHistory' | translate }}</div>
    </ng-template>
  </div>
  <!-- Comment Container -->
  <div class="feedback-column feedback-column--top">
    <label class="k-form-field feedback-column__label" [translate]="'components.stofffeedback.comments.label'"></label>
    <textarea #commentInput class="k-textarea"
      placeholder="{{'components.stofffeedback.comments.placeholder' | translate}}"></textarea>
  </div>

  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.emailExpected'"></label>
    <div style="width: 100%"> <input type="checkbox" class="k-checkbox" [(ngModel)]="feedback.emailExpected"
        [ngModelOptions]="{standalone: true}" name="cb_emailExpected" id="cb_emailExpected"
        style="vertical-align: bottom; margin: 0;" />
        <label class="k-checkbox-label"></label>
    </div>
  </div>

  <div class="feedback-column feedback-column--buttongrp" style="margin-bottom: 20px;">
    <button (click)="cancel()" class="btn btn-default"
      id="btn-cancel-edit">{{'components.systemfeedback.cancel' | translate}}</button>
    <button (click)="updateCommonFeedback()" [disabled]="commentInput.value === '' && initialFeedbackState === feedback.state" class="btn btn-primary" id="btn-update">
      <i class="fas fa-save"></i> {{'action.save' | translate}}
    </button>
  </div>
</ng-container>
