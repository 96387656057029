import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-struktursuche-dialog',
  templateUrl: './struktursuche-dialog.component.html',
  styleUrls: [ './struktursuche-dialog.component.css' ]
})
export class StruktursucheDialogComponent implements OnInit {

  public molfile;

  constructor(public router: Router) {
  }

  ngOnInit() {
  }

  onKetcherIFrameLoaded() {
    const ketcherFrame: any = document.getElementById('ketcher-frame');
    const ketcher = ketcherFrame.contentWindow.ketcher;
    ketcher.setMolecule(this.molfile);
  }
}
