import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiClient, ReferencesResult } from '../api-client';
import { ApiErrorHandlerService } from '../api-default-error-handler/api-error-handler.service';
import { ObjektId } from '@models/objekt-id';
import { parseNumber } from '@telerik/kendo-intl';

@Injectable()
export class ReferencesService {

  constructor(
    private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService) { }

  getReferences(id: ObjektId, skip?: number, pageSize?: number): Observable<ReferencesResult> {
    if (id.typ === 'Stoff') {
      return this.apiClient.apiStoffByStoffIdReferenziertDurchGet(id.id, skip, pageSize)
        .pipe
        (
          catchError(this.apiClient.apiStoffByStoffIdReferenziertDurchGetErrorHandlers({
            error401Handler: error401 => this.errorHandler.handle401Error(error401)
          }))
        );
    } else if (id.typ === 'Lieferant') {
      return this.apiClient.apiLieferantByLieferantIdReferenziertDurchGet(parseNumber(id.id), skip, pageSize)
        .pipe
        (
          catchError(this.apiClient.apiLieferantByLieferantIdReferenziertDurchGetErrorHandlers({
            error401Handler: error401 => this.errorHandler.handle401Error(error401)
          }))
        );
    } else if (id.typ === 'Spezies') {
      return this.apiClient.apiSpeziesBySpeziesIdReferenziertDurchGet(parseNumber(id.id), skip, pageSize)
        .pipe
        (
          catchError(this.apiClient.apiSpeziesBySpeziesIdReferenziertDurchGetErrorHandlers({
            error401Handler: error401 => this.errorHandler.handle401Error(error401)
          }))
        );
    } else if (id.typ === 'Zitat') {
      return this.apiClient.apiZitatByZitatIdReferenziertDurchGet(parseNumber(id.id), skip, pageSize)
        .pipe
        (
          catchError(this.apiClient.apiZitatByZitatIdReferenziertDurchGetErrorHandlers({
            error401Handler: error401 => this.errorHandler.handle401Error(error401)
          }))
        );
    }
  }
}
