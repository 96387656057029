<div *ngIf="configuration; else loading" class="content-with-navbar">
  <app-page-header pageTitle="{{'components.configurationsEdit.title' | translate}}">
    <a [routerLink]="['/admin/configuration']" class="btn btn-default" title="{{'action.backToList' | translate}}"><i
        class="fas fa-chevron-left"></i> {{'action.backToList' | translate}}</a>
  </app-page-header>

  <div class="x_panel" id="configuration_parameter">
    <div class="x_title" style="cursor:default">
      <h2> {{'components.configurationsEdit.editConfiguration' | translate}}</h2>
      <span style="font-size: x-small;">
      </span>
      <div class="clearfix"></div>
    </div>
    <div class="x_content">
      <form class="form-horizontal form-label-left">
        <div class="form-group">
          <label class="control-label col-md-3 col-sm-3 col-xs-3">{{'components.configurationsEdit.name' | translate}}
          </label>
          <div class="col-md-9 col-sm-9 col-xs-9">
            <input [(ngModel)]="configuration.name" class="form-control" name="name">
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-md-3 col-sm-3 col-xs-3">{{'components.configurationsEdit.description' | translate}}
          </label>
          <div class="col-md-9 col-sm-9 col-xs-9">
            <textarea [(ngModel)]="configuration.description" class="form-control" name="description"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="control-label col-md-3 col-sm-3 col-xs-3">{{'components.configurationsEdit.tag' | translate}}
          </label>
          <div class="col-md-9 col-sm-9 col-xs-9">
            <input [(ngModel)]="configuration.tag" class="form-control" name="tag" placeholder="{{'components.configurationsEdit.tagConstraints' | translate}}">
          </div>
        </div>
        <div class="form-group">
          <label
            class="control-label col-md-3 col-sm-3 col-xs-3">{{'components.configurationsEdit.sourceQuery' | translate}}
          </label>
          <div class="col-md-9 col-sm-9 col-xs-9">
            <textarea [(ngModel)]="configuration.sourceQuery" class="form-control" name="sourceQuery"
              style="min-height: 120px;"></textarea>
          </div>
        </div>

        <ng-template #deleteRankingDialog>
          <button kendoButton (click)="deleteRanking()" id="btn_yes">{{'label.yes' | translate}}</button>
          <button kendoButton id="btn_no" (click)="close()">{{'label.no' | translate}}</button>
        </ng-template>
        <div class="row">
          <div class="col-md-6">
            <h3>{{'components.configurationsEdit.hierarchy' | translate}}</h3>
           <ng-container *ngIf="merkmale && merkmale.length > 0">
            <kendo-treeview [nodes]="merkmale" textField="name" valueField='key' style="padding: 30px 0" class="configuration"
              kendoTreeViewExpandable kendoTreeViewHierarchyBinding childrenField="items" id="DossierIndexTree"
              selectBy="key">
              <ng-template kendoTreeViewNodeTemplate let-dataItem>
                <ng-container [ngSwitch]="getState(dataItem)">
                  <div class="k-checkbox-wrapper tableClass">
                      <ng-container *ngSwitchCase="'unchecked'">
                       
                        <input type="checkbox" id="L{{dataItem.hierarchy}}" class="k-checkbox" (click)="onCheckMerkmal($event, dataItem, true)">
                        <label tabindex="-1" class="k-checkbox-label" for="L{{dataItem.hierarchy}}"></label>
                     
                      </ng-container>  
                      <span *ngSwitchCase="'implicit'">
                        <input type="checkbox"  id="L{{dataItem.hierarchy}}" class="k-checkbox" (click)="onCheckMerkmal($event, dataItem, true)">
                        <label class="k-checkbox-label implicit" attr.for="L{{dataItem.hierarchy}}"></label>
                      </span>
                      <ng-container *ngSwitchCase="'checked'">
                        <!-- <span>
                        <input #checkboxHierarchyRef type="checkbox" class="k-checkbox" checked id="L{{dataItem.hierarchy}}" (click)="onCheckMerkmal($event, dataItem, false, deleteRankingDialog)">
                        </span> -->                       
                          <input #checkboxHierarchyRef tabindex="-1" class="k-checkbox" id="L{{dataItem.hierarchy}}" type="checkbox" checked  (click)="onCheckMerkmal($event, dataItem, false, deleteRankingDialog)">
                          <label tabindex="-1" class="k-checkbox-label" for="L{{dataItem.hierarchy}}"></label>                

                      </ng-container>
                      <ng-container *ngSwitchCase="'indeterminate'"> <span>
                        <input type="checkbox" class="k-checkbox" id="L{{dataItem.hierarchy}}" (click)="onCheckMerkmal($event, dataItem, true)">
                        <label class="k-checkbox-label indeterminate" attr.for="L{{dataItem.hierarchy}}"></label>
                        </span>
                      </ng-container>      
                   
                    </div>
                    <div class="tableCellClass">
                      <span> {{dataItem.name}} </span>
                      <span *ngSwitchCase="'checked'" (click)="edit(dataItem)" title="{{'action.edit' | translate}}" class="fas fa-pencil-alt fa-sm pointer" style="margin-left: 5px;"></span>
                    </div> 
               
                </ng-container>
              </ng-template>
            </kendo-treeview>
          </ng-container>

          </div>
          <div class="col-md-6">
            <ng-container *ngIf="selectedRankingStep">
              <app-configurations-ranking-step 
              [selectedRankingStep]="selectedRankingStep" 
              [selectedKey]="selectedKey"
              [rankingRulesListItems]="rankingRulesListItems">
              </app-configurations-ranking-step>
            </ng-container>
          </div>
        </div>

        <div class="ln_solid"></div>
        <div class="form-group form-buttons">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary" id="btn_save" (click)="submit()" [disabled]="!isValid">
              <i class="fas fa-save"></i> {{'action.save' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #loading>
  <div style="text-align: center">
    <i class="k-icon k-i-loading" style="font-size:50px; columns: #363636; margin: 20px"></i>
  </div>
</ng-template>
