import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Duck } from '@ngrx-ducks/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { StorageService } from 'app/core/services/storage/storage.service';
import * as _ from 'lodash';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { ClientInfo } from './core/services/benutzerprofil/models/clientInfo';
import { GlobalService } from './core/services/global/global.service';
import { I18nService } from './core/services/i18n/i18n.service';
import { IViews } from './modules/shared-area/ducks/panel/models/IViews';
import { getNamedViews } from './modules/shared-area/ducks/panel/panel.selectors';
import { UserProfileStore } from './modules/shared-area/ducks/user-profile/user-profile-store.service';
import { getUserProfileApiState } from './modules/shared-area/ducks/user-profile/user-profile.selectors';
import { UserDucks } from './modules/shared-area/ducks/user/user.ducks';
import { IAppState } from './modules/shared-area/root-state';
import { IntlService } from '@progress/kendo-angular-intl';
import { IView } from 'app/modules/shared-area/ducks/panel/models/IView';
import { environment } from '@environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'app';
  private namedViews$: Observable<IViews> = EMPTY;
  public toggle = false;
  private userProfileIsLoaded$: Observable<Boolean> = EMPTY;
  private userIsLoaded$: Observable<Boolean> = EMPTY;
  private subscriptions: Subscription[] = [];

  public get Initialized(): boolean {
    return this._initialized;
  }

  private subscriptionToggle: Subscription;
  private onAuthorizationResult: Subscription;
  private onModuleSetup: Subscription;
  private getIsAuthorized: Subscription;
  private _initialized = false;

  public maintenance$: Observable<any>;

  constructor(public globalService: GlobalService,
              private translate: TranslateService,
              private i18n: I18nService,
              public oidcSecurityService: OidcSecurityService,
              private storageService: StorageService,
              private router: Router,
              private userProfileStore: UserProfileStore,
              @Inject(UserDucks) private userDucks: Duck<UserDucks>,
              private store: Store<IAppState>,
              private intlService: IntlService,
              private location: Location) {
    this.maintenance$ = globalService.maintenance.asObservable();

    this.handleSaveClientInfo();

    this.userProfileIsLoaded$ = this.store.pipe(
      select(getUserProfileApiState),
      map(apistate => apistate === 'Success',
      ));

    this.getIsAuthorized = this.oidcSecurityService.isAuthenticated$
                               .pipe(
                                 filter(isAuthorized => isAuthorized),
                                 withLatestFrom(this.userProfileIsLoaded$),
                                 first(),
                                 tap(([ isAuthorized, isLoaded ]) => {
                                     if (!isLoaded) {
                                       this.userDucks.loadUser.dispatch();
                                       this.userProfileStore.effects.loadUserProfilesAsync();
                                     }
                                   },
                                 ),
                               )
                               .subscribe();

    this.userIsLoaded$ = this.store.pipe(
      select(state => state.shared.user.userData),
      filter(user => !!user),
      map(user => !!user),
    );

    this.subscriptionToggle = this.globalService.event.subscribe((event) => {
      // this.toggle = event;
    });
    this.initAndLoadLanguage();
    this.onResize();
  }

  ngOnInit(): void {
    this.onAuthorizationResult = this.oidcSecurityService
                                     .checkAuth()
                                     .subscribe(
                                       isAuthenticated => this.onAuthorizationResultComplete(isAuthenticated),
                                       () => this.oidcSecurityService.logoff(),
                                     );
  }

  public handleSaveClientInfo() {
    this.namedViews$ = this.store.pipe(select(getNamedViews));
    const apiState$ = this.store.pipe(select(getUserProfileApiState));
    this.subscriptions.push(
      this.namedViews$.pipe(
        withLatestFrom(apiState$),
        filter(([ views, apiState ]) => apiState === 'Success'),
        tap(([ views, apiState ]) => {
          const clientInfo = new ClientInfo();
          const userViews = _.omitBy(views, (view: { view: IView }) => view.view.isSystem);
          clientInfo.namedViews = userViews;
          this.userProfileStore.effects.saveClientInfoAsync(clientInfo);
        }))
          .subscribe());
  }

  onResize() {
    if (document.body.clientWidth <= 990) {
      this.toggle = true;
    } else {
      this.toggle = false;
    }
  }

  initAndLoadLanguage() {
    this.i18n.init();
    this.userIsLoaded$.pipe(
      first(),
      tap(() => {
        console.log('UBA Cheminfo Version: ' + environment.version);
        this._initialized = true;
      }))
        .subscribe();
  }

  ngOnDestroy() {
    this.subscriptionToggle.unsubscribe();
    if (this.onModuleSetup != null) {
      this.onModuleSetup.unsubscribe();
    }
    this.onAuthorizationResult.unsubscribe();
    this.getIsAuthorized.unsubscribe();

    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }

  private onAuthorizationResultComplete(isAuthenticated: boolean) {
    if (isAuthenticated) {
      const returnUrl: string = this.storageService.getItem('returnUrl');
      if (!_.isNil(returnUrl)) {
        this.storageService.removeItem('returnUrl');
        setTimeout(() => this.router.navigateByUrl(returnUrl), 0);
      }
      return;
    }
    // silent renew failed (session cookie is timed out)
    this.storageService.setItem('returnUrl', this.location.path());
    this.router.navigate([ '/login' ]);
  }

  formatDate(date: any): string {
    return this.intlService.formatDate(this.intlService.parseDate(date), 'g');
  }

  public signOut() {
    this.oidcSecurityService.logoff();
  }
}
