import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { IUser } from 'app/modules/shared-area/ducks/user/models/IUser';

@Directive({
  selector: '[appHasRight]',
})
export class HasRightDirective implements OnInit, OnDestroy {

  private currentUser;
  private rights: number[];
  private getUserDataSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private oidcSecurityService: OidcSecurityService,
  ) {
  }

  ngOnInit() {
    this.getUserDataSubscription = this.oidcSecurityService.userData$.subscribe((user: IUser) => {
      this.currentUser = user;
      this.updateView();
    });
  }

  ngOnDestroy() {
    if (this.getUserDataSubscription) {
      this.getUserDataSubscription.unsubscribe();
    }
  }

  @Input()
  set appHasRight(val) {
    this.rights = val;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    const user = this.currentUser;
    return user && user.right && _.isArray(this.rights) && this.rights.find(right => (_.isArray(user.right) && user.right.indexOf(right.toString()) >= 0 || user.right === right.toString()));
  }
}
