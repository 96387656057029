import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DossierAreaState } from 'app/modules/dossier-area/dossier-area.module';

const dossierAreaState = createFeatureSelector<DossierAreaState>('dossier-area');

export const isBusy = createSelector(dossierAreaState, state => state.merge.isBusy);
export const canMerge = createSelector(dossierAreaState, state => state.merge.canMerge);
export const getResult = createSelector(dossierAreaState, state => state.merge.result);
export const getId = createSelector(dossierAreaState, state => state.merge.id);
export const getAdditionalId = createSelector(dossierAreaState, state => state.merge.additionalId);
