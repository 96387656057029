import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiClient } from 'app/core/services/api-client/index';

export { API_BASE_URL } from './api-client';

@NgModule({
  imports: []
})
export class ApiClientModule {
  public static forRoot(): ModuleWithProviders<ApiClientModule> {
    return {
      providers: [ ApiClient ],
      ngModule: ApiClientModule
    };
  }
}
