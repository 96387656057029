import { registerLocaleData } from '@angular/common';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import { APP_INITIALIZER, Inject, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@angular/compiler';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MarkdownModule } from 'ngx-markdown';
// I18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
// Kendo UI
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/de/all';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadModule } from '@progress/kendo-angular-upload';
import { AuthorizationModule } from 'app/core/authorization/authorization.module';
import { InterceptorsForRootModule } from 'app/core/interceptors/interceptors-for-root.module';
import { NavbarModule } from 'app/core/layout/navbar/navbar.module';
import { API_BASE_URL } from 'app/core/services/api-client';
import { ApiClientModule } from 'app/core/services/api-client/api-client.module';
import { CoreServicesForRootModule } from 'app/core/services/core-services.module';
import { FileDialogComponent } from 'app/modules/dossier-area/entry-components/file-dialog/file-dialog.component';
import { FileDialogModule } from 'app/modules/dossier-area/entry-components/file-dialog/file-dialog.module';
import { StruktursucheDialogComponent } from 'app/modules/search-area/entry-components/struktursuche-dialog/struktursuche-dialog.component';
import { StruktursucheDialogModule } from 'app/modules/search-area/entry-components/struktursuche-dialog/struktursuche-dialog.module';
import { ErfassungenEffects, ErfassungenEffectsModule } from 'app/modules/shared-area/ducks/erfassungen/erfassungen.effects';
import { UserEffects, UserEffectsModule } from 'app/modules/shared-area/ducks/user/user.effects';
import { SachverhaltCreateComponent } from 'app/modules/shared-area/entry-components/sachverhalt/sachverhalt-create/sachverhalt-create.component';
import { SachverhaltCreateModule } from 'app/modules/shared-area/entry-components/sachverhalt/sachverhalt-create/sachverhalt-create.module';
import { SachverhaltEditComponent } from 'app/modules/shared-area/entry-components/sachverhalt/sachverhalt-edit/sachverhalt-edit.component';
import { SachverhaltEditModule } from 'app/modules/shared-area/entry-components/sachverhalt/sachverhalt-edit/sachverhalt-edit.module';
import { SettingsModule } from 'app/modules/shared-area/pages/settings-page/settings.module';
import { REDUCER_TOKEN, reducerProvider } from 'app/modules/shared-area/root-state';
// Helpers
import { SharedAreaModule } from 'app/modules/shared-area/shared-area.module';
import { ActionbarModule } from 'app/shared/components/actionbar/actionbar.module';
import { RangeFieldModule } from 'app/shared/components/range-field/range-field.module';
import { HasrightModule } from 'app/shared/directives/hasright/hasright.module';
import { NotHasRightModule } from 'app/shared/directives/nothasright/nothasright.module';
import { IsPanelVisibleModule } from 'app/shared/directives/is-panel-visible/is-panel-visible.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { ToastaModule } from 'ngx-toasta';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserProfileDucks } from './modules/shared-area/ducks/user-profile/user-profile.ducks';
import { UserDucks } from './modules/shared-area/ducks/user/user.ducks';
import { SelectMerkmalDialogComponent } from './modules/shared-area/entry-components/select-merkmal-dialog/select-merkmal-dialog.component';
import { SelectMerkmalDialogModule } from './modules/shared-area/entry-components/select-merkmal-dialog/select-merkmal-dialog.module';
import { CommonFeedbackComponent } from 'app/shared/entry-components/common-feedback/common-feedback.component';
import { StoffFeedbackComponent } from 'app/shared/entry-components/stoff-feedback/stoff-feedback.component';
import { StoffFeedbackModule } from 'app/shared/entry-components/stoff-feedback/stoff-feedback.module';
import { CommonFeedbackModule } from 'app/shared/entry-components/common-feedback/common-feedback.module';
import { DossiersEffects, DossiersEffectsModule } from './modules/shared-area/ducks/dossiers/dossiers.effects';
import { BatchDialogModule } from './modules/search-area/components/batch/batch-dialog.module';
import { BatchDialogComponent } from './modules/search-area/components/batch/batch-dialog.component';
import { BatchSachverhaltCreateDialogComponent } from './modules/search-area/components/batch/batch-sachverhalt-create-dialog.component';
import { BatchSachverhaltCreateDialogModule } from './modules/search-area/components/batch/batch-sachverhalt-create-dialog.module';
import { BatchDeleteDialogModule } from './modules/search-area/components/batch/batch-delete-dialog.module';
import { BatchDeleteDialogComponent } from './modules/search-area/components/batch/batch-delete-dialog.component';
import { ReferencesDialogComponent } from './modules/dossier-area/components/references/references-dialog.component';
import { ReferencesDialogModule } from './modules/dossier-area/components/references/references-dialog.module';
import { SystemInfoEffects, SystemInfoEffectsModule } from './modules/shared-area/ducks/system-info/system-info.effects';
import { SystemInfoServiceModule } from './core/services/system-info/system-info-service.module';
import { FeedbackServiceModule } from 'app/core/services/feedback/feedback-service.module';
import { FeedbackEffects, FeedbackEffectsModule } from 'app/modules/shared-area/ducks/feedback/feedback.effects';
import { ValidationRulesEditComponent } from './modules/admin-area/components/validation-rules-edit/validation-rules-edit.component';
import { ValidationRulesEditModule } from './modules/admin-area/components/validation-rules-edit/validation-rules-edit.module';
import { WindowEffects, WindowEffectsModule } from './modules/shared-area/ducks/window/window.effect';
import { MergeDialogComponent } from './modules/dossier-area/components/merge/merge-dialog.component';
import { MergeDialogModule } from './modules/dossier-area/components/merge/merge-dialog.module';
import { WartungsarbeitenServiceModule } from './core/services/wartungsarbeiten-service/wartungsarbeiten-service.module';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { DynamicLocaleId } from 'app/core/services/i18n/dynamicLocaleId';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { CustomTranslateLoader } from 'app/core/translation/custom-translate-loader';
import { Duck } from '@ngrx-ducks/core';
import { EditLookupTableModule } from './modules/shared-area/entry-components/edit-lookup-table/edit-lookup-table.module';
import { EditLookupTableComponent } from './modules/shared-area/entry-components/edit-lookup-table/edit-lookup-table.component';
import { CopySachverhalteComponent } from 'app/modules/dossier-area/entry-components/copy-sachverhalte/copy-sachverhalte.component';
import { CopySachverhalteModule } from 'app/modules/dossier-area/entry-components/copy-sachverhalte/copy-sachverhalte.module';
import { ThesaurusRulesEditComponent } from './modules/admin-area/components/thesaurus-rules-edit/thesaurus-rules-edit.component';
import { ThesaurusRulesEditModule } from './modules/admin-area/components/thesaurus-rules-edit/thesaurus-rules-edit.module';
import { RankingRulesEditModule } from './modules/admin-area/components/ranking-rules-edit/ranking-rules-edit.module';
import { RankingRulesEditComponent } from './modules/admin-area/components/ranking-rules-edit/ranking-rules-edit.component';
import { ConfigurationsEditModule } from './modules/admin-area/components/configurations-edit/configurations-edit.module';
import { ConfigurationsEditComponent } from './modules/admin-area/components/configurations-edit/configurations-edit.component';
import { RankingAuftragResultComponent } from './modules/admin-area/entry-components/ranking-auftrag-result/ranking-auftrag-result.component';
import { RankingAuftragResultModule } from './modules/admin-area/entry-components/ranking-auftrag-result/ranking-auftrag-result.module';
import { ExportAuftraegeEditModule } from './modules/admin-area/components/export-auftraege-edit/export-auftraege-edit.module';
import { ExportAuftraegeEditComponent } from './modules/admin-area/components/export-auftraege-edit/export-auftraege-edit.component';
import { ExportAuftraegeDownloadComponent } from './modules/admin-area/components/export-auftraege-download/export-auftraege-download.component';
import { ExportAuftraegeDownloadModule } from './modules/admin-area/components/export-auftraege-download/export-auftraege-download.module';
import { ImportAuftraegeLogModule } from './modules/admin-area/components/import-auftraege-log/import-auftraege-log.module';
import { ImportAuftraegeLogComponent } from './modules/admin-area/components/import-auftraege-log/import-auftraege-log.component';
import { ImportAuftraegeEditComponent } from './modules/admin-area/components/import-auftraege-edit/import-auftraege-edit.component';
import { ImportAuftraegeEditModule } from './modules/admin-area/components/import-auftraege-edit/import-auftraege-edit.module';
import { AuthModule, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';

// Interceptors

registerLocaleData(localeDe, localeDeExtra);
registerLocaleData(localeEn, localeEnExtra);

export function configureAuth(oidcConfigService: OidcConfigService) {
  return () => {

    const auth = environment.authentication;
    let silentRenewUrl = auth.redirect_url;
    silentRenewUrl += (silentRenewUrl.endsWith('/') ? '' : '/') + 'assets/silent_renew.html';

    return oidcConfigService.withConfig({
      triggerAuthorizationResultEvent: true,
      stsServer: auth.stsServer,
      redirectUrl: auth.redirect_url,
      // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer identified by the iss (issuer) Claim as an audience.
      // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience, or if it contains additional audiences not trusted by the Client.
      clientId: 'chemInfoSpaClient',
      scope: 'openid cheminfo.api cheminfo.profile',
      postLogoutRedirectUri: auth.post_logout_redirect_uri,
      postLoginRoute: '/',
      startCheckSession: false,
      responseType: 'id_token token',
      forbiddenRoute: '/forbidden',
      unauthorizedRoute: '/unauthorized',
      logLevel: auth.log_console_debug_active ? LogLevel.Debug : (auth.log_console_warning_active ? LogLevel.Warn : LogLevel.Error),
      silentRenew: true,
      renewTimeBeforeTokenExpiresInSeconds: 30,
      silentRenewUrl: silentRenewUrl,
      // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
      // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.
      maxIdTokenIatOffsetAllowedInSeconds: 60,
    });
  };
}

const effects = [
  DossiersEffectsModule,
  ErfassungenEffectsModule,
  FeedbackEffectsModule,
  SystemInfoEffectsModule,
  UserEffectsModule,
  WindowEffectsModule,
  EffectsModule.forRoot([
    DossiersEffects,
    ErfassungenEffects,
    FeedbackEffects,
    SystemInfoEffects,
    UserEffects,
    WindowEffects,
  ]),
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  entryComponents: [
    CommonFeedbackComponent,
    SelectMerkmalDialogComponent,
    BatchDialogComponent,
    BatchSachverhaltCreateDialogComponent,
    BatchDeleteDialogComponent,
    ReferencesDialogComponent,
    MergeDialogComponent,
    FileDialogComponent,
    CopySachverhalteComponent,
    SachverhaltCreateComponent,
    SachverhaltEditComponent,
    StoffFeedbackComponent,
    StruktursucheDialogComponent,
    ValidationRulesEditComponent,
    EditLookupTableComponent,
    ThesaurusRulesEditComponent,
    RankingRulesEditComponent,
    ExportAuftraegeEditComponent,
    ConfigurationsEditComponent,
    RankingAuftragResultComponent,
    ExportAuftraegeDownloadComponent,
    ImportAuftraegeLogComponent,
    ImportAuftraegeEditComponent,
  ],
  imports: [
    MarkdownModule.forRoot(),
    FileDialogModule,
    CopySachverhalteModule,
    SachverhaltCreateModule,
    SachverhaltEditModule,
    StoffFeedbackModule,
    EditLookupTableModule,
    StruktursucheDialogModule,
    SelectMerkmalDialogModule,
    BatchDialogModule,
    BatchSachverhaltCreateDialogModule,
    BatchDeleteDialogModule,
    ReferencesDialogModule,
    MergeDialogModule,
    SettingsModule,
    CommonFeedbackModule,
    RangeFieldModule,
    SharedAreaModule,
    ValidationRulesEditModule,
    ThesaurusRulesEditModule,
    RankingRulesEditModule,
    ConfigurationsEditModule,
    RankingAuftragResultModule,
    ExportAuftraegeEditModule,
    ExportAuftraegeDownloadModule,
    ImportAuftraegeLogModule,
    ImportAuftraegeEditModule,
    ApiClientModule.forRoot(),
    AuthorizationModule.forRoot(),
    ToastaModule.forRoot(),
    AuthModule.forRoot(),
    FeedbackServiceModule.forRoot(),
    SystemInfoServiceModule.forRoot(),
    WartungsarbeitenServiceModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: AppModule.GetTranslationLoader,
        deps: [HttpClient]
      },
    }),
    StoreModule.forRoot(REDUCER_TOKEN, {
      runtimeChecks: {
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionWithinNgZone: false,
      },
    }),
    CoreServicesForRootModule,
    InterceptorsForRootModule,
    ...effects,
    NavbarModule,
    IsPanelVisibleModule,
    ActionbarModule,
    HasrightModule,
    NotHasRightModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GridModule,
    DropDownsModule,
    DatePickerModule,
    InputsModule,
    WindowModule,
    DialogModule,
    ButtonsModule,
    TooltipModule,
    TreeViewModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot(),
    UploadModule,
    IntlModule,

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'Comparex ChemInfo',
      logOnly: environment.production,
    }),
  ],
  providers: [
    reducerProvider,
    {
      provide: LOCALE_ID,
      useClass: DynamicLocaleId,
      deps: [I18nService],
    },
    Title,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useFactory: AppModule.apiClientUrlFactory,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private oidcConfigService: OidcConfigService,
    @Inject(UserProfileDucks) private userProfileDucks: Duck<UserProfileDucks>,
    @Inject(UserDucks) private userDucks: Duck<UserDucks>,
  ) {
  }

  public static GetTranslationLoader(http: HttpClient): TranslateLoader {
    return new CustomTranslateLoader(http);
  }

  public static apiClientUrlFactory(): string {
    return environment.baseAPIUrl;
  }
}
