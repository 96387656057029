<div class="feedback-stoff"
     *ngIf="!isEditMode; else editView">
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label feedback-stoff-column__label--required"
           [translate]="'components.stofffeedback.subject'"></label>
    <input autoFocus
           class="k-textbox"
           placeholder="{{'components.stofffeedback.subject' | translate}}"
           [(ngModel)]="createFeedbackItem.subject"
           name="dossier_fb_subject" />
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label feedback-stoff-column__label--required"
           [translate]="'components.stofffeedback.comment'"></label>
    <textarea class="k-textarea"
              placeholder="{{'components.stofffeedback.comment' | translate}}"
              [(ngModel)]="createFeedbackItem.description"
              name="dossier_fb_comment"></textarea>
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label"
           [translate]="'components.stofffeedback.context'"></label>
    <input class="k-textbox"
           [disabled]="true"
           value="{{ ('components.stofffeedback.contexts.'+ createFeedbackItem.objektTyp) | translate}}"
           name="context" />
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label"
           [translate]="'components.stofffeedback.contextNr'"></label>
    <input class="k-textbox"
           [disabled]="true"
           [value]="createFeedbackItem.objektId"
           name="contextNr" />
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label feedback-stoff-column__label--required"
           [translate]="'components.stofffeedback.attribute'"></label>
    <input class="k-textbox"
           [disabled]="true"
           [value]="createFeedbackItem.merkmal ? createFeedbackItem.merkmal : ''"
           name="merkmal" />
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label feedback-stoff-column__label--required"
           [translate]="'components.stofffeedback.issueNumbers'"></label>
    <input class="k-textbox"
           [disabled]="true"
           [value]="createFeedbackItem.issueIds ? createFeedbackItem.issueIds.join(', ') : ''"
           name="issueNumbers" />
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label feedback-stoff-column__label--required"
           [translate]="'components.stofffeedback.category'"></label>
    <kendo-dropdownlist [data]="severityItems"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [valuePrimitive]="true"
                        [(ngModel)]="createFeedbackItem.category"
                        [disabled]="isEditMode">
    </kendo-dropdownlist>
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label feedback-stoff-column__label--required"
           [translate]="'models.feedback.priority'"></label>
    <kendo-dropdownlist [data]="priorityItems"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [valuePrimitive]="true"
                        [(ngModel)]="createFeedbackItem.priority">
    </kendo-dropdownlist>
  </div>
  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label"
           [translate]="'components.stofffeedback.attachments'"></label>
    <kendo-upload [(ngModel)]="files"
                  [saveUrl]="uploadSaveUrl"
                  [removeUrl]="uploadRemoveUrl"
                  [restrictions]="fileRestrictions"
                  [withCredentials]="false"
                  [disabled]="isEditMode">
      <kendo-upload-messages [cancel]="'upload.messages.cancel' | translate"
                             [clearSelectedFiles]="'upload.messages.clearSelectedFiles' | translate"
                             [dropFilesHere]="'upload.messages.dropFilesHere' | translate"
                             [headerStatusUploaded]="'upload.messages.headerStatusUploaded' | translate"
                             [headerStatusUploading]="'upload.messages.headerStatusUploading' | translate"
                             [invalidFileExtension]="'upload.messages.invalidFileExtension' | translate"

                             [invalidMaxFileSize]="'upload.messages.invalidMaxFileSize' | translate"
                             [invalidMinFileSize]="'upload.messages.invalidMinFileSize' | translate"
                             [remove]="'upload.messages.remove' | translate"
                             [retry]="'upload.messages.retry' | translate"
                             [select]="'upload.messages.select' | translate"
                             [uploadSelectedFiles]="'upload.messages.uploadSelectedFiles' | translate"></kendo-upload-messages>
    </kendo-upload>
  </div>

  <div class="feedback-stoff-column">
    <label class="k-form-field feedback-stoff-column__label"
           [translate]="'components.systemfeedback.emailExpected'"></label>
           <div style="width: 100%">
              <input type="checkbox" class="k-checkbox" [(ngModel)] = "createFeedbackItem.emailExpected" [ngModelOptions]="{standalone: true}"
                  name="cb_emailExpected" id="cb_emailExpected" style="vertical-align: bottom; margin: 0;" />
                  <label class="k-checkbox-label"></label>
          </div>
  </div>

  <div class="feedback-stoff-column feedback-stoff-column--buttongrp">
    <button (click)="cancel()"
            class="btn btn-default">{{'components.stofffeedback.cancel' | translate}}</button>
    <button (click)="sendToApi()"
            [disabled]="!canSend"
            class="btn btn-primary">
      <i class="far fa-paper-plane"></i> {{'components.stofffeedback.send' | translate}}
    </button>
  </div>
</div>

<!-- view of already created Stofffeedback -->
<ng-template #editView>
  <div class="feedback-stoff feedback-stoff--edit">
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.subject'"></label>
      <div class="feedback-stoff-column__value">{{ feedbackItem.subject }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.category'"></label>
      <div class="feedback-stoff-column__value">{{ 'components.stofffeedback.severities.' + feedbackItem.category |
        translate }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'models.feedback.creator'"></label>
      <div class="feedback-stoff-column__value">{{ feedbackItem.createdBy }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'models.feedback.creationdate'"></label>
      <div class="feedback-stoff-column__value">{{ feedbackItem.createdOn | kendoDate: 'G' }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.comment'"></label>
      <div class="feedback-stoff-column__value">{{ feedbackItem.description }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'models.feedback.priority'"></label>
      <div class="feedback-stoff-column__value">{{ 'models.feedback.priorities.' + feedbackItem.priority | translate }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.context'"></label>
      <div class="feedback-stoff-column__value">{{ ('components.stofffeedback.contexts.'+ feedbackItem.objektTyp) |
        translate}}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.contextNr'"></label>
      <div class="feedback-stoff-column__value">
        <a [href]="feedbackItem.url" target="_blank"><i class="fas fa-external-link-alt"></i> <span>{{ feedbackItem.objektId }}</span></a>
        </div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.attribute'"></label>
      <div class="feedback-stoff-column__value">{{ feedbackItem.merkmal }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.issueNumbers'"></label>
      <div class="feedback-stoff-column__value">{{ feedbackItem.issueIds }}</div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.attachments'"></label>
      <div class="feedback-stoff-column__value">
        <a *ngFor="let file of feedbackItem.files"
           [attr.href]="fileUrl(file.url)"><i class="fas fa-download"></i> {{file.fileName}}
        </a>
      </div>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.state.label'"></label>
      <kendo-dropdownlist [data]="feedbackState"
                          id="change-state"
                          [valuePrimitive]="true"
                          [value]="feedbackItem.state"
                          (valueChange)="updateStoffFeedbackState($event)">
        <ng-template kendoDropDownListItemTemplate
                     let-dataItem>
          {{ 'models.feedback.state.' + dataItem.toLowerCase() | translate}}
        </ng-template>
        <ng-template kendoDropDownListValueTemplate
                     let-dataItem>
          {{ 'models.feedback.state.' + dataItem.toLowerCase() | translate}}
        </ng-template>
      </kendo-dropdownlist>
    </div>
    <!-- History -->
    <div class="feedback-stoff-column feedback-stoff-column--top">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.comments.history'"></label>
      <div *ngIf="feedbackItem.history && feedbackItem.history.length > 0; else noHistory"
           class="feedback-stoff-column__value">
        <div *ngFor="let historyItem of feedbackItem.history"
             class="feedback-stoff-column__comment">
          <div class="feedback-stoff-column__comment--text">{{historyItem.comment}}</div>
          <div class="feedback-stoff-column__comment--by">
            <b>{{'components.stofffeedback.comments.created' | translate}}</b> {{historyItem.createdOn | kendoDate:
            'G'}} <b>{{'components.stofffeedback.comments.by' | translate}}</b> {{historyItem.createdBy}}
            <b>{{'components.stofffeedback.comments.state' | translate}}</b> {{'models.feedback.state.' + historyItem.state.toLowerCase() | translate}}
          </div>
        </div>
      </div>
      <ng-template #noHistory>
        <div class="feedback-stoff-column__value">{{ 'components.stofffeedback.comments.emptyHistory' | translate }}</div>
      </ng-template>
    </div>
    <!-- Comment Container -->
    <div class="feedback-stoff-column feedback-stoff-column--top">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.stofffeedback.comments.label'"></label>
      <textarea class="k-textarea"
                #commentInput
                placeholder="{{'components.stofffeedback.comments.placeholder' | translate}}"></textarea>
    </div>
    <div class="feedback-stoff-column">
      <label class="k-form-field feedback-stoff-column__label"
             [translate]="'components.systemfeedback.emailExpected'">
            </label>
             <div style="width: 100%"> <input
             type="checkbox"
             class="k-checkbox"
             [(ngModel)] = "feedbackItem.emailExpected"
             [ngModelOptions]="{standalone: true}"
             name="cb_emailExpected"
             style="vertical-align: bottom; margin: 0;"
             id="cb_emailExpected" />
             <label class="k-checkbox-label"></label>
            </div>
    </div>

    <div class="feedback-stoff-column feedback-stoff-column--buttongrp">
      <button class="btn btn-default"
              (click)="closeWindow.emit(null)">{{'components.stofffeedback.cancel' | translate}}</button>
      <button class="btn btn-primary"
              (click)="updateFeedback()"
              [disabled]="!canUpdate()">
        <i class="far fa-paper-plane"></i> {{'components.stofffeedback.send' | translate}}
      </button>
    </div>
  </div>
</ng-template>
