import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReferenceLinkComponent } from './reference-link.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { DossierHelperModule } from '../../services/dossier-helper/dossier-helper.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    I18nModule,
    DossierHelperModule
  ],
  declarations: [ ReferenceLinkComponent ],
  exports: [ ReferenceLinkComponent ]
})
export class ReferenceLinkModule {}
