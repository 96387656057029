import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { IDossiersState } from './dossiers.state';
import { IDeleteDossiersRef } from '@models/deleteDossiersRef';
import { BatchResult } from 'app/core/services/api-client';

export * from './dossiers.state';

@Ducksify<IDossiersState>({
  initialState: {
    isDeletingDossiers: false,
  },
})
export class DossiersDucks {
  @Action('[Dossiers] Lösche Dossiers')
  public deleteDossiers(state: IDossiersState, deleteDossiers: IDeleteDossiersRef): typeof state {
    return {
      ...state,
      isDeletingDossiers: true,
    };
  }

  @Action('[Dossiers] Lösche Dossiers fehlgeschlagen (Success)')
  public deleteDossiersSuccessWithErrors(state: IDossiersState, result: BatchResult): typeof state {
    return {
      ...state,
    };
  }

  @Action('[Dossiers] Lösche Dossiers (Success)')
  public deleteDossiersSuccess(state: IDossiersState): typeof state {
    return {
      ...state,
      isDeletingDossiers: false,
    };
  }

  @Action('[Dossiers] Lösche Dossiers (Error)')
  public deleteDossiersError(state: IDossiersState): typeof state {
    return {
      ...state,
      isDeletingDossiers: false,
    };
  }
}

export function dossiersReducer(state: IDossiersState, action: DucksifiedAction): typeof state {
  return reducerFrom(DossiersDucks)(state, action);
}

