import { Inject, Injectable, NgModule } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DossiersDucks } from './dossiers.ducks';
import { BatchService } from 'app/core/services/batch/batch.service';
import { IDeleteDossiersRef } from '@models/deleteDossiersRef';
import { BatchMode2, BatchResult } from 'app/core/services/api-client';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { BatchModule } from 'app/core/services/batch/batch.module';
import { ActionOf } from 'app/core/utils/action-of';

@NgModule({
  imports: [
    // CustomToastyModule, // import throws exception caused by a Toasty bug
    BatchModule,
  ],
})
export class DossiersEffectsModule {}

@Injectable()
export class DossiersEffects {
  constructor(private actions$: Actions,
    @Inject(DossiersDucks) private dossiersDucks: Duck<DossiersDucks>,
    private toastyService: CustomToastyService,
    private batchService: BatchService) {
  }

  @Effect()
  public deleteDossiers$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.dossiersDucks.deleteDossiers.type),
      map((action: any) => action.payload as IDeleteDossiersRef),
      switchMap((payload) => this.batchService.delete(payload.ids, payload.message, BatchMode2.FailOnAnyErrors)
        .pipe(
          map(result => {
            if (result.errors && result.errors.length > 0) {
              return this.dossiersDucks.deleteDossiersSuccessWithErrors.action(result);
            } else {
              return this.dossiersDucks.deleteDossiersSuccess.action();
            }
          }),
          catchError(error => of(this.dossiersDucks.deleteDossiersError.action())),
        ),
      ),
    );

  @Effect({ dispatch: false })
  public deleteDossiersSuccess$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.dossiersDucks.deleteDossiersSuccess.type),
      tap((action: ActionOf<BatchResult>) => {
        this.toastyService.toastSuccessFormatted('components.dossier.deleteDossierSuccessTitle', 'components.dossier.deleteDossierSuccessMessage', {});
      }),
    );

  @Effect({ dispatch: false })
  public deleteDossiersSuccessWithErrors$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.dossiersDucks.deleteDossiersSuccessWithErrors.type),
      tap((action: ActionOf<BatchResult>) => {
        this.toastyService.toastErrorFormatted('components.dossier.deleteDossierErrorTitle', 'components.dossier.deleteDossierErrorMessage', {});
      }),
    );
}
