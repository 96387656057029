import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Merkmal } from '@models/merkmalbaum';
import { ApiClient } from 'app/core/services/api-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomToastyService } from '../customToasty/custom-toasty.service';

@Injectable()
export class MerkmalService {

  public constructor(private http: HttpClient,
                     private customToastyService: CustomToastyService,
                     private apiClient: ApiClient,
                     private errorHandler: ApiErrorHandlerService) { }

  public GetMerkmalTree(): Observable<Merkmal[]> {
    return this.apiClient.apiMerkmalBaumGet()
               .pipe
               (
                 catchError(this.apiClient.apiMerkmalBaumGetErrorHandlers({
                   error401Handler: error401 => this.errorHandler.handle401Error(error401)
                 })),
                 map(merkmalBaum => merkmalBaum.map(merkmal => Merkmal.fromDto(merkmal)))
               );
  }

  public loadLockedMerkmale(): Observable<any> {
    const url = `${environment.baseAPIUrl}${environment.batchGesperrteMerkmale}`;
    return this
      .http
      .get(url)
      .pipe(catchError((error: any) => {
        console.log(error);
        this
          .customToastyService
          .toastError(error);
        return observableThrowError(error.error.message || error);
      }));
  }
}
