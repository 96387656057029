import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAdminAreaState } from 'app/modules/admin-area/admin-area.module';

const adminAreaState = createFeatureSelector<IAdminAreaState>('admin-area');
const rankingRulesPage = createSelector(adminAreaState, state => state.rankingRulesPage);
export const getRankingRules = createSelector(rankingRulesPage, state => state.rankingRules);
export const isRankingRulesBusy = createSelector(rankingRulesPage, state => state.isBusy);
export const selectedRankingRule = createSelector(rankingRulesPage, state => state.selectedRankingRule);
export const selectRankingRulesLastModified = createSelector(rankingRulesPage, state => state.lastModified);
export const getMerkmaleDictionary = createSelector(rankingRulesPage, state => state.merkmale);
export const getAllSuppliers = createSelector(rankingRulesPage, state => state.suppliers);
export const getAllLookupTables = createSelector(rankingRulesPage, state => state.lookupTables);
