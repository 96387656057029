import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { RankingRulesState } from './ranking-rules.state';
import { RankingRule, RankingRuleType } from 'app/core/services/api-client';

@Ducksify<RankingRulesState>({
  initialState: {
    rankingRules: [],
    isBusy: false,
    lastModified: undefined,
    selectedRankingRule: null,
    merkmale: {},
    suppliers: [],
    lookupTables: []
  },
})
export class RankingRulesDucks {
  public readonly getAllRankingRulesEffect = effect<void>('[Ranking rules (Effect)] Load all ranking rules');
  public readonly updateRankingRuleEffect = effect<RankingRule>('[Ranking rules (Effect)] Update a ranking rule');
  public readonly saveRankingRuleEffect = effect<RankingRule>('[Ranking rules (Effect)] Saves a ranking rule');
  public readonly deleteRankingRuleEffect = effect<string>('[Ranking rules (Effect)] Deletes a ranking rule');
  public readonly getAllSuppliersEffect = effect<void>('[Ranking rules (Effect)] Load all suppliers');
  public readonly getAllLookupTablesEffect = effect<void>('[Ranking rules (Effect)] Load all lookup tables');

  @Action('[Ranking rules] Load all ranking rules')
  public getAllRankingRules(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
      lastModified: undefined
    };
  }

  @Action('[Ranking rules] Load all ranking rules (Success)')
  public getAllRankingRulesSuccess(state: RankingRulesState, rankingRules: RankingRule[]): typeof state {
    return {
      ...state,
      isBusy: false,
      rankingRules: rankingRules,
      lastModified: new Date()
    };
  }

  @Action('[Ranking rules] Load all ranking rules (Error)')
  public getAllRankingRulesError(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Ranking rules] Update a ranking rule')
  public updateRankingRule(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Ranking rules] Update a ranking rule (Success)')
  public updateRankingRuleSuccess(state: RankingRulesState, rule: RankingRule): typeof state {
    return {
      ...state,
      isBusy: false,
      rankingRules: updateRankingRule(state.rankingRules, rule),
    };
  }

  @Action('[Ranking rules] Update a ranking rule (Error)')
  public updateRankingRuleError(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Ranking rules] Add new ranking rule')
  public addRankingRule(state: RankingRulesState, ruleName: string): typeof state {
    return {
      ...state,
      selectedRankingRule: addNewRankingRule(ruleName)
    };
  }

  @Action('[Ranking rules] Sets the selected ranking rule')
  public setSelectedRankingRule(state: RankingRulesState, ruleName: string): typeof state {
    return {
      ...state,
      selectedRankingRule: state.rankingRules.filter(rule => rule.name === ruleName)[0],
    };
  }

  @Action('[Ranking rules] Saves a ranking rule')
  public saveRankingRule(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Ranking rules] Saves a ranking rule (Success)')
  public saveRankingRuleSuccess(state: RankingRulesState, savedRule: RankingRule): typeof state {
    return {
      ...state,
      isBusy: false,
      rankingRules: [...state.rankingRules, savedRule],
      selectedRankingRule: null
    };
  }

  @Action('[Ranking rules] Saves a ranking rule (Error)')
  public saveRankingRuleError(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Ranking rules] Deletes a ranking rule')
  public deleteRankingRule(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Ranking rules] Deletes a ranking rule (Success)')
  public deleteRankingRuleSuccess(state: RankingRulesState, ruleName: string): typeof state {
    return {
      ...state,
      isBusy: false,
      rankingRules: state.rankingRules.filter(rule => rule.name !== ruleName),
    };
  }

  @Action('[Ranking rules] Deletes a ranking rule (Error)')
  public deleteRankingRuleError(state: RankingRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Ranking rules] Set merkmale')
  public setMerkmale(state: RankingRulesState, merkmale: {[key: string]: string[]}): typeof state {
    return {
      ...state,
      merkmale: merkmale
    };
  }

  @Action('[Ranking rules] Load all suppliers')
  public getAllSuppliers(state: RankingRulesState): typeof state {
    return {
      ...state,
      suppliers: []
    };
  }

  @Action('[Ranking rules] Load all suppliers (Success))')
  public getAllSuppliersSuccess(state: RankingRulesState, suppliers: string[]): typeof state {
    return {
      ...state,
      suppliers: suppliers
    };
  }


  @Action('[Ranking rules] Load all lookup tables')
  public getAllLookupTables(state: RankingRulesState): typeof state {
    return {
      ...state,
      lookupTables: []
    };
  }

  @Action('[Ranking rules] Load all lookup tables (Success))')
  public getAllLookupTablesSuccess(state: RankingRulesState, lookupTables: string[]): typeof state {
    return {
      ...state,
      lookupTables: lookupTables
    };
  }




}

function updateRankingRule(rules: RankingRule[], update: RankingRule): RankingRule[] {
  const r = [...rules];
  const newRules = r.filter(rule => rule.name !== update.name);
  newRules.push(update);
  return newRules;
}

function addNewRankingRule(ruleName: string): RankingRule {
  return new RankingRule({
    name: ruleName,
    description: '',
    type: undefined, // RankingRuleType
    attribute: '',
    extremums: undefined, // Extremum[]
    table: '',
    valuePriorities: undefined, // ValuePriority[]
    supplierPriorities: undefined, // SupplierPriority[]
    numericConditions: undefined // NumericCondition[]
  });
}

export function rankingRulesReducer(state: RankingRulesState, action: DucksifiedAction): typeof state {
  return reducerFrom(RankingRulesDucks)(state, action);
}
