import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ToastaModule } from 'ngx-toasta';
import { I18nService } from '../i18n/i18n.service';
import { BatchService } from './batch.service';

@NgModule({
  imports: [
    // HttpClientModule.forChild(), // Interceptors does not work when specified,
    ToastaModule,
    I18nModule
  ],
})
export class BatchModule {
  public static forRoot(): ModuleWithProviders<BatchModule> {
    return {
      ngModule: BatchModule,
      providers: [ BatchService ],
    };
  }
}
