import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiClient, SystemInfo } from '../api-client';
import { ApiErrorHandlerService } from '../api-default-error-handler/api-error-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SystemInfoService {
  constructor(private http: HttpClient,
    private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService) {
  }

  public getSystemInfos(): Observable<SystemInfo> {
    return this.apiClient.apiSystemInfoGet()
      .pipe(
        catchError(this.apiClient.apiSystemInfoGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401)
        })),
      );
  }
}
