import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaintenanceInterceptor } from 'app/core/interceptors/maintenance.interceptor';

@NgModule({
  // imports: [RouterModule]
})
export class MaintenanceInterceptorModule {
  public static forRoot(): ModuleWithProviders<MaintenanceInterceptorModule> {
    return {
      ngModule: MaintenanceInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MaintenanceInterceptor,
          multi: true,
        }
      ]
    };
  }
}

