<div *ngIf="dataLoading$ | async; else content"
     style="text-align: center">
  <i class="k-icon k-i-loading"></i>
  <p>{{'components.copysachverhalte.copy_facts' | translate}}</p>
</div>

<ng-template #content>
  <div class="form">
    <p>{{'components.copysachverhalte.description' | translate}}</p>

    <div class="form__column">
      <label class="k-form-field form__column__label form__column__label--required"
             translate="components.copysachverhalte.target"></label>
      <textarea autofocus
                class="k-textarea"
                [formControl]="targetIdsFormControl"
                placeholder="{{'components.copysachverhalte.target_description' | translate}}"></textarea>
    </div>
    <p *ngIf="isContainingOwnObjectId$ | async"
       class="validation-error">{{'components.copysachverhalte.validationerror' | translate}}</p>
    <div class="form__column">
      <label class="k-form-field form__column__label form__column__label--required"
             translate="components.copysachverhalte.facts"></label>
      <textarea class="k-textarea"
                placeholder="{{'components.copysachverhalte.facts_description' | translate}}"
                disabled="disabled"
                [value]="copySachverhalteIdsText$ | async"></textarea>
    </div>

    <p class="success"
       *ngIf="(successfulIds$ | async).length > 0">
      {{'components.copysachverhalte.success_ids' | translate}} {{(successfulIds$ | async).join(', ')}}
    </p>
    <p class="error"
       *ngIf="(errorIds$ | async).length > 0">
      {{'components.copysachverhalte.error_ids' | translate}} {{(errorIds$ | async).join(', ')}}
    </p>

    <div class="form__column form__column--buttongrp">
      <button class="btn btn-default" (click)="windowService.close()">{{'components.copysachverhalte.cancel' | translate}}</button>
      <button class="btn btn-primary" (click)="copy()" [disabled]="!(isValid$ | async)">
        <i class="far fa-paper-plane"></i> {{'components.copysachverhalte.copy' | translate}}
      </button>
    </div>
  </div>
</ng-template>
