import { NgModule } from '@angular/core';

import { ActionbarComponent } from 'app/shared/components/actionbar/actionbar.component';

@NgModule({
  imports: [],
  declarations: [ ActionbarComponent ],
  exports: [ ActionbarComponent ]
})
export class ActionbarModule {}
