import { Inject, Injectable, NgModule } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DossierDucks } from 'app/modules/dossier-area/pages/dossier/ducks/dossier.ducks';
import { Duck } from '@ngrx-ducks/core';
import { CustomWindowService } from 'app/core/services/custom-window-service/custom-window.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store/public_api';
import { WindowDucks } from './window.ducks';
import { SachverhaltDucks } from 'app/modules/shared-area/entry-components/sachverhalt/ducks/sachverhalt.ducks';
import { WindowParams } from './windowParams';
import { CustomWindowModule } from 'app/core/services/custom-window-service/custom-window.module';
import { ActionOf } from 'app/core/utils/action-of';

@NgModule({
  imports: [
    CustomWindowModule,
  ],
})
export class WindowEffectsModule {}

@Injectable()
export class WindowEffects {
  constructor(
    private actions$: Actions,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
    @Inject(SachverhaltDucks) private sachverhaltDucks: Duck<SachverhaltDucks>,
    @Inject(DossierDucks) private dossierDucks: Duck<DossierDucks>,
    private windowService: CustomWindowService,
  ) { }

  @Effect({ dispatch: false })
  closeWindow$: Observable<any> = this
    .actions$
    .pipe(
      ofType(
        this.windowDucks.closeWindow.type,
        this.sachverhaltDucks.createSachverhaltAndCloseSuccess.type,
        this.dossierDucks.loadSubstanceSuccess.type,
      ),
      tap(() => this.windowService.close()),
    );

  @Effect({ dispatch: false })
  openWindow$: Observable<WindowParams> = this
    .actions$
    .pipe(
      ofType(this.windowDucks.openWindow.type),
      map((action: ActionOf<WindowParams>) => action.payload),
      tap(payload => this.windowService.open(payload)),
    );

  @Effect()
  closeWindowAndOpenNext$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.windowDucks.closeWindowAndOpenNext.type),
      map((action: ActionOf<WindowParams>) => action.payload),
      tap(payload => this.windowService.close()),
      map(payload => this.windowDucks.openWindow.action(payload)),
    );
}
