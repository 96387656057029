import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { IsPanelVisibleDirective } from 'app/shared/directives/is-panel-visible/is-panel-visible.directive';

@NgModule({
  imports: [ StoreModule ],
  declarations: [ IsPanelVisibleDirective ],
  exports: [ IsPanelVisibleDirective ]
})
export class IsPanelVisibleModule {}
