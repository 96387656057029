import {ModuleWithProviders, NgModule} from '@angular/core';
import {SystemInfoService} from './system-info-service.service';
import {ApiErrorHandlerServiceModule} from '../api-default-error-handler/api-error-handler.service.module';
import {ApiClientModule} from '../api-client/api-client.module';

@NgModule({
  imports: [
    ApiErrorHandlerServiceModule,
    ApiClientModule
  ],
})

export class SystemInfoServiceModule {
  public static forRoot(): ModuleWithProviders<SystemInfoServiceModule> {
    return {
      ngModule: SystemInfoServiceModule,
      providers: [ SystemInfoService ],
    };
  }
}
