import { Inject, Injectable, NgModule } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ErfassungInfoService } from 'app/core/services/erfassung-info/erfassung-info.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErfassungenDucks } from './erfassungen.ducks';
import { ErfassungListItem, Mode, RequestForApprovalBatch } from 'app/core/services/api-client/api-client';
import { ErfassungInfoModule } from 'app/core/services/erfassung-info/erfassung-info.module';
import { ActionOf } from 'app/core/utils/action-of';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';

@NgModule({
  imports: [ ErfassungInfoModule ],
})
export class ErfassungenEffectsModule {}

@Injectable()
export class ErfassungenEffects {
  constructor(private actions$: Actions,
    @Inject(ErfassungenDucks) private erfassungenDucks: Duck<ErfassungenDucks>,
    private erfassungInfoService: ErfassungInfoService,
    private toastyService: CustomToastyService,
    private i18n: I18nService) {
  }

  @Effect()
  public submitErfassungen$ = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.submitErfassungen.type),
      map((action: any) => action.payload as RequestForApprovalBatch),
      switchMap((batch) => this.erfassungInfoService.submit(batch)
        .pipe(
          tap(result => {
            const failedCount = result.items.filter(e => e.failed === true).length;
            if (failedCount > 0) {
              this.toastyService.toastWarning(this.i18n.getLocalizedString('toasts.submitBatch.title'),
              this.i18n.getLocalizedString('toasts.submitBatch.failureMessage').replace('{{failed}}', failedCount.toString()));
            } else {
              this.toastyService.toastSuccess(this.i18n.getLocalizedString('toasts.submitBatch.title'),
              this.i18n.getLocalizedString('toasts.submitBatch.successMessage'));
            }
          }),
          map(() => this.erfassungenDucks.loadErfassungen.action()),
          catchError(error => of(this.erfassungenDucks.submitErfassungenError.action()))
        )
      )
    );

  @Effect()
  public approveAsLecturer$ = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.approveAsLecturer.type),
      map((action: any) => action.payload as number[]),
      switchMap((batch) => this.erfassungInfoService.approveAsLecturer(batch)
        .pipe(
          tap(result => {
            const failedCount = result.items.filter(e => e.failed === true).length;
            if (failedCount > 0) {
              this.toastyService.toastWarning(this.i18n.getLocalizedString('toasts.confirmLecturerBatch.title'),
              this.i18n.getLocalizedString('toasts.confirmLecturerBatch.failureMessage').replace('{{failed}}', failedCount.toString()));
            } else {
              this.toastyService.toastSuccess(this.i18n.getLocalizedString('toasts.confirmLecturerBatch.title'),
              this.i18n.getLocalizedString('toasts.confirmLecturerBatch.successMessage'));
            }
          }),
          map(() => this.erfassungenDucks.loadErfassungFreigabeList.action()),
          catchError(error => of(this.erfassungenDucks.approveAsLecturerError.action()))
        )
      )
    );

  @Effect()
  public approveAsAdmin$ = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.approveAsAdmin.type),
      map((action: any) => action.payload as number[]),
      switchMap((batch) => this.erfassungInfoService.approveAsAdmin(batch)
        .pipe(
          tap(result => {
            const failedCount = result.items.filter(e => e.failed === true).length;
            if (failedCount > 0) {
              this.toastyService.toastWarning(this.i18n.getLocalizedString('toasts.confirmAdminBatch.title'), this.i18n.getLocalizedString('toasts.confirmAdminBatch.failureMessage').replace('{{failed}}', failedCount.toString()));
            } else {
              this.toastyService.toastSuccess(this.i18n.getLocalizedString('toasts.confirmAdminBatch.title'), this.i18n.getLocalizedString('toasts.confirmAdminBatch.successMessage'));
            }
          }),
          map(() => this.erfassungenDucks.loadErfassungFreigabeList.action()),
          catchError(error => of(this.erfassungenDucks.approveAsAdminError.action()))
        )
      )
    );

  @Effect()
  public loadErfassungFreigabeList$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.loadErfassungFreigabeList.type),
      switchMap(() => this.erfassungInfoService.get(Mode.Approval)
        .pipe(
          map(erfassungFreigabeList => this.erfassungenDucks.loadErfassungFreigabeListSuccess.action(
            erfassungFreigabeList)),
          catchError(error => of(this.erfassungenDucks.loadErfassungenError.action())),
        ),
      ),
    );

  @Effect()
  public loadErfassungen$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.loadErfassungen.type),
      switchMap(() => this.erfassungInfoService.get(Mode.Own)
        .pipe(
          map(erfassungen => this.erfassungenDucks.loadErfassungenSuccess.action(erfassungen)),
          catchError(error => of(this.erfassungenDucks.loadErfassungenError.action())),
        ),
      ),
    );

  @Effect()
  public loadAllErfassungen$: Observable<Action> = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.loadAllErfassungen.type),
      switchMap(() => this.erfassungInfoService.get(Mode.All)
        .pipe(
          map(erfassungen => this.erfassungenDucks.loadAllErfassungenSuccess.action(erfassungen)),
          catchError(error => of(this.erfassungenDucks.loadAllErfassungenError.action())),
        ),
      ),
    );

  @Effect({ dispatch: false })
  public dismiss$ = this
    .actions$
    .pipe(
      ofType(this.erfassungenDucks.dismiss.type),
      map((action: ActionOf<ErfassungListItem>) => action.payload),
      switchMap(erfassungListItem => this.erfassungInfoService.dismiss(erfassungListItem.erfassungId)
        .pipe(
          map(() => this.erfassungenDucks.dismissSuccess.action()),
          catchError(error => of(this.erfassungenDucks.dismissError.action())),
        ),
      ),
    );
}
