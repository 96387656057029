import { NgModule } from '@angular/core';
import { ApiErrorHandlerServiceModule } from 'app/core/services/api-default-error-handler/api-error-handler.service.module';
import { BatchModule } from 'app/core/services/batch/batch.module';
import { BenutzerprofilModule } from 'app/core/services/benutzerprofil/benutzerprofil.module';
import { CustomWindowModule } from 'app/core/services/custom-window-service/custom-window.module';
import { CustomToastyModule } from 'app/core/services/customToasty/customToasty.module';
import { ErfassungInfoModule } from 'app/core/services/erfassung-info/erfassung-info.module';
import { GlobalModule } from 'app/core/services/global/global.module';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { StorageModule } from 'app/core/services/storage/storage.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { MerkmalModule } from './merkmal/merkmal.module';
import { FeedbackServiceModule } from './feedback/feedback-service.module';
import { ReferencesModule } from './references/references.module';
import { SystemInfoServiceModule } from './system-info/system-info-service.module';
import { WartungsarbeitenServiceModule } from './wartungsarbeiten-service/wartungsarbeiten-service.module';
import { LookupServiceModule } from './lookup-service/lookup-service.module';

@NgModule({
  imports: [
    CustomToastyModule.forRoot(),
    FeedbackServiceModule.forRoot(),
    GlobalModule.forRoot(),
    I18nModule.forRoot(),
    StorageModule.forRoot(),
    ValidationModule.forRoot(),
    CustomWindowModule.forRoot(),
    BenutzerprofilModule.forRoot(),
    ErfassungInfoModule.forRoot(),
    MerkmalModule.forRoot(),
    BatchModule.forRoot(),
    ReferencesModule.forRoot(),
    ApiErrorHandlerServiceModule.forRoot(),
    SystemInfoServiceModule.forRoot(),
    WartungsarbeitenServiceModule.forRoot(),
    LookupServiceModule.forRoot()
  ],
})
// only for root AppModule import
export class CoreServicesForRootModule {}

