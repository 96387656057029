import { Component, EventEmitter, Inject, OnInit, Output, SecurityContext } from '@angular/core';
import { IdentitaetPruefenResult } from 'app/core/services/api-client';
import { Observable } from 'rxjs';
import { Duck } from '@ngrx-ducks/core';
import { MergeDucks } from './ducks/merge.ducks';
import { canMerge, getResult, isBusy } from './ducks/merge.selectors';
import { DomSanitizer } from '@angular/platform-browser';
import { I18nService } from 'app/core/services/i18n/i18n.service';

@Component({
  selector: 'app-merge-dialog',
  templateUrl: './merge-dialog.component.html',
})
export class MergeDialogComponent implements OnInit {

  public isBusy$: Observable<boolean>;
  public canMerge$: Observable<boolean>;
  public result$: Observable<IdentitaetPruefenResult>;
  public additionalId: any;

  @Output()
  public closeWindow: EventEmitter<any | null> = new EventEmitter<any | null>();

  constructor(@Inject(MergeDucks) private mergeDuck: Duck<MergeDucks>,
    private i18n: I18nService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.isBusy$ = this.mergeDuck.pick(isBusy);
    this.canMerge$ = this.mergeDuck.pick(canMerge);
    this.result$ = this.mergeDuck.pick(getResult);
  }

  checkAdditional(): boolean {
    if (this.additionalId) {
      const additionalIdValue = Number.parseInt(this.additionalId, 10);
      if (additionalIdValue) { return true; }
    }
    return false;
  }

  merge() {
    if (this.additionalId) {
      const additionalIdValue = Number.parseInt(this.additionalId, 10);
      if (additionalIdValue) {
        console.log(additionalIdValue);
        this.mergeDuck.merge(additionalIdValue);
      }
    } else {
      console.log('Without additional ID');
      this.mergeDuck.merge(null);
    }
  }

  format(text: string): string {
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, text);
    return sanitized.replace(/(&#10;)/g, '<br>');
  }

  formatIdentitiesText(n: number): string {
    if (n === 0) {
      return this.i18n.getLocalizedString('components.merge.identitiesText0');
    } else if (n === 1) {
      return this.i18n.getLocalizedString('components.merge.identitiesText1');
    }
    return this.i18n.getLocalizedString('components.merge.identitiesTextN').replace('{{n}}', n.toString());
  }

  close() {
    this.closeWindow.emit(null);
  }
}
