import { createSelector } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterByStatus } from 'app/core/services/api-client';

const sharedState = (state: IAppState) => state.shared;
const feedbackState = createSelector(sharedState, state => state.feedback);

export const getSystemFeedback = createSelector(feedbackState, state => state.systemFeedback);
export const isLoadingSystemFeedbacks = createSelector(getSystemFeedback, state => state.apiState === 'Loading');

export const getOwnFeedback = createSelector(feedbackState, state => state.ownFeedback);
export const isLoadingOwnFeedbacks = createSelector(getOwnFeedback, state => state.apiState === 'Loading');

export const getStoffFeedback = createSelector(feedbackState, state => state.stoffFeedback);
export const isLoadingStoffFeedbacks = createSelector(getStoffFeedback, state => state.apiState === 'Loading');

export const getLookupTablesFeedback = createSelector(feedbackState, state => state.lookupTablesFeedback);
export const isLoadingLookupTablesFeedback = createSelector(getStoffFeedback, state => state.apiState === 'Loading');

export const isStofffeedbackWindowVisible = createSelector(feedbackState, state => state.isStofffeedbackWindowVisible);

export const getRoleContext = createSelector(feedbackState, state => state.roleContext);

export function mapTableFilterToArray(tableFilter: CompositeFilterDescriptor): FilterByStatus[] {
  if (tableFilter.filters && tableFilter.filters[ 0 ]) {
    const columnFilter = tableFilter.filters[ 0 ] as CompositeFilterDescriptor;
    const stateFilters = columnFilter.filters as FilterDescriptor[];
    if (stateFilters) {
      return stateFilters.map(x => x.value);
    }
  }
  return [];
}
