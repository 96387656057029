<div
 kendoTooltip position="right" [tooltipTemplate]="tooltipTemplate"
 filter="span[data-merkmal]">

<kendo-grid #grid [data]="gridView" [sortable]="{
    allowUnsort: true,
    mode: 'single'
    }" [sort]="sort"
    (sortChange)="sortChange($event)">
  <kendo-grid-column field="key" [title]="'components.rankingAuftragResult.grid.columns.name' | translate">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

      <span [attr.data-merkmal]="dataItem.key">{{dataItem.key}}</span>

  </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="value" [title]="'components.rankingAuftragResult.grid.columns.count' | translate" 
  [headerStyle]="{'width': '150px'}" [style]="{'width': '150px'}">
  </kendo-grid-column>

  <kendo-grid-messages [noRecords]="'grid.messages.noRecords' | translate"
    [pagerNextPage]="'grid.messages.pagerNextPage' | translate"
    [pagerPreviousPage]="'grid.messages.pagerPreviousPage' | translate"
    [pagerLastPage]="'grid.messages.pagerLastPage' | translate" [pagerPage]="'grid.messages.pagerPage' | translate"
    [pagerOf]="'grid.messages.pagerOf' | translate" [pagerItems]="'grid.messages.pagerItems' | translate"
    [pagerItemsPerPage]="'grid.messages.pagerItemsPerPage' | translate"
    [filterNotEqOperator]="'grid.messages.filterNotEqOperator' | translate"
    [filterIsNotNullOperator]="'grid.messages.filterIsNotNullOperator' | translate"
    [filterIsNullOperator]="'grid.messages.filterIsNullOperator' | translate"
    [filterIsEmptyOperator]="'grid.messages.filterIsEmptyOperator' | translate"
    [filterIsNotEmptyOperator]="'grid.messages.filterIsNotEmptyOperator' | translate"
    [filterStartsWithOperator]="'grid.messages.filterStartsWithOperator' | translate"
    [filterContainsOperator]="'grid.messages.filterContainsOperator' | translate"
    [filterNotContainsOperator]="'grid.messages.filterNotContainsOperator' | translate"
    [filterEndsWithOperator]="'grid.messages.filterEndsWithOperator' | translate"
    [filterGteOperator]="'grid.messages.filterGteOperator' | translate"
    [filterGtOperator]="'grid.messages.filterGtOperator' | translate"
    [filterLteOperator]="'grid.messages.filterLteOperator' | translate"
    [filterLtOperator]="'grid.messages.filterLtOperator' | translate"
    [filterIsTrue]="'grid.messages.filterIsTrue' | translate"
    [filterIsFalse]="'grid.messages.filterIsFalse' | translate"
    [filterBooleanAll]="'grid.messages.filterBooleanAll' | translate"
    [filterAfterOrEqualOperator]="'grid.messages.filterAfterOrEqualOperator' | translate"
    [filterAfterOperator]="'grid.messages.filterAfterOperator' | translate"
    [filterBeforeOperator]="'grid.messages.filterBeforeOperator' | translate"
    [filterBeforeOrEqualOperator]="'grid.messages.filterBeforeOrEqualOperator' | translate"
    [filterEqOperator]="'grid.messages.filterEqOperator' | translate">
  </kendo-grid-messages>
</kendo-grid>
</div>
<ng-template #tooltipTemplate let-anchor>
  <span [innerHTML]="getMerkmalName(anchor.nativeElement.getAttribute('data-merkmal'))"></span>
</ng-template>
