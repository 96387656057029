<div class="row"
     *ngIf="isLoaded$ | async">

  <form [formGroup]="searchForm"
        (ngSubmit)="onSubmit()"
        class="form-horizontal form-label-left">

    <div class="col-md-6" 
         style="width: 310px">
      <label class="control-label"
             style="float: left; padding-right: 10px">{{'dialog.searchTerm' | translate}}</label>
      <input class="form-control"
             formControlName="searchTerm"
             style="width: 190px;"
             type="text">
    </div>

    <div class="col-md-3"
         style="width: 230px">
      <label class="radio-inline" style="padding:auto; margin: auto auto auto 5px;">
        <input type="radio"
               formControlName="searchType"
               style="margin-top: 3px;"
               value="name">
        {{'dialog.displayName' | translate}}
      </label>

      <label class="radio-inline">
        <input type="radio"
               formControlName="searchType"
               style="margin-top: 3px;"
               value="key"
               checked>
        {{'dialog.shortName' | translate}}
      </label>
    </div>

    <div class="col-md-3"
         style="padding: 0; width: 140px">
      <ul class="treeview-header">
        <li>
          <button class="btn btn-default treeview-btn"
                type="submit"
                [title]="'action.find' | translate">
            <i class="fas fa-search"></i>
          </button>
        </li>
        <li>
          <button class="btn btn-default treeview-btn"
                (click)="prev()"
                [disabled]="!hasMatches"
                type="button"
                [title]="'action.previous' | translate">
            <i class="fas fa-arrow-circle-up"></i>
          </button>
        </li>
        <li>
          <button class="btn btn-default treeview-btn"
                (click)="next()"
                [disabled]="!hasMatches"
                type="button"
                [title]="'action.next' | translate">
            <i class="fas fa-arrow-circle-down"></i>
          </button>
        </li>
      </ul>
    </div>
  </form>

</div>
<div class="row"
     style="max-height: 600px; max-height: 68vh; overflow-y: scroll">
  <kendo-treeview *ngIf="isLoaded$ | async; else loading"
                  class="select-tree"
                  [nodes]="merkmale$ | async"
                  textField="name"
                  childrenField="items"
                  kendoTreeViewExpandable
                  kendoTreeViewSelectable
                  (selectionChange)="handleSelection($event)"
                  [(expandedKeys)]="expandedKeys"
                  [expandBy]="'hierarchy'"                  
                  kendoTreeViewHierarchyBinding>

    <ng-template kendoTreeViewNodeTemplate
                 let-dataItem>
      <ng-container *ngIf="dataItem.key; else hierarchy">
        <input #checkboxRef
               *ngIf="isMultiselectable"
               type="checkbox"
               style="position: relative; top: 2px;"
               id="L{{dataItem.key}}"
               [checked]="merkmale | isMerkmalChecked:alleMerkmale:dataItem.hierarchy:showAllMerkmale"
               (click)="toggleIsChecked(dataItem.hierarchy, checkboxRef.checked)"
        >
        <label class="{{dataItem.hierarchy}}"
               attr.for="L{{dataItem.key}}"
               attr.data-itemkey="{{dataItem.key}}"
               class="select-tree-item"
               [ngClass]="{'highlight': matchedNode === dataItem.hierarchy, 'link' : true, 'not-allowed' : dataItem.isLocked, 'disabled': dataItem.isDisabled}">
          {{dataItem.name}} <span class="merkmal-key">({{dataItem.key}})</span>
          <span *ngIf="dataItem.isLocked"
                [title]="dataItem.lockedBy">
            <i class="fas fa-lock fa-sm"></i>
          </span>
        </label>
      </ng-container>
      <ng-template #hierarchy>
        <input #checkboxHierarchyRef
               *ngIf="isMultiselectable"
               type="checkbox"
               id="L{{dataItem.hierarchy}}"
               [checked]="merkmale | isMerkmalChecked:alleMerkmale:dataItem.hierarchy:showAllMerkmale"
               (click)="toggleIsChecked(dataItem.hierarchy, checkboxHierarchyRef.checked)">
        <label class="{{dataItem.hierarchy}}"
               attr.for="L{{dataItem.hierarchy}}"
               [ngClass]="{'highlight': matchedNode === dataItem.hierarchy}">
          {{dataItem.name}}
        </label>
      </ng-template>
    </ng-template>

  </kendo-treeview>
  <button *ngIf="showApplyButton"
          type="button"
          class="btn btn-primary btn-apply"
          (click)="applyMerkmale()">
    {{ 'action.apply' | translate }}
  </button>

  <ng-template #loading>
    <div style="text-align: center">
      <i class="k-icon k-i-loading"
         style="font-size:50px; columns: #363636; margin: 20px"></i>
    </div>
  </ng-template>
</div>
