import { Inject, Injectable } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { NachschlagetabellenDucks } from './nachschlagetabellen.ducks';
import { LookupService } from '../../../../core/services/lookup-service/lookup-service.service';
import { NachschlageTabelle, NachschlageTabelleWertData } from '../../../../core/services/api-client';

@Injectable()
export class NachschlagetabellenEffects {
  public constructor(@Inject(NachschlagetabellenDucks)
  private readonly duck: Duck<NachschlagetabellenDucks>,
    private readonly service: LookupService) { }

  public async loadLookupTablesAsync(): Promise<void> {
    this.duck.nachschlagetabellenRequest();
    try {
      const lookupTables = await this.service.getLookupTables().toPromise();
      this.duck.loadNachschlagetabellenSuccess(lookupTables);
    } catch (error) {
      console.error(error);
    }
  }

  public async getLookupTableValues(name: string): Promise<NachschlageTabelle> {
    return await this.service.getLookupTableByName(name).toPromise();
  }

  public deleteLookupTableValue(tableName: string, wertId: number): Promise<any> {
    try {
      return this.service.deleteLookupTableValue(tableName, wertId).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  public postLookupTableValue(tableName: string, value: NachschlageTabelleWertData) {
    try {
      const response = this.service.postLookupTableValue(tableName, value).toPromise();
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}
