<ng-container *ngIf="rule">
  <div class="form-horizontal form-label-left">
    <input type="hidden" id="isNew" [value]="isNew">
    <div class="form-group">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.ruleName' | translate}}*
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <input kendoTextBox [attr.disabled]="isNew ? null : ''" id="ruleName" pattern="[A-Za-z0-9_]*"
          [(ngModel)]="rule.name" autoFocus>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.description' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <kendo-textbox-container style="width: 100%; height: 100px">
          <textarea kendoTextArea style="resize: none" id="description" [(ngModel)]="rule.description"></textarea>
        </kendo-textbox-container>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.type' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <kendo-dropdownlist [textField]="'text'" [valueField]="'value'" [value]="rule.type"
          [data]="rankingTypeListItems" [valuePrimitive]="true" (selectionChange)="onTypeSelectionChange($event)"
          [listHeight]="500" style="width:100%" id="ddl_type" [disabled]="!isNew">
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
  <ng-container [ngSwitch]="rule.type">
    <div class="form-group" *ngSwitchCase="'Supplier'">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.supplierPriorities' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <table class="table">
          <thead>
            <tr>
              <td>{{'components.rankingRulesEdit.grid.headers.supplier' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.priority' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.actions' | translate}}</td>
            </tr>
          </thead>
          <tbody *ngIf="rule.supplierPriorities && rule.supplierPriorities.length > 0">
            <tr *ngFor="let supplierPriority of rule.supplierPriorities; let rowIndex = index">
              <td>
                <kendo-autocomplete #autocomplete [data]="supplierOptions"
                  [placeholder]="'components.rankingRulesEdit.placeholder.supplier' | translate" [filterable]="true"
                  [suggest]="true" [name]="'tb_supplier_' + rowIndex" [(ngModel)]="supplierPriority.supplier"
                  [kendoDropDownFilter]="filterSettings" style="width: 100%;" (focus)="doOnFocus(autocomplete)">
                </kendo-autocomplete>

              </td>
              <td>
                <kendo-numerictextbox [(ngModel)]="supplierPriority.priority" [format]="'n'" [step]="1"
                  [autoCorrect]="true" style="width:100px"></kendo-numerictextbox>
              </td>
              <td>
                <button class="k-button k-button-icontext" name="btn_supplierPriority-delete" type="button"
                  (click)="deleteRow(rowIndex)" title="{{'action.deleteRow' | translate}}">
                  <i class="k-icon k-i-trash"></i> {{'action.delete' | translate}}
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">
                <button class="k-button k-button-icontext" id="btn_supplierPriority-add" type="button"
                  (click)="addRow()" title="{{'action.addRow' | translate}}">
                  <i class="k-icon k-i-plus"></i> {{'action.add' | translate}}
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="form-group" *ngSwitchCase="'NumericCondition'">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.attribute' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <kendo-autocomplete #merkmalNumeric [data]="merkmalOptions"
          [placeholder]="'components.rankingRulesEdit.placeholder.merkmal' | translate" [filterable]="true"
          [suggest]="true" (filterChange)="onMerkmalFilterChanged($event)" (valueChange)="onMerkmalChanged($event)"
          name="tb_merkmal" [(ngModel)]="rule.attribute" (focus)="doOnFocus(merkmalNumeric)">
        </kendo-autocomplete>
      </div>
      <label class="control-label col-md-3 col-sm-3 col-xs-3" style="margin-top: 30px;">
        {{'components.rankingRulesEdit.numericConditions' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9" style="margin-top: 30px;">
        <table class="table numeric-conditions">
          <thead>
            <tr>
              <td>{{'components.rankingRulesEdit.grid.headers.field' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.operator' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.lower' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.upper' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.actions' | translate}}</td>
            </tr>
          </thead>
          <tbody *ngIf="rule.numericConditions && rule.numericConditions.length > 0">
            <tr *ngFor="let numericCondition of rule.numericConditions; let rowIndex = index">
              <td style="width: 50px;">

                <kendo-autocomplete #autocomplete [data]="feldOptions"
                  [placeholder]="'components.rankingRulesEdit.placeholder.field' | translate" [filterable]="true"
                  [suggest]="true" [name]="'tb_numeric-condition_' + rowIndex" [(ngModel)]="numericCondition.field"
                  [kendoDropDownFilter]="filterSettings" (focus)="doOnFocus(autocomplete)">
                </kendo-autocomplete>

              </td>
              <td style="width: 75px;">
                <kendo-dropdownlist [textField]="'text'" [valueField]="'value'" [(ngModel)]="numericCondition.operator"
                  [data]="numericConditionOperatorListItems" [valuePrimitive]="true" style="width:50px"
                  name="ddl_operator">
                </kendo-dropdownlist>
              </td>
              <td style="width: 50px;">
                <kendo-numerictextbox [(ngModel)]="numericCondition.lower" [autoCorrect]="true" style="width:100px">
                </kendo-numerictextbox>
              </td>
              <td style="width: 50px;">
                <kendo-numerictextbox [(ngModel)]="numericCondition.upper" [autoCorrect]="true" style="width:100px">
                </kendo-numerictextbox>
              </td>
              <td>
                <button class="k-button k-button-icontext" name="btn_supplierPriority-delete" type="button"
                  (click)="deleteRow(rowIndex)" title="{{'action.deleteRow' | translate}}">
                  <i class="k-icon k-i-trash"></i> {{'action.delete' | translate}}
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <button class="k-button k-button-icontext" id="btn_numericCondition-add" type="button"
                  (click)="addRow()" title="{{'action.addRow' | translate}}">
                  <i class="k-icon k-i-plus"></i> {{'action.add' | translate}}
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="form-group" *ngSwitchCase="'LookupTable'">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.table' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <kendo-autocomplete #tablename [data]="lookupTableOptions"
          [placeholder]="'components.rankingRulesEdit.placeholder.lookupTable' | translate" [filterable]="true"
          [suggest]="true" name="tb_lookupTable" [(ngModel)]="rule.table" [kendoDropDownFilter]="filterSettings"
          (focus)="doOnFocus(tablename)">
        </kendo-autocomplete>
      </div>
      <label class="control-label col-md-3 col-sm-3 col-xs-3" style="margin-top: 30px;">
        {{'components.rankingRulesEdit.valuePriorities' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9" style="margin-top: 30px;">
        <table class="table">
          <thead>
            <tr>
              <td>{{'components.rankingRulesEdit.grid.headers.text' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.priority' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.actions' | translate}}</td>
            </tr>
          </thead>
          <tbody *ngIf="rule.valuePriorities && rule.valuePriorities.length > 0">
            <tr *ngFor="let valuePriority of rule.valuePriorities; let rowIndex = index">
              <td><input type="text" [name]="'tb_priority_' + rowIndex" class="k-textbox"
                  [(ngModel)]="valuePriority.value">
              </td>
              <td>
                <kendo-numerictextbox [(ngModel)]="valuePriority.priority" [format]="'n'" [step]="1"
                  [autoCorrect]="true" style="width:100px"></kendo-numerictextbox>
              </td>
              <td>
                <button class="k-button k-button-icontext" name="btn_supplierPriority-delete" type="button"
                  (click)="deleteRow(rowIndex)" title="{{'action.deleteRow' | translate}}">
                  <i class="k-icon k-i-trash"></i> {{'action.delete' | translate}}
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">
                <button class="k-button k-button-icontext" id="btn_supplierPriority-add" type="button"
                  (click)="addRow()" title="{{'action.addRow' | translate}}">
                  <i class="k-icon k-i-plus"></i> {{'action.add' | translate}}
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="form-group" *ngSwitchCase="'Extremum'">
      <label class="control-label col-md-3 col-sm-3 col-xs-3">
        {{'components.rankingRulesEdit.attribute' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9">
        <kendo-autocomplete #merkmalExtremum [data]="merkmalOptions"
          [placeholder]="'components.rankingRulesEdit.placeholder.merkmal' | translate" [filterable]="true"
          [suggest]="true" (filterChange)="onMerkmalFilterChanged($event)" (valueChange)="onMerkmalChanged($event)"
          name="tb_merkmal" [(ngModel)]="rule.attribute" (focus)="doOnFocus(merkmalExtremum)">
        </kendo-autocomplete>
      </div>
      <label class="control-label col-md-3 col-sm-3 col-xs-3" style="margin-top: 30px;">
        {{'components.rankingRulesEdit.extrema' | translate}}
      </label>
      <div class="col-md-9 col-sm-9 col-xs-9" style="margin-top: 30px;">
        <table class="table">
          <thead>
            <tr>
              <td>{{'components.rankingRulesEdit.grid.headers.field' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.operator' | translate}}</td>
              <td>{{'components.rankingRulesEdit.grid.headers.actions' | translate}}</td>
            </tr>
          </thead>
          <tbody *ngIf="rule.extremums && rule.extremums.length > 0">
            <tr *ngFor="let extremum of rule.extremums; let rowIndex = index">
              <td>
                <kendo-autocomplete #autocomplete [data]="feldOptions"
                  [placeholder]="'components.rankingRulesEdit.placeholder.field' | translate" [filterable]="true"
                  [suggest]="true" [id]="'tb_extremum_' + rowIndex" [name]="'tb_extremum_' + rowIndex"
                  [(ngModel)]="extremum.field" [kendoDropDownFilter]="filterSettings" (focus)="doOnFocus(autocomplete)">
                </kendo-autocomplete>
              </td>
              <td>
                <kendo-dropdownlist [textField]="'text'" [valueField]="'value'" [(ngModel)]="extremum.operator"
                  [data]="extremumOperatorListItems" [valuePrimitive]="true" style="width:75px" name="ddl_operator">
                </kendo-dropdownlist>
              </td>
              <td>
                <button class="k-button k-button-icontext" name="btn_extremum-delete" type="button"
                  (click)="deleteRow(rowIndex)" title="{{'action.deleteRow' | translate}}">
                  <i class="k-icon k-i-trash"></i> {{'action.delete' | translate}}
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3">
                <button class="k-button k-button-icontext" id="btn_extremum-add" type="button" (click)="addRow()"
                  title="{{'action.addRow' | translate}}">
                  <i class="k-icon k-i-plus"></i> {{'action.add' | translate}}
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </ng-container>

  <div class="form-group" style="display: flex; justify-content: flex-end; padding:5px">
    <button id="btn_close" class="btn btn-default" (click)="cancel()">{{'action.cancel' | translate}}</button>
    <button id="btn_save" class="btn btn-primary" type="submit" (click)="submit()" [disabled]="!isValid">
      <i class="fas fa-save"></i>
      {{'action.applyChanges' | translate}}</button>
  </div>
</ng-container>
