<div class="feedback-body" *ngIf="!isEditMode; else editView">
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label feedback-column__label--required"
      [translate]="'components.systemfeedback.category'"></label>
    <kendo-dropdownlist [data]="categoryItems" [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
      [(ngModel)]="createFeedback.category" name="category" id="ddl_category">
    </kendo-dropdownlist>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label feedback-column__label--required"
      [translate]="'components.systemfeedback.subject'"></label>
    <input autoFocus class="k-textbox" placeholder="{{'components.systemfeedback.subject' | translate}}"
      [(ngModel)]="createFeedback.subject" name="subject" id="tb_subject" />
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label feedback-column__label--required"
      [translate]="'components.systemfeedback.description'"></label>
    <textarea class="k-textarea" placeholder="{{'components.systemfeedback.description' | translate}}"
      [(ngModel)]="createFeedback.description" name="description" id="area_description"></textarea>
  </div>
  <div class="feedback-column" *ngIf="createFeedback.category == 'LookupTableEntry'">
    <label class="k-form-field feedback-column__label"
      [translate]="'components.systemfeedback.lookupTableName'"></label>
    <input class="k-textbox k-text-info" [(ngModel)]="createFeedback.nachschlageTabelleName"
      name="nachschlageTabelleName" id="tb_nachschlageTabelleName" />
  </div>
  <div class="feedback-column" *ngIf="createFeedback.category == 'LookupTableEntry'">
    <label class="k-form-field feedback-column__label"
      [translate]="'components.systemfeedback.lookupTableValue'"></label>
    <input class="k-textbox k-text-info" [(ngModel)]="createFeedback.nachschlageTabelleWert" name="nachschlageTabelleWert"
      id="tb_nachschlageTabelleWert" />
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.url'"></label>
    <input class="k-textbox k-text-info" [disabled]="true" [value]="createFeedback.url" name="url" id="tb_url" />
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label feedback-column__label--required"
      [translate]="'models.feedback.priority'"></label>
    <kendo-dropdownlist [data]="priorityItems" [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
      [(ngModel)]="createFeedback.priority" id="ddl_priority">
    </kendo-dropdownlist>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.attachments'"></label>
    <kendo-upload [(ngModel)]="files" [saveUrl]="uploadSaveUrl" [removeUrl]="uploadRemoveUrl"
      [restrictions]="fileRestrictions" [withCredentials]="false">
      <kendo-upload-messages [cancel]="'upload.messages.cancel' | translate"
        [clearSelectedFiles]="'upload.messages.clearSelectedFiles' | translate"
        [dropFilesHere]="'upload.messages.dropFilesHere' | translate"
        [headerStatusUploaded]="'upload.messages.headerStatusUploaded' | translate"
        [headerStatusUploading]="'upload.messages.headerStatusUploading' | translate"
        [invalidFileExtension]="'upload.messages.invalidFileExtension' | translate"

        [invalidMaxFileSize]="'upload.messages.invalidMaxFileSize' | translate"
        [invalidMinFileSize]="'upload.messages.invalidMinFileSize' | translate"
        [remove]="'upload.messages.remove' | translate" [retry]="'upload.messages.retry' | translate"
        [select]="'upload.messages.select' | translate"
        [uploadSelectedFiles]="'upload.messages.uploadSelectedFiles' | translate"></kendo-upload-messages>
    </kendo-upload>
  </div>
  <div class="feedback-column">
    <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.emailExpected'"></label>
    <div style="width: 100%">
      <input type="checkbox" class="k-checkbox" [(ngModel)]="createFeedback.emailExpected"
        [ngModelOptions]="{standalone: true}" name="cb_emailExpected" id="cb_emailExpected" style="vertical-align: bottom; margin: 0;" />
        <label class="k-checkbox-label"></label>
    </div>
  </div>

  <div class="feedback-column feedback-column--buttongrp">
    <button (click)="cancel()" class="btn btn-default"
      id="btn_cancel">{{'components.systemfeedback.cancel' | translate}}</button>
    <button (click)="sendToApi()" [disabled]="!canSend" class="btn btn-primary" id="btn_send">
      <i class="far fa-paper-plane"></i> {{'components.systemfeedback.send' | translate}}
    </button>
  </div>
</div>

<!-- open/edit view of system feedback -->
<ng-template #editView>
  <div class="feedback-body">
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.subject'"></label>
      <div class="feedback-column__value">{{ feedback.subject }}</div>
    </div>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.category'"></label>
      <div class="feedback-column__value" [translate]="'components.systemfeedback.categories.' + feedback.category">
      </div>
    </div>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'models.feedback.creator'"></label>
      <div class="feedback-column__value">{{ feedback.createdBy }}</div>
    </div>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'models.feedback.creationdate'"></label>
      <div class="feedback-column__value">{{ feedback.createdOn | kendoDate: 'G' }}</div>
    </div>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.description'"></label>
      <div class="feedback-column__value">{{ feedback.description }}</div>
    </div>
    <ng-container *ngIf="feedback.category == 'LookupTableEntry'">
      <div class="feedback-column">
        <label class="k-form-field feedback-column__label"
          [translate]="'components.systemfeedback.lookupTableName'"></label>
        <div class="feedback-column__value">{{ feedback.nachschlageTabelleName }}</div>
      </div>
      <div class="feedback-column">
        <label class="k-form-field feedback-column__label"
          [translate]="'components.systemfeedback.lookupTableValue'"></label>
        <div class="feedback-column__value">{{ feedback.nachschlageTabelleWert }}</div>
      </div>
    </ng-container>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'models.feedback.priority'"></label>
      <div class="feedback-column__value" [translate]="'models.feedback.priorities.' + feedback.priority"></div>
    </div>
    <div class="feedback-column" *ngIf="feedback.url">
      <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.url'"></label>
      <div class="feedback-column__value">
        <a [href]="feedback.url" title="{{ 'action.view' | translate }}: {{ feedback.url }}" target="_blank"
          class="feedback-column__value--link"><span>{{ feedback.url }}</span> <i
            class="fas fa-external-link-alt"></i></a>
      </div>
    </div>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'components.stofffeedback.attachments'"></label>
      <div class="feedback-column__value">
        <a *ngFor="let file of feedback.files" [attr.href]="fileUrl(file.url)" class="feedback-column__value--link"><i
            class="fas fa-download"></i> {{file.fileName}}
        </a>
      </div>
    </div>
    <div class="feedback-column">
      <label class="k-form-field feedback-column__label" [translate]="'components.stofffeedback.state.label'"></label>
      <kendo-dropdownlist [data]="feedbackState" id="change-state" [valuePrimitive]="true" [value]="feedback.state"
        (valueChange)="updateCommonFeedbackState($event)">
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          {{ 'models.feedback.state.' + dataItem.toLowerCase() | translate}}
        </ng-template>
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          {{ 'models.feedback.state.' + dataItem.toLowerCase() | translate}}
        </ng-template>
      </kendo-dropdownlist>
    </div>
    <div class="feedback-column feedback-column--top">
      <label class="k-form-field feedback-column__label" [translate]="'components.systemfeedback.comments'"></label>
      <div *ngIf="feedback.history && feedback.history.length > 0; else noHistory" class="feedback-column__value">
        <div *ngFor="let historyItem of feedback.history" class="feedback-column__comment">
          <div class="feedback-column__comment--text">{{historyItem.comment}}</div>
          <div class="feedback-column__comment--by">
            <b>{{'components.systemfeedback.history.label.createdOn' | translate}}</b>
            {{historyItem.createdOn | kendoDate: 'G'}}
            <b>{{'components.systemfeedback.history.label.createdBy' | translate}}</b> {{historyItem.createdBy}}
            <b>{{'components.systemfeedback.history.label.state' | translate}}</b>
            {{'models.feedback.state.' + historyItem.state.toLowerCase() | translate}}
          </div>

        </div>
      </div>
      <ng-template #noHistory>
        <div class="feedback-column__value">{{ 'components.stofffeedback.comments.emptyHistory' | translate }}</div>
      </ng-template>
    </div>
    <!-- Comment Container -->
    <div class="feedback-column feedback-column--top">
      <label class="k-form-field feedback-column__label"
        [translate]="'components.stofffeedback.comments.label'"></label>
      <textarea #commentInput class="k-textarea"
        placeholder="{{'components.stofffeedback.comments.placeholder' | translate}}"></textarea>
    </div>

    <div class="feedback-column">
      <label class="k-form-field feedback-column__label"
        [translate]="'components.systemfeedback.emailExpected'"></label>
      <div style="width: 100%">
        <input type="checkbox" class="k-checkbox" [(ngModel)]="feedback.emailExpected"
          [ngModelOptions]="{standalone: true}" name="cb_emailExpected" id="cb_emailExpected" style="vertical-align: bottom; margin: 0;" />
          <label class="k-checkbox-label"></label>
      </div>
    </div>

    <div class="feedback-column feedback-column--buttongrp">
      <button (click)="cancel()" class="btn btn-default"
        id="btn-cancel-edit">{{'components.systemfeedback.cancel' | translate}}</button>
      <button (click)="updateCommonFeedback()" [disabled]="!canUpdate()" class="btn btn-primary" id="btn-update">
        <i class="far fa-paper-plane"></i> {{'components.systemfeedback.send' | translate}}
      </button>
    </div>
  </div>
</ng-template>
