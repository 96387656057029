import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { Sachverhalt, SachverhaltBearbeiten, SachverhaltErstellen } from '@models/dossier/dossier-models';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import { SachverhaltState } from './sachverhalt.state';
import { DossierSachverhaltLoeschenBeantragen } from 'app/modules/dossier-area/ducks/models/dossierSachverhaltLoeschenBeantragen';
import { LoadSachverhalt } from 'app/modules/dossier-area/ducks/models/loadSachverhalt';

@Ducksify<SachverhaltState>({
  initialState: {
    sachverhalt: null,
    serverValidationErrors: { invalidFields: [], summary: [] },
    isSachverhaltLoading: true,
    sachverhaltEdit: {
      sachverhaltId: null,
      erfassungSachverhaltId: null,
    },
  },
})
export class SachverhaltDucks {
  readonly dossierBearbeitenSachverhaltAenderungenVerwerfen = effect<DossierSachverhaltLoeschenBeantragen>('[Dossier - Effect] Dossier Bearbeiten Sachverhalt Aenderungen Verwerfen');
  readonly dossierBearbeitenSachverhaltAenderungenVerwerfenError = effect<DossierSachverhaltLoeschenBeantragen>('[Dossier - Effect] Dossier Bearbeiten Sachverhalt Aenderungen Verwerfen Error');
  readonly dossierSachverhaltLoeschenBeantragen = effect<DossierSachverhaltLoeschenBeantragen>('[Dossier - Effect] Dossier Sachverhalt Loeschen Beantragen');
  readonly dossierSachverhaltLoeschenBeantragenError = effect<void>('[Dossier - Effect] Dossier Sachverhalt Loeschen Beantragen Error');
  readonly dossierNeuenSachverhaltLoeschen = effect<DossierSachverhaltLoeschenBeantragen>('[Dossier - Effect] Dossier Neuen Sachverhalt Loeschen');
  readonly dossierNeuenSachverhaltLoeschenError = effect<void>('[Dossier - Effect] Dossier Neuen Sachverhalt Loeschen');
  readonly createSachverhaltAndCloseSuccess = effect<void>('[Dossier - Effect] Create Sachverhalt And Close Window Success');
  readonly openSelectMerkmalDialog = effect('[Dossier - Effect] Open select Merkmal Dialog for create sachverhalt');
  readonly createSachverhaltAndOpen = effect<SachverhaltErstellen>('[Dossier - Effect] Create Sachverhalt And Open new Window');
  readonly loadSachverhaltError = effect<void>('[Dossier - Effect] Load Sachverhalt Error');
  readonly speichereSachverhaltInDossier = effect<SachverhaltBearbeiten>('[Dossier - Effect] Speichere Sachverhalt in Dossier');
  readonly bearbeiteNeuenSachverhaltInDossier = effect<SachverhaltBearbeiten>('[Dossier - Effect] Speichere neuen Sachverhalt in Dossier');
  readonly bearbeiteNeuenSachverhaltInDossierError = effect<void>('[Dossier - Effect] Speichere neuen Sachverhalt in Dossier (Error)');

  @Action('[Dossier] Sachverhalt Edit Set')
  public loadSachverhaltEditSet(state: SachverhaltState, sachverhaltEdit: any): typeof state {
    return {
      ...state,
      sachverhaltEdit: { ...sachverhaltEdit },
    };
  }

  @Action('[Dossier] Start Loading Sachverhalt')
  public startLoadingSachverhalt(state: SachverhaltState): typeof state {
    return {
      ...state,
      isSachverhaltLoading: true,
    };
  }

  @Action('[Dossier] Load Sachverhalt')
  public loadSachverhaltEffect(state: SachverhaltState, param: LoadSachverhalt): typeof state {
    return {
      ...state,
      isSachverhaltLoading: true,
    };
  }

  @Action('[Dossier] Load Sachverhalt Success')
  public loadSachverhaltSuccess(state: SachverhaltState, sachverhalt: Sachverhalt): typeof state {
    return {
      ...state,
      sachverhalt: sachverhalt,
      isSachverhaltLoading: false,
    };
  }

  @Action('[Sachverhalt] set server validation errors on saving sachverhalt')
  public setServerValidationError(state: SachverhaltState, serverValidationErrors: IServerValidationErrors): typeof state {
    return {
      ...state,
      serverValidationErrors,
    };
  }

  @Action('[Sachverhalt] reset server validation errors')
  public resetServerValidationError(state: SachverhaltState): typeof state {
    return {
      ...state,
      serverValidationErrors: {
        invalidFields: [],
        summary: [],
      },
    };
  }

  @Action('[Sachverhalt] Create Sachverhalte Success')
  public createSachverhalteSuccess(state: SachverhaltState): typeof state {
    return {
      ...state,
    };
  }
}

export function sachverhaltReducer(state: SachverhaltState, action: DucksifiedAction): typeof state {
  return reducerFrom(SachverhaltDucks)(state, action);
}
