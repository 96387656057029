import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ImportAuftragLogItem } from 'app/core/services/api-client';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/modules/shared-area/root-state';
import { getSelectedImportAuftragLog } from '../../pages/import-page/ducks/import-page.selectors';

@Component({
  selector: 'app-import-auftraege-log',
  templateUrl: './import-auftraege-log.component.html',
  styleUrls: ['./import-auftraege-log.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ImportAuftraegeLogComponent implements OnInit {


  public selectedExportAuftragLog$: Observable<ImportAuftragLogItem[]>;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    this.selectedExportAuftragLog$ = this.store.pipe(select(getSelectedImportAuftragLog));
  }
}
