import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { ThesaurusRulesState } from './thesaurus-rules.state';
import { ThesaurusRule } from 'app/core/services/api-client';

@Ducksify<ThesaurusRulesState>({
  initialState: {
    thesaurusRules: [],
    selectedThesaurusRule: null,
    isBusy: false
  },
})
export class ThesaurusRulesDucks {
  public readonly getAllThesaurusRulesEffect = effect<void>('[Thesaurus rules (Effect)] Load all thesaurus rules');
  public readonly updateThesaurusRuleEffect = effect<ThesaurusRule>('[Thesaurus rules (Effect)] Update a thesaurus rule');
  public readonly saveThesaurusRuleEffect = effect<ThesaurusRule>('[Thesaurus rules (Effect)] Saves a thesaurus rule');
  public readonly deleteThesaurusRuleEffect = effect<string>('[Thesaurus rules (Effect)] Deletes a thesaurus rule');

  @Action('[Thesaurus rules] Load all thesaurus rules')
  public getAllThesaurusRules(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Thesaurus rules] Load all thesaurus rules (Success)')
  public getAllThesaurusRulesSuccess(state: ThesaurusRulesState, thesaurusRules: ThesaurusRule[]): typeof state {
    return {
      ...state,
      isBusy: false,
      thesaurusRules: thesaurusRules,
    };
  }

  @Action('[Thesaurus rules] Load all thesaurus rules (Error)')
  public getAllThesaurusRulesError(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Thesaurus rules] Update a thesaurus rule')
  public updateThesaurusRule(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Thesaurus rules] Update a thesaurus rule (Success)')
  public updateThesaurusRuleSuccess(state: ThesaurusRulesState, rule: ThesaurusRule): typeof state {
    return {
      ...state,
      isBusy: false,
      thesaurusRules: updateThesaurusRule(state.thesaurusRules, rule),
    };
  }

  @Action('[Thesaurus rules] Update a thesaurus rule (Error)')
  public updateThesaurusRuleError(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Thesaurus rules] Add new thesaurus rule')
  public addThesaurusRule(state: ThesaurusRulesState, rule: ThesaurusRule): typeof state {
    return {
      ...state,
      selectedThesaurusRule: rule,
    };
  }

  @Action('[Thesaurus rules] Sets the name from the selected thesaurus rule')
  public setSelectedThesaurusRule(state: ThesaurusRulesState, selectedThesaurusRule: ThesaurusRule): typeof state {
    return {
      ...state,
      selectedThesaurusRule: selectedThesaurusRule,
    };
  }

  @Action('[Thesaurus rules] Saves a thesaurus rule')
  public saveThesaurusRule(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Thesaurus rules] Saves a thesaurus rule (Success)')
  public saveThesaurusRuleSuccess(state: ThesaurusRulesState, savedRule: ThesaurusRule): typeof state {
    return {
      ...state,
      isBusy: false,
      thesaurusRules: [...state.thesaurusRules, savedRule],
      selectedThesaurusRule: null
    };
  }

  @Action('[Thesaurus rules] Saves a thesaurus rule (Error)')
  public saveThesaurusRuleError(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Thesaurus rules] Deletes a thesaurus rule')
  public deleteThesaurusRule(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Thesaurus rules] Deletes a thesaurus rule (Success)')
  public deleteThesaurusRuleSuccess(state: ThesaurusRulesState, ruleName: string): typeof state {
    return {
      ...state,
      isBusy: false,
      thesaurusRules: state.thesaurusRules.filter(rule => rule.name !== ruleName),
    };
  }

  @Action('[Thesaurus rules] Deletes a thesaurus rule (Error)')
  public deleteThesaurusRuleError(state: ThesaurusRulesState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }
}

function updateThesaurusRule(rules: ThesaurusRule[], update: ThesaurusRule): ThesaurusRule[] {
  const r = [ ...rules ];
  const newRules = r.filter(rule => rule.name !== update.name);
  newRules.push(update);
  return newRules;
}

function addNewThesaurusRule(ruleName: string): ThesaurusRule {
  return new ThesaurusRule({
    name: ruleName,
    keys: [],
    description: '',
    sourceQuery: '',
    intermediateQuery: '',
    targetQuery: ''
  });
}

export function thesaurusRulesReducer(state: ThesaurusRulesState, action: DucksifiedAction): typeof state {
  return reducerFrom(ThesaurusRulesDucks)(state, action);
}
