import { Injectable, EventEmitter } from '@angular/core';
import { ExpertSearch, ExternalQuery } from '@models/expert-search';
import { SimpleSearch } from '@models/simple-search';
import { TableSearch } from '@models/table-search';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {

    public event = new EventEmitter();
    public visiblePanels: string[] = [];
    public expertSearch: ExpertSearch;
    public tableSearch: TableSearch;
    public simpleSearch: SimpleSearch;
    public maintenance = new BehaviorSubject<any>(null);

    constructor() {
        this.expertSearch = new ExpertSearch;
        this.expertSearch.SearchQueryString = new ExternalQuery();
        this.tableSearch = new TableSearch;
        this.simpleSearch = new SimpleSearch;
    }

    toggleNav(toggle: boolean) {
        this.event.next(toggle);
    }

    togglePanels(panel: string): string[] {
        const index = this.visiblePanels.indexOf(panel);

        if (index !== -1) {
            this.visiblePanels.splice(index, 1);
        } else {
            this.visiblePanels.push(panel);
        }

        return this.visiblePanels;
    }

    showPanel(panel: string): string[] {
        const index = this.visiblePanels.indexOf(panel);
        if (index === -1) {
            this.visiblePanels.push(panel);
        }
        return this.visiblePanels;
    }

    hidePanel(panel: string): string[] {
        const index = this.visiblePanels.indexOf(panel);
        if (index !== -1) {
            this.visiblePanels.splice(index, 1);
        }
        return this.visiblePanels;
    }

    initializePanels(panels: string[]): string[] {
        this.visiblePanels = panels;
        return this.visiblePanels;
    }
}
