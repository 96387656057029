import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { KeyValue } from 'app/core/utils/keyValue';
import * as _ from 'lodash';
import { SachverhaltField, SachverhaltPrimitiveValue, SachverhaltSet } from '../../../../models/dossier/dossier-models';
import { FeedbackService } from 'app/core/services/feedback/feedback.service';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { CreateFeedbackItemCategory } from 'app/core/services/api-client';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { IUser } from 'app/modules/shared-area/ducks/user/models/IUser';

@Component({
  selector: 'app-dynamic-control',
  templateUrl: './dynamic-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicControlComponent implements OnInit, OnDestroy {

  @Input()
  public optionValues: SachverhaltSet;

  @Input()
  public field: SachverhaltField;

  @Input()
  public value: SachverhaltPrimitiveValue;

  @Input()
  public maximizeWidth = true;

  @Output()
  public rangeChanged = new EventEmitter<{ value: KeyValue[], fieldKey: string }>();

  @Output()
  public textValueChange = new EventEmitter<{ value: any, fieldKey: string }>();

  @Output()
  public intValueChange = new EventEmitter<{ value: number, fieldKey: string }>();

  @Output()
  public doubleValueChange = new EventEmitter<{ value: number, fieldKey: string }>();

  @Output()
  public dateChange = new EventEmitter<{ value: Date, fieldKey: string }>();

  @Output()
  public dropdownValueChange = new EventEmitter<{ value: any, fieldKey: string }>();

  @Output()
  public fileLinkChanged = new EventEmitter<{ value: any, fieldKey: string }>();

  public userHasRight = false;
  private requiredUserRight = '710';
  private userDataSubscr: Subscription;

  constructor(private feedbackService: FeedbackService,
              private i18n: I18nService,
              private securityService: OidcSecurityService) {

  }

  ngOnInit() {
    this.userDataSubscr = this.securityService.userData$.subscribe((user: IUser) => {
      if (user && user.right != null) {
        this.userHasRight = user.right.includes(this.requiredUserRight);
      }
    });
  }

  ngOnDestroy(): void {
    this.userDataSubscr.unsubscribe();
  }

  public StringAsDate(value: string): Date {
    if (_.isNull(value) || _.isUndefined(value)) {
      return null;
    }
    return new Date(value);
  }

  public validateNumber(event: any) {
    const numRegex = new RegExp('^[0-9]*$');
    const maxValue = 2147483647; // intMax
    if (numRegex.test(event.target.value) === false) {
      event.target.value = event.target.value.replace(/\D/g, '');
    }
    if (parseInt(event.target.value, 10) > maxValue) {
      event.target.value = maxValue.toString();
    }
  }

  public intValueChangeHandler(value: number, field: string) {
    this.intValueChange.emit({ value: value, fieldKey: field });
  }

  openFeedbackWindow(table: string, value: string) {
    const subject = this.i18n.getLocalizedString('components.lookup.feedback.subject')
                        .replace('{{table}}', table);
    const description = this.i18n.getLocalizedString('components.lookup.feedback.description')
                            .replace('{{table}}', table);
    this.feedbackService.openTemplated(subject, description, CreateFeedbackItemCategory.LookupTableEntry, table, value);
  }

  feedbackButtonIsVisible(field: SachverhaltField): boolean {
    return !(this.userHasRight && field.isOpen) && field.table !== null;
  }
}
