import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { ToastaService } from 'ngx-toasta';

@Injectable()
export class InternalServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastyService: ToastaService,
    private i18n: I18nService,
  ) {
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe
      (
        catchError(error => {
          console.log(error);
          if (error instanceof HttpErrorResponse && error.status === 500) {
            this.toastyService.error({
              title: this.i18n.getLocalizedString('errors.500.title'),
              msg: this.i18n.getLocalizedString('errors.500.message'),
            });
          }
          return throwError(error);
        }),
      );
  }
}

