import { I18nService } from 'app/core/services/i18n/i18n.service';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { catchError } from 'rxjs/operators';
import { ToastaService } from 'ngx-toasta';
import { ApiClient, CreateFeedbackItem, FeedbackItem, FeedbackItemsResult, FilterByStatus, Mode2, UpdateFeedbackItem, CreateFeedbackItemCategory } from 'app/core/services/api-client/api-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';

export class SelectedSachverhalte {
  Sachverhalte: number[];
  MerkmalName: string;
  MerkmalId: number;
}

export class OpenTemplatedMessage {
  subject: string;
  description: string;
  category: CreateFeedbackItemCategory;
  table: string;
  value: string;
}

@Injectable()
export class FeedbackService {

  private openTemplatedSource = new BehaviorSubject<OpenTemplatedMessage>(null);
  openTemplatedMessage = this.openTemplatedSource.asObservable();

  private stofffeedbackGeoeffnetSource = new BehaviorSubject(false);
  currentStofffeedbackGeoeffnetMessage = this.stofffeedbackGeoeffnetSource.asObservable();

  private selectedSachverhalte: number[] = [];
  private selectedMerkmalName: string;
  private selectedMerkmalId?: number;

  private selectedSachverhalteSource = new BehaviorSubject(new SelectedSachverhalte());
  currentSelectedSachverhalteMessage = this.selectedSachverhalteSource.asObservable();

  constructor(private http: HttpClient,
    private apiClient: ApiClient,
    private customToastyService: CustomToastyService,
    private toastyService: ToastaService,
    private i18n: I18nService,
    private errorHandler: ApiErrorHandlerService) {

  }

  private publishSelectedSachverhalte() {
    const selectedSachverhalte = new SelectedSachverhalte();
    selectedSachverhalte.MerkmalName = this.selectedMerkmalName;
    selectedSachverhalte.MerkmalId = this.selectedMerkmalId;
    selectedSachverhalte.Sachverhalte = this.selectedSachverhalte;
    this.selectedSachverhalteSource.next(selectedSachverhalte);
  }

  public getOwnFeedbackList(skip: number, pageSize: number, filterByStatus: FilterByStatus[], sortColumn: string, sortAsc: boolean): Observable<FeedbackItemsResult> {
    return this.apiClient.apiFeedbackGet(Mode2.Own, skip, pageSize, filterByStatus, sortColumn, sortAsc).pipe(
      catchError(
        this.apiClient.apiFeedbackGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }),
      ),
    );
  }

  public getSystemFeedbackList(skip: number, pageSize: number, filterByStatus: FilterByStatus[], sortColumn: string, sortAsc: boolean): Observable<FeedbackItemsResult> {
    return this.apiClient.apiFeedbackGet(Mode2.System, skip, pageSize, filterByStatus, sortColumn, sortAsc).pipe(
      catchError(
        this.apiClient.apiFeedbackGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }),
      ),
    );
  }

  public getStoffFeedbackList(skip: number, pageSize: number, filterByStatus: FilterByStatus[], sortColumn: string, sortAsc: boolean): Observable<FeedbackItemsResult> {
    return this.apiClient.apiFeedbackGet(Mode2.Stoffdaten, skip, pageSize, filterByStatus, sortColumn, sortAsc).pipe(
      catchError(
        this.apiClient.apiFeedbackGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }),
      ),
    );
  }

  public getLookupTablesFeedbackList(skip: number, pageSize: number, filterByStatus: FilterByStatus[], sortColumn: string, sortAsc: boolean): Observable<FeedbackItemsResult> {
    return this.apiClient.apiFeedbackGet(Mode2.LookupTables, skip, pageSize, filterByStatus, sortColumn, sortAsc).pipe(
      catchError(
        this.apiClient.apiFeedbackGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }),
      ),
    );
  }

  public save(feedback: CreateFeedbackItem): Observable<any> {
    return this.apiClient.apiFeedbackByFeedbackUidPost(feedback.feedbackUid, feedback)
      .pipe
      (
        catchError(this.apiClient.apiFeedbackByFeedbackUidPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error409Handler: error409 => this.errorHandler.handle409Error(error409),
        })),
      );
  }

  public update(feedbackUid: string, values?: UpdateFeedbackItem | null | undefined): Observable<FeedbackItem> {
    return this.apiClient.apiFeedbackByFeedbackUidPut(feedbackUid, values).pipe(
      catchError(this.apiClient.apiFeedbackByFeedbackUidPutErrorHandlers({
        error400Handler: error400 => this.errorHandler.handle400Error(error400),
        error401Handler: error401 => this.errorHandler.handle401Error(error401),
        error403Handler: error403 => this.errorHandler.handle403Error(error403),
        error404Handler: error404 => this.errorHandler.handle404Error(error404),
      })),
    );
  }

  public clear(ticketGuid: string): Observable<any> {
    const url = environment.baseAPIUrl + environment.feedback + ticketGuid;
    return this.http
      .delete(url)
      .pipe(catchError((error: any) => {
        this.customToastyService.toastError(error);
        return observableThrowError(error);
      }));
  }

  public openTemplated(subject: string, description: string, category: CreateFeedbackItemCategory, table: string, value: string) {
    this.openTemplatedSource.next({
      subject: subject,
      description: description,
      category: category,
      table: table,
      value: value
    });
  }


  public clearSachverhalte() {
    this.selectedSachverhalte = [];
    this.selectedMerkmalName = null;
    this.selectedMerkmalId = null;
    this.publishSelectedSachverhalte();
  }

  public isSachverhaltSelected(sachverhaltId: number): boolean {
    return this.selectedSachverhalte.indexOf(sachverhaltId) >= 0;
  }

  public toggleSachverhalt(sachverhaltId: number, merkmalName: string, merkmalId: number): boolean {
    // console.log("toggleSachverhalt(" + sachverhaltId + ", " + merkmalName + ")");
    if (this.selectedSachverhalte.indexOf(sachverhaltId) >= 0) {
      this.selectedSachverhalte.splice(this.selectedSachverhalte.indexOf(sachverhaltId), 1);
      if (this.selectedSachverhalte.length === 0) {
        this.selectedMerkmalName = null;
        this.selectedMerkmalId = null;
      }
      this.publishSelectedSachverhalte();
      return true;
    } else if (this.selectedSachverhalte.length === 0 || this.selectedMerkmalName === merkmalName) {
      this.selectedSachverhalte.push(sachverhaltId);
      this.selectedMerkmalName = merkmalName;
      this.selectedMerkmalId = merkmalId;
      this.publishSelectedSachverhalte();
      return true;
    } else {
      this.toastyService.info({
        title: this.i18n.getLocalizedString('components.stofffeedback.multiAttributeToastTitle'),
        msg: this.i18n.getLocalizedString('components.stofffeedback.multiAttributeToastMessage'),
        theme: 'bootstrap',
      });
      return false;
    }
  }
}
