import { Action, DucksifiedAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { IReferencesState } from './references.state';
import { ObjektId } from '@models/objekt-id';
import { ReferencesResult } from 'app/core/services/api-client';

@Ducksify<IReferencesState>({
  initialState: {
    references: null,
    isBusy: false,
    id: null,
  },
})
export class ReferencesDucks {

  @Action('[References] Show references')
  showReferences(state: IReferencesState, id: ObjektId): typeof state {
    return {
      ...state,
      id: id,
      references: null,
      isBusy: true,
    };
  }

  @Action('[References] Show references (Success)')
  showReferencesSuccess(state: IReferencesState, result: ReferencesResult): typeof state {
    return {
      ...state,
      references: result,
      isBusy: false,
    };
  }

  @Action('[References] Load more')
  loadMore(state: IReferencesState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[References] Load more (Success)')
  loadMoreSuccess(state: IReferencesState, result: ReferencesResult): typeof state {
    const newState = {
      ...state,
      isBusy: false,
    };
    newState.references.total = result.total;
    for (const r of result.references) {
      newState.references.references.push(r);
    }
    return newState;
  }
}

export function referencesReducer(state: IReferencesState, action: DucksifiedAction): typeof state {
  return reducerFrom(ReferencesDucks)(state, action);
}
