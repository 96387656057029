import { ModuleWithProviders, NgModule } from '@angular/core';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { CustomWindowService } from 'app/core/services/custom-window-service/custom-window.service';

@NgModule({
  imports: [ WindowModule ],
})
export class CustomWindowModule {
  public static forRoot(): ModuleWithProviders<CustomWindowModule> {
    return {
      ngModule: CustomWindowModule,
      providers: [ CustomWindowService ],
    };
  }
}
