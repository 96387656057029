import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiClientModule } from 'app/core/services/api-client/api-client.module';
import { ApiErrorHandlerServiceModule } from 'app/core/services/api-default-error-handler/api-error-handler.service.module';
import { ErfassungInfoService } from 'app/core/services/erfassung-info/erfassung-info.service';

@NgModule({
  imports: [
    ApiClientModule,
    ApiErrorHandlerServiceModule
  ],
})
export class ErfassungInfoModule {
  public static forRoot(): ModuleWithProviders<ErfassungInfoModule> {
    return {
      ngModule: ErfassungInfoModule,
      providers: [ ErfassungInfoService ],
    };
  }
}
