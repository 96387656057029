import { ModuleWithProviders, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { I18nService } from 'app/core/services/i18n/i18n.service';

@NgModule({
  imports: [ TranslateModule ]
})
export class I18nModule {
  static forRoot(): ModuleWithProviders<I18nModule> {
    return {
      ngModule: I18nModule,
      providers: [ I18nService ]
    };
  }
}
