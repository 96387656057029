import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ActionbarModule } from 'app/shared/components/actionbar/actionbar.module';

import { TextBoxModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ConfigurationsEditComponent } from './configurations-edit.component';
import { PageHeaderModule } from 'app/core/layout/page-header/page-header.module';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { StoreModule } from '@ngrx/store';
import { ConfigurationRankingStepModule } from '../configuration-ranking-step/configuration-ranking-step.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    GridModule,
    I18nModule,
    TranslateModule,
    CommonModule,
    ActionbarModule,
    TextBoxModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropDownsModule,
    NumericTextBoxModule,
    PageHeaderModule,
    TreeViewModule,
    StoreModule,
    ConfigurationRankingStepModule,
    RouterModule
  ],
  declarations: [ ConfigurationsEditComponent ],
  exports: [ ConfigurationsEditComponent ],
})
export class ConfigurationsEditModule {
}
