import { SuchanfrageKontext } from 'app/core/services/api-client';

export class ExpertSearch {
  SearchClause: string;
  StructureSearch: any;
  Operation: string;
  SearchQueryString: ExternalQuery;
  Kontext: SuchanfrageKontext;
  constructor() {
    this.SearchClause = '';
  }
}

export class ExternalQuery {
  key: string;
  value: string;

  constructor() {
    this.key = '';
    this.value = '';
  }
}
