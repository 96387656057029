<form (ngSubmit)="submit()"
      [formGroup]="form"
      class="form-horizontal form-label-left">
  <input type="hidden"
         id="isNew"
         formControlName="isNew">
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.ruleName' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input kendoTextBox
             *ngIf="isNew"
             id="name"
             pattern="[A-Za-z0-9_]*"
             formControlName="name">
      <ng-container *ngIf="!isNew">
        <div style="padding-top:6px">{{rule.name}}</div>
        <input type="hidden"
               id="name"
               formControlName="name">
      </ng-container>
    </div>
  </div>

  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.sourceQuery' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input style="width: 100%"
             class="k-textbox"
             id="sourceQuery"
             formControlName="sourceQuery">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.intermediateQuery' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input style="width: 100%"
             class="k-textbox"
             id="intermediateQuery"
             formControlName="intermediateQuery">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.targetQuery' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input style="width: 100%"
             class="k-textbox"
             id="targetQuery"
             formControlName="targetQuery">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.keys' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input kendoTextBox
             style="width: 100%"
             id="keys"
             formControlName="keys">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.rule' | translate}}*
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <input style="width: 100%"
             class="k-textbox"
             id="rule"
             formControlName="rule">
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.description' | translate}}
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9">
      <kendo-textbox-container style="width: 100%; height: 100px">
        <textarea kendoTextArea
                  style="resize: none"
                  id="description"
                  formControlName="description"></textarea>
      </kendo-textbox-container>
    </div>
  </div>
  <div class="form-group">
    <label class="control-label col-md-3 col-sm-3 col-xs-3">
      {{'components.thesaurusRulesEdit.active' | translate}}
    </label>
    <div class="col-md-9 col-sm-9 col-xs-9" style="padding-top: 5px;">
      <input type="checkbox" formControlName="active" id="active" class="k-checkbox"> 
      <label class="k-checkbox-label" [for]="'active'"></label>
    </div>
  </div>
  <div class="form-group"
       style="display: flex; justify-content: flex-end; padding:5px">
    <button id="btn_close"
            class="btn btn-default"
            (click)="cancel()">{{'action.cancel' | translate}}</button>
    <button id="btn_save"
            class="btn btn-primary"
            type="submit"
            [disabled]="form.invalid">
      <i class="fas fa-save"></i>
      {{'action.save' | translate}}</button>
  </div>
</form>
