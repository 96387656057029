import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from 'angular-auth-oidc-client';
import { AuthorizationGuard } from 'app/core/authorization/authorization.guard';
import { LoginModule } from 'app/core/authorization/login/login.module';
import { NotFoundModule } from 'app/core/authorization/not-found/not-found.module';
import { UnauthorizedModule } from 'app/core/authorization/unauthorized/unauthorized.module';
import { StorageModule } from 'app/core/services/storage/storage.module';

@NgModule({
  imports: [
    RouterModule,
    AuthModule,
    StorageModule,
    UnauthorizedModule,
    LoginModule,
    NotFoundModule
  ],
})
export class AuthorizationModule {
  static forRoot(): ModuleWithProviders<AuthorizationModule> {
    return {
      ngModule: AuthorizationModule,
      providers: [AuthorizationGuard]
    };
  }
}
