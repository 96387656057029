import { Inject, NgModule, Injectable } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { FeedbackDucks } from './feedback.ducks';
import { FeedbackService } from 'app/core/services/feedback/feedback.service';
import { CreateFeedbackItem, UpdateFeedbackItem } from 'app/core/services/api-client';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { FeedbackServiceModule } from 'app/core/services/feedback/feedback-service.module';
import { getOwnFeedback, getStoffFeedback, getSystemFeedback, mapTableFilterToArray, getLookupTablesFeedback } from 'app/modules/shared-area/ducks/feedback/feedback.selectors';
import { first } from 'rxjs/internal/operators/first';
import { SortDescriptor } from '@progress/kendo-data-query';

@NgModule({
  imports: [
    // CustomToastyModule, // import throws exception caused by a Toasty bug
    FeedbackServiceModule,
  ],
})
export class FeedbackEffectsModule {}

@Injectable()
export class FeedbackEffects {
  public constructor(
    @Inject(FeedbackDucks) private readonly feedbacksDucks: Duck<FeedbackDucks>,
    private readonly toastyService: CustomToastyService,
    private readonly feedbackService: FeedbackService) {
  }

  public async loadOwnFeedbacks(): Promise<void> {
    this.feedbacksDucks.loadOwnFeedbacks();
    try {
      const { skip, pageSize, filter, sort } = await this.feedbacksDucks.pick(getOwnFeedback).pipe(first()).toPromise();
      const [ sortColumn, sortAsc ] = this.getSortParameters(sort);
      const feedbackItems = await this.feedbackService.getOwnFeedbackList(skip, pageSize, mapTableFilterToArray(filter), sortColumn, sortAsc).toPromise();
      this.feedbacksDucks.loadOwnFeedbacksSuccess(feedbackItems);
    } catch (error) {
      this.feedbacksDucks.loadOwnFeedbacksError();
    }
  }

  public async loadSystemFeedbacks(): Promise<void> {
    this.feedbacksDucks.loadSystemFeedbacks();
    try {
      const { skip, pageSize, filter, sort } = await this.feedbacksDucks.pick(getSystemFeedback).pipe(first()).toPromise();
      const [ sortColumn, sortAsc ] = this.getSortParameters(sort);
      const feedbackItems = await this.feedbackService.getSystemFeedbackList(skip, pageSize, mapTableFilterToArray(filter), sortColumn, sortAsc).toPromise();
      this.feedbacksDucks.loadSystemFeedbacksSuccess(feedbackItems);
    } catch (error) {
      this.feedbacksDucks.loadSystemFeedbacksError();
    }
  }

  public async loadStoffFeedbacks(): Promise<void> {
    this.feedbacksDucks.loadStoffFeedbacks();
    try {
      const { skip, pageSize, filter, sort } = await this.feedbacksDucks.pick(getStoffFeedback).pipe(first()).toPromise();
      const [ sortColumn, sortAsc ] = this.getSortParameters(sort);
      const feedbackItems = await this.feedbackService.getStoffFeedbackList(skip, pageSize, mapTableFilterToArray(filter), sortColumn, sortAsc).toPromise();
      this.feedbacksDucks.loadStoffFeedbacksSuccess(feedbackItems);
    } catch (error) {
      this.feedbacksDucks.loadStoffFeedbacksError();
    }
  }

  public async loadLookupTablesFeedbacks(): Promise<void> {
    this.feedbacksDucks.loadLookupTablesFeedbacks();
    try {
      const { skip, pageSize, filter, sort } = await this.feedbacksDucks.pick(getLookupTablesFeedback).pipe(first()).toPromise();
      const [ sortColumn, sortAsc ] = this.getSortParameters(sort);
      const feedbackItems = await this.feedbackService.getLookupTablesFeedbackList(skip, pageSize, mapTableFilterToArray(filter), sortColumn, sortAsc).toPromise();
      this.feedbacksDucks.loadLookupTablesFeedbacksSuccess(feedbackItems);
    } catch (error) {
      this.feedbacksDucks.loadLookupTablesFeedbacksError();
    }
  }

  public async createFeedback(feedback: CreateFeedbackItem): Promise<void> {
    try {
      await this.feedbackService.save(feedback).toPromise();
      this.toastyService.toastSuccessFormatted('feedback.messages.createSuccess.title', 'feedback.messages.createSuccess.message', {});
    } catch (error) {
      this.toastyService.toastErrorFormatted('feedback.messages.createError.title', 'feedback.messages.createError.message', {});
    }
  }

  public async updateFeedbackEffect(update: { uid: string, item: UpdateFeedbackItem }): Promise<void> {
    try {
      const updated = await this.feedbackService.update(update.uid, update.item).toPromise();
      this.toastyService.toastSuccessFormatted('feedback.messages.updateSuccess.title', 'feedback.messages.updateSuccess.message', {});
      this.feedbacksDucks.updateLocalFeedback(updated);
    } catch (error) {
      this.toastyService.toastErrorFormatted('feedback.messages.updateError.title', 'feedback.messages.updateError.message', {});
    }
  }

  public cancelFeedback = async (ticketGuid: string) => {
    try {
      await this.feedbackService.clear(ticketGuid).toPromise();
    } catch (error) {
      console.error('Unable to cancel feedback.');
    }
  }

  private getSortParameters(sort: SortDescriptor[]): [ string | null, boolean | null ] {
    if (sort.length === 0) {
      return [ null, null ];
    }
    const sorting = sort[ 0 ];
    return [ sorting.field, sorting.dir === 'asc' ];
  }
}
