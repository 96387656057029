import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DossierAreaState } from 'app/modules/dossier-area/dossier-area.module';
import { IAppState } from 'app/modules/shared-area/root-state';
import { DossierHelperService } from 'app/modules/dossier-area/services/dossier-helper/dossier-helper.service';

const dossierAreaState = createFeatureSelector<DossierAreaState>('dossier-area');
const sharedArea = (state: IAppState) => state.shared;
const showmMerkmaleSelector = createSelector(sharedArea, state => state.panels.dossierView.shownMerkmale);
const showAllMerkmaleSelector = createSelector(sharedArea, state => state.panels.dossierView.showAllMerkmale);

export const dossierSelector = createSelector(dossierAreaState, state => {
  return state.dossier;
});

export const getSubstanceId = createSelector(dossierSelector, state => state.id);

export const getDossier = createSelector(dossierSelector, state => state.dossier);
export const getFilteredDossier = createSelector(getDossier, showmMerkmaleSelector, showAllMerkmaleSelector, (dossier, shownMerkmale, showAllMerkmale) =>
  dossier ? DossierHelperService.removeMerkmaleFromDossier(dossier, shownMerkmale, showAllMerkmale) : null);

export const isDossierLoading = createSelector(dossierSelector, state => state.isLoading);
export const isDossierDeleted = createSelector(dossierSelector, state => state.isDeleted === true && state.isDeleting === false);
export const getRoleContext = createSelector(dossierSelector, state => state.roleContext);

export const getDossierType = createSelector(getDossier, state => state ? state.type : null);

export const renderingItemsSelector = createSelector(dossierSelector, state => state.renderingItems);
export const isPartialUpdateInProgressSelector = createSelector(dossierSelector, state => state.isPartialUpdateInProgress);
export const isPanelRenderingInProgressSelector = createSelector(renderingItemsSelector, isPartialUpdateInProgressSelector,
  (renderingItems, isPartialUpdateInProgress) => renderingItems > 0 || isPartialUpdateInProgress);

export const renderStateSelector = createSelector(dossierSelector, state => state.renderState);
export const panelRenderStateSelector = (panelName: string) => createSelector(renderStateSelector, state => state.get(panelName));

export const panelIsRenderingSelector = (panelName: string) => createSelector(panelRenderStateSelector(panelName), state => {
  return state === 'rendering';
});
export const panelIsRenderedSelector = (panelName: string) => createSelector(panelRenderStateSelector(panelName), state => {
  return state === 'rendered';
});

export const searchStateSelector = createSelector(dossierSelector, state => state.searchState);
export const searchSelector = createSelector(searchStateSelector, state => state.search);
export const ignoreCaseSelector = createSelector(searchStateSelector, state => state.isIgnoreCase);
export const searchHitsSelector = createSelector(searchStateSelector, state => state.hits);
export const getNumberOfSearchResultsSelector = createSelector(searchStateSelector, state => state.hits.size);
export const getCurrentSearchHitSelector = createSelector(searchStateSelector, state => state.currentHit);
export const isSubmitBusy = createSelector(dossierSelector, state => state.submitIsBusy);
export const searchStateStateSelector = createSelector(searchStateSelector, state => state.state);

const copySachverhalteSelector = createSelector(dossierSelector, state => state.copySachverhalte);
export const showCopySachverhalteDialogSelector = createSelector(copySachverhalteSelector, state => state.showDialog);
export const copySachverhalteIdsSelector = createSelector(copySachverhalteSelector, state => state.sachverhaltIds);
export const copySachverhalteIsLoadingSelector = createSelector(copySachverhalteSelector, state => state.isLoading);

