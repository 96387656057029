import { Component, OnInit, Input, Inject } from '@angular/core';
import * as _ from 'lodash';
import { FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';
import { environment } from '../../../../../environments/environment';
import { CustomToastyService } from '../../../../core/services/customToasty/custom-toasty.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { DossierDucks } from '../../pages/dossier/ducks/dossier.ducks';
import { Duck } from '@ngrx-ducks/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.css']
})
export class FileDialogComponent implements OnInit {

  public selectedKey = '';
  public selectedKeyIndex = '';
  public expandedKeys = new Array<string>();
  public merkmale: any[];
  public uploadSaveUrl: string;
  public uploadRemoveUrl: string;
  public fileRestrictions: FileRestrictions = {
    // maxFileSize: 4194304, // Bytes = 4 MB
    allowedExtensions: ['.zip']
  };



  @Input() id: string;
  @Input() dialog: DialogRef;

  constructor(@Inject(DossierDucks) private dossierDucks: Duck<DossierDucks>,
    private customToastyService: CustomToastyService,
    public oidcSecurityService: OidcSecurityService) {
  }

  ngOnInit() {
    if (!_.isNull(this.selectedKeyIndex) && !_.isUndefined(this.selectedKeyIndex)) {
      const newKeys = [];
      this.expandedKeys.forEach(index => {
        if (this.selectedKeyIndex.indexOf(index) !== -1) {
          newKeys.push(index);
        }
      });
      this.expandedKeys = newKeys;
    }
    this.uploadSaveUrl = environment.baseAPIUrl + '/api/Stoff/' + this.id + '/Bearbeiten/ChemSketchImportieren';
  }

  public completed() {
    this.dialog.close();
    this.dossierDucks.loadSubstance({
      id: this.id,
    });
  }

  errorEventHandler(e: any) {
    this.customToastyService.toastError(e.response);
  }

  onUpload(e: UploadEvent) {

    const token = this.oidcSecurityService.getToken();
    if (token !== '') {
      const tokenValue = 'Bearer ' + token;
      e.headers = e.headers.append('Authorization', tokenValue);
    }
  }
}
