import { ApprovalHistoryItem, DossierEintrag, DossierEintragItem } from '@models/dossier/dossier-models';
import { Locks } from '@models/dossier/locks';
import { Dossier as DossierDto, DossierErfassungStatus, Reference, DossierErfassungType, UserInfo } from 'app/core/services/api-client';
import { Role } from 'app/modules/shared-area/ducks/user/models/role';

export class Dossier {
  erfassungStatus?: 'Neu' | 'InFreigabe' | 'Freigegeben' | 'Zurueckgewiesen' | 'Abgeschlossen' | 'AbgeschlossenMitFehler' | 'AbgeschlossenAusgeblendet' | 'AbgeschlossenMitFehlerAusgeblendet' | 'WirdGeloescht';
  erfassungType?: 'Create' | 'Update' | 'Delete' | 'Merge' | 'BatchUpdate' | 'BatchDelete' | 'Import';
  items: (DossierEintrag | DossierEintragItem)[];
  locks: Locks;
  erfassungTitle?: string;
  erfassungOwner: UserInfo;
  roleContext?: Role;
  erfassungRequestForApprovalMessage: string;
  erfassungApprovalHistory: ApprovalHistoryItem[];
  affectedObjects: Reference[];
  name: string;
  objektTyp: 'Stoff' | 'Zitat' | 'Lieferant' | 'Spezies';
  objektId?: string;
  type?: number;
  errors?: number;
  erfassungId?: number;

  public static fromDto(dossierDto: DossierDto): Dossier {
    if (dossierDto == null) {
      return null;
    }
    const vm = new Dossier();
    vm.erfassungStatus = <keyof typeof DossierErfassungStatus>dossierDto.erfassungStatus;
    vm.erfassungType = <keyof typeof DossierErfassungType>dossierDto.erfassungType;
    vm.erfassungOwner = dossierDto.erfassungOwner;
    vm.erfassungRequestForApprovalMessage = dossierDto.erfassungRequestForApprovalMessage;
    vm.name = dossierDto.name;
    vm.objektId = dossierDto.objektId;
    vm.objektTyp = dossierDto.objektTyp;
    vm.erfassungTitle = dossierDto.erfassungTitel;
    vm.errors = dossierDto.errors;
    vm.erfassungId = dossierDto.erfassungId;
    vm.items = <any>dossierDto.items;

    vm.locks = Locks.fromDto(dossierDto.locks);
    if (dossierDto.erfassungApprovalHistory) {
      vm.erfassungApprovalHistory = dossierDto.erfassungApprovalHistory.map(x => ApprovalHistoryItem.fromDto(x));
    }

    vm.affectedObjects = dossierDto.affectedObjects;

    vm.type = dossierDto.type;

    return vm;
  }
}
