import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { FileInfo, FileRestrictions } from '@progress/kendo-angular-upload';
import { uuid } from 'lodash-uuid';
import { DropdownListTextItem } from 'app/models/dropdownListTextItem';
import { I18nService } from 'app/core/services/i18n/i18n.service';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { categoryItems, priorityItems } from 'app/models/feedback';
import { environment } from '@environments/environment';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ICanCancel, ITriggerOnClose } from 'app/core/services/custom-window-service/custom-window.service';
import {
  CreateFeedbackItem,
  CreateFeedbackItemCategory,
  CreateFeedbackItemPriority,
  CreateFeedbackItemType,
  FeedbackItem,
  FeedbackItemCategory,
  FeedbackItemState,
  UpdateFeedbackItem,
  UpdateFeedbackItemState,
} from 'app/core/services/api-client/api-client';
import { FeedbackService } from 'app/core/services/feedback/feedback.service';
import { FeedbackStore } from 'app/modules/shared-area/ducks/feedback/feedback-store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback-view',
  styleUrls: [ './common-feedback.component.scss' ],
  templateUrl: './common-feedback.component.html',
})
export class CommonFeedbackComponent implements OnInit, ICanCancel, ITriggerOnClose {

  @ViewChild('commentInput', { read: ElementRef }) private commentInput: ElementRef<HTMLTextAreaElement>;
  public uploadSaveUrl: string;
  public uploadRemoveUrl: string;
  public fileRestrictions: FileRestrictions = {
    maxFileSize: 4194304, // Bytes = 4 MB
  };
  public files: FileInfo[];
  public feedbackState: string[];

  @Input() isEditMode = false;

  public feedbackUid = uuid();
  @Input()
  public feedback = new FeedbackItem();
  public createFeedback = new CreateFeedbackItem();
  public categoryListItems = categoryItems;
  public categoryItems: DropdownListTextItem[];
  public priorityListItems = priorityItems;
  public priorityItems: DropdownListTextItem[];
  @Output()
  public closeWindow: EventEmitter<FeedbackItem | null> = new EventEmitter<FeedbackItem | null>();

  private initialFeedbackState: string;

  constructor(private feedbackService: FeedbackService,
              private i18n: I18nService,
              private validationService: ValidationService,
              @Inject(WindowDucks) public windowDucks: Duck<WindowDucks>,
              private feedbackStore: FeedbackStore,
              private router: Router,
              private oidcSecurityService: OidcSecurityService,
  ) {
    this.setCategoryItems();
    this.setPriorityItems();

    this.createFeedback.subject = '';
    this.createFeedback.description = '';
    this.createFeedback.nachschlageTabelleName = '';
    this.createFeedback.nachschlageTabelleWert = '';
    this.createFeedback.category = CreateFeedbackItemCategory.None;
    this.createFeedback.priority = CreateFeedbackItemPriority.Normal;
    this.createFeedback.url = window.location.href;
    this.createFeedback.type = CreateFeedbackItemType.System;
  }

  ngOnInit(): void {
    this.setFeedbackState();
  }

  public setCategoryItems(): void {
    this.categoryItems = [];
    this.categoryListItems.forEach((key: string) => {
      this.categoryItems.push({
        text: this.i18n.getLocalizedString('components.systemfeedback.categories.' + key),
        value: key,
      });
    });
  }

  public setPriorityItems(): void {
    this.priorityItems = [];
    this.priorityListItems.forEach((key: string) => {
      this.priorityItems.push({
        text: this.i18n.getLocalizedString('models.feedback.priorities.' + key),
        value: key,
      });
    });
  }

  public setFeedbackState(): void {
    this.feedbackState = [];
    Object.keys(FeedbackItemState)
          .filter(state => this.canChangeState(state))
          .map(state => {
            this.feedbackState.push(state);
          });
  }

  private canChangeState(state: string) {
    const oldState = this.feedback.state || FeedbackItemState.Open;
    const newState = FeedbackItemState[ state ];
    return this.feedbackStore.validateFeedbackStateTransition(oldState, newState);
  }

  public async sendToApi() {
    this.createFeedback.feedbackUid = this.feedbackUid;
    await this.feedbackStore.effects.createFeedback(this.createFeedback);
    this.closeWindow.emit(this.feedback);

    // it's not possible to create a stoff-feedback inside the feedback-list view
    if (this.router.url === '/feedback' && this.createFeedback.type === CreateFeedbackItemType.System) {
      this.feedbackStore.effects.loadOwnFeedbacks();
      this.feedbackStore.effects.loadSystemFeedbacks();
      this.feedbackStore.effects.loadLookupTablesFeedbacks();
    }
  }

  private clear() {
    if (!this.isEditMode) {
      this.feedbackService.clear(this.createFeedback.feedbackUid);
    }
  }

  public cancel() {
    this.clear();
    this.closeWindow.emit(null);
  }

  public get canSend() {
    return !this.validationService.isNullOrEmpty(this.createFeedback.category)
           && !this.validationService.isNullOrEmpty(this.createFeedback.priority)
           && !this.validationService.isNullOrEmpty(this.createFeedback.subject)
           && !this.validationService.isNullOrEmpty(this.createFeedback.url)
           && !this.validationService.isNullOrEmpty(this.createFeedback.description);
  }

  public canUpdate(): boolean {
    if (this.commentInput) {
      return this.commentInput.nativeElement.value !== '' || this.initialFeedbackState !== this.feedback.state;
    }
    return false;
  }

  public updateCommonFeedbackState(state: FeedbackItemState) {

    this.feedback.state = FeedbackItemState[ state ];
  }

  public async updateCommonFeedback() {
    const item = new UpdateFeedbackItem({
      comment: this.commentInput.nativeElement.value,
      state: UpdateFeedbackItemState[ this.feedback.state ],
      emailExpected: this.feedback.emailExpected,
    });
    const update = <{ uid: string, item: UpdateFeedbackItem }>({
      uid: this.feedback.feedbackUid,
      item: item,
    });
    await this.feedbackStore.effects.updateFeedbackEffect(update);
    this.closeWindow.emit(this.feedback);
  }

  public initCommonFeedback(feedback: FeedbackItem | CreateFeedbackItem, isNew = false) {
    this.isEditMode = !isNew;
    if (!this.isEditMode) {
      this.createFeedback.feedbackUid = this.feedbackUid;
      this.uploadSaveUrl = environment.baseAPIUrl + environment.feedback + this.feedbackUid + '/save';
      this.uploadRemoveUrl = environment.baseAPIUrl + environment.feedback + this.feedbackUid + '/remove';
      const data = {
        ...this.createFeedback,
        ...feedback,
      };
      this.createFeedback = new CreateFeedbackItem(data as CreateFeedbackItem);
    } else {
      this.feedback = new FeedbackItem(feedback as FeedbackItem);
      if (this.feedback.category && this.feedback.category === FeedbackItemCategory.LookupTableEntry) {
        this.feedback.url = null;
      }
      this.initialFeedbackState = this.feedback.state;
    }
  }

  public fileUrl(url: string) {
    const token = this.oidcSecurityService.getToken();
    const apiServer = new URL(environment.baseAPIUrl).origin;
    return `${apiServer}${url}?access_token=${token}`;
  }
}
