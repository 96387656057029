import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'isMerkmalChecked',
  pure: true,
})
export class IsMerkmalCheckedPipe implements PipeTransform {
  transform(merkmale: { [ key: string ]: boolean }, alleMerkmale: string[], merkmalKey: string, showAllMerkmale: boolean): boolean {
    const isMerkmal = merkmalKey.indexOf('-M') > -1;
    if (isMerkmal) {
      return this.isChecked(<boolean>merkmale[ merkmalKey ], showAllMerkmale);
    }

    if (showAllMerkmale) {
      return !alleMerkmale
        .filter(merkmal => merkmal.startsWith(merkmalKey))
        .every(key => merkmale[ key ] === false);
    }

    return alleMerkmale
      .filter(merkmal => merkmal.startsWith(merkmalKey))
      .some(key => merkmale[ key ]);
  }

  private isChecked(isShowMerkmal: null | undefined | boolean, showAllMerkmale: boolean) {
    return isNil(isShowMerkmal) ? showAllMerkmale : isShowMerkmal;
  }
}
