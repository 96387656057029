import { Injectable } from '@angular/core';
import { ApiClient, ErfassungListItem } from 'app/core/services/api-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FreigabeProzessStartenResult, FreigebenResult, Mode, RequestForApprovalBatch } from 'app/core/services/api-client/api-client';

@Injectable()
export class ErfassungInfoService {

  public constructor(private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService,
 ) { }

  public get(mode: Mode): Observable<ErfassungListItem[]> {
    return this.apiClient.apiErfassungGet(mode)
      .pipe(
        catchError(this.apiClient.apiErfassungGetErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        })),
      );
  }

  public dismiss(erfassungId: number): Observable<any> {
    return this.apiClient.apiErfassungByErfassungIdAusblendenPost(erfassungId)
      .pipe(
        catchError(this.apiClient.apiErfassungByErfassungIdAusblendenPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403),
          error404Handler: error404 => this.errorHandler.handle404Error(error404)
        }))
      );
  }

  public submit(batch: RequestForApprovalBatch): Observable<FreigabeProzessStartenResult> {
    return this.apiClient.apiStoffBatchFreigabeProzessStartenPost(false, batch)
      .pipe(
        catchError(this.apiClient.apiStoffBatchFreigabeProzessStartenPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }))
      );
  }

  public approveAsAdmin(batch: number[]): Observable<FreigebenResult> {
    return this.apiClient.apiErfassungBatchFreigebenAlsLaenderadministratorPost(false, batch)
      .pipe(
        catchError(this.apiClient.apiErfassungBatchFreigebenAlsLaenderadministratorPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }))
      );
  }

  public approveAsLecturer(batch: number[]): Observable<FreigebenResult> {
    return this.apiClient.apiErfassungBatchFreigebenAlsFachlektorPost(false, batch)
      .pipe(
        catchError(this.apiClient.apiErfassungBatchFreigebenAlsFachlektorPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
        }))
      );
  }
}
