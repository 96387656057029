import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from 'angular-auth-oidc-client';
import { ApiErrorHandlerService } from 'app/core/services/api-default-error-handler/api-error-handler.service';
import { I18nModule } from 'app/core/services/i18n/i18n.module';

@NgModule({
  imports: [
    // ToastaModule.forChild(),
    I18nModule,
    RouterModule,
    AuthModule
  ],
})
export class ApiErrorHandlerServiceModule {
  public static forRoot(): ModuleWithProviders<ApiErrorHandlerServiceModule> {
    return {
      ngModule: ApiErrorHandlerServiceModule,
      providers: [ ApiErrorHandlerService ]
    };
  }
}
