import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { ActionbarModule } from 'app/shared/components/actionbar/actionbar.module';

import { TextBoxModule, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ImportAuftraegeEditComponent } from './import-auftraege-edit.component';
import { UploadModule } from '@progress/kendo-angular-upload';

@NgModule({
  imports: [
    I18nModule,
    TranslateModule,
    CommonModule,
    ActionbarModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropDownsModule,
    UploadModule,
  ],
  declarations: [ ImportAuftraegeEditComponent ],
  exports: [ ImportAuftraegeEditComponent ],
})
export class ImportAuftraegeEditModule {
}
