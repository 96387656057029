import { Component, Inject, OnInit } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { Sachverhalt } from '@models/dossier/dossier-models';
import { select, Store } from '@ngrx/store';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import { ValidationService } from 'app/core/services/validation/validation.service';
import { IAppState } from 'app/modules/shared-area/root-state';
import { EMPTY, Observable } from 'rxjs';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { BatchDucks } from 'app/modules/search-area/components/batch/ducks/batch.ducks';
import { isBusy, getSachverhaltModel, getServerValidationErrors } from './ducks/batch.selectors';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';

@Component({
  selector: 'app-batch-sachverhalt-create-dialog',
  templateUrl: './batch-sachverhalt-create-dialog.component.html'
})
export class BatchSachverhaltCreateDialogComponent implements OnInit {

  public isBusy$: Observable<boolean>;
  public sachverhaltModel$: Observable<Sachverhalt>;
  public emptyFields: string[];
  public serverValidationErrors$: Observable<IServerValidationErrors> = EMPTY;

  @Output()
  public closeWindow: EventEmitter<any | null> = new EventEmitter<any | null>();

  constructor(private store: Store<IAppState>,
    private validation: ValidationService,
    private toastyService: CustomToastyService,
    @Inject(BatchDucks) private batchDucks: Duck<BatchDucks>,
  ) {
    this.emptyFields = [];
  }

  ngOnInit() {
    this.isBusy$ = this.store.pipe(select(isBusy));
    this.sachverhaltModel$ = this.store.pipe(select(getSachverhaltModel));
    this.serverValidationErrors$ = this.store.pipe(select(getServerValidationErrors));
  }

  isFormValid(sachverhaltModel: Sachverhalt, message: string): boolean {
    const emptyMandatoryFieldCount = this.validation.checkForRequiredFields(sachverhaltModel.fields, sachverhaltModel.values).length;
    const invalidLookupFieldCount = this.validation.checkLookupFields(sachverhaltModel.fields, sachverhaltModel.values).length;
    const messageNotEmpty = message != null && message.length > 0;
    return emptyMandatoryFieldCount === 0 && invalidLookupFieldCount === 0 && messageNotEmpty;
  }

  create(sachverhaltModel: Sachverhalt, message: string) {
    const emptyRequiredFields = this.validation.checkForRequiredFields(
      sachverhaltModel.fields,
      sachverhaltModel.values
    );
    const invalidLookupFields = this.validation.checkLookupFields(
      sachverhaltModel.fields,
      sachverhaltModel.values
    );
    if (emptyRequiredFields.length === 0 && invalidLookupFields.length === 0 && message) {
      this.batchDucks.createSachverhalt([sachverhaltModel.values, message]);
    } else {
      this.emptyFields = emptyRequiredFields.concat(invalidLookupFields);
      if (emptyRequiredFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.notAllRequiredFieldsAreSet');
      }
      if (invalidLookupFields.length > 0) {
        this.toastyService.toastWarning('validation.titles.error', 'validation.messages.invalidLookupFieldValues');
      }
    }
  }

  public close() {
    this.closeWindow.emit(null);
  }
}
