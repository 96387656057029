import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: [ './page-header.component.scss' ],
})
export class PageHeaderComponent implements OnInit {
  /**
   * The page title as string
   */
  @Input() pageTitle: string;

  /**
   * Whether or not there should be some content before the pageTitle.
   * If set to true, use class "left-content" to wrap the content.
   */
  @Input() hasContentBeforeTitle = false;
  @Input() hasContentAfterTitle = false;

  @Input()
  public isLoading = false;

  constructor() { }

  ngOnInit() {
  }

}
