import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAdminAreaState } from 'app/modules/admin-area/admin-area.module';

const adminAreaState = createFeatureSelector<IAdminAreaState>('admin-area');
const configurationsPage = createSelector(adminAreaState, state => state.configurationsPage);
export const getConfigurations = createSelector(configurationsPage, state => state.configurations);
export const isConfigurationsBusy = createSelector(configurationsPage, state => state.isConfigurationBusy);
export const isConfigurationLoading = createSelector(configurationsPage, state => state.isConfigurationLoading);
export const selectedConfigurationName = createSelector(configurationsPage, state => state.selectedConfigurationName);
export const selectedConfiguration = createSelector(configurationsPage, state => state.selectedConfiguration);

export const getRankingAuftraege = createSelector(configurationsPage, state => state.rankingAuftraege);
export const isRankingAuftraegeLoading = createSelector(configurationsPage, state => state.isRankingAuftraegeLoading);

