import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { HasRightDirective } from 'app/shared/directives/hasright/hasright.directive';

@NgModule({
  imports: [ AuthModule ],
  declarations: [ HasRightDirective ],
  exports: [ HasRightDirective ]
})
export class HasrightModule {}
