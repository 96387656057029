import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiClient, BatchDeleteRequest, BatchResult, BatchAddFactRequest, BatchMode2, BatchMode, ObjectAttributeLock, BatchGetAttributeLocksRequest, BadRequestResultReason } from '../api-client';
import { ApiErrorHandlerService } from '../api-default-error-handler/api-error-handler.service';
import { SachverhaltSet, Sachverhalt } from '@models/dossier/dossier-models';
import { IServerValidationErrors } from '../validation/models/IServerValidationErrors';

@Injectable()
export class BatchService {

  constructor(
    private apiClient: ApiClient,
    private errorHandler: ApiErrorHandlerService) { }

  delete(ids: string[], message: string, mode: BatchMode2): Observable<BatchResult> {
    const payload = new BatchDeleteRequest({ objektIds: ids, message: message });
    return this.apiClient.apiStoffBatchLoeschenPost(mode, payload)
      .pipe
      (
        catchError(this.apiClient.apiStoffBatchLoeschenPostErrorHandlers({
          error401Handler: error401 => this.errorHandler.handle401Error(error401)
        }))
      );
  }

  getAddFactModel(merkmal: string): Observable<Sachverhalt> {
    return this.apiClient.apiStoffBatchNeuerSachverhaltGet(merkmal)
      .pipe
      (
        catchError(this.apiClient.apiStoffBatchNeuerSachverhaltGetErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        })),
        map(response => Sachverhalt.fromDto(response))
      );
  }

  getAddFactLocks(ids: string[]): Observable<ObjectAttributeLock[]> {
    const payload = new BatchGetAttributeLocksRequest({ objektIds: ids });
    return this.apiClient.apiStoffBatchMerkmalSperrenPost(payload)
    .pipe
    (
      catchError(this.apiClient.apiStoffBatchMerkmalSperrenPostErrorHandlers({
        error401Handler: error401 => this.errorHandler.handle401Error(error401)
      }))
    );
  }

  addFact(ids: string[], merkmal: string, message: string, values: SachverhaltSet, mode: BatchMode): Observable<BatchResult> {
    const payload = new BatchAddFactRequest({ objektIds: ids, message: message, input: values });
    return this.apiClient.apiStoffBatchNeuerSachverhaltPost(merkmal, mode, payload)
      .pipe
      (
        catchError(this.apiClient.apiStoffBatchNeuerSachverhaltPostErrorHandlers({
          error400Handler: error400 => this.errorHandler.handle400Error(error400),
          error401Handler: error401 => this.errorHandler.handle401Error(error401),
          error403Handler: error403 => this.errorHandler.handle403Error(error403)
        }))
      );
  }
}
