import { DossierApprovalHistoryItem, ISachverhaltBearbeiten, SachverhaltBearbeitenFieldBase } from 'app/core/services/api-client';

export class SachverhaltBearbeiten {
  Id: string;
  SachverhaltId?: number;
  ErfassungSachverhaltId?: number;
  Values: SachverhaltSet;

  constructor(id: string, sachverhaltId: number, erfassungSachverhaltId: number, values: SachverhaltSet) {
    this.Id = id;
    this.SachverhaltId = sachverhaltId;
    this.ErfassungSachverhaltId = erfassungSachverhaltId;
    this.Values = values;
  }
}

export class SachverhaltErstellen {
  Id: string;
  Merkmal: string;
  Values: SachverhaltSet;
  MerkmalHinzufuegenErneutOeffnen: boolean;

  constructor(id: string, merkmal: string, values: SachverhaltSet, merkmalHinzufuegenErneutOeffnen: boolean = false) {
    this.Id = id;
    this.Merkmal = merkmal;
    this.Values = values;
    this.MerkmalHinzufuegenErneutOeffnen = merkmalHinzufuegenErneutOeffnen;
  }
}

export class SachverhalteErstellen {
  id: string;
  merkmal: string;
  values: SachverhaltSet;
  message?: string;
}

export class AffectedDossier {
  id: string;
  name: string;
}

export class ApprovalHistoryItem {
  userName: string;
  message: string;
  date: Date;
  action: string;

  public static fromDto(dto: DossierApprovalHistoryItem): ApprovalHistoryItem {
    if (dto == null) {
      return null;
    }
    const vm = new ApprovalHistoryItem();
    vm.userName = dto.userName;
    vm.message = dto.message;
    vm.date = dto.date;
    vm.action = dto.action;
    return vm;
  }

}

export class DossierEintrag {
  name: string;
  type: string;
  items: (DossierEintragCategory | DossierEintragItem)[];
  hierarchieId: number;
  order: number;
  match?: boolean;
}

export class DossierEintragCategory {
  hierarchieId: number;
  order: number;
  name: string;
  type: string;
  items: DossierEintragItem[];
  match?: boolean;
}

export class DossierEintragItem {
  type: string;
  name: string;
  merkmalId: number;
  defaultViewType: string;
  order: number;
  fields: DossierEintragItemField[];
  facts: Fact[];
  match?: boolean;
  notDeletable?: boolean;
}

export class DossierEintragItemField {
  key: string;
  type: string;
  itemType: string;
  name: string;
  isAdmin: boolean;
  fields?: DossierEintragItemField[];
  match?: boolean;
  unit?: string;
}

export class Fact {
  values: Value[];
  sachverhaltId?: number;
  erfassungSachverhaltId?: number;
  state?: string;
  match?: boolean;
  requiresLecturerApproval?: boolean;
  requiresAdminApproval?: boolean;
  approvalClass: 'requiresLecturerApproval' | 'requiresAdminApproval';
  merkmalId: number;
  tags: string[];
  key?: string;
  selected?: boolean;
  selectedState?: string;
}

export class Value {
  [key: string]: any;

  fileName?: string;
  value?: any;
  state?: string;
  match?: boolean;
  type?: string;

  constructor(options: {
    value?: any,
    type?: string
  } = {}) {
    this.value = options.value;
    this.type = options.type || '';
  }
}

export class SachverhaltField {
  key: string;
  type: string;
  label: string;
  required?: boolean;
  multiple?: boolean;
  unit?: string;
  options?: string[];
  fields?: SachverhaltField[];
  table: string;
  multiline: boolean;
  isOpen: boolean;
  operators: string[];
  plausibility: string[];
  units: string[];
  minOperatorKey?: string;
  minValueKey?: string;
  minUnitKey?: string;
  maxOperatorKey?: string;
  maxValueKey?: string;
  maxUnitKey?: string;
  descriptionKey?: string;
  plausibilityKey?: string;
  stringValueKey?: string;
  hinweisText?: string;
  valueLength?: number;

  public static fromDto(dto: SachverhaltBearbeitenFieldBase): SachverhaltField {
    if (dto == null) {
      return null;
    }
    const vm = new SachverhaltField();
    vm.key = dto.key;
    vm.type = dto.type;
    vm.label = dto.label || '';
    vm.required = dto.required;
    vm.multiple = dto.multiple;
    vm.unit = dto.defaultUnit;
    vm.options = dto.options;
    vm.fields = (dto.fields && dto.fields.length > 0) ? dto.fields.map(field => SachverhaltField.fromDto(field)) : [];
    vm.table = dto.table;
    vm.multiline = dto.multiline;
    vm.isOpen = dto.isOpen;
    vm.operators = dto.operators;
    vm.plausibility = dto.plausibility;
    vm.units = dto.units;
    vm.minOperatorKey = dto.minOperatorKey;
    vm.minValueKey = dto.minValueKey;
    vm.minUnitKey = dto.minUnitKey;
    vm.maxOperatorKey = dto.maxOperatorKey;
    vm.maxValueKey = dto.maxValueKey;
    vm.maxUnitKey = dto.maxUnitKey;
    vm.descriptionKey = dto.descriptionKey;
    vm.plausibilityKey = dto.plausibilityKey;
    vm.stringValueKey = dto.stringValueKey;
    vm.hinweisText = dto.hinweisText;
    vm.valueLength = dto.valueLength;
    return vm;
  }
}

export class Sachverhalt {
  header: SachverhaltHeader;
  fields: SachverhaltField[];
  values: SachverhaltSet;
  merkmal: string;

  public static fromDto(dto: ISachverhaltBearbeiten): Sachverhalt {
    if (dto == null) {
      return null;
    }
    const vm = new Sachverhalt();
    vm.header = dto.header;
    vm.fields = dto.fields.map(field => SachverhaltField.fromDto(field));
    vm.values = dto.values;
    return vm;
  }

}

export class SachverhaltHeader {
  merkmal: string;
  merkmalName: string;
  hinweisText?: string;
}

export type SachverhaltPrimitiveValue = string | number | null;

export type SachverhaltSetEntry = SachverhaltPrimitiveValue | SachverhaltPrimitiveValue[] | SachverhaltSet[];

export class SachverhaltSet {
  [key: string]: SachverhaltSetEntry;
}

export function isSachverhaltSubset(value: SachverhaltSetEntry): value is SachverhaltSet[] {
  return Array.isArray(value);
}

export function isSachverhaltPrimitives(value: SachverhaltSetEntry): value is SachverhaltPrimitiveValue[] {
  return Array.isArray(value);
}

export function isSachverhaltPrimitive(value: SachverhaltSetEntry): value is SachverhaltPrimitiveValue {
  return !Array.isArray(value);
}

export class StofflisteStoffTreffer {
  sachverhaltId?: number;
  wertId?: number;
}

export class FavoriteMerkmal {
  key: string;
  displayName: string;
  timestamp?: Date;
  disabled?: boolean;
  message?: string;
}

export class FlatMerkmal {
  [key: string]: {
    id: number;
    allowedTypes: number[] | undefined;
  }
}

export interface DisplayTypes {
  [gsblId: string]: {
    tables: number[];
    lists: number[];
  };
}

export interface ApprovalRequiredFact {
  id: number;
  parentContId: string;
  admin: boolean;
  lecturer: boolean;
}
