import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { DynamicControlModule } from 'app/modules/dossier-area/components/dynamic-control/dynamic-control.module';
import { DynamicSachverhalteFormComponent } from 'app/modules/dossier-area/components/dynamic-sachverhalte-form/dynamic-sachverhalte-form.component';
import { SubsetFieldNameModule } from 'app/modules/dossier-area/pipes/subset-field-name/subset-field-name.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ValidationModule,
    DynamicControlModule,
    SubsetFieldNameModule,
    TooltipModule,
    MarkdownModule,
  ],
  declarations: [ DynamicSachverhalteFormComponent ],
  exports: [ DynamicSachverhalteFormComponent ]
})
export class DynamicSachverhalteFormModule {}
