import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { ImportAuftrag, ImportAuftragLogItem, Dossier, ErfassungListItem } from 'app/core/services/api-client';
import { ImportPageState } from './import-page.state';


@Ducksify<ImportPageState>({
  initialState: {
    importAuftraege: [],
    isImportAuftraegeBusy: false,
    lastModified: undefined,
    selectedImportAuftrag: null,
    downloadImportAuftrag: null,
    lieferanten: null,
    selectedImportAuftragLog: [],
    importErfassungen: [],
    isImportErfassungenBusy: false
  },
})
export class ImportPageDucks {
  public readonly getImportAuftraegeEffect = effect<void>('[ImportPage (Effect)] Load all Importauftraege');
  public readonly getImportErfassungenEffect = effect<void>('[ImportPage (Effect)] Load all Importerfassungen');
  public readonly updateImportAuftragEffect = effect<ImportAuftrag>('[ImportPage (Effect)] Update an Importauftrag');
  public readonly saveImportAuftragEffect = effect<ImportAuftrag>('[ImportPage (Effect)] Saves an Importauftrag');
  public readonly deleteImportAuftragEffect = effect<number>('[ImportPage (Effect)] Deletes an Importauftrag');
  public readonly getLieferantenEffect = effect<void>('[ImportPage (Effect)] Load Lieferanten');
  public readonly getImportFormateEffect = effect<void>('[ImportPage (Effect)] Load all import formats');
  public readonly getLogEffect = effect<number>('[ImportPage (Effect)] Load Log');
  public readonly dismissImportErfassungEffect = effect<number>('[ImportPage (Effect)] Importerfassung ausblenden');

  @Action('[ImportPage] Load all Importauftraege')
  public getAllImportAuftraege(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: true,
      lastModified: undefined
    };
  }

  @Action('[ImportPage] Load all Importauftraege (Success)')
  public getAllImportAuftraegeSuccess(state: ImportPageState, importAuftraege: ImportAuftrag[]): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
      importAuftraege: importAuftraege,
      lastModified: new Date()
    };
  }

  @Action('[ImportPage] Load all Importauftraege (Error)')
  public getAllImportAuftraegeError(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
    };
  }

  @Action('[ImportPage] Update a Importauftrag')
  public updateImportAuftrag(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: true,
    };
  }

  @Action('[ImportPage] Update a Importauftrag (Success)')
  public updateImportAuftragSuccess(state: ImportPageState, importAuftrag: ImportAuftrag): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
      importAuftraege: updateImportAuftrag(state.importAuftraege, importAuftrag),
    };
  }

  @Action('[ImportPage] Update a Importauftrag (Error)')
  public updateImportAuftragError(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
    };
  }

  @Action('[ImportPage] Add new Importauftrag')
  public addImportAuftrag(state: ImportPageState, name: string): typeof state {
    return {
      ...state,
      selectedImportAuftrag: addNewImportAuftrag(name)
    };
  }

  @Action('[ImportPage] Sets the selected Importauftrag')
  public setSelectedImportAuftrag(state: ImportPageState, importAuftrag: ImportAuftrag): typeof state {
    return {
      ...state,
      selectedImportAuftrag: importAuftrag,
    };
  }

  @Action('[ImportPage] Saves a Importauftrag')
  public saveImportAuftrag(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: true,
    };
  }

  @Action('[ImportPage] Saves a Importauftrag (Success)')
  public saveImportAuftragSuccess(state: ImportPageState, savedImportAuftrag: ImportAuftrag): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
      importAuftraege: [...state.importAuftraege, savedImportAuftrag],
      selectedImportAuftrag: null
    };
  }

  @Action('[ImportPage] Saves a Importauftrag (Error)')
  public saveImportAuftragError(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
    };
  }

  @Action('[ImportPage] Deletes a Importauftrag')
  public deleteImportAuftrag(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: true,
    };
  }

  @Action('[ImportPage] Deletes a Importauftrag (Success)')
  public deleteImportAuftragSuccess(state: ImportPageState, id: number): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
      importAuftraege: state.importAuftraege.filter(importAuftrag => importAuftrag.id !== id),
    };
  }

  @Action('[ImportPage] Deletes a Importauftrag (Error)')
  public deleteImportAuftragError(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportAuftraegeBusy: false,
    };
  }

  @Action('[ImportPage] Sets the selected Importauftrag for Download')
  public setImportAuftragDownload(state: ImportPageState, downloadImportAuftrag: ImportAuftrag): typeof state {
    return {
      ...state,
      downloadImportAuftrag: downloadImportAuftrag,
    };
  }




  @Action('[ImportPage] Load import formats')
  public getImportFormats(state: ImportPageState): typeof state {
    return {
      ...state,
      // isBusy: true,
    };
  }

  @Action('[ImportPage] Load import formats (Success)')
  public getImportFormatsSuccess(state: ImportPageState, importFormats: { [key: string]: string }): typeof state {
    return {
      ...state,
      lieferanten: importFormats,
      // isBusy: false,
    };
  }

  @Action('[ImportPage] Load import formats (Error)')
  public getImportFormatsError(state: ImportPageState): typeof state {
    return {
      ...state,
      // isBusy: false,
    };
  }

  @Action('[ImportPage] Load import formats')
  public getLieferanten(state: ImportPageState): typeof state {
    return {
      ...state,
      // isBusy: true,
    };
  }

  @Action('[ImportPage] Load Lieferanten (Success)')
  public getLieferantenSuccess(state: ImportPageState, lieferanten: { [key: string]: string }): typeof state {
    return {
      ...state,
      lieferanten: lieferanten,
      // isBusy: false,
    };
  }

  @Action('[ImportPage] Load Lieferanten (Error)')
  public getLieferantenError(state: ImportPageState): typeof state {
    return {
      ...state,
      // isBusy: false,
    };
  }

  @Action('[ImportPage] Load Log')
  public getLog(state: ImportPageState): typeof state {
    return {
      ...state,
      selectedImportAuftragLog: []
    };
  }

  @Action('[ImportPage] Load Log (Success)')
  public getLogSuccess(state: ImportPageState, log: ImportAuftragLogItem[]): typeof state {
    return {
      ...state,
      selectedImportAuftragLog: log,
    };
  }

  @Action('[ImportPage] Load Log (Error)')
  public getLogError(state: ImportPageState, log: ImportAuftragLogItem[]): typeof state {
    return {
      ...state,
      selectedImportAuftragLog: [],
    };
  }



  @Action('[ImportPage] Importauftrag ausblenden')
  public dismissImportErfassung(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportErfassungenBusy: true,
    };
  }

  @Action('[ImportPage] Importauftrag ausblenden (Success)')
  public dismissImportErfassungSuccess(state: ImportPageState, id: number): typeof state {
    return {
      ...state,
      isImportErfassungenBusy: false,
      importErfassungen: state.importErfassungen.filter(e => e.erfassungId !== id),
    };
  }

  @Action('[ImportPage] Importauftrag ausblenden (Error)')
  public dismissImportErfassungError(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportErfassungenBusy: false,
    };
  }



  @Action('[ImportPage] Load Erfassungen')
  public getImportErfassungen(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportErfassungenBusy: true
    };
  }

  @Action('[ImportPage] Load Erfassungen (Success)')
  public getImportErfassungenSuccess(state: ImportPageState, erfassungen: ErfassungListItem[]): typeof state {
    return {
      ...state,
      isImportErfassungenBusy: false,
      importErfassungen: erfassungen
    };
  }

  @Action('[ImportPage] Load Erfassungen (Error)')
  public getImportErfassungenError(state: ImportPageState): typeof state {
    return {
      ...state,
      isImportErfassungenBusy: false
    };
  }

}

function updateImportAuftrag(importAuftraege: ImportAuftrag[], update: ImportAuftrag): ImportAuftrag[] {
  const r = [...importAuftraege];
  const newImportAuftraege = r.filter(importAuftrag => importAuftrag.id !== update.id);
  newImportAuftraege.push(update);
  return newImportAuftraege;
}

function addNewImportAuftrag(name: string): ImportAuftrag {
  return new ImportAuftrag({
    lieferantId: 78 // fake
  });
}

export function importPageReducer(state: ImportPageState, action: DucksifiedAction): typeof state {
  return reducerFrom(ImportPageDucks)(state, action);
}
