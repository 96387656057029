import { Component, Inject, OnInit } from '@angular/core';
import { Duck } from '@ngrx-ducks/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { BatchDucks } from 'app/modules/search-area/components/batch/ducks/batch.ducks';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { isBusy } from './ducks/batch.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';

@Component({
  selector: 'app-batch-delete-dialog',
  templateUrl: './batch-delete-dialog.component.html'
})
export class BatchDeleteDialogComponent implements OnInit {

  public isBusy$: Observable<boolean>;

  @Output()
  public closeWindow: EventEmitter<any | null> = new EventEmitter<any | null>();

  constructor(private store: Store<IAppState>,
    @Inject(BatchDucks) private batchDucks: Duck<BatchDucks>,
  ) {
  }

  ngOnInit() {
    this.isBusy$ = this.store.pipe(select(isBusy));
  }

  create(message: string) {
    if (message) {
      this.batchDucks.delete(message);
    }
  }

  public close() {
    this.closeWindow.emit(null);
  }
}
