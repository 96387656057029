import { Action as DucksAction, Ducksify, reducerFrom } from '@ngrx-ducks/core';
import { IBatchState } from './batch.state';
import { BatchResult, ObjectAttributeLock } from 'app/core/services/api-client';
import { Sachverhalt, SachverhaltSet } from '@models/dossier/dossier-models';
import { IServerValidationErrors } from 'app/core/services/validation/models/IServerValidationErrors';
import { Action } from '@ngrx/store';

@Ducksify<IBatchState>({
  initialState: {
    isBusy: false,
    ids: null,
    locks: null,
    merkmal: null,
    request: null,
    result: null,
    sachverhaltModel: null,
    sachverhaltServerValidationErrors: {
      invalidFields: [],
      summary: [],
    },
  },
})
export class BatchDucks {
  @DucksAction('[Batch] Begin create Sachverhalt')
  beginCreateSachverhalt(state: IBatchState, ids: string[]): typeof state {
    return {
      ...state,
      isBusy: true,
      ids: ids,
      locks: null,
      request: null,
    };
  }

  @DucksAction('[Batch] Open SelectMerkmalDialog')
  openSelectMerkmalDialog(state: IBatchState, locks: ObjectAttributeLock[]): typeof state {
    return {
      ...state,
      locks: locks,
      isBusy: false,
    };
  }

  @DucksAction('[Batch] Merkmal selected, open BatchSachverhaltCreateDialog and load Sachverhalt model')
  merkmalSelectedOpenBatchSachverhaltCreateDialogAndLoadSachverhaltModel(state: IBatchState, merkmal: string): typeof state {
    return {
      ...state,
      isBusy: true,
      merkmal: merkmal,
      sachverhaltModel: null,
      sachverhaltServerValidationErrors: { invalidFields: [], summary: [] },
    };
  }

  @DucksAction('[Batch] Load Sachverhalt model (Success)')
  loadSachverhaltModelSuccess(state: IBatchState, sachverhaltModel: Sachverhalt): typeof state {
    return {
      ...state,
      isBusy: false,
      sachverhaltModel: sachverhaltModel,
    };
  }

  @DucksAction('[Batch] Create Sachverhalt')
  createSachverhalt(state: IBatchState, payload: [ SachverhaltSet, string ]): typeof state {
    return {
      ...state,
      isBusy: true,
      request: {
        type: 'AddFact',
        ids: state.ids,
        merkmal: state.merkmal,
        values: payload[ 0 ],
        message: payload[ 1 ],
      },
      result: null,
      sachverhaltServerValidationErrors: {
        invalidFields: [],
        summary: [],
      },
    };
  }

  @DucksAction('[Batch] Create Sachverhalt failed with validation errors')
  createSachverhaltFailedWithValidationErrors(state: IBatchState, validationErrors: IServerValidationErrors): typeof state {
    return {
      ...state,
      isBusy: false,
      sachverhaltServerValidationErrors: validationErrors,
    };
  }

  @DucksAction('[Batch] Begin delete')
  beginDelete(state: IBatchState, ids: string[]): typeof state {
    return {
      ...state,
      ids: ids,
      request: null,
    };
  }

  @DucksAction('[Batch] Open BatchDeleteDialog')
  openBatchDeleteDialog(state: IBatchState): typeof state {
    return {
      ...state,
    };
  }

  @DucksAction('[Batch] Delete')
  delete(state: IBatchState, message: string): typeof state {
    return {
      ...state,
      isBusy: true,
      request: {
        type: 'Delete',
        ids: state.ids,
        message: message,
      },
      result: null,
    };
  }

  @DucksAction('[Batch] Batch succeeded, open BatchDialog')
  batchSuccessOpenBatchDialog(state: IBatchState, result: BatchResult): typeof state {
    return {
      ...state,
      isBusy: false,
      result: result,
    };
  }

  @DucksAction('[Batch] Retry')
  retry(state: IBatchState, ignoreErrors: boolean): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @DucksAction('[Batch] Hide Batch Spinner')
  hideBatchSpinner(state: IBatchState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @DucksAction('[Batch] Retry (Success)')
  retrySuccess(state: IBatchState, result: BatchResult): typeof state {
    return {
      ...state,
      isBusy: false,
      result: result,
    };
  }

  @DucksAction('[Batch] Retry (Error)')
  retryError(state: IBatchState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }
}

export function batchReducer(state: IBatchState, action: Action): IBatchState {
  return reducerFrom(BatchDucks)(state, action);
}
