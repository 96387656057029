import { createSelector } from '@ngrx/store';
import { IAppState } from '../../root-state';

const sharedState = (state: IAppState) => state.shared;
const merkmalState = createSelector(sharedState, state => state.merkmale);

export const getLastSelectedMerkmal = createSelector(merkmalState, state => state.lastSelectedMerkmal);
export const getMerkmale = createSelector(merkmalState, state => state.merkmaleTree);
export const getFavoriteAttributes = createSelector(merkmalState, state => state.favoriteAttributes);
export const isLoaded = createSelector(merkmalState, state => state.apiState === 'Failed' || state.apiState === 'Loaded');
export const isMerkmaleSucessfullyLoaded = createSelector(merkmalState, state => state.apiState === 'Loaded');
