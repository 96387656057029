import { ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalService } from 'app/core/services/global/global.service';

@NgModule({})
export class GlobalModule {
  static forRoot(): ModuleWithProviders<GlobalModule> {
    return {
      ngModule: GlobalModule,
      providers: [ GlobalService ]
    };
  }
}
