import { ModuleWithProviders, NgModule } from '@angular/core';
import { CustomToastyService } from 'app/core/services/customToasty/custom-toasty.service';
import { I18nModule } from 'app/core/services/i18n/i18n.module';

@NgModule({
  imports: [ /*ToastaModule.forChild() */ I18nModule ]
})
export class CustomToastyModule {
  static forRoot(): ModuleWithProviders<CustomToastyModule> {
    return {
      ngModule: CustomToastyModule,
      providers: [ CustomToastyService ]
    };
  }
}
