<div class="k-numerictextbox maximize-input">
    <span class="k-numeric-wrap">
        <span class="k-input">
            <a [href]="url">{{fileName}}</a>
        </span>
        <span *ngIf="!hideClear" class="k-select">
            <span class="k-link" (click)="clear()">
                <span class="k-icon k-i-trash" style="top: 5px;"></span>
            </span>
        </span>
    </span>
</div>