import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UploadModule } from '@progress/kendo-angular-upload';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { CommonFeedbackComponent } from './common-feedback.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import { AutofocusDirectiveModule } from '../../directives/autoFocus/autofocus.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    TranslateModule,
    ValidationModule,
    DropDownsModule,
    UploadModule,
    IntlModule,
    AutofocusDirectiveModule,
    RouterModule,
  ],
  declarations: [ CommonFeedbackComponent ],
  exports: [ CommonFeedbackComponent ],
})
export class CommonFeedbackModule {}
