import { ModuleWithProviders, NgModule } from '@angular/core';
import { StorageService } from 'app/core/services/storage/storage.service';

@NgModule({
  imports: []
})
export class StorageModule {
  static forRoot(): ModuleWithProviders<StorageModule> {
    return {
      ngModule: StorageModule,
      providers: [ StorageService ]
    };
  }
}
