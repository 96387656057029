import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { EditLookupTableComponent } from './edit-lookup-table.component';
import { HasrightModule } from 'app/shared/directives/hasright/hasright.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ButtonModule } from '@progress/kendo-angular-buttons';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    StoreModule,
    ValidationModule,
    I18nModule,
    TranslateModule,
    WindowModule,
    FormsModule,
    HasrightModule,
    DropDownsModule,
    IntlModule,
    ButtonModule
  ],
  declarations: [ EditLookupTableComponent ],
  exports: [ EditLookupTableComponent ],
})
export class EditLookupTableModule {}
