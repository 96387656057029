<!-- {{selectedRankingStep | json}}
{{selectedKey}} -->
<ng-container *ngIf="selectedKey">
  <h3>{{'components.configurationRankingStep.ruleSet' | translate}} <span style="font-style: italic; font-size: small;">({{selectedKey}})</span></h3>
  <div class="k-widget" style="padding: 20px;">
  <table class="table">
      <tr *ngFor="let step of selectedRankingStep; let tableIndex = index; last as lastTable" style="margin-top: 30px;">
        <td>
          <input type="checkbox" class="k-checkbox" id="cb_first-or-default__{{tableIndex}}" [(ngModel)]="step.firstOrDefault">
          <label for="cb_first-or-default__{{tableIndex}}" class="k-checkbox-label">{{'components.configurationRankingStep.firstOrDefault' | translate}}</label>
          <table class="table" style="width: 100%;">
            <thead class="header">
              <tr>
                <td scope="col">{{'components.configurationRankingStep.ruleName' | translate}}</td>
                <td scope="col" style="width: 75px; text-align:center;">{{'components.configurationRankingStep.isConditional' | translate}}
                </td>
                <td scope="col" style="width: 75px;">
                  <button class="k-button k-button-icontext" name="btn_delete-cascade_{{tableIndex}}" type="button"
                    (click)="deleteTable(tableIndex)">
                    <i class="k-icon k-i-trash"></i> {{'action.deleteCascade' | translate}}
                  </button>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rule of step.steps; index as rowIndex">
                <td>
                    <kendo-dropdownlist [data]="rankingRulesListItems" 
                    [textField]="'text'" [valueField]="'value'" valuePrimitive="true"
                    [(ngModel)]="rule.ruleName" style="width: 100%;" name="ddl_rule-name"
                    [defaultItem]="defaultRankingRuleListItem"></kendo-dropdownlist>
                </td>
                <td style="text-align: center;">
                  <input id="cb_{{tableIndex}}_{{rowIndex}}" type="checkbox" class="k-checkbox" [(ngModel)]="rule.isConditional" [ngModelOptions]="{standalone: true}">
                  <label for="cb_{{tableIndex}}_{{rowIndex}}" class="k-checkbox-label"></label>
                  
                </td>
                <td>
                  <button *ngIf="rowIndex !== 0" class="k-button k-button-icontext" name="btn_delete-row_{{tableIndex}}_{{rowIndex}}" type="button"
                    (click)="deleteRow(tableIndex, rowIndex)">
                    <i class="k-icon k-i-trash"></i> {{'action.deleteRow' | translate}}
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3">
                  <button class="k-button k-button-icontext" id="btn_add-row_{{tableIndex}}" type="button"
                    (click)="addRow(tableIndex)">
                    <i class="k-icon k-i-plus"></i> {{'action.addRow' | translate}}
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </td>
      </tr>
  </table>
  <table class="table">
    <tr>
      <td>
        <button class="k-button k-button-icontext" id="btn_add-cascade" type="button" (click)="addTable()">
          <i class="k-icon k-i-plus"></i> {{'action.addRuleCascade' | translate}}
        </button>
      </td>
    </tr>
  </table>
</div>
</ng-container>

