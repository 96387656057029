<div [ngSwitch]="field.type">

       <ng-container *ngSwitchCase="'string'">
              <input *ngIf="!field.multiline" kendoTextBox [ngClass]="{'maximize-input': maximizeWidth}" [value]="value || ''"
                     (blur)="textValueChange.emit({value: $event, fieldKey: field.key })" [title]="field.key"
                     [attr.maxlength]="field.valueLength"
                     >
              <textarea *ngIf="field.multiline === true" rows="5" class="k-textarea"
                     style="display:block; width:100%; resize: none" [attr.maxlength]="field.valueLength"
                     [value]="value || ''" (blur)="textValueChange.emit({value: $event, fieldKey: field.key })" [title]="field.key"></textarea>
       </ng-container>

       <app-range-field *ngSwitchCase="'range'" [field]="field" [minOperator]="optionValues[field.minOperatorKey]"
              [minValue]="optionValues[field.minValueKey]" [minUnit]="optionValues[field.minUnitKey]" [maxOperator]="optionValues[field.maxOperatorKey]"
              [maxValue]="optionValues[field.maxValueKey]" [maxUnit]="optionValues[field.maxUnitKey]" [description]="optionValues[field.descriptionKey]"
              [plausibility]="optionValues[field.plausibilityKey]" [stringValue]="optionValues[field.stringValueKey]"
              [stringValueLength]="253" [stringDescriptionLength]="253"
              (onRangeChanged)="rangeChanged.emit({ value: $event, fieldKey: field.key })">
       </app-range-field>
       <input *ngSwitchCase="'int'" kendoTextBox [ngClass]="{'maximize-input': maximizeWidth}" [value]="value || ''"
              pattern="[0-9]*" maxlength="10" (keyup)="validateNumber($event)" (change)="intValueChangeHandler($event.target.value, field.key)" [title]="field.key" />

       <kendo-numerictextbox *ngSwitchCase="'double'" [ngClass]="{'maximize-input': maximizeWidth}" [value]="value"
              [autoCorrect]="'true'" [format]="'##.00'" (valueChange)="doubleValueChange.emit({ value: $event, fieldKey: field.key })" [title]="field.key">
       </kendo-numerictextbox>

       <kendo-datepicker *ngSwitchCase="'datetime'" [ngClass]="{'maximize-input': maximizeWidth}" [value]="StringAsDate(value)"
              (valueChange)="dateChange.emit({ value: $event, fieldKey: field.key })">
              <kendo-datepicker-messages [today]="'datepicker.messages.today' | translate" [toggle]="'datepicker.messages.toggle' | translate" [title]="field.key">
              </kendo-datepicker-messages>
       </kendo-datepicker>

       <ng-container *ngSwitchCase="'lookup'">
              <kendo-autocomplete #autocomplete [ngClass]="{'maximize-input': maximizeWidth}" [data]="field.options"
                     [placeholder]="'components.searchField.placeholderAutocomplete' | translate" [value]="value"
                     class="input-group"
                     [title]="field.table"
                     (valueChange)="dropdownValueChange.emit({value: $event, fieldKey: field.key})" (focus)="autocomplete.toggle(true)">
              </kendo-autocomplete>
              <span *ngIf="feedbackButtonIsVisible(field)" class="k-button" style="margin-left: -35px" (click)="openFeedbackWindow(field.table, value)" title="{{ 'components.lookup.actions.request' | translate}}">
                     <i class="fas fa-comment"></i>
              </span>
       </ng-container>

       <app-file-link *ngSwitchCase="'file'" [value]="value" (valueChange)="fileLinkChanged.emit({ value: $event, fieldKey: field.key })" [title]="field.key">
       </app-file-link>

       <app-file-link *ngSwitchCase="'image'" [value]="value" (valueChange)="fileLinkChanged.emit({ value: $event, fieldKey: field.key })" [title]="field.key">
       </app-file-link>

</div>
