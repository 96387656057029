import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UploadModule } from '@progress/kendo-angular-upload';
import { I18nModule } from 'app/core/services/i18n/i18n.module';
import { ValidationModule } from 'app/core/services/validation/validation.module';
import { StoffFeedbackComponent } from './stoff-feedback.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import { RouterModule } from '@angular/router';
import { AutofocusDirectiveModule } from '../../directives/autoFocus/autofocus.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    I18nModule,
    ValidationModule,
    TranslateModule,
    DropDownsModule,
    UploadModule,
    IntlModule,
    RouterModule,
    AutofocusDirectiveModule,
  ],
  declarations: [ StoffFeedbackComponent ],
  exports: [ StoffFeedbackComponent ],
})
export class StoffFeedbackModule {
}
