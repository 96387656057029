import { Action, DucksifiedAction, Ducksify, effect, reducerFrom } from '@ngrx-ducks/core';
import { ExportAuftrag } from 'app/core/services/api-client';
import { ExportAuftrageState } from './export-auftraege.state';

@Ducksify<ExportAuftrageState>({
  initialState: {
    exportAuftraege: [],
    isBusy: false,
    lastModified: undefined,
    selectedExportAuftrag: null,
    rankingTags: [],
    downloadExportAuftrag: null,
    exportFormats: null
  },
})
export class ExportAuftraegeDucks {
  public readonly getAllExportAuftraegeEffect = effect<void>('[Exportauftraege (Effect)] Load all Exportauftraege');
  public readonly updateExportAuftragEffect = effect<ExportAuftrag>('[Exportauftraege (Effect)] Update a Exportauftrag');
  public readonly saveExportAuftragEffect = effect<ExportAuftrag>('[Exportauftraege (Effect)] Saves a Exportauftrag');
  public readonly deleteExportAuftragEffect = effect<number>('[Exportauftraege (Effect)] Deletes a Exportauftrag');
  public readonly getRankingTagsEffect = effect<void>('[Exportauftraege (Effect)] Load all ranking tags');
  public readonly getExportFormateEffect = effect<void>('[Exportauftraege (Effect)] Load all export formats');

  @Action('[Exportauftraege] Load all Exportauftraege')
  public getAllExportAuftraege(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: true,
      lastModified: undefined
    };
  }

  @Action('[Exportauftraege] Load all Exportauftraege (Success)')
  public getAllExportAuftraegeSuccess(state: ExportAuftrageState, exportAuftraege: ExportAuftrag[]): typeof state {
    return {
      ...state,
      isBusy: false,
      exportAuftraege: exportAuftraege,
      lastModified: new Date()
    };
  }

  @Action('[Exportauftraege] Load all Exportauftraege (Error)')
  public getAllExportAuftraegeError(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Exportauftraege] Update a Exportauftrag')
  public updateExportAuftrag(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Exportauftraege] Update a Exportauftrag (Success)')
  public updateExportAuftragSuccess(state: ExportAuftrageState, exportAuftrag: ExportAuftrag): typeof state {
    return {
      ...state,
      isBusy: false,
      exportAuftraege: updateExportAuftrag(state.exportAuftraege, exportAuftrag),
    };
  }

  @Action('[Exportauftraege] Update a Exportauftrag (Error)')
  public updateExportAuftragError(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Exportauftraege] Add new Exportauftrag')
  public addExportAuftrag(state: ExportAuftrageState, name: string): typeof state {
    return {
      ...state,
      selectedExportAuftrag: addNewExportAuftrag(name)
    };
  }

  @Action('[Exportauftraege] Sets the selected Exportauftrag')
  public setSelectedExportAuftrag(state: ExportAuftrageState, exportAuftrag: ExportAuftrag): typeof state {
    return {
      ...state,
      selectedExportAuftrag: exportAuftrag,
    };
  }

  @Action('[Exportauftraege] Saves a Exportauftrag')
  public saveExportAuftrag(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Exportauftraege] Saves a Exportauftrag (Success)')
  public saveExportAuftragSuccess(state: ExportAuftrageState, savedExportAuftrag: ExportAuftrag): typeof state {
    return {
      ...state,
      isBusy: false,
      exportAuftraege: [...state.exportAuftraege, savedExportAuftrag],
      selectedExportAuftrag: null
    };
  }

  @Action('[Exportauftraege] Saves a Exportauftrag (Error)')
  public saveExportAuftragError(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }

  @Action('[Exportauftraege] Deletes a Exportauftrag')
  public deleteExportAuftrag(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: true,
    };
  }

  @Action('[Exportauftraege] Deletes a Exportauftrag (Success)')
  public deleteExportAuftragSuccess(state: ExportAuftrageState, id: number): typeof state {
    return {
      ...state,
      isBusy: false,
      exportAuftraege: state.exportAuftraege.filter(exportAuftrag => exportAuftrag.id !== id),
    };
  }

  @Action('[Exportauftraege] Deletes a Exportauftrag (Error)')
  public deleteExportAuftragError(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      isBusy: false,
    };
  }


  @Action('[Exportauftraege] Load ranking tags')
  public getRankingTags(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      // isBusy: true,
    };
  }

  @Action('[Exportauftraege] Load ranking tags (Success)')
  public getRankingTagsSuccess(state: ExportAuftrageState, rankingTags: string[]): typeof state {
    return {
      ...state,
      rankingTags: rankingTags,
      // isBusy: false,
    };
  }

  @Action('[Exportauftraege] Load ranking tags (Error)')
  public getRankingTagsError(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      // isBusy: false,
    };
  }


  @Action('[Exportauftraege] Sets the selected Exportauftrag for Download')
  public setExportAuftragDownload(state: ExportAuftrageState, downloadExportAuftrag: ExportAuftrag): typeof state {
    return {
      ...state,
      downloadExportAuftrag: downloadExportAuftrag,
    };
  }




  @Action('[Exportauftraege] Load export formats')
  public getExportFormats(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      // isBusy: true,
    };
  }

  @Action('[Exportauftraege] Load export formats (Success)')
  public getExportFormatsSuccess(state: ExportAuftrageState, exportFormats: {[key: string]: string}): typeof state {
    return {
      ...state,
      exportFormats: exportFormats,
      // isBusy: false,
    };
  }

  @Action('[Exportauftraege] Load export formats (Error)')
  public getExportFormatsError(state: ExportAuftrageState): typeof state {
    return {
      ...state,
      // isBusy: false,
    };
  }




}

function updateExportAuftrag(exportAuftraege: ExportAuftrag[], update: ExportAuftrag): ExportAuftrag[] {
  const r = [...exportAuftraege];
  const newExportAuftraege = r.filter(exportAuftrag => exportAuftrag.id !== update.id);
  newExportAuftraege.push(update);
  return newExportAuftraege;
}

function addNewExportAuftrag(name: string): ExportAuftrag {
  return new ExportAuftrag({
    name: name,
    description: ''
  });
}

export function exportAuftraegeReducer(state: ExportAuftrageState, action: DucksifiedAction): typeof state {
  return reducerFrom(ExportAuftraegeDucks)(state, action);
}
