import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ThesaurusRule } from 'app/core/services/api-client';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectedThesaurusRule } from '../../pages/thesaurus-rules-page/ducks/thesaurus-rules.selectors';
import { IAppState } from 'app/modules/shared-area/root-state';
import { ThesaurusRulesDucks } from '../../pages/thesaurus-rules-page/ducks/thesaurus-rules.ducks';
import { Duck } from '@ngrx-ducks/core';
import { WindowDucks } from 'app/modules/shared-area/ducks/window/window.ducks';

@Component({
  selector: 'app-thesaurus-rules-edit',
  templateUrl: './thesaurus-rules-edit.component.html',
  styleUrls: ['./thesaurus-rules-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThesaurusRulesEditComponent implements OnInit {

  public rule: ThesaurusRule;
  public selectedThesaurusRule$: Observable<ThesaurusRule>;
  public form: FormGroup;
  public ruleRegex: RegExp = /^\*\.\(([^)]*)\)=\*\.\(([^)]*)\)(:\*\.\(([^)]*)\)=\*\.\(([^)]*)\))?$/;
  constructor(
    private fb: FormBuilder,
    private store: Store<IAppState>,
    @Inject(ThesaurusRulesDucks) private thesaurusRuleDucks: Duck<ThesaurusRulesDucks>,
    @Inject(WindowDucks) private windowDucks: Duck<WindowDucks>,
  ) {
  }

  ngOnInit() {
    this.form = this.createGroup();
    this.selectedThesaurusRule$ = this.store.pipe(select(selectedThesaurusRule));
    this.selectedThesaurusRule$.subscribe(rule => {
      this.rule = rule;
      if (rule) {
        this.setValues(rule);
      }
    });
  }

  private createGroup() {
    const group = this.fb.group({
      rule: [null, { validators: [Validators.required, Validators.pattern(this.ruleRegex)], updateOn: 'blur' }],
      name: [null, { validators: Validators.required, updateOn: 'blur' }],
      keys: [null, { validators: Validators.required, updateOn: 'blur' }],
      sourceQuery: [null, { validators: Validators.required, updateOn: 'blur' }],
      intermediateQuery: [null, { updateOn: 'blur' }],
      targetQuery: [null, { validators: Validators.required, updateOn: 'blur' }],
      description: [null, { updateOn: 'blur' }],
      active: [null],
      isNew: ['true', { validators: Validators.required, updateOn: 'blur' }],
    });
    return group;
  }

  private setValues(rule: ThesaurusRule): void {
    const values = this.flattenThesaurusRule(rule);
    const ctrls = this.form.controls;
    for (const key of Object.keys(ctrls)) {
      ctrls[key].setValue(values[key]);
    }
    ctrls['isNew'].setValue(rule.name === '[new]');
  }

  private flattenThesaurusRule(rule: ThesaurusRule): { [key: string]: string; } {
    const result = {};
    for (const key of Object.keys(rule)) {
      result[key] = rule[key];
    }
    return result;
  }

  private createThesaurusRule(values: { [key: string]: string }): ThesaurusRule {
    const result = new ThesaurusRule({
      name: values['name'],
      rule: values['rule'],
      description: values['description'],
      sourceQuery: values['sourceQuery'],
      intermediateQuery: values['intermediateQuery'],
      targetQuery: values['targetQuery'],
      active: !!values['active']
      // merkmalDisplayName: values['merkmalDisplayName']
    });
    result.keys = [];
    if (!_.isNil(values['keys'])) {
      const keys = values['keys'].toString().split(',').map(value => value.trim());
      if (_.isArray(keys)) {
        keys.forEach(field => {
          result.keys.push(field);
        });
      }
    }
    return result;
  }

  public submit() {
    const ctrls = this.form.controls;
    const payload = {};
    for (const key of Object.keys(ctrls)) {
      payload[key] = ctrls[key].value;
    }
    const rule = this.createThesaurusRule(payload);
    if (!!ctrls['isNew'].value) {
      this.thesaurusRuleDucks.saveThesaurusRuleEffect.dispatch(rule);
    } else {
      this.thesaurusRuleDucks.updateThesaurusRuleEffect.dispatch(rule);
    }
  }

  public cancel() {
    this.windowDucks.closeWindow.dispatch();
  }

  public get isNew() {
    return _.isNil(this.rule) || (this.rule.name && this.rule.name === '[new]');
  }
}
