import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { AuthInterceptor } from 'app/core/interceptors/auth.interceptor';

@NgModule({
  imports: [AuthModule],
})
export class AuthInterceptorModule {
  public static forRoot(): ModuleWithProviders<AuthInterceptorModule> {
    return {
      ngModule: AuthInterceptorModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        }
      ]
    };
  }
}
